import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { Header, ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { ConfigurationService } from '../../services/configuration.service';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { LoggerService } from '../../services/logger.service';
import { Customer } from '../../models/consumer';
import { CONSUMER_PORTAL_APP_LANG } from '../../languages';
import { SelectOptions } from '../../shared/components/base-select/base-select.component';
import { TranslateService } from '@ngx-translate/core';
import { HamburgerItem } from '../../models/hamburger-model';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LocalStorageKeys } from '../../enums/local-storage-keys.enum';
import { DEFAULT_LOCALE } from '../../constants/default.const';
import { AppFacadeService } from '../../services/app-facade.service';
import { AppMediatorService } from '../../services/app-mediator.service';
@Component({
    selector: 'por-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
    customerId!: string;

    constructor(
        @Inject(CONSUMER_PORTAL_APP_LANG) languageOptions: SelectOptions[],
        private readonly configService: ConfigurationService,
        private readonly logger: LoggerService,
        private readonly translateService: TranslateService,
        private readonly breakpointObserver: BreakpointObserver,
        readonly appFacadeService: AppFacadeService,
        private readonly appMediatorService: AppMediatorService
    ) {
        this.languageOptions = languageOptions;
    }

    languageOptions: SelectOptions[];
    inputs: ConsumerPortalConfig | undefined;
    logo = '';
    uiUrl: string | undefined;
    header!: Header | undefined;
    @Input() config = '';
    subscriptions: Subscription[] = [];
    @Input() contractId: string | undefined;
    @Input() width: string | undefined;
    @Input() height: string | undefined;
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    @Output() readonly appOutput: EventEmitter<{ [key: string]: object }> = new EventEmitter<{ [key: string]: object }>();
    title: string | undefined;
    visible = true;
    @Input() logoPosition: Position | string | undefined;
    @Input() isLoginButtonVisible = true;
    logoWidth!: string;
    @Output() readonly logout = new EventEmitter();

    @Output() readonly selectedLocale = new EventEmitter<string>();
    @Input() customer!: Customer | null;
    hamburgerItems: HamburgerItem[] = [
        {
            label: 'Home',
            page: ComponentsToLoad.AccountSummary
        },
        {
            label: 'Contracts',
            page: ComponentsToLoad.Contracts
        },
        {
            label: 'Invoices',
            page: ComponentsToLoad.Invoices
        },
        {
            label: 'Items Out',
            page: ComponentsToLoad.ItemsOut
        },
        {
            label: 'Tracking',
            page: ComponentsToLoad.Tracking
        }
    ];

    selectedLanguageOption!: string;
    isMobile = false;

    ngOnInit(): void {
        this.subscriptions.push(
            this.breakpointObserver.observe(['(max-width: 992px)']).subscribe((result: BreakpointState) => {
                this.isMobile = result.matches;
            })
        );
        const storedLanguage = this.appMediatorService.localStorageService.getItem(LocalStorageKeys.ContentLang)?.toString();
        this.selectedLanguageOption = storedLanguage != null ? storedLanguage : DEFAULT_LOCALE;
        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
            this.logger.logAssert(this.inputs?.customerId, 'customerId is not defined');
            this.customerId = this.inputs?.customerId || '0';
            this.header = this.inputs?.header as Header;
            this.title = this.header.headerTitle;
        }
        if (this.inputs?.header?.logoUrl && this.showHeaderSection()) {
            this.logo = this.inputs?.header?.logoUrl;
        }
        if (this.inputs?.header?.logoWidth) {
            this.logoWidth = this.inputs?.header?.logoWidth;
        }
        this.elementLoaded.emit(true);
        this.hamburgerItems = this.hamburgerItems.filter((item: HamburgerItem) => {
            if (item?.page === ComponentsToLoad.Invoices && !this.appFacadeService.featureToggleService.isAvailable('invoices')) {
                return false;
            }
            if (item?.page === ComponentsToLoad.Tracking && !this.appFacadeService.featureToggleService.isAvailable('trackingTab')) {
                return false;
            }
            return true;
        });
        this.uiUrl = this.inputs?.uiUrl;
    }
    setHomePageTab(): void {
        if (!this.appFacadeService.versionToggleService.isQuickLinkVersion()) {
            this.appFacadeService.setActiveTab(ComponentsToLoad.AccountSummary);
            this.appFacadeService.updateLocation(ComponentsToLoad.AccountSummary);
        }
    }

    changePage(tab: ComponentsToLoad | null): void {
        if (tab) {
            this.appFacadeService.setActiveTab(tab);
        }
    }

    handleSelectedLocale(value: string): void {
        this.appMediatorService.localStorageService.setItem(LocalStorageKeys.ContentLang, value);
        this.translateService.use(value ? value : DEFAULT_LOCALE);
    }

    showHeaderSection(): boolean {
        return (
            !(!this.appFacadeService.featureToggleService.isAvailable('showHeaderSections') && !this.appFacadeService.versionToggleService.isQuickLinkVersion()) ||
            this.appFacadeService.featureToggleService.isAvailable('showHeaderSections')
        );
    }

    openConsumerPortal(): void {
        if (this.appFacadeService.versionToggleService.isQuickLinkPdfExternalPortal() && this.header?.exterConsumerPortalUrl) {
            window.open(this.header?.exterConsumerPortalUrl, '_blank');
        } else {
            this.logout.emit();
        }
    }

    showLoginMoreInfoButton(): boolean {
        return this.appFacadeService.featureToggleService.isAvailable('canHaveQuickLinkLoginButton') && this.appFacadeService.versionToggleService.isQLButNotConsumerQL();
    }

    onClose(): void {
        this.visible = !this.visible;
    }

    openModal(): void {
        this.visible = true;
    }
}

export enum Position {
    Left = 'left',
    Right = 'right',
    Center = 'center'
}
