import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToggleValueFormat } from '../../../models/base-button-toggle';
@Component({
    selector: 'por-base-button-toggle',
    templateUrl: './base-button-toggle.component.html',
    styleUrls: ['./base-button-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseButtonToggleComponent {
    @Input() currentValue: unknown | undefined;
    @Output() readonly onclick = new EventEmitter<unknown>();
    @Input() values: ToggleValueFormat[] = [];
    @Input() class: string | undefined;
    @Input() featureName = '';

    toggleHandler(event: { value: string }) {
        this.onclick.emit(event?.value);
        this.currentValue = event?.value;
    }
}
