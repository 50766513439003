/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: DB model
export class UserResponse {

  Id?: string;

  Email!: string;

  VerificationCode?: string;

  VerificationCodeExpirationDateTime?: Date;

  PasswordExpirationDateTime?: Date;

  OrganizationId?: string;
}