<div class="por-busy-indicator">
  <p class="theme-paragraph">{{message? message: ('We are looking for your records linked to your email.' | translate)}}</p>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
