<div class="portalnav-wrap por-ac-invoices">
    <div class="portalnav wrapper" [ngStyle]="{ height: height, width: width }" class="wrapper">
        <div id="mainportalpage" class="main-invoice-page">
            <h1 class="theme-heading">{{ 'Invoices' | translate | uppercase }}</h1>
            <div class="date-range">
                <por-filters
                    [initFilters]="initialStatusFilters"
                    [isProcessing]="(appFacadeService.getLoader() | async) || false"
                    [searchPlaceholder]="filterComponentProps.searchPlaceHolder"
                    [dateLabel]="filterComponentProps.dateLabel"
                    (filterOutput)="filter($any($event))"
                    (exportCSV)="exportCSV()"
                    [uiUrl]="uiUrl"
                    [enableExport]="$any(invoices$ | async | count) > 0"
                    [startDateLabel]="'From'"
                    [endDateLabel]="'To'"></por-filters>
            </div>
            <div class="content-wrapper">
                <div class="amount-due-wrap" *ngIf="invoiceTotalAmountDue$ | async as invoiceTotalAmountDue" [ngStyle]="applyWidthStyle">
                    <span>{{ 'TotalDue' | translate }} : {{ invoiceTotalAmountDue | formatCurrency }}</span>
                </div>

                <ag-grid-angular
                    *ngIf="gridReadyObservable$ | async"
                    style="width: 100%; height: 100%"
                    class="ag-theme-alpine ag-grid"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowData]="invoices$ | async"
                    [animateRows]="true"
                    [rowHeight]="rowHeight"
                    [headerHeight]="headerHeight"
                    (gridReady)="onGridReady($event)"
                    [isFullWidthRow]="isFullWidthRow"
                    [fullWidthCellRenderer]="fullWidthCellRenderer"
                    [context]="context"
                    [suppressRowClickSelection]="true"
                    (cellClicked)="onCellClicked($event)"></ag-grid-angular>
            </div>
            <div class="middle">
                <por-base-button [class]="'theme-button'" *ngIf="(noMoreRecord$ | async) === false" (onclick)="loadMore()" [featureName]="'load-more-invoice'">
                    {{ 'Load More' | translate }}
                </por-base-button>
            </div>
        </div>
    </div>
</div>
<por-base-modal [id]="'contract-detail-page'" (onclose)="isDetailPageOpened = false">
    <por-contract-detail *ngIf="isDetailPageOpened" [contractDetail]="contractDetail" [uiUrl]="inputs?.uiUrl" [customerId]="customerId"></por-contract-detail>
</por-base-modal>
<por-base-modal [id]="'invoice-detail-page'" (onclose)="isInvoiceDetailPageOpened = false">
    <por-invoice-detail *ngIf="isInvoiceDetailPageOpened" [invoiceDetail]="invoiceDetail"></por-invoice-detail>
</por-base-modal>
