<div class="portalnav-wrap">
    <div class="portalnav">
        <div class="arrow-back">
            <por-base-button theme="primary" (onclick)="return()" class="p-0" [featureName]="'close-item-button'">
                <mat-icon>close</mat-icon>
            </por-base-button>
        </div>
        <h1 class="align-items-center d-inline-flex">
            <img class="header-image" src="{{ uiUrl }}/assets/images/noun-return-4960178.svg" width="40" />
            {{ 'Request Call Off' | translate }}
        </h1>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <th>{{ 'SerialNumber' | translate }}</th>
                    <th>{{ 'itemName' | translate }}</th>
                    <th>{{ 'Contract #' | translate }}</th>
                    <th>{{ 'Contract Date' | translate }}</th>
                    <th>{{ 'dueDate' | translate }}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items">
                        <td>{{ item?.serialNumber ? item?.serialNumber : '&nbsp;' }}</td>
                        <td>{{ item.itemName }}</td>
                        <td>{{ item.contractId }}</td>
                        <td>{{ item.startDate | date : dateFormat }}</td>
                        <td>{{ item.dueDate | date : dateFormat }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <form class="form-wrapper mt-2" [formGroup]="returnForm" porAddFeature featureName="request-off-hire-form">
            <por-accordion>
                <por-accordion-group title="{{ 'Pickup Request' | translate }}">
                    <div class="form-group mt-2">
                        <div class="col-2">
                            <label>
                                {{ 'Date' | translate }}
                                <span class="star-err">*</span>
                            </label>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    (focus)="picker.open()"
                                    formControlName="requestedDate"
                                    [min]="minDate"
                                    required
                                    porAddFeature
                                    featureName="calloff-date-input" />
                                <mat-datepicker-toggle matSuffix [for]="picker" porAddFeature featureName="picker-calloff-datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div
                                class="error flex mt-xs px-xs ng-star-inserted"
                                *ngIf="requestedDate?.invalid &&
								(requestedDate?.dirty || requestedDate?.touched) &&
								(requestedDate?.errors?.['required'] || requestedDate?.errors?.['futureDate'])">
                                <div class="flex items-center justify-center text-error ng-star-inserted">
                                    <i class="flex items-center mr-xs w-xs max-h-xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="fill-current" style="width: 16px; height: 16px">
                                            <use href="assets/symbols.svg#info"></use>
                                        </svg>
                                    </i>
                                    <p class="p-none m-none">{{ 'InvalidDate' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label>
                                {{ 'Time' | translate }}
                                <span class="star-err">*</span>
                            </label>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    matTimepicker
                                    [ngxMatTimepicker]="timepicker"
                                    class="mat-time-picker-custom"
                                    [format]="24"
                                    [required]="true"
                                    placeholder="{{ 'Choose a time' | translate }}"
                                    formControlName="requestedTime"
                                    [required]="true"
                                    porAddFeature
                                    featureName="calloff-time-input"
                                    (click)="openTimePicker()"
                                    [value]="currentTime" />
                                <ngx-mat-timepicker #timepicker></ngx-mat-timepicker>
                                <mat-icon matSuffix (click)="openTimePicker()">access_time</mat-icon>
                            </mat-form-field>
                            <div
                                class="error flex mt-xs px-xs ng-star-inserted"
                                *ngIf="requestedTime?.invalid &&
								(requestedTime?.dirty || requestedTime?.touched) &&
								requestedTime?.errors?.['required'] ">
                                <div class="flex items-center justify-center text-error ng-star-inserted">
                                    <i class="flex items-center mr-xs w-xs max-h-xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="fill-current" style="width: 16px; height: 16px">
                                            <use href="assets/symbols.svg#info"></use>
                                        </svg>
                                    </i>
                                    <p class="p-none m-none">{{ 'RequiredError' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-2">
                            <p>
                                {{ 'Pickups are typically scheduled for following day.' | translate }}
                            </p>
                            <p>
                                {{ 'Is this equipment secure overnight.' | translate }}
                                <span class="star-err">*</span>
                            </p>
                        </div>
                        <div class="col-2">
                            <apx-form-field>
                                <apx-radio-group formControlName="isSecure" [classList]="'radioGroup'">
                                    <apx-radio-button value="yes" porAddFeature featureName="radio-calloff-secure-yes">
                                        {{ 'Yes' | translate }}
                                    </apx-radio-button>

                                    <apx-radio-button value="no" porAddFeature featureName="radio-calloff-secure-no">
                                        {{ 'No' | translate }}
                                    </apx-radio-button>
                                </apx-radio-group>
                            </apx-form-field>
                        </div>
                    </div>
                </por-accordion-group>
                <por-accordion-group title="{{ 'Site Information' | translate }}" [isValid]="checkPickupRequest()">
                    <div class="form-group mt-2">
                        <div class="col-1">
                            <apx-form-field
                                label="{{ 'Current Location of Equipment' | translate }}"
                                hint="{{ this.getDeliveryAddress() !== '' ? ('Is the equipment still at this address?' | translate) : '' }}"
                                [classList]="'required'"
                                [error]="address?.invalid && (address?.dirty || address?.touched) ?
							address?.errors?.['required'] ? ('RequiredError' | translate) :
                            address?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :
                            ''
							: '' ">
                                <input apxInput placeholder="{{ 'Current Location of Equipment' | translate }}" formControlName="address" porAddFeature featureName="calloff-current-location-input" />
                            </apx-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-1">
                            <apx-form-field
                                label="{{ 'Gate or Lock Combo if necessary' | translate }}"
                                [error]="gateCombo?.invalid && (gateCombo?.dirty || gateCombo?.touched) ?
							        gateCombo?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							        : '' ">
                                <input apxInput placeholder="{{ 'Gate or Lock Combo' | translate }}" formControlName="gateCombo" porAddFeature featureName="calloff-gate-lock-combo-input" />
                            </apx-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-2">
                            <apx-form-field
                                label="{{ 'Job Site Contact Name' | translate }}"
                                [classList]="'required'"
                                [error]="jobSiteName?.invalid && (jobSiteName?.dirty || jobSiteName?.touched) ?
							jobSiteName?.errors?.['required'] ? ('RequiredError' | translate) :
                            jobSiteName?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							: '' ">
                                <input apxInput placeholder="{{ 'Job Site Contact Name' | translate }}" formControlName="jobSiteName" porAddFeature featureName="calloff-jobsite-name-input" />
                            </apx-form-field>
                        </div>
                        <div class="col-2">
                            <apx-form-field
                                label="{{ 'Job Site Contact Phone #' | translate }}"
                                [classList]="'required'"
                                [error]="jobSitePhone?.invalid && (jobSitePhone?.dirty || jobSitePhone?.touched) ?
							jobSitePhone?.errors?.['required'] ? ('RequiredError' | translate) :
                            jobSitePhone?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							: '' ">
                                <input
                                    apxInput
                                    placeholder="{{ 'Enter job site contact number' | translate }}"
                                    formControlName="jobSitePhone"
                                    porAddFeature
                                    featureName="calloff-jobsite-phone-input" />
                            </apx-form-field>
                        </div>
                    </div>
                </por-accordion-group>
                <por-accordion-group title="{{ 'Returner Information' | translate }}" [isValid]="checkSiteInfo()">
                    <div class="form-group mt-2">
                        <div class="col-2">
                            <apx-form-field
                                label="{{ 'Name of Person Requesting Call Off' | translate }}"
                                [classList]="'required'"
                                [error]="customerName?.invalid && (customerName?.dirty || customerName?.touched) ?
							customerName?.errors?.['required'] ? ('RequiredError' | translate) :
                            customerName?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							: '' ">
                                <input
                                    apxInput
                                    placeholder="{{ 'Enter the name of Person' | translate }}"
                                    formControlName="customerName"
                                    [ngModel]="(customer$ | async)?.Name"
                                    porAddFeature
                                    featureName="calloff-name-input" />
                            </apx-form-field>
                        </div>
                        <div class="col-2">
                            <apx-form-field
                                label="{{ 'Phone Number' | translate }}"
                                [classList]="'required'"
                                [error]="customerPhone?.invalid && (customerPhone?.dirty || customerPhone?.touched) ?
							customerPhone?.errors?.['required'] ? ('RequiredError' | translate) :
                            customerPhone?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							: '' ">
                                <input
                                    apxInput
                                    placeholder="{{ 'Phone Number' | translate }}"
                                    formControlName="customerPhone"
                                    [ngModel]="(customer$ | async)?.Phones ? ((customer$ | async)?.Phones | formatNumberCustomer) : ''"
                                    porAddFeature
                                    featureName="calloff-phone-input" />
                            </apx-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-1">
                            <apx-form-field
                                label="{{ 'Email Address' | translate }}"
                                [classList]="'required'"
                                [error]="email?.invalid && (email?.dirty || email?.touched) ?
							email?.errors?.['required'] ? ('RequiredError' | translate) :
							email?.errors?.['email'] ? ('EmailValid' | translate) :
							''
							: '' ">
                                <input apxInput type="email" placeholder="{{ 'Enter email address' | translate }}" formControlName="email" porAddFeature featureName="calloff-email-input" />
                            </apx-form-field>
                        </div>
                    </div>
                    <div class="form-group font-roboto font-medium text-input text-text-base items-start">
                        <div class="col-1">
                            <label class="mb-xs">
                                <span>{{ 'Special Instruction for Pickup' | translate }}</span>
                            </label>
                            <textarea
                                class="font-roboto font-medium rounded-input border-solid border-input px-xs text-input text-input-text border-input-border-default"
                                formControlName="comments"
                                porAddFeature
                                featureName="calloff-special-instructions-textarea"></textarea>
                        </div>
                    </div>
                </por-accordion-group>
            </por-accordion>
            <div class="form-group mt-2 justify-content-center">
                <por-base-button [featureName]="'SubmitRequestForPickup' | translate" [type]="'submit'" [disabled]="returnForm.invalid.toString() | convertBoolean" (onclick)="sendCallOff()">
                    {{ 'SubmitRequestForPickup' | translate }}
                </por-base-button>
            </div>
        </form>
    </div>
</div>
