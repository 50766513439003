<div class="cf sectnav mobile-responsive">
    <ul class="por-menu" *ngIf="appFacadeService.getActiveTab() | async as activeTab">
        <li *ngFor="let url of navigations" class="navigations theme-list-item">
            <span *ngIf="url?.tab === 'AdminDifference'">
                <a href="https://www.point-of-rental.com/ecommerce/consumer-portal/" target="_blank">{{ url?.text }}</a>
            </span>
            <span *ngIf="url?.tab !== 'AdminDifference'">
                <por-base-button [ngClass]="{ 'is-active': url?.tab === activeTab }" (click)="toggleTab(url?.tab)" [featureName]="url?.tab || ''">
                    <span>{{ url?.text }}</span>
                </por-base-button>
            </span>
        </li>
    </ul>
</div>
