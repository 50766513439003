export interface SendLinkRequest {
    email?: string;
    phone?: string;
    customerId: string;
    id: string;
    contractId: string;
    itemName: string;
    serialNumber: string;
    organizationName?: string;
    lineItemId: string;
    types?: UtilityRequestType[];
}
export interface SendLinkResponse {
    sendLinkRequest: SendLinkRequest;
}
export enum UtilityRequestType {
    SMS = 'SMS',
    Email = 'Email'
}
