import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UploadFile } from '../../models/upload-file';
import { NotificationService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'por-drag-n-drop',
    templateUrl: './drag-n-drop.component.html',
    styleUrls: ['./drag-n-drop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragNDropComponent {
    constructor(private readonly notification: NotificationService, private readonly translate: TranslateService) {}
    files: UploadFile[] = [];
    @Output() readonly uploadSuccess = new EventEmitter<UploadFile[]>();
    @Output() readonly uploadStarted = new EventEmitter<boolean>();
    @Output() readonly fileDeleted = new EventEmitter<number>();
    @Output() readonly uploadError = new EventEmitter<Error>();
    @ViewChild('fileDropRef') fileInput!: ElementRef<HTMLInputElement>;

    allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
    /**
     * on file drop handler
     */
    onFileDropped($event: UploadFile[]) {
        if ($event && this.validateFile($event)) {
            this.prepareFilesList($event);
        }
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files?: UploadFile[]) {
        if (files && this.validateFile(files)) {
            this.prepareFilesList(files);
        }
    }

    validateFile(files: UploadFile[]) {
        if (files && files[0]) {
            const file = files[0];
            if (!this.allowedExtensions.includes(file.type)) {
                this.notification.error(this.translate.instant('InvalidFileType'));
                return false;
            }

            if (file.size >= 2108588) {
                this.notification.error(this.translate.instant('InvalidFileSize'));
                return false;
            }

            return true;
        } else {
            return false;
        }
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
        this.fileDeleted.emit(index);
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number, stop?: boolean) {
        if (stop) return;
        setTimeout(() => {
            if (index === this.files.length) {
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (this.files[index]?.progress === 100) {
                        this.uploadFilesSimulator(index + 1, true);
                        this.uploadSuccess.emit(this.files);
                        this.resetFiles();
                        clearInterval(progressInterval);
                    } else {
                        if (this.files.length > 0) {
                            if (this.files[index].progress !== undefined) {
                                this.files[index].progress += 5;
                            }
                        }
                    }
                }, 200);
            }
        }, 1000);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: UploadFile[]) {
        for (const item of files) {
            item.progress = 0;
            this.files.push(item);
        }
        this.uploadStarted.emit(true);
        this.uploadFilesSimulator(0);
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes: number, decimals?: number) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals && decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    resetFiles() {
        this.files = [];
        if (this.fileInput) {
            this.fileInput.nativeElement.value = '';
        }
    }
}
