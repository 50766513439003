import { Component, EventEmitter, Input, Output } from '@angular/core';
import {} from '../../../models/contract-model';
import { ErrorDetail } from '../../models/request-body.interface';

@Component({
    selector: 'por-error-dailog-box',
    templateUrl: './error-dailog-box.component.html',
    styleUrls: ['./error-dailog-box.component.scss']
})
export class ErrorDailogBoxComponent {
    @Input() data!: ErrorDetail; // Dynamic message input
    @Output() readonly retry = new EventEmitter<void>();
    onRetryClick(): void {
        this.retry.emit(); // Emit the retry event
    }
}
