// reducers/payment.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { loadPaymentProcessorAvailabilitySuccess, loadPaymentProcessorAvailabilityFailure } from '../actions/processors.actions';
import { initialState } from '../state/processors.state';

export const paymentProcessorsReducer = createReducer(
    initialState,
    on(loadPaymentProcessorAvailabilitySuccess, (state, { processors }) => ({
        ...state,
        isPaymentProcessorAvailable: processors
    })),
    on(loadPaymentProcessorAvailabilityFailure, state => ({
        ...state,
        isPaymentProcessorAvailable: false
    }))
);
