import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PorPaymentPageComponent } from './components/por-payment-page/por-payment-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material-modules';
import { PorPayService } from './services/por-pay.service';
import { LazyElementsModule } from '@angular-extensions/elements';
import { PorMultipaymentComponent } from './components/por-multipayment/por-multipayment.component';

@NgModule({
    declarations: [PorPaymentPageComponent, PorMultipaymentComponent],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, SharedModule, MaterialModule, LazyElementsModule],
    exports: [PorPaymentPageComponent, PorMultipaymentComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [DatePipe, PorPayService]
})
export class PaymentModule {}
