import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Invoice } from '../../models/invoice-model';
import { TranslateService } from '@ngx-translate/core';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { AppMediatorService } from '../../services/app-mediator.service';

@Component({
    selector: 'por-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailComponent implements OnInit {
    @Input()
    invoiceDetail!: Invoice;

    dateFormat: string;

    constructor(@Inject(LOCALE_ID) locale: string, private readonly translateService: TranslateService, private readonly appMediatorService: AppMediatorService) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
    }
}
