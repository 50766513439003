import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'por-base-checkbox',
    templateUrl: './base-checkbox.component.html',
    styleUrls: ['./base-checkbox.component.scss']
})
export class BaseCheckboxComponent {
    @Input() isChecked = false;

    @Input() isDisabled = false;

    @Input() showLabel = true;

    @Output() readonly checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    checkedHandler(isChecked: boolean): void {
        this.checkboxChange.emit(isChecked);
    }
}
