/* eslint-disable @typescript-eslint/no-inferrable-types */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppFacadeService } from '../../../services/app-facade.service';
@Component({
    selector: 'por-app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
    constructor(readonly appFacadeService: AppFacadeService, private readonly cdn: ChangeDetectorRef) {}
    ngOnInit(): void {
        this.appFacadeService.getLoader().subscribe(() => {
            this.cdn.detectChanges();
        });
    }
}
