export interface Modal {
    id?: string;
    open?: () => void;
    close?: () => void;
}

export enum ModalStateEnum {
    OPEN = 'open',
    CLOSE = 'close'
}
