<div
  class="footer porFooter wrapper por-ac-footer"
  [ngStyle]="{ width: width, height: height }"
  [ngClass]="{
    'align-left': position === 'left',
    'align-right': position === 'right',
    'align-center': position === 'center'
  }"
  #footer
>
{{footerText}}</div>
