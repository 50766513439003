import { createReducer, on } from '@ngrx/store';
import { MULTI_CONTRACT_MAX_SELECTION_ALLOWED } from '../../../constants/default.const';
import { ContractCP, ContractDetail } from '../../../models/contract-model';
import {
    clearContractDetailInStore,
    clearContractinStore,
    closePayment,
    continueMultipayment,
    failLoadContractDetails,
    increasePageNumberContract,
    loadContractAction,
    openContract,
    resetContractsLatestToShow,
    resetPageNumberContract,
    setContractDetailData,
    setNoMoreRecord,
    setPaymentOpen,
    setSelectedContracts,
    unsetSelectedContracts
} from './contract.actions';

export const contractsFeatureKey = 'contracts';

export interface ContractState {
    pageNumber: number;
    contracts: ContractCP[];
    selectedContracts: string[];
    noMoreRecord: boolean;
    selectedContractId: string;
    paymentOpen: boolean;
    error: string | null;
    contractDetail: ContractDetail | null;
}

export const initialState: ContractState = {
    pageNumber: 1,
    contracts: [],
    selectedContracts: [],
    noMoreRecord: false,
    selectedContractId: '',
    paymentOpen: false,
    error: null,
    contractDetail: null
};

export const contractReducer = createReducer(
    initialState,
    on(loadContractAction, (state: ContractState, { contracts, pageSize = 25 }: { contracts: ContractCP[]; pageSize?: number }) => {
        if (contracts && contracts.length > 0) {
            // Mark the latest 25 contracts as `latestToShow = true`
            const latestContracts: ContractCP[] = contracts.slice(0, pageSize).map(contract => ({
                ...contract,
                latestToShow: true
            }));

            const filteredContractsWithLatestFlagReset = state.contracts.filter(contract => !latestContracts.some(latestContract => latestContract.contractId === contract.contractId));
            // Append the remaining contracts without the `latestToShow` flag
            const remainingContracts: ContractCP[] = contracts.slice(pageSize).map(contract => ({
                ...contract,
                latestToShow: false
            }));

            return {
                ...state,
                contracts: [...filteredContractsWithLatestFlagReset, ...latestContracts, ...remainingContracts]
            };
        }
        return state;
    }),
    on(setNoMoreRecord, (state: ContractState, { record }: { record: boolean }) => {
        return { ...state, noMoreRecord: record };
    }),
    on(clearContractinStore, (state: ContractState) => {
        return { ...state, contracts: [] };
    }),
    on(increasePageNumberContract, (state: ContractState) => {
        return { ...state, pageNumber: state?.pageNumber + 1 };
    }),
    on(resetPageNumberContract, (state: ContractState) => {
        return { ...state, pageNumber: 1 };
    }),
    on(openContract, (state: ContractState, { selectedContractId }: { selectedContractId: string }) => {
        return { ...state, selectedContractId };
    }),
    on(setSelectedContracts, (state: ContractState, { selectedContracts }: { selectedContracts: string[] }) => {
        if (state.selectedContracts.length <= MULTI_CONTRACT_MAX_SELECTION_ALLOWED) {
            return { ...state, selectedContracts };
        }

        // Return the state unchanged if the condition is not met
        return state;
    }),
    on(unsetSelectedContracts, (state: ContractState, { unselectContracts }) => ({
        ...state,
        selectedContracts: state.selectedContracts.filter(contractId => !unselectContracts.includes(contractId))
    })),
    on(resetContractsLatestToShow, (state: ContractState) => ({
        ...state,
        contracts: state.contracts.map(contract => ({
            ...contract,
            latestToShow: false
        }))
    })),
    on(setPaymentOpen, (state: ContractState, { paymentOpen }) => {
        return { ...state, paymentOpen };
    }),
    on(closePayment, (state: ContractState) => {
        return { ...state, paymentOpen: false };
    }),
    on(continueMultipayment, (state: ContractState) => {
        return { ...state, paymentOpen: true };
    }),
    on(failLoadContractDetails, (state: ContractState, { error }) => {
        return { ...state, contracts: [], error };
    }),
    on(setContractDetailData, (state: ContractState, { data }) => {
        return { ...state, contractDetail: data };
    }),
    on(clearContractDetailInStore, (state: ContractState) => {
        return { ...state, selectedContractId: '', contractDetail: null };
    })
);
