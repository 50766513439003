<div class="portalnav-wrap">
    <div class="portalnav">
        <div class="arrow-back">
            <por-base-button porAddFeature featureName="send-document-link-close" theme="primary" (onclick)="return()" class="p-0">
                <mat-icon>close</mat-icon>
            </por-base-button>
        </div>
        <h1 class="align-items-center d-inline-flex title">
            {{ 'SendDocuments' | translate }}
        </h1>
        <form class="form-wrapper" [formGroup]="sendForm" porAddFeature featureName="send-document-link-sendform" (ngSubmit)="sendDocumentLinks()">
            <div class="form-group mt-2">
                <div class="col-3">
                    <apx-form-field
                        [classList]="'required'"
                        [error]="email?.invalid && (email?.dirty || email?.touched) ?
                            email?.errors?.['required'] ? ('RequiredError' | translate) :
                            email?.errors?.['email'] ? ('EmailValid' | translate) :
                            ''
                        : '' ">
                        <input apxInput placeholder="{{ 'Enter email address' | translate }} *" formControlName="email" porAddFeature featureName="send-document-link-email-input" />
                    </apx-form-field>
                </div>
            </div>
            <div>
                <div class="col-1 text-align-center">
                    {{ 'or' | translate }}
                </div>
                <div class="form-group mt-2">
                    <div class="col-3">
                        <por-intl-tel-input
                            [cssClass]="'country-code'"
                            [preferredCountries]="['us']"
                            [enableAutoCountrySelect]="false"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="true"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="true"
                            formControlName="phone"
                            porAddFeature
                            featureName="send-document-link-phone-input"></por-intl-tel-input>
                    </div>
                </div>
            </div>
            <div class="form-group mt-2 justify-content-center">
                <por-base-button porAddFeature featureName="submit-send-document-link" [type]="'submit'" [disabled]="sendForm.pristine || sendForm.invalid">
                    {{ 'send' | translate }}
                </por-base-button>
            </div>
        </form>
    </div>
</div>
