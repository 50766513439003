import { createReducer, on } from '@ngrx/store';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { loadVersionRedirectionFailure, setVersionRedirection } from '../actions/versionredirection.actions';

export const versionFeatureKey = 'redirectionVersion';

export interface Redirectstate {
    versionRedirection: { toLoad: ComponentsToLoad; orgId: string };
    errorMessage?: string;
}

export const initialState: Redirectstate = {
    versionRedirection: { toLoad: ComponentsToLoad.Subdomain, orgId: '' },
    errorMessage: ''
};

export const redirectionVersionReducer = createReducer(
    initialState,

    on(setVersionRedirection, (state, { redirectionVersion }) => ({
        ...state,
        versionRedirection: redirectionVersion
    })),
    on(loadVersionRedirectionFailure, (state, { error }) => ({
        ...state,
        errorMessage: error
    }))
);
