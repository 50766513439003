<ng-container [ngSwitch]="enabledTab$ | async">
    <div class="rental-requests-modal">
        <ng-container *ngSwitchCase="'DefaultView'">
            <h1 class="theme-heading">{{ 'Selected Items' | translate }}</h1>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <th>{{ 'SerialNumber' | translate }}</th>
                        <th>{{ 'itemName' | translate }}</th>
                        <th>{{ 'Contract #' | translate }}</th>
                        <th>{{ 'Contract Date' | translate }}</th>
                        <th>{{ 'dueDate' | translate }}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of items">
                            <td>{{ item?.serialNumber ? item?.serialNumber : '&nbsp;' }}</td>
                            <td [innerHTML]="item.itemName"></td>
                            <td>{{ item.contractId }}</td>
                            <td>{{ item.startDate | date : dateFormat }}</td>
                            <td>{{ item.dueDate | date : dateFormat }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3">
                <div class="col text-align-center" [ngClass]="{ 'w-100': totalItems > 1 }">
                    <h1 class="theme-heading" *ngIf="isReturnRequestsAvailable()">{{ 'Requests' | translate }}</h1>
                    <div class="d-inline-flex justify-content-center w-100 m-d-block">
                        <ng-template porFeatureAvailability [featureToken]="['callOffAbility']">
                            <div class="col-3">
                                <button class="btn-link" (click)="renderRequestReturn()" porAddFeature featureName="request-calloff-button">
                                    <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-return-4960178.svg" porAddFeature featureName="request-calloff-button" />
                                    <label class="d-flex justify-content-center color-black" porAddFeature featureName="request-calloff-button">{{ 'Request Return' | translate }}</label>
                                </button>
                            </div>
                        </ng-template>

                        <div class="col-3 hidden">
                            <button disabled="true" class="btn-link" porAddFeature featureName="request-extension-button">
                                <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-event-37208.svg" porAddFeature featureName="request-extension-button" />
                                <label class="d-flex justify-content-center" porAddFeature featureName="request-extension-button">{{ 'Request Extension' | translate }}</label>
                                <span class="d-flex justify-content-center">{{ 'Coming Soon' | translate }}</span>
                            </button>
                        </div>
                        <ng-template porFeatureAvailability [featureToken]="['requestService']">
                            <div class="col-3">
                                <button class="btn-link" (click)="renderRequestService()" porAddFeature featureName="request-service-button">
                                    <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-service-2474061.svg" porAddFeature featureName="request-service-button" />
                                    <label class="d-flex justify-content-center" porAddFeature featureName="request-service-button">{{ 'Request Service' | translate }}</label>
                                </button>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="col text-align-center mt-3 hidden" *ngIf="totalItems === 1">
                    <h1 class="theme-heading">{{ 'Product Info' | translate }}</h1>
                    <div class="d-inline-flex justify-content-center w-100 m-d-block">
                        <div class="col-3">
                            <button disabled="true" class="btn-link" porAddFeature featureName="details-manual-button">
                                <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-magnify-2683859-676767.svg" />
                                <span class="d-flex justify-content-center">{{ 'DetailsManual' | translate }}</span>
                                <span class="d-flex justify-content-center">{{ 'Coming Soon' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'RequestReturnView'">
            <por-close-item-out
                [customerId]="customerId"
                [items]="items"
                [uiUrl]="uiUrl"
                (isFormReturn)="handleRentalFormReturn()"
                (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-close-item-out>
        </ng-container>
        <ng-container *ngSwitchCase="'RequestServiceView'">
            <por-service-request
                [customerId]="customerId"
                [items]="items"
                [uiUrl]="uiUrl"
                (isFormReturn)="handleRentalFormReturn()"
                (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-service-request>
        </ng-container>
    </div>
</ng-container>
