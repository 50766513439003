import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NetworkService implements OnDestroy {
    constructor(@Inject('Window') private readonly window: Window, private readonly notificationService: NotificationService, private readonly translateService: TranslateService) {
        this.window.addEventListener('online', this.onNetworkOnline.bind(this));
        this.window.addEventListener('offline', this.onNetworkOffline.bind(this));
    }

    private onNetworkOnline() {
        this.notificationService.success(this.translateService.instant('ApplicationOnline'));
    }

    private onNetworkOffline() {
        this.notificationService.error(this.translateService.instant('ApplicationOffline'));
    }

    ngOnDestroy(): void {
        this.window.removeEventListener('online', this.onNetworkOnline.bind(this));
        this.window.removeEventListener('offline', this.onNetworkOffline.bind(this));
    }
}
