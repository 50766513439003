import { setAuthenticated, setRedirected } from '../actions/auth.actions';
import { ReducerType, initialState } from '../state/app.state';
import { createReducer, on } from '@ngrx/store';

export const authReducer: ReducerType = createReducer(
    initialState,
    on(setAuthenticated, (state, { isAuthenticated }) => {
        return { ...state, isAuthenticated };
    }),
    on(setRedirected, (state, { isRedirected }) => {
        return { ...state, isRedirected };
    })
);
