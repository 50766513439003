<div class="por consumer-login" id="apx" *ngIf="$any(demoBtnVisibilityCount$ | async) < 5; else demoComponent">
    <div class="consumer-login-container">
        <ng-container [ngSwitch]="activeView$ | async">
            <ng-container *ngSwitchCase="'CustomerSelection'" [ngTemplateOutlet]="customerSelection"></ng-container>
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="login"></ng-container>
        </ng-container>
        <por-loader *ngIf="showLoader"></por-loader>
    </div>
</div>

<ng-template #login>
    <div class="main">
        <img class="consumer-login-logo" [src]="inputs?.logoUrl" (click)="showDemoApp()" *ngIf="!inputs?.depotName; else showDepotName" />
        <por-alert *ngIf="showAlert" [alertMessage]="alertMessage"></por-alert>
        <div class="login-wrap" [ngSwitch]="selectedTemplate">
            <por-signin
                *ngSwitchCase="'signin'"
                (selectedTemplate)="activeTemplate($event)"
                (loginData)="handleLoginData($event)"
                [config]="config"
                [isDisableButton]="isDisableButton"
                [quickLinkUrl]="quickLinkUrl"></por-signin>
            <por-signup *ngSwitchCase="'signup'" (selectedTemplate)="activeTemplate($event)" (signupData)="handleSignupData($event)" [config]="config" [isDisableButton]="isDisableButton"></por-signup>
            <por-verify
                *ngSwitchCase="'verify'"
                (selectedTemplate)="activeTemplate($event)"
                (verificationData)="handleVerificationData($event)"
                [config]="config"
                [isDisableButton]="isDisableButton"
                [userEmail]="userEmail"></por-verify>
            <por-forgot-password
                *ngSwitchCase="'forgot-password'"
                (selectedTemplate)="activeTemplate($event)"
                (forgotPasswordData)="handleForgotPasswordData($event)"
                [config]="config"
                [isDisableButton]="isDisableButton"></por-forgot-password>
            <por-change-password
                *ngSwitchCase="'change-password'"
                (selectedTemplate)="activeTemplate($event)"
                (changePasswordData)="handleChangePasswordData($event)"
                [config]="config"
                [isDisableButton]="isDisableButton"
                [userEmail]="userEmail"></por-change-password>
            <por-busy-indicator *ngSwitchCase="'busy-indicator'" (selectedTemplate)="activeTemplate($event)" [config]="config"></por-busy-indicator>
            <ng-container *ngSwitchCase="'configError'">
                <por-alert [alertMessage]="'InvalidSubDomainOrgId' | translate"></por-alert>
            </ng-container>
        </div>
        <div class="adv-notation" *ngIf="showAdvanceNotation()">
            <span>ADV</span>
        </div>
    </div>
</ng-template>
<ng-template #customerSelection>
    <por-customer-selection [contacts]="userAccounts || []" [selectedContact]="selectedContact" (contactSelected)="setContact($event)"></por-customer-selection>
</ng-template>
<ng-template #demoComponent>
    <por-consumer-portal-demo></por-consumer-portal-demo>
</ng-template>

<ng-template #showDepotName>
    <span class="consumer-login-logo depot-name">{{ inputs?.depotName }}</span>
</ng-template>
