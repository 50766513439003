import { BehaviorSubject, interval, Subscription, timer } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { HelpersService } from '../../../services/helpers.service';
import { LATLONG } from '../../../models/map-configuration.model';
import { MapActionsService } from '../../../services/map-action.service';
import { ConsumerPortalConfig } from '../../../models/consumer-portal-config';

@Component({
    selector: 'por-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapComponent implements OnDestroy, OnInit {
    mapReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    map: google.maps.Map<HTMLElement> | undefined;
    markerCluster: MarkerClusterer | undefined;
    infoWindow: google.maps.InfoWindow | undefined;

    subscriptions: Subscription[] = [];
    scripts: HTMLScriptElement[] = [];
    @Input() pushpins: LATLONG[] = [];
    @Input() config: ConsumerPortalConfig | undefined;

    constructor(private readonly helperService: HelpersService, private readonly mapActionsService: MapActionsService) {}

    ngOnInit(): void {
        if (this.config) {
            this.mapActionsService.config = this.config;
            this.scripts.push(this.helperService.loadScript(this.mapActionsService.getScript()));
            const intervalSub = interval(1000).subscribe(() => {
                if (this.scripts.length !== 0) {
                    this.loadMap();
                    intervalSub.unsubscribe();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.scripts.map((el: HTMLScriptElement) => {
            this.helperService.removeScript(el);
        });
        this.mapReady.next(false);
    }

    loadMap() {
        this.mapReady.next(true);
        this.subscriptions.push(
            timer(1000).subscribe(() => {
                const htmlElement = document.getElementById('map');
                if (htmlElement) {
                    const firstPushpin = this.pushpins[0];
                    const centerLatLng = new google.maps.LatLng(Number(firstPushpin.lat), Number(firstPushpin.long));

                    this.map = new google.maps.Map(htmlElement, {
                        center: centerLatLng,
                        zoom: 8
                    });
                    this.markerCluster = new MarkerClusterer({ map: this.map });
                    this.infoWindow = new google.maps.InfoWindow();

                    this.plugPoints();
                }
            })
        );
    }

    pushpinClicked(marker: google.maps.Marker, pushpin: LATLONG) {
        this.infoWindow?.close();
        this.infoWindow?.setContent(`${pushpin.title}<br>${pushpin.description}`);
        this.infoWindow?.open(this.map, marker);
    }

    plugPoints() {
        if (this.pushpins.length > 0) {
            this.pushpins.map((pushpin: LATLONG) => {
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(Number(pushpin.lat), Number(pushpin.long)),
                    map: this.map,
                    title: pushpin.title
                });

                marker.addListener('click', () => {
                    this.pushpinClicked(marker, pushpin);
                });

                this.markerCluster?.addMarker(marker);
            });
        }
    }
}
