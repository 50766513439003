import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowGroupingDisplayType, RowNode } from 'ag-grid-community';
import { AnalyticsDataResponse } from '../../../interfaces/analytics-data-response.interface';
import { ReturnRequestType } from '../../../../enums/return-request-type.enum';
import { DropdownOption } from '../../../../shared/models/dropdown-option.model';
import { BehaviorSubject, finalize, Observable, switchMap, tap } from 'rxjs';
import { ConsumerService } from '../../../services/consumers.services';
import { TranslateService } from '@ngx-translate/core';
import { AppMediatorService } from '../../../../services/app-mediator.service';

@Component({
    selector: 'por-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsComponent implements OnInit {
    constructor(private readonly consumerService: ConsumerService, private readonly translateService: TranslateService, private readonly appMediatorService: AppMediatorService) {}

    groupDisplayType: RowGroupingDisplayType = 'singleColumn';
    columnDefs: ColDef[] = [];
    defaultColDef: ColDef = {
        flex: 1,
        minWidth: 100,
        suppressMenu: true,
        cellStyle: {
            /* eslint-disable @typescript-eslint/naming-convention */
            'padding-top': '0'
        }
    };
    autoGroupColumnDef: ColDef = {
        minWidth: 200,
        cellRendererParams: {
            suppressCount: true
        },
        headerName: this.translateService.instant('MonthCustomerName')
    };
    gridOptions: GridOptions = {
        suppressAggFuncInHeader: true,
        groupIncludeFooter: true,
        groupIncludeTotalFooter: true
    };

    requestTypeDropdownOptions: DropdownOption[] = [];
    selectedRequestType = ReturnRequestType.Reorder;
    yearDropdownOptions: DropdownOption[] = [];
    selectedYear = new Date().getFullYear().toString(); //Default to Current Year
    private readonly reloadTrigger$ = new BehaviorSubject<void>(undefined);
    data$: Observable<AnalyticsDataResponse[]> = this.reloadTrigger$.pipe(
        tap(() => {
            if (this.gridApi) {
                this.gridApi.showLoadingOverlay(); // Show AG grid inbuilt loader before fetching
            }
        }),
        switchMap(() => this.consumerService.getAnalyticsData({ type: this.selectedRequestType, year: Number(this.selectedYear) })),
        finalize(() => {
            if (this.gridApi) {
                this.gridApi.hideOverlay(); // Hide loader after fetching
            }
        })
    );
    private gridApi!: GridApi;

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.columnDefs = [
            {
                field: 'month',
                rowGroup: true,
                hide: true,
                cellRenderer: (params: { value: number; node: RowNode }) => {
                    if (params.node.footer) {
                        return this.translateService.instant('Total');
                    }
                    const date = new Date(parseInt(this.selectedYear, 10), params.value - 1, 1);
                    return `${date.toLocaleString('en-US', { month: 'short' })} (${params.node.allChildrenCount})`;
                }
            },
            {
                field: 'customerName',
                rowGroup: true,
                hide: true,
                cellRenderer: (params: { value: string; node: RowNode }) => {
                    if (params.node.footer) {
                        return this.translateService.instant('Total');
                    }
                    return `${params.value} (${params.node.allChildrenCount})`;
                }
            },
            {
                headerName: 'Contract ID',
                field: 'contractId',
                cellRenderer: (params: { value: string; data: AnalyticsDataResponse }) => {
                    return params.value ? `${params.value} (${params.data.totalCount})` : '';
                }
            },
            {
                headerName: this.translateService.instant('Total Amount'),
                field: 'totalAmount',
                aggFunc: 'sum',
                valueFormatter: params => {
                    return params?.value ? params.value.toFixed(2) : '';
                }
            }
        ];
        this.requestTypeDropdownOptions = [{ label: 'Clone/Reorder', value: ReturnRequestType.Reorder }];

        /**
         * Year DropDown values current year -5 years
         */
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 5;

        for (let year = startYear; year <= currentYear; year++) {
            this.yearDropdownOptions.push({
                label: year.toString(),
                value: year.toString()
            });
        }
    }

    onGridReady(params: GridReadyEvent<AnalyticsDataResponse>) {
        this.gridApi = params.api;
    }

    onRequestTypeValueChange(newValue: string | number): void {
        this.selectedRequestType = newValue as ReturnRequestType;
        this.reloadTrigger$.next();
    }

    onYearValueChange(newValue: string | number): void {
        if (typeof newValue === 'string' || typeof newValue === 'number') {
            this.selectedYear = newValue.toString();
            this.reloadTrigger$.next();
        }
    }
}
