/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: DB model
export class User {
    Email?: string;
    StoreName?: string;
    Password?: string;
    code?: string;
    Name?: string;
    CompanyName?: string;
    PhoneNumber?: string;
}
