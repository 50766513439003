import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModalService } from '@por/ecom-shared';

@Component({
    selector: 'por-verification-code-help-text',
    templateUrl: './verification-code-help-text.component.html',
    styleUrls: ['./verification-code-help-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationCodeHelpTextComponent {
    @Input() email = '';
    constructor(private readonly modal: ModalService) {}

    closeModal(id: string) {
        this.modal.close(id);
    }
}
