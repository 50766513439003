export enum AuthKeys {
  AcceessToken = 'accesstoken',
  User = 'user',
  RefreshToken = 'refreshToken',
  AppData = 'appData'
}

export enum LocalStorageKeys {
  ContentLang = 'content-lang',
}
