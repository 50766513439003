/**
 * Values are IETF language tags.
 * Combination of ISO 639-1 language code and ISO 3166-1 alpha-2 country code
 */
export enum Language {
    DE_DE = 'de-DE',
    EN_US = 'en-US',
    EN_GB = 'en-GB',
    ES_MX = 'es-MX',
    FR_CA = 'fr-CA'
}
