<div class="base-texarea-wrapper">
    <mat-form-field class="" appearance="outline">
        <mat-label>{{ label }}</mat-label>
        <textarea
            matInput
            [placeholder]="placeholder"
            (input)="updateNewValue($event)"
            (blur)="touched()"
            [disabled]="isDisabled"
            [(ngModel)]="model"
            [required]="required"
            porAddFeature
            [featureName]="featureName"></textarea>
        <mat-error *ngIf="formField?.hasError('required')">required*</mat-error>
    </mat-form-field>
</div>
