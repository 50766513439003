import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'por-ad-drag-drop',
    templateUrl: './ad-drag-drop.component.html',
    styleUrls: ['./ad-drag-drop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdDragDropComponent implements OnInit {
    @Input() title = '';
    @Input() uiTitle = '';
    @Input() currentImage: string | null = '';
    @Input() allowDelete = false; // Allow to delete/clear current image
    @Input() previewDimension: { width?: string; height?: string } = {
        width: '100%',
        height: 'auto'
    };

    uploading$ = new BehaviorSubject(false);
    @Output() readonly uploadSuccess = new EventEmitter();
    imageRendering = false; // Track image rendering from source to UI
    @Output() readonly removeFile = new EventEmitter();

    ngOnInit(): void {
        if (this.currentImage) {
            this.imageRendering = true;
        }
    }

    handleFile($e: File[]) {
        if ($e.length > 0) {
            this.currentImage = null;
            this.imageRendering = true;
            this.uploadSuccess.emit($e[0]);
            this.uploading$.next(false);
        }
    }

    /**
     * This function handle delete/stop during file upload is in processing stage
     */
    handleFileDelete(): void {
        this.uploading$.next(false);
        this.handleImageRender();
    }

    handleImageRender(): void {
        this.imageRendering = false;
    }

    /**
     * Emits to parent app that remove button is clicked, so value can be set blank
     */
    clearFile(): void {
        this.removeFile.emit();
    }
}
