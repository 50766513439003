<div class="start">
    <span class="cp-label">{{ startDateLabel | translate }}</span>
    <span class="cp-label m-view">{{ 'startDate' | translate }}</span>
    <input matInput [max]="endDate" (focus)="startDateInput.open()" [matDatepicker]="startDateInput" [(ngModel)]="startDate" (dateChange)="updateDate()" porAddFeature [featureName]="featureName" />
    <mat-datepicker-toggle matIconSuffix [for]="startDateInput"></mat-datepicker-toggle>
    <mat-datepicker #startDateInput></mat-datepicker>
</div>
<div class="end">
    <span class="cp-label theme-paragraph">{{ endDateLabel | translate }}</span>
    <span class="cp-label theme-paragraph m-view">{{ 'End Date' | translate }}</span>
    <input matInput [min]="startDate" [(ngModel)]="endDate" (focus)="endDateInput.open()" [matDatepicker]="endDateInput" (dateChange)="updateDate()" porAddFeature [featureName]="featureName" />
    <mat-datepicker-toggle matIconSuffix [for]="endDateInput"></mat-datepicker-toggle>
    <mat-datepicker #endDateInput></mat-datepicker>
</div>

<div class="clear_btn">
    <por-base-button (onclick)="clearDates()" featureName="date-filter-clear-button"><mat-icon>add_circle_outline</mat-icon></por-base-button>
</div>
