import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBoolean'
})
export class ConvertBooleanPipe implements PipeTransform {

  transform(value: string | number | boolean | null): boolean {
    if(!value){
      return false;
    }
    return Boolean(JSON.parse(value as string));
  }

}
