import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppFacadeService } from '../../services/app-facade.service';
import { DocumentData } from '../../store/domains/document/document.reducers';
import { Subscription } from 'rxjs';
import { GetDocumentParam } from '../../models/contract-model';

@Component({
    selector: 'por-document-lists',
    templateUrl: './document-lists.component.html',
    styleUrls: ['./document-lists.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListsComponent implements OnInit, OnDestroy {
    @Input() uiUrl!: string;
    subscriptions: Subscription[] = [];

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ required: true }) documentParams!: GetDocumentParam;

    constructor(readonly appFacadeService: AppFacadeService) {}
    ngOnInit(): void {
        this.appFacadeService.loadDocuments(this.documentParams);
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
        this.appFacadeService.cancelLoadDocuments();
        this.appFacadeService.emptyDocument();
    }
    openDocumentUrl(documentType: string): void {
        this.subscriptions.push(
            this.appFacadeService.openPdfdocument(documentType).subscribe((response: DocumentData[]) => {
                if (response.length > 0) {
                    this.appFacadeService.pdf.openPdfinNewTab(response[0].url);
                }
            })
        );
    }
}
