import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { closePayment, ContractActionTypes, setSelectedContracts } from '../actions/contract.actions';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppFacadeService } from '../../services/app-facade.service';
import { PaymentData, PaymentMinMaxBoundary } from '../../payment/models/payment-data.model';
import {
    createTransaction,
    failCreateTransaction,
    failLoadPaymentBoundaries,
    failVerifyPayment,
    PaymentActionTypes,
    setConfigData,
    setDialogEnabled,
    setMultiPaymentBoundaries,
    setPaymentAppInput,
    setTransactionLoaded,
    successCreateTransaction,
    successVerifyPayment
} from '../actions/payment.actions';
import { AppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { getContractsTotal, getSelectedContracts, getSelectedContractsModels } from '../selector/contracts.selectors';
import { selectConfig } from '../selector/config.selectors';
import { ConfigActionTypes } from '../actions/config.actions';
import { TranslateService } from '@ngx-translate/core';
import { CreateTransactionResponse } from '../../payment/models/create-transaction-response-model';
import { PaymentAppPayOutput } from '../../payment/models/paymentOutput';
import { LoggerService, NotificationService } from '../../services';
import { getCustomer } from '../selector/customer.selectors';
import { selectPaymentAppOutput, selectMultipaymentBoundaries } from '../selector/payment.selectors';
import { PorTransactionService } from '../../por-transaction.service';
import { AppMediatorService } from '../../services/app-mediator.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContractService } from '../../services/contract.service';
import { showDialog } from '../actions/dialog.actions';
import { DialogContentType } from '../../enums/dialog-type.enum';

@Injectable()
export class PaymentEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly appFacadeService: AppFacadeService,
        private readonly appMediatorService: AppMediatorService,
        private readonly translateService: TranslateService,
        private readonly porTransactionService: PorTransactionService,
        private readonly notification: NotificationService,
        private readonly logger: LoggerService,
        private readonly contractService: ContractService,
        private readonly store: Store<AppState>
    ) {}

    loadPaymentBoundaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContractActionTypes.MultiPaymentContinue),
            tap(() => {
                this.appFacadeService.setLoading(true);
            }),
            withLatestFrom(this.store.select(getSelectedContracts)),
            withLatestFrom(this.store.select(getContractsTotal)),
            withLatestFrom(this.store.select(selectConfig)),
            switchMap(([[[action, selectedContracts], total], config]) =>
                this.appMediatorService.porPayService.getPaymentBoundaryMultiContract(config.customerId ?? '', selectedContracts, total).pipe(
                    map((paymentBoundaries: PaymentMinMaxBoundary[]) => {
                        this.appFacadeService.setLoading(false);
                        return setMultiPaymentBoundaries({ paymentBoundaries });
                    }),
                    catchError(error => {
                        this.appFacadeService.setLoading(false);
                        return of(failLoadPaymentBoundaries({ error: error.message }));
                    })
                )
            )
        )
    );

    onSetMultiboundaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.SetMultiPaymentBoundaries),
            withLatestFrom(this.store.select(selectMultipaymentBoundaries)),
            switchMap(([action, paymentBoundaries]) => {
                if (paymentBoundaries) {
                    return of(createTransaction());
                }
                return of();
            })
        )
    );

    resetPaymentBoundaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContractActionTypes.PaymentWindowClosed),
            map(() => setMultiPaymentBoundaries({ paymentBoundaries: null }))
        )
    );

    setConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigActionTypes.SetConfig),
            map(action => setConfigData({ config: action }))
        )
    );

    createTransaction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.CreateTransaction),
            tap(() => {
                this.appFacadeService.setLoading(true);
            }),
            withLatestFrom(this.store.select(getSelectedContracts)),
            withLatestFrom(this.store.select(getSelectedContractsModels)),
            withLatestFrom(this.store.select(getContractsTotal)),
            withLatestFrom(this.store.select(selectConfig)),
            switchMap(([[[[action, selectedContracts], selectedContractsModels], total], config]) => {
                setTransactionLoaded({ transactionLoaded: false });

                const amountToPay: string = this.appMediatorService.porPayService.formatCurrencyForPorPay(total ? total.toString() : '0');

                return this.appMediatorService.porPayService
                    .createPayment(config.customerId ?? '', 'USD', Number(amountToPay), config.organizationId ?? '', selectedContractsModels[0]?.depotId ?? '', selectedContracts, Number(total))
                    .pipe(
                        switchMap((res: unknown) => {
                            this.appFacadeService.setLoading(false);
                            return of(successCreateTransaction({ res }));
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return [
                                setTransactionLoaded({ transactionLoaded: true }),
                                failCreateTransaction({ error: this.translateService.instant('CreateTransactionFailed') + error?.message }),
                                setDialogEnabled({ dialogEnabled: true })
                            ];
                        })
                    );
            })
        )
    );

    successTransaction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.SuccessCreateTransaction),
            switchMap((action: { type: string; res: unknown }) => {
                const response: CreateTransactionResponse = action.res as CreateTransactionResponse;
                if (response.token && response.token === 'NO_PAYMENT_METHOD_AVAILABLE') {
                    return [
                        setTransactionLoaded({ transactionLoaded: true }),
                        failCreateTransaction({ error: this.translateService.instant('CreateTransactionFailed') + this.translateService.instant('NoPaymentMethodAvailable') }),
                        setDialogEnabled({ dialogEnabled: true })
                    ];
                }

                return this.appMediatorService.porPayService.getPaymentConfiguration(response?.apiKey, response?.transactionId).pipe(
                    switchMap(configuration => {
                        return [setPaymentAppInput({ paymentAppInput: JSON.stringify(configuration) }), setTransactionLoaded({ transactionLoaded: true })];
                    })
                );
            })
        )
    );

    paymentFinished$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.PaymentFinished),
            withLatestFrom(this.store.select(getSelectedContractsModels)),
            withLatestFrom(this.store.select(getCustomer)),
            withLatestFrom(this.store.select(selectConfig)),
            withLatestFrom(this.store.select(selectPaymentAppOutput)),
            switchMap(([[[[action, selectedContractsModels], customer], config], paymentAppOutput]) => {
                this.logger.logInfo(paymentAppOutput?.detail);
                const detail: PaymentAppPayOutput = paymentAppOutput?.detail as PaymentAppPayOutput;

                if (detail?.ResponseCode === 200) {
                    this.notification.success(detail?.Message);
                    this.notification.success(this.translateService.instant('Please wait till we verify the received payment') + '.');

                    this.appFacadeService.setLoading(true);

                    const paymentData: PaymentData = this.appMediatorService.porPayService.getPaymentData(customer, config.organizationId ?? '', detail, selectedContractsModels);

                    return this.appMediatorService.porPayService.verifyPayment(config.customerId ?? '', paymentData).pipe(
                        switchMap(res => {
                            this.logger.logWarning(res);
                            this.appFacadeService.setLoading(false);
                            this.porTransactionService.setTransactionSuccess(true);
                            this.contractService.loadContracts({ customerId: config.customerId ?? '' });
                            return [
                                successVerifyPayment({ lastPaymentDetail: detail }),
                                closePayment(),
                                setSelectedContracts({ selectedContracts: [] }),
                                showDialog({ dialogContentType: DialogContentType.PaymentSuccess })
                            ];
                        }),
                        catchError(error => {
                            this.logger.logError(error, '', true);
                            this.appFacadeService.setLoading(false);
                            return [failVerifyPayment({ error: 'Failed to verify payment' }), setDialogEnabled({ dialogEnabled: true })];
                        })
                    );
                } else if (detail?.ResponseCode === 400 && detail?.Error === 'Validation failed') {
                    this.logger.logError(paymentAppOutput?.detail, '', true);
                    return [
                        setPaymentAppInput({ paymentAppInput: '' }),
                        failCreateTransaction({ error: this.translateService.instant('PaymentWebComponentFailed') + paymentAppOutput?.detail?.Message }),
                        setDialogEnabled({ dialogEnabled: true })
                    ];
                } else {
                    this.logger.logError(paymentAppOutput?.detail, '', true);
                    return [
                        setPaymentAppInput({ paymentAppInput: '' }),
                        failCreateTransaction({ error: this.translateService.instant('PaymentWebComponentFailed') + (paymentAppOutput?.detail?.Message ? paymentAppOutput?.detail?.Message : '') }),
                        setDialogEnabled({ dialogEnabled: true })
                    ];
                }
            })
        )
    );
}
