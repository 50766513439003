import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Redirection } from '../../types/redirection.type';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { loadVersionRedirection, loadVersionRedirectionFailure, setVersionRedirection } from '../actions/versionredirection.actions';
import { TranslateService } from '@ngx-translate/core';
import { VersionToggleService } from '../../services/version-toggle';
import { AppFacadeService } from '../../services/app-facade.service';

@Injectable()
export class VersionRedirectionEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly versionToggleService: VersionToggleService,
        private readonly translateService: TranslateService,
        private readonly appFacadeService: AppFacadeService
    ) {}

    loadVersionRedirection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadVersionRedirection),
            tap(() => {
                this.appFacadeService.setLoading(true);
            }),
            switchMap(action =>
                this.getComponentVersion(action.orgId).pipe(
                    map((redirectionVersion: Redirection) => {
                        this.appFacadeService.setLoading(false);
                        return setVersionRedirection({ redirectionVersion });
                    }),
                    catchError(error => {
                        this.appFacadeService.setLoading(false);
                        return of(loadVersionRedirectionFailure({ error: error.message }));
                    })
                )
            )
        )
    );

    private getComponentVersion(orgId: string) {
        return this.versionToggleService.getOrgVersion(orgId).pipe(
            map(() => {
                const toLoad = this.determineComponentToLoad();
                return { toLoad, orgId };
            }),
            catchError(e => {
                let errorMessage = e?.error?.message;
                let toLoad: ComponentsToLoad = ComponentsToLoad.ConsumerPortalError;

                if (errorMessage.includes('OrganizationIamCredsNotFound')) {
                    if (this.versionToggleService.isQuickLinkVersion()) {
                        errorMessage = this.translateService.instant('OrganizationIamCredsNotFound');
                    } else {
                        errorMessage = '';
                        toLoad = ComponentsToLoad.BasicConsumerPortal;
                    }
                }

                return of({
                    toLoad,
                    orgId
                });
            })
        );
    }

    private determineComponentToLoad(): ComponentsToLoad {
        if (this.versionToggleService.isConsumerQuickLinkVersion() && this.versionToggleService.isAdvanceVersion()) {
            return ComponentsToLoad.ConsumerPortal;
        }
        if (this.versionToggleService.isQuickLinkVersion() && !this.versionToggleService.isConsumerQuickLinkVersion()) {
            return ComponentsToLoad.QuickLink;
        }
        if (this.versionToggleService.isAdvanceVersion()) {
            return ComponentsToLoad.ConsumerPortal;
        }
        return ComponentsToLoad.BasicConsumerPortal;
    }
}
