import { createReducer, on } from '@ngrx/store';
import { quickLinkInitialState } from './quicklink.state';
import { setQuickLinkData } from './quicklink.actions';

export const quickLinkDataReducer = createReducer(
    quickLinkInitialState,
    on(setQuickLinkData, (state, { quickLinkData }) => ({
        ...state,
        quickLinkData
    }))
);
