import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Directive({
    selector: '[porFeatureAvailability]'
})
export class AvailabilityDirective implements OnChanges {
    @Input() featureToken: string[] = [];
    @Input() options?: { subFeature: string; state: string; ignoreFeatureCheck?: boolean };
    @Input() fallbackTemplate?: TemplateRef<any>;

    constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef, private readonly featureToggleService: FeatureToggleService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['featureToken'] || changes['options']) {
            this.checkAvailability();
        }
    }

    private checkAvailability(): void {
        this.viewContainer.clear();

        // Check for availability with options if provided
        if (this.options && this.options.subFeature && this.options.state) {
            const isAnyFeatureAvailable = this.featureToken.some(token => this.featureToggleService.isAvailable(token, this.options));

            if (isAnyFeatureAvailable) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                return;
            }
        }

        // Fallback to checking availability with just the featureToken
        const isAnyFeatureAvailable = this.featureToken.some(token => this.featureToggleService.isAvailable(token));

        if (isAnyFeatureAvailable) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.fallbackTemplate) {
            this.viewContainer.createEmbeddedView(this.fallbackTemplate);
        }
    }
}
