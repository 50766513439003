<div class="invoice-details-page">
    <div class="header-section">
        <div class="header">
            <h1 class="theme-heading">{{ 'InvoiceDetails' | translate }}</h1>
            <por-base-button [featureName]="'invoice-detail-close'" (click)="onCloseButtonClick()" icon="close"></por-base-button>
        </div>
    </div>
    <div class="content">
        <ng-container [ngTemplateOutlet]="id_consumer"></ng-container>
    </div>
</div>
<ng-template #id_consumer>
    <div>
        <ng-container [ngTemplateOutlet]="id_common_dates"></ng-container>
        <div class="detail">
            <ng-container [ngTemplateOutlet]="id_common_stats"></ng-container>
            <ng-container [ngTemplateOutlet]="id_common_btns"></ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="id_common_line_item_table"></ng-container>
    </div>
</ng-template>
<ng-template #id_common_stats>
    <div class="stats">
        <p>
            <label porAddFeature featureName="invoice-detail-invoice-number">{{ 'invoiceNumber' | translate }}</label>
            <span>{{ invoiceDetail.Name }}</span>
        </p>
    </div>
</ng-template>
<ng-template #id_common_dates>
    <div class="dates">
        <div>
            <p *ngIf="invoiceDetail.InvoiceDate">
                <label porAddFeature featureName="invoice-detail-invoice-date-label">{{ 'invoiceDate' | translate }} :</label>
                <span>{{ invoiceDetail.InvoiceDate | date : dateFormat }}</span>
            </p>
        </div>
    </div>
</ng-template>
<ng-template #id_common_btns>
    <div class="pay-btns">
        <span class="pdf-btn" *ngIf="featureToggleService.isAvailable('isInvoicePdfDisplayed')">
            <por-base-button
                *ngIf="appFacadeService.isDocumentExists('Invoice') | async"
                [loading]="(appFacadeService.isDocumentLoading('Invoice') | async) === true"
                porAddFeature
                [featureName]="'invoice-pdf-btn'"
                (click)="openDocumentUrl('Invoice')">
                <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-pdf-file-5441350.svg" />
            </por-base-button>
        </span>
    </div>
</ng-template>
<ng-template #id_common_line_item_table>
    <div class="line-items-table">
        <table>
            <thead>
                <th class="text-center">{{ 'stockNumber' | translate }}</th>
                <th>{{ 'columnNameItem' | translate }}</th>
                <th class="text-center">{{ 'startDate' | translate }}</th>
                <th class="text-center">{{ 'End Date' | translate }}</th>
                <th class="text-center">{{ 'period' | translate }}</th>
                <th class="text-center">{{ 'Rate' | translate }}</th>
                <th class="text-center">{{ 'cost' | translate }}</th>
            </thead>
            <tbody>
                <tr>
                    <td colspan="7" class="text-center">{{ 'Coming Soon' | translate }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6" class="text-right">{{ 'netCharge' | translate }}</td>
                    <td class="text-center">{{ invoiceDetail.GrandTotal | formatCurrency }}</td>
                </tr>
                <tr>
                    <td colspan="6" class="text-right">{{ 'tax' | translate }}</td>
                    <td class="text-center">{{ invoiceDetail.TotalTax | formatCurrency }}</td>
                </tr>
                <tr>
                    <td colspan="6" class="text-right">{{ 'grossCharge' | translate }}</td>
                    <td class="text-center">{{ invoiceDetail.GrandTaxTotal | formatCurrency }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</ng-template>
