import { AfterViewInit, Component, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'por-contract-detail-renderer-component',
    template: `
        <button (click)="loadContractDetail()" porAddFeature featureName="contract-button-details" class="btn-link" [dynamicValue]="cellValue">
            {{ cellValue }}
        </button>
    `,
    styleUrls: ['contract-detail.component.scss']
})
export class PorContactDetailRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    public cellValue!: string;
    contractId!: string;
    testId!: string;
    public params!: ICellRendererParams;

    constructor(private readonly cdref: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.cdref.detectChanges();
    }
    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.cellValue = this.getValueToDisplay(params);
        this.params = params;
        this.contractId = params.data?.contractId;
        this.testId = `contract-detail-button-${this.contractId}`;
    }

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
        return true;
    }

    loadContractDetail() {
        this.params.context?.componentParent.loadContractDetailModal(this.contractId);
    }

    getValueToDisplay(params: ICellRendererParams) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }
}
