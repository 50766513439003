import { setDialogDisplayed, showDialog } from '../actions/dialog.actions';
import { initialDialogState } from '../state/dialog.state';
import { createReducer, on } from '@ngrx/store';

export const dialogReducer = createReducer(
    initialDialogState,
    on(showDialog, (state, { dialogContentType }) => {
        return { ...state, dialogContentType, isDialogDisplayed: true };
    }),
    on(setDialogDisplayed, (state, { isDialogDisplayed }) => {
        return { ...state, isDialogDisplayed };
    })
);
