import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { Address } from '../../types';

@Pipe({
    name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {
    checkEmpty(value?: string) {
        return !isEmpty(value) ? value : '';
    }
    transform(value: Address[]): unknown {
        if (!value?.length) {
            return;
        }
        const billingAddress = value.filter((address: Partial<Address>) => address?.Type === 'Billing');
        return this.format(billingAddress[0]);
    }
    format(address: Address): string {
        const addressFields = [
            this.checkEmpty(address.Line1),
            this.checkEmpty(address.Line2),
            this.checkEmpty(address.City),
            this.checkEmpty(address.GeoRegion2),
            this.checkEmpty(address.Country),
            address.PostalCode ? address.PostalCode : ''
        ];
        return addressFields.filter(v => v).join(', ');
    }
}
