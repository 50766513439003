import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PayMinimumAmountEnum } from '../../../enums/pay-minimum-amount.enum';
import { isRouteParams } from '../../../utils/routeParams-utils';
import { ConfigState } from './config.reducers';

export const selectConfigState = createFeatureSelector<ConfigState>('config');

export const selectConfig = createSelector(selectConfigState, (state: ConfigState) => state.config);

export const selectRouteParams = createSelector(selectConfig, config => {
    const routeParams = config?.routeTo?.routeParams;
    return isRouteParams(routeParams) ? routeParams : undefined;
});

export const selectRouteParamsCurrency = createSelector(selectRouteParams, routeParams => {
    return routeParams?.currencyCode;
});

export const selectRouteParamsTotal = createSelector(selectRouteParams, routeParams => {
    return routeParams?.amount;
});

export const selectRouteParamsContractId = createSelector(selectRouteParams, routeParams => {
    return routeParams?.contractId ? routeParams?.contractId : '';
});

export const selectRouteParamsContractCustomerId = createSelector(selectRouteParams, routeParams => {
    return routeParams?.contractCustomerId ? routeParams?.contractCustomerId : '';
});

export const selectCustomerId = createSelector(selectConfig, selectRouteParams, (state, routeParams) => {
    const customerIdFromRoute = routeParams?.contractCustomerId;
    const customerIdFromState = state?.customerId;

    // Ensure a valid fallback is returned
    return customerIdFromRoute ?? customerIdFromState ?? '0';
});

export const selectPayMinimumAmount = createSelector(selectConfig, state => {
    switch (state?.payMinimumAmountDueText) {
        case PayMinimumAmountEnum.InitialPaymentDue.toString():
            return {
                text: PayMinimumAmountEnum.InitialPaymentDue.toString(),
                translate: true
            };
        case PayMinimumAmountEnum.PayMinimumAmount.toString():
            return {
                text: PayMinimumAmountEnum.PayMinimumAmount.toString(),
                translate: true
            };
        default:
            return {
                text: state?.payMinimumAmountDueText ?? '',
                translate: false
            };
    }
});
