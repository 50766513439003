import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';
import { AppFacadeService } from '../../services/app-facade.service';
import { QuickLinkService } from '../../services/quicklink.service';
import { ConfigurationService } from '../../services/configuration.service';
import { redirectionFailure, redirectionSuccess, startRedirection, setRedirectionLoginSession, redirectToConsumerPortal, redirectToSubdomain, redirectConfigSet } from '../actions/redirection.action';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { QuickLink } from '../../models/quicklink.model';
import { LoginSession } from '../../models/login-session.model';
import { LoggerService } from '../../services/logger.service';
import { ErrorType } from '../../models/error.model';
import { ErrorService } from '../../services/error.service';
import { setQuickLinkData } from '../actions/quicklink.actions';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { setConfig } from '../actions/config.actions';
import { AuthKeys } from '../../enums/local-storage-keys.enum';
import { AppMediatorService } from '../../services/app-mediator.service';

@Injectable()
export class RedirectionEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly appFacadeService: AppFacadeService,
        private readonly quickLinkService: QuickLinkService,
        private readonly configService: ConfigurationService,
        private readonly logger: LoggerService,
        private readonly errorService: ErrorService,
        private readonly appMediatorService: AppMediatorService
    ) {}

    startRedirection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startRedirection),
            tap(() => this.appFacadeService.setLoading(true)),
            tap(() => this.appMediatorService.localStorageService.removeSessionItem(AuthKeys.User)), // Clear session storage, so that new data get available on interceptor
            switchMap(({ redirection, cpInput }) => {
                if (redirection.toLoad !== ComponentsToLoad.QuickLink) {
                    return of(redirectionSuccess({ redirection, cpInput }));
                }

                // Handle QuickLink
                return this.quickLinkService.getConsumerData().pipe(
                    concatMap(quicklinkData => {
                        const authUser = quicklinkData as unknown as QuickLink;
                        // Set Consumer Portal Configurations
                        cpInput = this.configService.consolidateConsumerPortalConfig(undefined, {
                            ...cpInput,
                            // Note camelCase: DB Model
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Auth: authUser,
                            customerId: authUser?.CustomerId,
                            contractId: authUser.ContractId,
                            organizationId: authUser?.OrganizationId,
                            organizationType: authUser.OrganizationType
                        });

                        // Dispatch multiple actions as a flattened stream
                        return [
                            setQuickLinkData({ quickLinkData: authUser }),
                            setConfig({ config: cpInput }),
                            setRedirectionLoginSession({
                                session: {
                                    ...authUser,
                                    customerId: authUser.CustomerId,
                                    initialLogin: true
                                } as LoginSession
                            }),
                            redirectionSuccess({
                                redirection: {
                                    ...redirection,
                                    toLoad: ComponentsToLoad.ConsumerPortal,
                                    orgId: authUser.OrganizationId
                                },
                                cpInput
                            })
                        ];
                    }),
                    catchError(() => of(redirectionFailure({ error: 'FailedExtractQuickLink' })))
                );
            })
        )
    );

    redirectionSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(redirectionSuccess),
            switchMap(({ redirection, cpInput }) => {
                // Routed to subdomain
                if (redirection.orgId === '' && redirection.toLoad === ComponentsToLoad.Subdomain) {
                    return of(redirectToSubdomain());
                }

                if (redirection.orgId === '' && redirection.toLoad === ComponentsToLoad.ConsumerPortal) {
                    return of(redirectToConsumerPortal());
                }

                // Routed to consumer portal, after getting orgId from subdomain
                return this.configService.getCpOrgConfig({ ...cpInput, organizationId: redirection.orgId }).pipe(
                    concatMap(orgConfig => {
                        // Update OrgConfig
                        const config: ConsumerPortalConfig = this.configService.consolidateConsumerPortalConfig(undefined, { ...cpInput, ...orgConfig });
                        return [setConfig({ config }), redirectConfigSet({ isConfigSet: true }), redirectToConsumerPortal()];
                    }),
                    catchError(error => {
                        // If org config not found in the database, then use default and redirect to consumer portal
                        this.logger.logError(error);
                        this.logger.alertDevError(error);
                        this.errorService.setError(ErrorType.InvalidSubDomainOrgId);
                        return of(redirectToConsumerPortal());
                    })
                );
            })
        )
    );
}
