export type QueryOptions = {
    columns?: QueryOptionsColumn[];
    filters?: QueryOptionsFilter | QueryOptionsFilter[];
    pagination?: QueryOptionsPagination;
    sorting?: QueryOptionsSort | QueryOptionsSort[];
};

export type QueryOptionsColumn = string;

export type QueryOptionsFilterComposition = {
  field?: never;
  type: 'AND' | 'and' | 'OR' | 'or';
  value: QueryOptionsFilter[];
};

export type QueryOptionsFilterNull = {
  field: string;
  type: 'IS NULL' | 'is null' | 'ISNULL' | 'isnull' | 'NOT NULL' | 'not null' | 'NOTNULL' | 'notnull';
  value?: never;
};

export type QueryOptionsFilterIn = {
  field: string;
  type: 'IN' | 'in' | 'NOT IN' | 'not in';
  value: string[];
};

export type QueryOptionsFilterNumericComparison = {
  field: string;
  type: '<' | '>' | '>=' | '<=';
  value: number;
};

export type QueryOptionsFilterStringComparison = {
  field: string;
  type: 'LIKE' | 'NOT LIKE' | 'NOT REGEXP' | 'REGEXP' | 'RLIKE';
  value: string;
};

export type QueryOptionsFilterEquality = {
  field: string;
  type?: '<>' | '!=' | 'NOT' | '=';
  value: string | number;
};

export type QueryOptionsFilter =
    | QueryOptionsFilterComposition
    | QueryOptionsFilterNull
    | QueryOptionsFilterIn
    | QueryOptionsFilterNumericComparison
    | QueryOptionsFilterStringComparison
    | QueryOptionsFilterEquality;

export type QueryOptionsSort = {
    field: string;
    order?: 'ASC' | 'asc' | 'DESC' | 'desc';
};

export type QueryOptionsPagination = {
  page: number;
  pageSize: number;
};

// ========================

/**
 * @deprecated Use QueryOptionsFilter instead.
 */
export interface ApiFilter {
    Field: string;
    Type: '==' | '!=' | '>' | '>=' | '<' | '<=' | 'IN' | 'NOT IN' | 'IS NULL' | 'NOT NULL' | 'AND' | 'OR' | 'LIKE' | '!LIKE' | 'RLIKE' | '!RLIKE' | 'LLIKE' | '!LLIKE';
    Value: string | ApiFilter[];
}

/**
 * @deprecated Use QueryOptionsPagination instead.
 */
export interface ApiPaging {
    Page: number;
    PageSize: number;
    TotalCount: number;
}

/**
 * @deprecated Use QueryOptionsSort instead.
 */
export interface ApiSort {
    Field: string;
    Order: 'asc' | 'desc';
}
[];
