import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, catchError, map, of, switchMap, take, tap } from 'rxjs';
import { ConsumerService } from '../../../services/consumers.services';
import { Consumer } from '../../../models/consumer-model';
import isEmpty from 'lodash-es/isEmpty';
import { LoggerService } from '../../../../services';

type ConsumersObservable = { search: string; pageNumber: number; records: Consumer[]; noMoreRecord: boolean };
@Component({
    selector: 'por-admin-account-list',
    templateUrl: './admin-account-list.component.html',
    styleUrls: ['./admin-account-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminAccountListComponent implements OnDestroy {
    constructor(private readonly consumersService: ConsumerService, @Inject('Window') private readonly window: Window, private readonly logger: LoggerService) {}
    loading$ = new BehaviorSubject(false);
    subscriptions: Subscription[] = [];
    /* Need to use oldrecord as the observable is not emitting the latest value*/
    oldRecords: Consumer[] = [];
    private readonly consumers = new BehaviorSubject<ConsumersObservable>({ records: [], pageNumber: 1, search: '', noMoreRecord: false });
    consumers$ = this.consumers.pipe(
        tap(() => {
            this.loading$.next(true);
        }),
        switchMap((payload: ConsumersObservable) => {
            const pageNumber = payload.pageNumber || 1;
            return this.consumersService.getAllConsumers(payload.pageNumber, payload.search).pipe(
                map((res: Consumer[]) => {
                    let newRecord: Consumer[] = [];
                    if (payload.pageNumber > 1) {
                        newRecord = [...this.oldRecords, ...res];
                        this.oldRecords = newRecord;
                    } else {
                        newRecord = [...res];
                        this.oldRecords = [...res];
                    }
                    const objToReturn: ConsumersObservable = {
                        records: newRecord,
                        search: payload.search,
                        pageNumber: pageNumber,
                        noMoreRecord: payload.noMoreRecord
                    };

                    if (isEmpty(res)) {
                        objToReturn.noMoreRecord = true;
                    } else {
                        objToReturn.noMoreRecord = false;
                    }

                    if (isEmpty(payload.search)) {
                        objToReturn.pageNumber = ++objToReturn.pageNumber;
                    } else {
                        if (payload.pageNumber === 1) {
                            objToReturn.pageNumber = ++objToReturn.pageNumber;
                        }
                    }
                    return objToReturn;
                })
            );
        }),
        catchError(error => {
            this.logger.logError(error);
            const objToReturn: ConsumersObservable = {
                records: [],
                search: '',
                pageNumber: 1,
                noMoreRecord: false
            };
            this.loading$.next(false);
            return of(objToReturn);
        }),
        tap(() => {
            this.loading$.next(false);
        })
    );

    loadMore() {
        const oldValues = { ...this.consumers.getValue() };
        this.consumers.next({
            search: oldValues.search,
            pageNumber: ++oldValues.pageNumber,
            records: oldValues.records,
            noMoreRecord: oldValues.noMoreRecord
        });
    }

    generateQuickLink(id: string) {
        this.loading$.next(true);
        this.subscriptions.push(
            this.consumersService
                .generateQuickLink(id)
                .pipe(
                    take(1),
                    switchMap(res => {
                        const { url } = res as { url: string };
                        this.window.open(url, '_blank');
                        return of(res);
                    }),
                    catchError(e => {
                        this.logger.logError(e);
                        return of(false);
                    }),
                    tap(() => {
                        this.loading$.next(false);
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    setSearch(search: string) {
        this.consumers.next({
            search,
            pageNumber: 1,
            records: [],
            noMoreRecord: false
        });
    }
}
