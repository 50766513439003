<div class="d-inline-flex justify-content-evenly w-100 m-d-block">
    <div class="col-3">
        <por-document-button
            [label]="'Request Return' | translate"
            [featureToken]="['callOffAbility']"
            [visible]="requestReturnButtonVisible"
            [featureName]="'request-calloff-button'"
            [uiUrl]="uiUrl"
            [loading]="false"
            [imgUrl]="'noun-return-4960178.svg'"
            (onclick)="renderRequestReturn()" />
    </div>
    <div class="col-3" *ngIf="requestServiceButtonVisible">
        <por-document-button
            [label]="'Request Service' | translate"
            [featureToken]="['requestService']"
            [visible]="requestServiceButtonVisible"
            [featureName]="'request-service-button'"
            [uiUrl]="uiUrl"
            [loading]="false"
            [imgUrl]="'noun-service-2474061.svg'"
            (onclick)="renderRequestService()" />
    </div>
    <div class="col-3" *ngIf="sendDocumentButtonVisible">
        <por-document-button
            [label]="'SendDocument' | translate"
            [visible]="requestServiceButtonVisible"
            [featureName]="'send-document-link'"
            [uiUrl]="uiUrl"
            [loading]="false"
            [imgUrl]="'mail-signal-1684972-1442449.svg'"
            (onclick)="renderSendDocumentLink()" />
    </div>
</div>
