export function trimName(itemName: string | undefined | null): string {
    if (typeof itemName !== 'string') {
        return '';
    }
    return itemName.replace(/\s*\(.*$/, '').trim();
}

/**
 * split currencyCode from Amount
 * @param value
 * @returns string
 */
export function splitCurrencyAmount(value: string): string {
    if (!value) return '';
    const parts = value.split(' '); // Assuming the format is "USD 298.77"
    if (parts.length !== 2) return value; // Return as is if it doesn't match expected format

    return `<span class="currency">${parts[0]}</span> <span class="amount">${parts[1]}</span>`;
}
