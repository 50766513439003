import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Directive({
  selector: '[porClickOutSide]',
})
export class ClickOutSideDirective {
  constructor(private readonly elementRef: ElementRef, @Inject(DOCUMENT) private readonly document: Document) {}

  @Output() readonly clickedOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const elements = this.document.getElementsByTagName('mat-datepicker-content')
  
    if (
      !targetElement ||
      targetElement.classList.toString().includes('mat-calendar') ||
      targetElement.classList.toString().includes('por-ac-contracts') ||
      targetElement.id.includes('mat-calendar') ||
      elements.length > 0
    ) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickedOutside.emit(event);
    }
  }
}
