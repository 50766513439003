import { Injectable } from '@angular/core';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { map } from 'rxjs/operators';
import { OrganizationVersion } from '../models/organization-detail.model';
import { Observable } from 'rxjs';
import { OrganizationVersionEnum } from '../enums/organization-version.enum';
import { QuickLinkService } from './quicklink.service';
import { GapiLicense, GapiLicenseType } from '../enums/gapi-license';
import { FeatureToggleService } from './feature-toggle.service';

@Injectable({
    providedIn: 'root'
})
export class VersionToggleService {
    constructor(private readonly consumerPortalApi: ConsumerPortalApiService, private readonly quickLinkService: QuickLinkService, private readonly featureToggleService: FeatureToggleService) {}
    activeVersion: OrganizationVersionEnum = OrganizationVersionEnum.Basic;
    hasPayment = false;
    /**
     * Make it generic so in future we can add more licenses type
     */
    orgnizationLicenses: OrganizationVersion = {
        version: OrganizationVersionEnum.Basic,
        payment: false,
        externalConsumerPortal: false
    };

    getOrgVersion(orgId: string): Observable<OrganizationVersion> {
        return this.consumerPortalApi.get<OrganizationVersion>(`organization-config/${orgId}/version`).pipe(
            map(data => {
                const response = data as unknown as OrganizationVersion;
                this.setActiveVersion(response);
                this.setPaymentActive(response);
                this.orgnizationLicenses = response;
                return response;
            })
        );
    }

    setActiveVersion(version: OrganizationVersion) {
        this.activeVersion = version.version;
    }

    setPaymentActive(version: OrganizationVersion) {
        this.hasPayment = version.payment;
    }

    isLicenseActive(licenseKey: GapiLicenseType): boolean {
        switch (licenseKey) {
            case GapiLicense.AcquiringCCProccessing:
                return this.hasPayment;
            case GapiLicense.ExternalConsumerPortal:
                return this.orgnizationLicenses.externalConsumerPortal;
            default:
                false;
        }
        return false;
    }

    isAdvanceVersion(): boolean {
        return this.isActiveVersion() === OrganizationVersionEnum.Advanced;
    }

    isBasicVersion(): boolean {
        return this.isActiveVersion() === OrganizationVersionEnum.Basic;
    }

    isActiveVersion(): OrganizationVersionEnum {
        if (this.featureToggleService.isSyrinx()) {
            this.activeVersion = OrganizationVersionEnum.Advanced;
        }
        return this.activeVersion;
    }

    isQuickLinkVersion(): boolean {
        return QuickLinkService.isQuickLink;
    }
    isConsumerQuickLinkVersion(): boolean {
        return this.quickLinkService.consumerQuickLinkVersion;
    }

    isQLButNotConsumerQL(): boolean {
        return this.isQuickLinkVersion() && !this.isConsumerQuickLinkVersion();
    }

    isNotQLOrConsumerQL(): boolean {
        return !this.isQuickLinkVersion() || this.isConsumerQuickLinkVersion();
    }

    isQuickLinkPdfExternalPortal(): boolean {
        return this.isQuickLinkVersion() && this.isLicenseActive(GapiLicense.ExternalConsumerPortal);
    }

    /**
     * Check for ExternalConsumerPortalURL is false for basic version
     * @returns {boolean}
     */
    isBasicExternalConsumerPortalURL(): boolean {
        return this.isBasicVersion() && this.isLicenseActive(GapiLicense.ExternalConsumerPortal);
    }

    /**
     * Check for QuickLink is Stock version
     * @returns {boolean}
     */
    isStockQuickLinkVersion(): boolean {
        return this.quickLinkService.stockQuickLinkVersion;
    }

    /**
     * Check for QuickLink is contract QL, not Consumer and Stock QL
     * @returns {boolean}
     */
    isQLButNotConsumerAndNotQLStockQL(): boolean {
        return this.isQuickLinkVersion() && !this.isConsumerQuickLinkVersion() && !this.isStockQuickLinkVersion();
    }
}
