import { itemsOutFeatureKey, ItemsOutState } from './../reducers/items-out.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const itemsOutSelector = createFeatureSelector<ItemsOutState>(itemsOutFeatureKey);

export const getAllItemsOut = createSelector(itemsOutSelector, (state: ItemsOutState) => state.items);

export const getPageNumberItemsOut = createSelector(itemsOutSelector, (state: ItemsOutState) => state.pageNumber);

export const getNoMoreRecordSelector = createSelector(itemsOutSelector, (state: ItemsOutState) => state.noMoreRecord);
