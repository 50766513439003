import { Observable, Subscription } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import isEmpty from 'lodash-es/isEmpty';
import { TranslateService } from '@ngx-translate/core';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { NotificationService } from '../../services/notification.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LoggerService } from '../../services/logger.service';
import { Customer, CustomerSummary } from '../../models/consumer';
import { CustomerSummaryActionTypes } from '../../store/actions/customer-summary.actions';
import { getCustomer, getCustomerSummary } from '../../store/selector/customer.selectors';
import { AppMediatorService } from '../../services/app-mediator.service';
@Component({
    selector: 'por-summary',
    templateUrl: './account-summary.component.html',
    styleUrls: ['./account-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSummaryComponent implements OnInit, OnDestroy {
    customer$!: Observable<Customer>;
    customerSummary$!: Observable<CustomerSummary>;
    customerId: string | undefined;
    subscriptions: Subscription[] = [];
    inputs: ConsumerPortalConfig | undefined;
    @Input() config: string | undefined;
    @Input() width: string | undefined;
    @Input() height: string | undefined;
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    @Output() readonly appOutput: EventEmitter<{ [key: string]: object }> = new EventEmitter<{ [key: string]: object }>();
    @Output() readonly openContractsWithFilter = new EventEmitter<string>();
    isLoading = true;
    customer = {};
    constructor(
        private readonly store: Store,
        private readonly notification: NotificationService,
        private readonly configService: ConfigurationService,
        private readonly translateService: TranslateService,
        private readonly logger: LoggerService,
        private readonly appMediatorService: AppMediatorService
    ) {}

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
            this.logger.logAssert(this.inputs?.customerId, 'customerId is not defined');
            this.customerId = this.inputs?.customerId || '0';
        }

        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            this.elementLoaded.emit(false);
            return;
        }

        this.customerSummary$ = this.store.pipe(select(getCustomerSummary));
        this.customer$ = this.store.pipe(select(getCustomer));

        this.store.dispatch({ type: CustomerSummaryActionTypes.LoadCustomer, payload: { id: this.customerId } });
        this.store.dispatch({ type: CustomerSummaryActionTypes.LoadCustomerSummary, payload: { id: this.customerId } });

        this.subscriptions.push(
            this.customerSummary$.subscribe(summary => {
                if (summary && !isEmpty(summary)) {
                    this.isLoading = false;
                }
            })
        );

        this.elementLoaded.emit(true);
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    openContractsAndFilter(filters: string): void {
        this.openContractsWithFilter.emit(filters);
    }
}
