/* eslint-disable max-lines */
import isEmpty from 'lodash-es/isEmpty';
import { LoggerService } from '../../services/logger.service';
import { AppFacadeService } from '../../services/app-facade.service';
import { NotificationService } from '../../services/notification.service';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { select, Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, debounceTime, delay, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ApplyColumnStateParams, ColDef, ColumnApi, GridApi, GridReadyEvent, CellDoubleClickedEvent, IsFullWidthRowParams, ProcessCellForExportParams, CsvExportParams } from 'ag-grid-community';
import { ConfigurationService } from '../../services/configuration.service';
import { ContractService } from '../../services/contract.service';
import { getNoMoreRecord, getContracts, getSelected } from '../../store/selector/contracts.selectors';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../services';
import { formatDateType } from '../../types';
import { AgGridService } from '../../services/ag-grid.service';
import { ContractFullWidthRowComponent } from '../contract-full-width-row/contract-full-width-row.component';
import { ContractCP, ContractDetail } from '../../models/contract-model';
import { PorContactDetailRendererComponent } from '../contract-detail/contract-detail-renderer.component';
import { Filter, FilterOutput } from '../../shared/models/filters.model';
import { increasePageNumberContract, resetContractsLatestToShow, resetPageNumberContract } from '../../store/actions/contract.actions';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { ContractDetailComponent } from '../contract-detail/contract-detail.component';
import { Customer } from '../../models/consumer';
import { VersionToggleService } from '../../services/version-toggle';
import { ProductDetail } from '../../models/product-detail';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { AppMediatorService } from '../../services/app-mediator.service';
import { FormatCurrencyPipe } from '../../pipes/format-currency.pipe';
import { ContractFilterStatus } from '../../enums/contract-filter-status.enum';
import { ContractSelectionCheckboxRendererComponent } from './renderer/contract-selection-checkbox-renderer.component';

@Component({
    selector: 'por-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractsComponent implements OnInit, OnDestroy {
    headerHeight = 35;
    rowHeight = 65;
    gridReady$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    gridReadyObservable$: Observable<boolean> = this.gridReady$.pipe(delay(1000));
    contractlist: Subject<boolean> = new Subject<boolean>();
    contractlist$ = this.contractlist.asObservable().pipe(
        tap(() => {
            this.isDetailPageOpened = false;
        }),
        delay(2000),
        tap(() => {
            this.loadContracts();
        })
    );
    contractDetail!: ContractDetail[];
    productDetail!: ProductDetail;
    @Input() customer!: Customer | null;

    public rowSelection: 'single' | 'multiple' = 'multiple';

    public context: { componentParent: ContractsComponent };
    overlayStates = false;

    constructor(
        public appFacadeService: AppFacadeService,
        public contractService: ContractService,
        private readonly store: Store,
        private readonly notification: NotificationService,
        private readonly logger: LoggerService,
        private readonly configService: ConfigurationService,
        private readonly datePipe: FormatDatePipe,
        private readonly translateService: TranslateService,
        private readonly modal: ModalService,
        private readonly agGrid: AgGridService,
        private readonly breakpointObserver: BreakpointObserver,
        readonly featureService: FeatureToggleService,
        readonly versionToggle: VersionToggleService,
        private readonly appMediatorService: AppMediatorService,
        private readonly formatCurrency: FormatCurrencyPipe,
        private readonly cdn: ChangeDetectorRef
    ) {
        this.context = {
            componentParent: this
        };
    }

    public getScreenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
    public filterComponentProps: { searchPlaceHolder: string; dateLabel: string } = {
        searchPlaceHolder: this.translateService.instant('searchContracts'),
        dateLabel: this.translateService.instant('selectStartDateRange')
    };
    contracts$!: Observable<ContractCP[]>;
    isRecordAvaible$: Observable<boolean> = this.store.pipe(select(getNoMoreRecord));
    customerId!: string;
    uiUrl: string | undefined;
    filters: FilterOutput | undefined;
    subscriptions: Subscription[] = [];
    gridApi!: GridApi;
    gridColumnApi!: ColumnApi;
    paymentPageEnabled$: Observable<boolean> = new Observable();
    isDetailPageOpened = false;
    filterValues: Filter[] = [
        {
            label: this.translateService.instant('Quotes'),
            value: ContractFilterStatus.Quote
        },
        {
            label: this.translateService.instant('Reservation'),
            value: ContractFilterStatus.Reservation
        },
        {
            label: this.translateService.instant('Open'),
            value: ContractFilterStatus.Open
        },
        {
            label: this.translateService.instant('Returned'),
            value: ContractFilterStatus.Returned
        },
        {
            label: this.translateService.instant('Closed'),
            value: ContractFilterStatus.Closed
        },
        {
            label: this.translateService.instant('showAll'),
            value: ContractFilterStatus.ShowAll
        }
    ];

    inputs: ConsumerPortalConfig | undefined;
    @Input() config: string | undefined;
    @Input() width: string | undefined;
    @Input() height: string | undefined;
    @Input() initialStatusFilters?: string[];
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    @Output() readonly appOutput: EventEmitter<ContractCP[]> = new EventEmitter<ContractCP[]>();
    @Output() readonly clearInitFilters: EventEmitter<boolean> = new EventEmitter<boolean>();
    public columnDefs: ColDef[] = [];
    @ViewChild(ContractDetailComponent) contractDetailComponent!: ContractDetailComponent;
    selectedContracts: string[] = [];
    public fullWidthCellRenderer = ContractFullWidthRowComponent;
    public isSearchSupported = this.featureService.isAvailable('textSearchMultipleFilters');
    public defaultColDef: ColDef = {
        sortable: true,
        suppressMovable: true,
        resizable: true,
        wrapText: true,
        sortingOrder: ['asc', 'desc'],
        headerComponentParams: {
            template: this.getHeaderTemplate()
        }
    };

    isFullWidth = false;
    public isFullWidthRow: (params: IsFullWidthRowParams) => boolean = () => {
        return this.isFullWidth;
    };

    private readonly formatDate = (params: formatDateType) => {
        const date = this.datePipe.transform(params.value);
        return `<span class="theme-table-row">${date}</span>`;
    };

    private readonly formatAmount = (params: { value: number }) => {
        const { value } = params;
        return `<span class="theme-table-row">${this.formatCurrency.transform(String(value))}</span>`;
    };

    private readonly formatAmountDue = (params: { value: number; data: { paymentPending: boolean } }) => {
        const { value } = params;
        return `<span class="theme-table-row">${this.contractService.getAmountDueText(value, params.data.paymentPending)}</span>`;
    };

    openViewModal(id: string) {
        this.modal.open(id + '-view');
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridApi.setDomLayout('autoHeight');
        this.gridColumnApi = params.columnApi;
        const columnState: ApplyColumnStateParams = {
            state: [
                {
                    colId: 'openDate',
                    sort: 'desc'
                }
            ]
        };
        this.gridColumnApi.applyColumnState(columnState);
        this.agGrid.setHorizontalScrollBar();
        this.autoSizeAll();
        document.querySelectorAll('.ag-header-container .ag-checkbox-input').forEach(e => {
            e.setAttribute('data-testid', 'por-cp-header-checkbox');
        });
    }

    onCellDoubleClicked(e: CellDoubleClickedEvent) {
        this.loadContractDetailModal(e.data?.contractId);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth.next(window.innerWidth);
    }

    setResponsiveGrid(width: number) {
        this.gridReady$.next(false);
        if (width > 1024) {
            this.columnDefs = [
                ...(this.featureService.isAvailable('multiplePaymentAbility')
                    ? [
                          {
                              field: 'selection',
                              headerName: '',
                              minWidth: 50,
                              cellClass: 'theme-table-row',
                              cellRenderer: ContractSelectionCheckboxRendererComponent
                          }
                      ]
                    : []),
                {
                    field: 'contractName',
                    headerName: this.translateService.instant('contract'),
                    minWidth: 120,
                    cellRenderer: PorContactDetailRendererComponent,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'GrandTotal',
                    headerName: this.translateService.instant('amount'),
                    minWidth: 100,
                    cellClass: 'theme-table-row',
                    cellRenderer: this.formatAmount,
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'AmountDue',
                    headerName: this.translateService.instant('amountDue'),
                    minWidth: 140,
                    cellRenderer: this.formatAmountDue,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'status',
                    headerName: this.translateService.instant('status'),
                    minWidth: 100,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'openDate',
                    headerName: this.translateService.instant('startDate'),
                    cellRenderer: this.formatDate,
                    minWidth: 130,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'closeDate',
                    headerName: this.translateService.instant('closeDate'),
                    cellRenderer: this.formatDate,
                    minWidth: 130,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'purchaseOrder',
                    headerName: this.translateService.instant('purchaseOrder'),
                    minWidth: 130,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'info',
                    headerName: this.translateService.instant('location'),
                    width: 300,
                    autoHeight: true,
                    wrapText: true,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                }
            ];

            if (this.featureService.isAvailable('contractGridJobColumnAvailable')) {
                this.columnDefs.splice(8, 0, {
                    field: 'jobNumber',
                    headerName: this.translateService.instant('jobNumber'),
                    width: 200,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                });
            }
            this.headerHeight = 35;
            this.isFullWidth = false;
            this.rowHeight = 65;
        }
        if (width < 1024) {
            this.columnDefs = [];
            this.headerHeight = 0;
            this.isFullWidth = true;
            this.rowHeight = 373;
        }
        this.gridReady$.next(true);
    }

    autoSizeAll() {
        this.gridColumnApi?.autoSizeAllColumns();
        this.gridApi?.sizeColumnsToFit();
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.subscriptions.push(
            this.getScreenWidth.pipe(debounceTime(1000)).subscribe((width: number) => {
                this.setResponsiveGrid(width);
            })
        );

        this.subscriptions.push(
            this.appFacadeService.getSelectedContracts().subscribe(contracts => {
                this.selectedContracts = [...(contracts || [])];
            })
        );
        this.subscriptions.push(this.contractlist$.subscribe());

        document.body.classList.add('por-ac-contracts');
        this.isMultipaymentAvailable();
        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
            this.logger.logAssert(this.inputs?.customerId, 'customerId is not defined');
            this.customerId = this.inputs?.customerId || '0';
        }

        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            this.elementLoaded.emit(false);
            return;
        }

        /* Assigning the Contract Observable the values */
        this.contracts$ = this.store.pipe(select(getContracts));

        // Subscribe to changes in selected state
        const selectedContractId$: Observable<string> = this.store.pipe(select(getSelected));

        this.subscriptions.push(
            selectedContractId$.subscribe(selectedContractId => {
                if (selectedContractId) {
                    // Open the contract when selected is true
                    this.reloadContract(selectedContractId);
                }
            })
        );

        /* Calling get contract on Initialization */
        if (this.initialStatusFilters) {
            this.filters = {
                multifilters: this.initialStatusFilters,
                search: '',
                dates: {
                    startDate: undefined,
                    endDate: undefined
                }
            };
            setTimeout(() => {
                this.clearInitFilters.emit();
            });
            /**
             * If have filter at inIt then reset LatestToShow and Page Number
             */
            this.store.dispatch(resetPageNumberContract());
            this.store.dispatch(resetContractsLatestToShow());
        }
        this.loadContracts();
        this.elementLoaded.emit(true);
        this.subscriptions.push(
            this.contracts$.subscribe(contracts => {
                if (!isEmpty(contracts)) {
                    this.appOutput.emit(contracts);
                }
            })
        );

        // checking window size
        this.subscriptions.push(
            this.breakpointObserver
                .observe(['(max-width: 992px)'])
                .pipe(
                    tap((value: BreakpointState) => {
                        if (value.matches) {
                            this.filterComponentProps = {
                                searchPlaceHolder: this.translateService.instant('searchContracts'),
                                dateLabel: this.translateService.instant('filterbyDate')
                            };
                        } else {
                            this.filterComponentProps = {
                                searchPlaceHolder: this.translateService.instant('quick search Contract#, PO#, and location'),
                                dateLabel: this.translateService.instant('selectStartDateRange')
                            };
                        }
                    }),
                    distinctUntilChanged()
                )
                .subscribe()
        );

        this.uiUrl = this.inputs?.uiUrl;

        this.filterValues = this.filterValues.filter((item: Filter) => (item.value === 'Closed' && this.versionToggle.isBasicVersion() ? false : true));

        /**
         * Dispatch action to get payment processor on load, only when this feature is available
         */
        if (this.featureService.isAvailable('multiplePaymentAbility')) {
            this.appFacadeService.getPaymentProcessors(this.customerId);
        }
    }

    filter($e: FilterOutput) {
        this.filters = $e;
        this.contractService.filter($e, this.customerId);
    }

    loadContracts() {
        const filters: string[] =
            this.filters?.multifilters?.map((value: string) => {
                return value;
            }) || [];
        this.contractService.loadContracts({
            customerId: this.customerId,
            status: filters,
            startDate: this.filters?.dates?.startDate,
            endDate: this.filters?.dates?.endDate
        });
    }

    loadMore() {
        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            return;
        }

        const filters: string[] =
            this.filters?.multifilters?.map((value: string) => {
                return value;
            }) || [];
        this.store.dispatch(increasePageNumberContract());
        this.contractService.loadContracts({
            customerId: this.customerId,
            status: filters,
            startDate: this.filters?.dates?.startDate,
            endDate: this.filters?.dates?.endDate,
            search: this.filters?.search
        });

        this.gridApi.refreshCells();
    }

    exportCSV() {
        if (this.selectedContracts.length) {
            return;
        }

        const params: CsvExportParams = {
            skipHeader: false,
            skipGroups: true,
            fileName: 'Contracts.csv',
            columnKeys: ['contractName', 'GrandTotal', 'AmountDue', 'status', 'openDate', 'closeDate', 'purchaseOrder', 'info'],
            processCellCallback: (param: ProcessCellForExportParams) => {
                return this.processCellCSV(param);
            }
        };
        this.gridApi.exportDataAsCsv(params);
    }

    processCellCSV(params: ProcessCellForExportParams): string {
        // Always use CONSTANT_CASE
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const DATE_NODES_INDEX = [4, 5];
        if (params.accumulatedRowIndex && DATE_NODES_INDEX.includes(params.accumulatedRowIndex)) {
            return this.datePipe.transform(params.value);
        }
        return params.value;
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }
    reloadContract(contractId: string) {
        this.isDetailPageOpened = false;
        this.loadContractDetailModal(contractId);
    }

    loadContractDetailModal(contractId: string) {
        this.appFacadeService.setLoading(true);

        this.subscriptions.push(
            this.contractService
                .getContractsDetails(this.customerId, contractId)
                .pipe(
                    tap(data => {
                        this.contractDetail = data;
                        this.isDetailPageOpened = true;
                        this.cdn.detectChanges();
                        this.appFacadeService.setLoading(false);
                    })
                )
                .subscribe()
        );
    }

    onSelectionChanged(isSelected: boolean, contractId: string): void {
        this.addRemoveContractFromState(isSelected, contractId);
        if (this.selectedContracts.length === 0) {
            this.toggleOverlay(false);
        } else if (this.selectedContracts.length > 0) {
            this.toggleOverlay(true);
        }
    }

    getHeaderTemplate() {
        return `<div class="ag-cell-label-container" role="presentation" data-testid="por-cp-contract-header">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
            <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" aria-hidden="true"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                <span ref="eText" class="ag-header-cell-text"></span>
                <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true" data-testid="por-cp-contract-header-filter"></span>
                <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
                <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
                <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
                <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
            </div>
        </div>`;
    }

    toggleOverlay(state: boolean) {
        this.overlayStates = state;
    }

    onFullWidthRowSelectionChanged(isSelected: boolean, contractId: string): void {
        this.addRemoveContractFromState(isSelected, contractId);
    }

    private addRemoveContractFromState(isSelected: boolean, contractId: string): void {
        if (isSelected) {
            this.selectedContracts.push(contractId);
            this.appFacadeService.setSelectedContracts(this.selectedContracts);
        } else {
            this.appFacadeService.unsetSelectedContracts([contractId]);
        }
    }

    public isMultipaymentAvailable(): boolean {
        if (this.featureService.isAvailable('capturePaymentFeature') && this.featureService.isAvailable('multiplePaymentAbility')) {
            return true;
        } else {
            return false;
        }
    }

    onPaymentClose(): void {
        this.appFacadeService.setPaymentOpen(false);
    }
}
