<ng-container *ngIf="(appFacadeService.getTransactionLoaded() | async) && (appFacadeService.isPaymentOpen() | async); else loading">
    <div *ngIf="appFacadeService.getPaymentAppInput() | async as paymentAppInput">
        <por-payment-app-credit-card
            id="paymentAppCreditCard"
            *axLazyElement="porPayURL"
            [paymentAppInput]="paymentAppInput"
            (paymentAppOutput)="appFacadeService.onPaymentFinished($any($event))"></por-payment-app-credit-card>
    </div>
</ng-container>
<ng-template #loading>
    <span>
        {{ 'Loading' | translate }}
    </span>
</ng-template>
<ng-container *ngIf="appFacadeService.isDialogEnabled() | async">
    <por-app-confirm-dialog [message]="(appFacadeService.getPaymentError() | async) ?? ''" (confirmed)="closeDialog()"></por-app-confirm-dialog>
</ng-container>
