import { createReducer, on } from '@ngrx/store';
import { ProductDocumentType } from '../../../enums/product-document-type.enum';
import { emptyDocumentData, loadDocument, loadDocumentFinished } from './document.actions';

export const documentsFeatureKey = 'docData';
export interface DocState {
    documentData: DocumentData[];
}
export interface DocumentData {
    docType: string;
    url: string;
    isLoading: boolean; // Set when the document is loading
    contractId?: string;
    orgType?: string;
    isURLFound: boolean; // Set when the URL is found
}

export const initialState: DocState = {
    documentData: []
};

export const documentReducer = createReducer(
    initialState,
    on(loadDocument, state => {
        return {
            ...state,
            documentData: [
                ...state.documentData,
                { docType: ProductDocumentType.InspectionCertificate, url: '', contractId: '', orgType: '', isURLFound: false, isLoading: true },
                { docType: ProductDocumentType.SafetyInstruction, url: '', contractId: '', orgType: '', isURLFound: false, isLoading: true },
                { docType: ProductDocumentType.ServiceCertificate, url: '', contractId: '', orgType: '', isURLFound: false, isLoading: true }
            ]
        };
    }),
    on(loadDocumentFinished, (state, { documentData }) => {
        const withoutTheOne = [...state.documentData].filter(doc => doc.docType !== documentData.docType);
        const withAll = [...withoutTheOne, documentData];
        return {
            ...state,
            documentData: withAll
        };
    }),
    on(emptyDocumentData, state => {
        return { ...state, documentData: [] };
    })
);
