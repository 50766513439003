import { TranslateLoader } from '@ngx-translate/core';
import { Language, TranslationsMap } from '@por/shared/core';
import { of } from 'rxjs';

/**
 * Custom ngx-translate loader that loads the JSON translation files from a translations map.
 * This depends on JSON modules being enabled for the app.
 * The JSON values will exist as static values in the compiled app so no need for external assets, ideal for Angular Elements.
 */
export class UITranslateLoader implements TranslateLoader {
  constructor(private translations: TranslationsMap) {}
  getTranslation(lang: Language) {
    return of(this.translations[lang]);
  }
}
