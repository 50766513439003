import { createFeatureSelector, createSelector } from '@ngrx/store';
import { customerFeatureKey, CustomerState } from '../reducers/customer-reducer';
import { Customer } from '../../models/consumer';

export const customerSelector = createFeatureSelector<CustomerState>(customerFeatureKey);

export const getCustomer = createSelector(customerSelector, (state: CustomerState) => state.information);

export const getCustomerSummary = createSelector(customerSelector, (state: CustomerState) => state.customerSummary);

export const getCustomerPaymentData = createSelector(customerSelector, (storeState: CustomerState) => {
    const customer: Customer = storeState.information;

    /* eslint-disable @typescript-eslint/naming-convention */
    let email = '',
        country = '',
        address1 = '',
        address2 = '',
        city = '',
        state = '',
        postalCode = '';
    if (customer?.Emails && customer.Emails.length > 0) {
        email = customer.Emails?.[0]?.Email;
    }
    if (customer?.Addresses && customer.Addresses.length > 0) {
        country = customer.Addresses?.[0]?.Country ? customer.Addresses?.[0]?.Country : 'US';
        city = customer.Addresses?.[0]?.City ? customer.Addresses?.[0]?.City : '';
        state = customer.Addresses?.[0]?.Province ? customer.Addresses?.[0]?.Province : '';
        postalCode = customer.Addresses?.[0]?.PostalCode ? customer.Addresses?.[0]?.PostalCode : '';
        address1 = customer.Addresses?.[0]?.Line1 ? customer.Addresses?.[0]?.Line1 : '';
        address2 = customer.Addresses?.[0]?.Line2 ? customer.Addresses?.[0]?.Line2 : '';
    }

    return {
        Name: customer?.Name ? customer?.Name : '',
        Country: country,
        Email: email,
        Address1: address1,
        Address2: address2,
        City: city,
        State: state,
        PostalCode: postalCode
    };
});
