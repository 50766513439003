import { Inject, Injectable } from '@angular/core';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { Observable } from 'rxjs';
import { ContractPdfResponse } from '../models/pdf-response';
import { DOCUMENT } from '@angular/common';
import { GetUrlRequest } from '../models/get-url-request';

@Injectable({
    providedIn: 'root'
})
export class PdfService {
    constructor(private readonly customerPortal: ConsumerPortalApiService, @Inject(DOCUMENT) private readonly document: Document) {}

    downloadPdf(customerId: string, contractId: string = '', documentField: string = '', documentValue: string = ''): Observable<ContractPdfResponse> {
        return this.customerPortal.get(
            `customer/${customerId}/contracts/${contractId}/getpdf?DocumentField=${documentField}&DocumentValue=${documentValue}`
        ) as unknown as Observable<ContractPdfResponse>;
    }

    getUrl(getUrlRequest: GetUrlRequest): Observable<ContractPdfResponse> {
        return this.customerPortal.get(
            `customer/${getUrlRequest.customerId}/contracts/${getUrlRequest.contractId}/geturl?Field=${getUrlRequest.linkField}`
        ) as unknown as Observable<ContractPdfResponse>;
    }

    openPdfinNewTab(url: string) {
        if (url) {
            const a = this.document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('target', '_blank');
            a.click();
            a.remove();
        }
    }
}
