import { InvoicesState, invoicesFeatureKey } from './../reducers/invoices.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const invoiceSelector = createFeatureSelector<InvoicesState>(invoicesFeatureKey);

export const getPageNumberInvoices = createSelector(invoiceSelector, (state: InvoicesState) => state.pageNumber);

export const getInvoices = createSelector(invoiceSelector, (state: InvoicesState) => state.invoices);

export const getNoMoreRecordInvoices = createSelector(invoiceSelector, (state: InvoicesState) => state.noMoreRecord);
