import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AppFacadeService } from '../services/app-facade.service';
import { take } from 'rxjs';

@Pipe({
    name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {
    constructor(private readonly currencyPipe: CurrencyPipe, private readonly appFacadeService: AppFacadeService, @Inject(LOCALE_ID) private readonly locale: string) {}

    transform(value: string | number | undefined | null, currencyCode?: string | null): string {
        if (value === undefined || value === null) {
            return '-';
        }

        let result = '-';
        const numericValue = typeof value === 'string' ? +value : value;

        const subscription = this.appFacadeService
            .getCurrency()
            .pipe(take(1))
            .subscribe(currency => {
                currencyCode = currencyCode || currency;
                if (!currencyCode) {
                    result = !isNaN(numericValue as number) ? numericValue.toFixed(2) : '-';
                } else if (typeof value === 'number' || !isNaN(numericValue as number)) {
                    const formattedValue = this.currencyPipe.transform(value, currencyCode, 'symbol', '1.2-2', this.locale);
                    result = formattedValue === null ? '0.00' : formattedValue;
                } else {
                    result = '-';
                }
            });

        // Unsubscribe to prevent memory leaks
        subscription.unsubscribe();
        return result;
    }
}
