import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { EventLogsRequest, EventLogsResponse } from '../models/event-logs.interface';

@Injectable({
    providedIn: 'root'
})
export class EventLogsService {
    constructor(private readonly logger: LoggerService, private readonly consumerPortalApi: ConsumerPortalApiService) {}

    post(data: EventLogsRequest): Observable<EventLogsResponse> {
        const params = {
            controller: `${data.CustomerId}/event-logs`,
            method: 'POST',
            body: data
        };

        return this.consumerPortalApi.post<EventLogsResponse>(params).pipe(
            tap({
                next: (res: EventLogsResponse) => {
                    return res;
                },
                error: (err: HttpErrorResponse) => {
                    this.logger.logError(err);
                    this.logger.alertDevError(err);
                    return of(true);
                }
            })
        );
    }
}
