import { Injectable } from '@angular/core';
import { Modal } from '../models/modal.model';

@Injectable({ providedIn: 'root' })
export class ModalService {
    private modals: Modal[] = [];

    add(modal: Modal) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id
        const modal = this.modals.find(x => x.id === id);
        if (modal && modal?.open) {
            modal.open();
        }
    }

    close(id: string) {
        // close modal specified by id
        const modal = this.modals.find(x => x.id === id);
        if (modal && modal.close) {
            modal.close();
        }
    }
}
