import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Customer } from '../../models/consumer';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getCustomer } from '../../store/selector/customer.selectors';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import isEmpty from 'lodash-es/isEmpty';
import { AppFacadeService } from '../../services/app-facade.service';
import { CustomerSummaryActionTypes } from '../../store/actions/customer-summary.actions';

@Component({
    selector: 'por-basic-consumer-portal',
    templateUrl: './basic-consumer-portal.component.html',
    styleUrls: ['./basic-consumer-portal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicConsumerPortalComponent implements OnInit, OnDestroy {
    @Input() config!: string;
    @Output() readonly logout = new EventEmitter();
    customer$!: Observable<Customer>;
    customerId: string | undefined;
    inputs: ConsumerPortalConfig | undefined;
    subscriptions: Subscription[] = [];
    showLoader = false;

    constructor(private readonly store: Store, public readonly appFacadeService: AppFacadeService) {}

    ngOnInit(): void {
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
            this.customerId = this.inputs?.customerId || '0';
        }
        this.appFacadeService.setLoading(true);
        this.customer$ = this.store.pipe(select(getCustomer));
        this.store.dispatch({ type: CustomerSummaryActionTypes.LoadCustomer, payload: { id: this.customerId } });
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    logoutUser(): void {
        this.logout.emit();
    }
}
