<ng-container *ngIf="totalItems > 0; noRecordsAllowed">
    <ng-container [ngSwitch]="enabledTab$ | async">
        <div class="rental-requests-modal">
            <ng-container *ngSwitchCase="'DefaultView'">
                <h1 class="theme-heading">{{ 'Selected Items' | translate }}</h1>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>{{ 'SerialNumber' | translate }}</th>
                            <th>{{ 'itemName' | translate }}</th>
                            <th>{{ 'Contract #' | translate }}</th>
                            <th>{{ 'Contract Date' | translate }}</th>
                            <th>{{ 'dueDate' | translate }}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items">
                                <td>{{ item?.serialNumber ? item?.serialNumber : '&nbsp;' }}</td>
                                <td [innerHTML]="item.itemName"></td>
                                <td>{{ item.contractId }}</td>
                                <td>{{ item.startDate | date : dateFormat }}</td>
                                <td>{{ item.dueDate | date : dateFormat }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-3">
                    <div class="col text-align-center" [ngClass]="{ 'w-100': totalItems > 1 }">
                        <h1 class="theme-heading" *ngIf="isReturnRequestsAvailable()">{{ 'Requests' | translate }}</h1>
                        <por-request-actions-buttons
                            class="mt-3"
                            [uiUrl]="uiUrl ?? ''"
                            [requestServiceButtonVisible]="isReturnServiceAvailable()"
                            (requestReturnClick)="renderRequestReturn()"
                            (requestServiceClick)="renderRequestService()"></por-request-actions-buttons>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'RequestReturnView'">
                <por-close-item-out
                    [customerId]="customerId"
                    [items]="items"
                    [uiUrl]="uiUrl"
                    (isFormReturn)="handleRentalFormReturn()"
                    (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-close-item-out>
            </ng-container>
            <ng-container *ngSwitchCase="'RequestServiceView'">
                <por-service-request
                    [customerId]="customerId"
                    [items]="items"
                    [uiUrl]="uiUrl"
                    (isFormReturn)="handleRentalFormReturn()"
                    (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-service-request>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #noRecordsAllowed>
    <p>{{ 'NoRecordsAllowedToCallOff' | translate }}</p>
</ng-template>
