<div class="image-section" [class.loading]="loading" [ngClass]="layout">
    <ng-container *ngFor="let adBanner of banners">
        <div
            *ngIf="adBanner.banner"
            class="image-box"
            (click)="openExternalUrl(adBanner.externalUrl, adBanner.source ?? '')"
            porAddFeature
            featureName="ad-image-`adBanner.banner`"
            [style.cursor]="adBanner.externalUrl ? 'pointer' : 'default'">
            <img [src]="adBanner.banner" alt="" />
        </div>
    </ng-container>
</div>
