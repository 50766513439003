import { StoreModule } from '@ngrx/store';
import { MaterialModule } from './material-modules';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import store from './store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ColorPickerModule } from 'ngx-color-picker';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { ConsumerLoginLanguage, ConsumerLoginModule } from '@por/consumer-login/ui-lib';
import { SharedModule } from './shared/shared.module';
import { devModules } from './store-dev-tools';
export { CONSUMER_PORTAL_ADMIN_APP_CONFIG } from './models/consumer-portal-admin-config';
import { TranslateLoader, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { UIMissingTranslationHandler, UITranslateLoader } from '@por/shared/ui/translations';
import * as EnUSTranslations from '../assets/en-US.json';
import * as EnGBTranslations from '../assets/en-GB.json';
import * as FrCATranslations from '../assets/fr-CA.json';
import { Language } from '@por/shared/core';
import { MatMenuModule } from '@angular/material/menu';
import { ApxButtonModule, ApxIconModule, ApxFormsModule, ApxInputModule, ApxRadioModule } from '@por/apex';
import { CustomerPortalAdminComponent } from './admin/components/admin/admin.component';
import { CustomerPortalUiModule } from './customer-portal-ui.module';
import { AdminPageSidebarComponent } from './admin/components/admin/admin-page-sidebar/admin-page-sidebar.component';
import { AdminMandatoryComponent } from './admin/components/admin/admin-mandatory/admin-mandatory.component';
import { AdminWebpayComponent } from './admin/components/admin/admin-webpay/admin-webpay.component';
import { AdminImagesComponent } from './admin/components/admin/admin-images/admin-images.component';
import { AdminSettingsComponent } from './admin/components/admin/admin-settings/admin-settings.component';
import { AdminHeaderComponent } from './admin/components/admin/admin-header/admin-header.component';
import { AdminOverviewComponent } from './admin/components/admin/admin-overview/admin-overview.component';
import { AdminAdSettingComponent } from './admin/components/admin/admin-ad-setting/admin-ad-setting.component';
import { AdDragDropComponent } from './admin/components/admin/ad-drag-drop/ad-drag-drop.component';
import { AdminAccountListComponent } from './admin/components/admin/admin-account-list/admin-account-list.component';
import { ConsumersListsComponent } from './admin/components/admin/admin-account-list/lists/lists.component';
import { DEFAULT_LOCALE } from './constants/default.const';
import { AdminWysiwygComponent } from './admin/components/admin/admin-wysiwyg/admin-wysiwyg.component';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        StoreModule.forRoot({
            ...store
        }),
        ...devModules,
        FormsModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        NgxSkeletonLoaderModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        AgGridModule,
        CommonModule,
        NgxSkeletonLoaderModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule,
        SharedModule,
        ConsumerLoginModule,
        ApxFormsModule,
        ApxInputModule,
        ApxRadioModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new UITranslateLoader({
                        [Language.EN_US]: {
                            ...EnUSTranslations,
                            ...ConsumerLoginLanguage.enUSTranslations
                        },
                        [Language.EN_GB]: {
                            ...EnGBTranslations,
                            ...ConsumerLoginLanguage.enGBTranslations
                        },
                        [Language.FR_CA]: {
                            ...FrCATranslations
                        }
                    });
                }
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: UIMissingTranslationHandler
            },
            defaultLanguage: DEFAULT_LOCALE
        }),
        ApxButtonModule,
        CustomerPortalUiModule,
        ColorPickerModule,
        ApxIconModule
    ],
    declarations: [
        CustomerPortalAdminComponent,
        AdminPageSidebarComponent,
        AdminMandatoryComponent,
        AdminWebpayComponent,
        AdminImagesComponent,
        AdminSettingsComponent,
        AdminHeaderComponent,
        AdminOverviewComponent,
        AdminAdSettingComponent,
        AdDragDropComponent,
        AdminAccountListComponent,
        ConsumersListsComponent,
        AdminWysiwygComponent
    ],
    exports: [CustomerPortalAdminComponent],
    providers: []
})
export class CustomerPortalAdminUiModule {}
