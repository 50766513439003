import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'por-request-actions-buttons',
    templateUrl: './request-actions-buttons.component.html',
    styleUrls: ['./request-actions-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestActionsButtonsComponent {
    @Input() requestServiceButtonVisible = false;
    @Input() requestReturnButtonVisible = true;
    @Input() sendDocumentButtonVisible = false;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ required: true }) uiUrl!: string;

    @Output() readonly requestReturnClick = new EventEmitter();
    @Output() readonly requestServiceClick = new EventEmitter();
    @Output() readonly sendDocumentClick = new EventEmitter();

    renderRequestReturn() {
        this.requestReturnClick.emit();
    }

    renderRequestService() {
        this.requestServiceClick.emit();
    }

    renderSendDocumentLink() {
        this.sendDocumentClick.emit();
    }
}
