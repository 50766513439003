import { createAction, props } from '@ngrx/store';
import { Invoice } from '../../models/invoice-model';

export enum InvoiceActionTypes {
    LoadInvoiceAction = '[InvoicesActions] Load InvoicesActions',
    SetInvoiceLoading = '[InvoicesActions] Set InvoicesLoading',
    SetNoMoreRecord = '[InvoicesActions] Set setNoMoreRecord',
    ClearInvoicesInStore = '[InvoicesActions] Clear InvoicesinStore',
    IncreasePageNumber = '[InvoicesActions] IncreasePageNumber',
    FetchInvoicesAction = '[InvoicesActions] Fetch Invoices'
}

export const loadInvoicesAction = createAction(InvoiceActionTypes.LoadInvoiceAction, props<{ invoices: Invoice[] }>());

export const setInvoicesLoading = createAction(InvoiceActionTypes.SetInvoiceLoading, props<{ loading: boolean }>());

export const setNoMoreRecordInvoices = createAction(InvoiceActionTypes.SetNoMoreRecord, props<{ record: boolean }>());

export const clearInvoicesinStore = createAction(InvoiceActionTypes.ClearInvoicesInStore);

export const increasePageNumberInvoices = createAction(InvoiceActionTypes.IncreasePageNumber);

export const fetchInvoices = createAction(InvoiceActionTypes.FetchInvoicesAction, props<{ customerId: number | string; startDate?: string; endDate?: string; search?: string }>());
