import { createAction, props } from '@ngrx/store';
import { ConsumerPortalConfig } from '../../../models/consumer-portal-config';
import { Redirection } from '../../../types/redirection.type';
import { LoginSession } from '../../../models/login-session.model';

export enum RedirectionActionTypes {
    StartRedirection = '[Redirection] Start',
    RedirectionSuccess = '[Redirection] Success',
    RedirectionFailure = '[Redirection] Failure',
    SetRedirectionSession = '[Auth] Set Redirection Login Session',
    RedirectToSubDomain = '[Redirection] Redirect to Subdomain',
    RedirectToCP = '[Redirection] Redirect to Consumer Portal',
    RedirectConfigSet = '[Redirection] Redirect to Config set',
    RedirectToPayment = '[Redirection] Redirect to Consumer Payment Screen',
    RedirectProcessRoute = '[Redirection] Process Route'
}
/**
 * It handles component redirection actions
 */
export const startRedirection = createAction(RedirectionActionTypes.StartRedirection, props<{ redirection: Redirection; cpInput: ConsumerPortalConfig }>());

export const redirectionSuccess = createAction(RedirectionActionTypes.RedirectionSuccess, props<{ redirection: Redirection; cpInput: ConsumerPortalConfig }>());

export const redirectionFailure = createAction(RedirectionActionTypes.RedirectionFailure, props<{ error: string }>());

export const setRedirectionLoginSession = createAction(RedirectionActionTypes.SetRedirectionSession, props<{ session: LoginSession }>());

export const redirectToSubdomain = createAction(RedirectionActionTypes.RedirectToSubDomain);

export const redirectToConsumerPortal = createAction(RedirectionActionTypes.RedirectToCP);

export const redirectConfigSet = createAction(RedirectionActionTypes.RedirectConfigSet, props<{ isConfigSet: boolean }>());

export const redirectToPayment = createAction(RedirectionActionTypes.RedirectToPayment);

export const processRoute = createAction(RedirectionActionTypes.RedirectProcessRoute, props<{ cpInput: ConsumerPortalConfig; orgId: string }>());
