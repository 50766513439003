import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AgGridService {
    private mutationObserver!: MutationObserver;

    setHorizontalScrollBar() {
        // css class selectors
        const headerSelector = '.ag-header';
        const scrollSelector = '.ag-body-horizontal-scroll';
        const scrollViewportSelector = '.ag-body-horizontal-scroll-viewport';
        const scrollContainerSelector = '.ag-body-horizontal-scroll-container';

        // get scrollbar elements
        const scrollElement: HTMLElement | null = document.querySelector(scrollSelector);

        const scrollViewportElement: HTMLElement | null = document.querySelector(scrollViewportSelector);
        const scrollContainerElement: HTMLElement | null = document.querySelector(scrollContainerSelector);

        // create scrollbar clones
        const cloneElement: Element = scrollElement?.cloneNode(true) as Element;

        const cloneViewportElement = cloneElement.querySelector(scrollViewportSelector);
        const cloneContainerElement = cloneElement.querySelector(scrollContainerSelector);

        // insert scrollbar clone
        const headerElement: HTMLElement | null = document.querySelector(headerSelector);
        if (headerElement) {
            headerElement.insertAdjacentElement('afterend', cloneElement);
        }

        // add event listeners to keep scroll position synchronized
        if (scrollViewportElement && cloneViewportElement) {
            scrollViewportElement.addEventListener('scroll', () =>
                cloneViewportElement.scrollTo({
                    left: scrollViewportElement.scrollLeft
                })
            );
        }

        if (cloneViewportElement && scrollViewportElement) {
            cloneViewportElement.addEventListener('scroll', () =>
                scrollViewportElement.scrollTo({
                    left: cloneViewportElement.scrollLeft
                })
            );
        }

        // create a mutation observer to keep scroll size synchronized
        if (scrollContainerElement && cloneContainerElement && cloneViewportElement) {
            this.mutationObserver = new MutationObserver(mutationList => {
                for (const mutation of mutationList) {
                    switch (mutation.target) {
                        case scrollElement:
                            cloneElement.setAttribute('style', scrollElement?.getAttribute('style') || '');
                            break;
                        case scrollViewportElement:
                            cloneViewportElement.setAttribute('style', scrollViewportElement?.getAttribute('style') || '');
                            break;
                        case scrollContainerElement:
                            cloneContainerElement.setAttribute('style', scrollContainerElement?.getAttribute('style') || '');
                            break;
                        default:
                            break;
                    }
                }
            });
        }

        // start observing the scroll elements for `style` attribute changes
        if (scrollElement) {
            this.mutationObserver.observe(scrollElement, {
                attributeFilter: ['style'],
                subtree: true
            });
        }
        /* Removing ag-hidden Class for the bar to show up */
        document.querySelectorAll('.ag-body-horizontal-scroll').forEach(e => {
            e.classList.remove('ag-hidden');
        });
    }
}
