import { Directive, HostListener, Input } from '@angular/core';
import { AppFacadeService } from '../../services/app-facade.service';

@Directive({
    selector: '[porAppPushState]'
})
export class PushStateDirective {
    constructor(private readonly appFacadeService: AppFacadeService) {}
    @Input() tab!: string | undefined;
    @HostListener('click', ['$event']) onClick(event: Event) {
        event.preventDefault();
        this.appFacadeService.updateLocation(this.tab || '');
    }
}
