import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Customer } from '../../../models/consumer';
import { AccountSummaryService } from '../../../services/account-summary.service';
import { setCustomerCurrency } from '../../app-wide/currencycode/currencycode.actions';
import { CustomerSummaryActionTypes } from '../customer-summary/customer-summary.actions';

@Injectable()
export class CustomerEffects {
    loadCustomers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerSummaryActionTypes.LoadCustomer),
            switchMap((action: { type: string; payload: { id: string } }) =>
                this.summaryServices.getCustomer(action.payload?.id ?? '').pipe(
                    map(customer => ({ type: CustomerSummaryActionTypes.LoadCustomerFinished, customer: customer })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    handleLoadedCustomer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerSummaryActionTypes.LoadCustomerFinished),
            map((action: { customer: Customer }) => {
                const currencyCode = action.customer.CurrencyCode || '';
                return setCustomerCurrency({ currencyCode });
            })
        )
    );

    loadCustomerSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerSummaryActionTypes.LoadCustomerSummary),
            exhaustMap((action: { type: string; payload: { id: string } }) =>
                this.summaryServices.getCustomerSummary(action.payload?.id ?? '').pipe(
                    map(summary => ({ type: CustomerSummaryActionTypes.LoadCustomerSummaryFinished, summary: summary })),
                    catchError(() => of({ type: CustomerSummaryActionTypes.LoadCustomerSummaryError }))
                )
            )
        )
    );

    constructor(private readonly actions$: Actions, private readonly summaryServices: AccountSummaryService) {}
}
