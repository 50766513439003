import { QueryOptionsPagination } from './interfaces/index';

export const paginationDefault: QueryOptionsPagination = {
    page: 1,
    pageSize: 25
};

/**
 * These headers are specified in PoR's Unified Application Spec doc:
 * https://docs.google.com/document/d/1jx2pCV5iTuBEbNzpxFVvM79ofCOcw26L_eDMkZTu8M4/edit?usp=sharing
 */

export const columnsHeader = 'x-columns';
export const filtersHeader = 'x-filter';
export const paginationHeader = 'x-paging';
export const sortingHeader = 'x-sort';

export const headers = {
    columns: columnsHeader,
    filters: filtersHeader,
    pagination: paginationHeader,
    sorting: sortingHeader
};
