import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganizationIdService } from '../../services/organization-id.service';
import { TranslateService } from '@ngx-translate/core';
import { SubDomainRedirectionConfig } from '../../models/sub-domin-redirection';
import { OrgDetail } from '../../models/organization-detail.model';
import { AppMediatorService } from '../../services/app-mediator.service';

@Component({
    selector: 'por-subdomain-redirection',
    templateUrl: './subdomain-redirection.component.html',
    styleUrls: ['./subdomain-redirection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubdomainRedirectionComponent implements OnInit {
    constructor(private readonly organizationIdService: OrganizationIdService, private readonly translateService: TranslateService, private readonly appMediatorService: AppMediatorService) {}

    subscription: Subscription[] = [];
    @Output() readonly setActiveComponent = new EventEmitter();
    @Input() redirectionConfig: SubDomainRedirectionConfig | undefined;
    loadingRecords = true;
    message = this.translateService.instant('Routing to sub domain');

    ngOnInit(): void {
        this.subscription.push(
            this.organizationIdService
                .getOrgIdFromUrl(this.url)
                .pipe(
                    tap(x => {
                        if (x === false) {
                            this.message = this.translateService.instant('Invalid Sub Domain URL');
                        } else {
                            const orgDetail: OrgDetail = x as OrgDetail;
                            if (orgDetail?.Type) {
                                this.appMediatorService.localStorageService.setOrganizationType(orgDetail?.Type);
                            }
                            this.setActiveComponent.emit(x);
                        }
                        this.loadingRecords = false;
                    })
                )
                .subscribe()
        );
    }

    private get url() {
        return (window?.location?.href.includes('localhost') ? this.redirectionConfig?.defaultSubDomain : window?.location?.href) || '';
    }
}
