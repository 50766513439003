/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: Required by API, GAPI
import { Inject, Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { Pendo } from '../models/pendo';
import { OrganizationType } from '../types/organization-types';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services';
import { CPEnvironment } from '../../models';
import { ConsumerPortalEnviromentConfig } from '../../models/environment-consumer-portal';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { Searchable } from '../../models/searchable.model';
import { VersionToggleService } from '../../services/version-toggle';
import { AppMediatorService } from '../../services/app-mediator.service';

declare global {
    interface Window {
        pendo: Pendo;
    }
}

@Injectable({
    providedIn: 'root'
})
export class PendoService {
    pendoBaseUrl = 'https://cdn.pendo.io';
    pendo!: Pendo;

    constructor(
        @Inject('PENDO_API_KEY') private readonly apiKey: string,
        @Inject(CPEnvironment) private readonly appConfig: ConsumerPortalEnviromentConfig,
        private readonly translate: TranslateService,
        private readonly logger: LoggerService,
        private readonly appMediatorService: AppMediatorService,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly versionToggleService: VersionToggleService
    ) {
        this.injectPendo();
    }

    async injectPendo() {
        if (!this.apiKey) {
            throw new Error(this.translate.instant('Pendo Key Not Found'));
        }
        await new Promise(resolve => {
            const script = document.createElement('script');
            script.async = true;
            script.src = `${this.pendoBaseUrl}/agent/static/${this.apiKey}/pendo.js`;
            script.id = 'pendo-script';
            document.head.appendChild(script);
            script.onerror = async () => {
                // The script may have been blocked by an ad blocker
                this.logger.logError('Pendo script is blocked by adblocker', '', true);
                resolve(false);
            };
            script.onload = async () => {
                // when enableDebugging should load extra js
                const sub = interval(100).subscribe(() => {
                    if (window?.pendo) {
                        this.pendo = window.pendo;
                        sub.unsubscribe();
                        resolve(true);
                    }
                });
            };
        });
    }

    initializePendo({
        orgId,
        customerId,
        email,
        name,
        appName,
        RMS = 'Syrinx',
        companyName
    }: {
        orgId: string;
        customerId: string;
        email: string;
        name: string;
        appName?: string;
        RMS?: OrganizationType;
        companyName: string;
    }) {
        {
            /*
             ** Remove this console once testing is done
             */
            this.logger.logInfo({
                orgId,
                customerId,
                email,
                name,
                appName,
                RMS,
                companyName
            });
        }
        if (!this.apiKey) {
            throw new Error(this.translate.instant('Pendo Key Not Found'));
        }
        const orgName = this.appMediatorService.localStorageService.getOrganizationName;
        const pendoObj = {
            visitor: {
                id: this.versionToggleService.isQuickLinkVersion() ? `QL-${orgId}` : customerId ?? `${orgId}-${email}`,
                identifier: this.versionToggleService.isQuickLinkVersion() ? `QL-${orgId}` : customerId ?? `${orgId}-${email}`,
                email,
                RemoteUserId: customerId,
                name: name ?? email,
                AppVersion: this.appConfig.version || '',
                SDKVersion: this.pendo?.getVersion ? this.pendo.getVersion() : '',
                AppName: appName ? appName : 'ConsumerPortal',
                RMS, // This should be 'Elite', 'Syrinx' or 'Essentials'
                CustomerId: customerId ?? `${orgId}-${email}`
            },
            account: {
                id: orgId,
                name: orgName, // Currently we don't have a name for the organization. Its stored in the GAPI orgnization table
                organizationType: RMS
            }
        };
        this.appMediatorService.localStorageService.cleanItemsStartWith('_pendo_');
        this.pendo?.initialize(pendoObj);
        this.pendo?.identify(pendoObj);
    }

    trackUserEvents(customerId: string): Observable<Searchable[]> {
        const params = {
            controller: `customer/${customerId}/track-user-events`,
            method: 'POST',
            body: ''
        };
        return this.consumerPortalApi.post({ ...params });
    }
}
