import { LowerCasePipe } from '@angular/common';
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[porAddFeature]'
})
export class AddFeatureClassDirective implements OnInit {
    constructor(private readonly renderer: Renderer2, private readonly hostElement: ElementRef, private readonly lower: LowerCasePipe) {}

    @Input() featureName!: string;
    @Input() dynamicValue?: string;
    ngOnInit(): void {
        if (this.featureName) {
            this.renderer.addClass(this.hostElement.nativeElement, `cp-pendo-${this.lower.transform(this.featureName).replace(/ /g, '-')}`);

            const explodeFeatureName = this.featureName.split('-');
            let inputElement: ElementRef = this.hostElement.nativeElement;
            switch (explodeFeatureName[0]) {
                case 'picker':
                    inputElement = this.hostElement.nativeElement.querySelector('button');
                    break;
                case 'radio':
                case 'checkbox':
                    inputElement = this.hostElement.nativeElement.querySelector('input');
                    break;
                case 'label':
                    inputElement = this.hostElement.nativeElement.querySelector('label');
                    break;
                default:
            }
            const finalDynamicValue = this.dynamicValue ? `-${this.dynamicValue}` : '';
            this.renderer.setAttribute(inputElement, 'data-testid', `por-cp-${this.lower.transform(this.featureName).replace(/ /g, '-')}${finalDynamicValue}`);
        }
    }
}
