<button
    apxButton
    [ngClass]="ngClassObject"
    [variant]="theme"
    [type]="type"
    [class]="class"
    [disabled]="disabled"
    (click)="buttonClick($event)"
    [title]="title"
    porAddFeature
    [featureName]="featureName">
    <mat-icon *ngIf="icon" [class]="iconClass">{{ icon }}</mat-icon>
    <ng-content></ng-content>
</button>
