import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'por-svg-icon',
    template: `
        <span [innerHTML]="svgIcon"></span>
    `,
    styleUrls: ['./icon-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnChanges {
    @Input()
    public name?: string;

    public svgIcon!: SafeHtml;

    constructor(private readonly httpClient: HttpClient, private readonly sanitizer: DomSanitizer, private readonly cdr: ChangeDetectorRef) {}

    public ngOnChanges(): void {
        if (!this.name) {
            this.svgIcon = '';
            this.cdr.detectChanges();
            return;
        }
        this.httpClient.get(`assets/images/${this.name}.svg`, { responseType: 'text' }).subscribe(value => {
            this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
            this.cdr.detectChanges();
        });
    }
}
