import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'por-verification-code-help-text',
    templateUrl: './verification-code-help-text.component.html',
    styleUrls: ['./verification-code-help-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationCodeHelpTextComponent {
    @Input() email = '';
    @Output() readonly modalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

    closeModal() {
        this.modalClosed.emit(true);
    }
}
