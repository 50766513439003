import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'por-base-button',
    templateUrl: './base-button.component.html',
    styleUrls: ['./base-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseButtonComponent {
    @Input() text = 'Submit';
    @Input() theme: 'primary' | 'secondary' = 'primary'; /* 3 themes primary, secondary, dark-primary */
    @Input() class = '';
    @Input() title = '';
    @Input() disabled = false;
    @Input() icon: string | undefined;
    @Input() iconClass: string | undefined;
    @Input() type = 'button';
    @Input() loading = false;
    @Output() readonly onclick = new EventEmitter();
    @Input() featureName = '';
    @Input() paymentButtonStyle = '';
    buttonClick(event: Event) {
        this.onclick.emit(event);
    }

    get ngClassObject() {
        return {
            /* note camelCase: used here as class name */
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '--disabled': this.disabled,
            [this.paymentButtonStyle]: true
        };
    }
}
