<ng-template porFeatureAvailability [featureToken]="['canHaveProductsKeyDocumentsView']" [fallbackTemplate]="defaultTemplate">
    <ng-container [ngTemplateOutlet]="productDetailDocumentsTemplate"></ng-container>
</ng-template>
<ng-template #defaultTemplate>
    <ng-container [ngTemplateOutlet]="productDetailNormalTemplate"></ng-container>
</ng-template>

<ng-template #productDetailNormalTemplate>
    <div class="product-details-page">
        <div class="header">
            <h1 class="theme-heading">{{ 'ProductDetails' | translate }}</h1>
            <por-base-button
                (click)="closePanel.emit(true)"
                icon="close"
                featureName="product-detail-close-button"
                *ngIf="!appFacadeService.versionToggleService.isStockQuickLinkVersion()"></por-base-button>
        </div>

        <div class="wrapper-product">
            <div class="content">
                <ng-container [ngTemplateOutlet]="documentsInformation"></ng-container>
            </div>
            <div class="links" *ngIf="isContentAvailable('OtherUrls')">
                <h3 class="title">{{ 'Documents' | translate }}</h3>
                <a *ngFor="let link of productDetail?.OtherUrls" [href]="link" target="_blank">
                    <img src="{{ uiUrl }}/assets/images/noun-pdf-file-5441350Gray.svg" />
                </a>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #productDetailDocumentsTemplate>
    <div class="product-details-page document-template">
        <div class="top-bar" *ngIf="!isMobileLayout">
            <h1 class="theme-heading">{{ 'ProductDetails' | translate }}</h1>
        </div>
        <div class="header">
            <h1 *ngIf="isContentAvailable('Name')" class="title">
                {{ productDetail?.Name }}
                <span *ngIf="isContentAvailable('SerialNumber')" [ngClass]="{ 'd-flex': isMobileLayout }">
                    {{ productDetail?.SerialNumber }}
                </span>
            </h1>
            <por-base-button
                (click)="closePanel.emit(true)"
                icon="close"
                featureName="product-detail-close-button"
                *ngIf="!appFacadeService.versionToggleService.isStockQuickLinkVersion()"></por-base-button>
        </div>
        <div class="sub-header">
            <h3>{{ 'Documents' | translate }}</h3>
        </div>
        <div class="wrapper-product">
            <div class="content">
                <div class="cols d-flex full-width">
                    <div class="col-50" [ngClass]="{ 'no-product-data': !isContentAvailable('Images') && !isContentAvailable('StoredFiles') && !isContentAvailable('Description') }">
                        <ng-container [ngTemplateOutlet]="documentsList"></ng-container>
                    </div>
                    <div class="col-50" *ngIf="isContentAvailable('Images') || isContentAvailable('StoredFiles') || isContentAvailable('Description')">
                        <ng-container [ngTemplateOutlet]="documentsInformation"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #documentsList>
    <por-document-lists [uiUrl]="uiUrl ?? ''" [documentParams]="documentParams"></por-document-lists>
    <div class="documents">
        <ng-container *ngIf="!appFacadeService.versionToggleService.isStockQuickLinkVersion()">
            <div *ngIf="appFacadeService.checkIfDocumentsExist() | async">
                <div class="text-align-center">
                    <button class="btn-link d-inline-flex" porAddFeature featureName="send-taglink-btn" (click)="openModal()">
                        <img class="actions-image" src="{{ uiUrl }}/assets/images/mail-signal-1684972-1442449.svg" porAddFeature featureName="send-taglink-btn" />
                        <label porAddFeature featureName="send-taglink-btn">{{ 'SendDocument' | translate }}</label>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #documentsInformation>
    <div class="image-section" *ngIf="isContentAvailable('Images')">
        <img [src]="productDetail?.Images?.[0]?.['Uri']" [alt]="productDetail?.Images?.[0]?.['AltText']" />
    </div>
    <div class="image-section" *ngIf="isContentAvailable('StoredFiles')">
        <img [src]="productDetail?.StoredFiles?.[0]?.['PublicURL']" />
    </div>
    <div class="description" *ngIf="productDetail">
        <ng-template porFeatureAvailability [featureToken]="['canHaveProductsKeyDocumentsView']" [fallbackTemplate]="productName"></ng-template>
        <ng-template #productName>
            <h3 *ngIf="isContentAvailable('Name')" class="title">{{ productDetail.Name }}</h3>
        </ng-template>
        <span *ngIf="isContentAvailable('Description')">{{ productDetail.Description }}</span>
    </div>
</ng-template>

<por-base-modal [id]="'product-detail-modal'" [modalWidth]="'modal-lg'">
    <por-send-document-link
        [sendLinkRequestData]="sendLinkRequestData"
        (isFormReturn)="handleRentalFormReturn()"
        (sendLinkRequestInfo)="handleSendDocumentFormSubmission($event)"></por-send-document-link>
</por-base-modal>
