import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'por-alert',
    templateUrl: './alert-component.component.html',
    styleUrls: ['./alert-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponentComponent {
    @Input()
    alertMessage!: string;
}
