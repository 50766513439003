import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
// this pipe will round a value like 2 will be 2.00 up to 2 decimals
  transform( value:string ): string {
    return Number(value).toFixed(Math.max(value.split('.')[1]?.length, 2) || 2)
  }

}
