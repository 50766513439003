import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'por-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent implements OnInit, OnDestroy {
    search = '';

    @Input() class = '';
    @Input() disabled = false;
    @Input() label = '';
    @Input() placeholder = '';
    @Input() featureName = '';
    subscriptions: Subscription[] = [];
    searchValueChanged = new BehaviorSubject<string>('');
    @Input() value = '';
    @Output() readonly searched = new EventEmitter<string>();
    @Output() readonly inputFocussed = new EventEmitter<unknown>();
    @Output() readonly searchEnterPressed = new EventEmitter<void>();
    @Output() readonly rightButtonClicked = new EventEmitter<boolean>();

    ngOnInit(): void {
        if (!isEmpty(this.value)) {
            this.search = this.value;
        }
        this.subscriptions.push(
            this.searchValueChanged.pipe(debounceTime(800), distinctUntilChanged()).subscribe(search => {
                if (!isEmpty(search)) {
                    this.searched.emit(search);
                } else {
                    this.searched.emit('');
                }
            })
        );
    }

    clearSearch() {
        if (this.search === '') {
            return;
        }
        this.search = '';
        this.searchValueChanged.next('');
        this.searched.emit('');
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    enterPressed(event: Event): void {
        event.preventDefault();
        this.searchEnterPressed.emit();
    }
}
