<div class="content-wrapper">
    <div class="filters">
        <por-base-select
            [label]="'RequestType' | translate"
            [selectStyle]="'request-type'"
            [options]="requestTypeDropdownOptions"
            [model]="selectedRequestType"
            (modelChange)="onRequestTypeValueChange($event)"></por-base-select>
        <por-base-select [label]="'Year' | translate" [selectStyle]="'year'" [options]="yearDropdownOptions" [model]="selectedYear" (modelChange)="onYearValueChange($event)"></por-base-select>
    </div>
    <div class="grid-wrapper">
        <ng-container *ngIf="data$ | async as rowData; else loading">
            <ag-grid-angular
                style="width: 100%; height: 100%"
                class="ag-theme-alpine ag-grid"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [autoGroupColumnDef]="autoGroupColumnDef"
                [groupDisplayType]="groupDisplayType"
                [rowData]="rowData"
                [gridOptions]="gridOptions"
                (gridReady)="onGridReady($event)" />
        </ng-container>

        <ng-template #loading>
            <por-busy-indicator [message]="'Loading' | translate" />
        </ng-template>
    </div>
</div>
