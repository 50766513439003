import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocState, DocumentData, documentsFeatureKey } from '../reducers/documents.reducer';
import { ProductDocumentType } from '../../enums/product-document-type.enum';

// Select the document feature state
export const selectDocumentState = createFeatureSelector<DocState>(documentsFeatureKey);

// Selector to get all document data
export const selectAllDocumentData = createSelector(selectDocumentState, (state: DocState) => state.documentData);

// Selector to get document data by type
export const selectDocumentDataByType = (docType: string) => createSelector(selectAllDocumentData, (documents: DocumentData[]) => documents.filter(doc => doc.docType === docType));

// Selector to determine if any document found
export const selectIsDocumentExists = (docType: string) =>
    createSelector(selectAllDocumentData, (documents: DocumentData[]) => {
        return documents.some(doc => doc.docType === docType && (doc.isURLFound || doc.isLoading));
    });

// Selector to determine if any document loading
export const selectIsDocumentLoading = (docType: string) =>
    createSelector(selectAllDocumentData, (documents: DocumentData[]) => {
        return documents.some(doc => doc.docType === docType && doc.isLoading);
    });

// Selector to determine if any time any of the document exists
export const selectAreDocumentsExist = createSelector(selectAllDocumentData, (documents: DocumentData[]) => {
    return documents.some(doc => {
        return [ProductDocumentType.InspectionCertificate, ProductDocumentType.SafetyInstruction, ProductDocumentType.ServiceCertificate].some(
            d => doc.docType === ProductDocumentType[d] && doc.isURLFound
        );
    });
});

// Selector to determine finally any of the document exists after loading untill then it assumes true
export const selectAreDocumentsExistAfterLoading = createSelector(selectAllDocumentData, (documents: DocumentData[]) => {
    // Any document is loading assumes true
    if (documents.filter(doc => doc.isLoading).length > 0) {
        return true;
    }

    // Any document is found
    return documents.filter(doc => doc.isURLFound).length > 0;
});
