import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UploadService } from '../../../services/upload.service';
import { BehaviorSubject, Subscription, take, tap } from 'rxjs';
import { LoggerService, NotificationService } from '../../../../services';
import { BannerConfig, FileType, SubBannerFileType } from '../../../models/file-type-upload';
import { OrganizationConfig } from '../../../../models/organization-config.model';
import isEmpty from 'lodash-es/isEmpty';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppFacadeService } from '../../../../services/app-facade.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppFormValidators } from '../../../validators/app.validators';

@Component({
    selector: 'por-admin-ad-setting',
    templateUrl: './admin-ad-setting.component.html',
    styleUrls: ['./admin-ad-setting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminAdSettingComponent implements OnInit, OnDestroy {
    constructor(
        private readonly fb: FormBuilder,
        private readonly uploadService: UploadService,
        private readonly notification: NotificationService,
        private readonly logger: LoggerService,
        private readonly translate: TranslateService,
        readonly appFacadeService: AppFacadeService
    ) {}
    @Input() formValues!: Partial<{
        faviconUrl: string;
        logoUrl: string;
        primaryColor: string;
        secondaryColor: string;
        notificationEmail: string;
        supportEmail: string;
        storeName: string;
        subDomain: string;
        externalConsumerPortalURL?: string;
        banner1?: string;
        banner1ExternalUrl?: string;
        banner2?: string;
        banner2ExternalUrl?: string;
        banner3?: string;
        banner3ExternalUrl?: string;
        banner4?: string;
        banner4ExternalUrl?: string;
    }>;
    @Output() readonly uploadSuccess = new EventEmitter<{ url: string; fileType: FileType; subFileType: SubBannerFileType | null }>();
    @Output() readonly submitForm: EventEmitter<OrganizationConfig> = new EventEmitter<OrganizationConfig>();
    subscriptions: Subscription[] = [];
    fileType = FileType;
    uploading$ = new BehaviorSubject<boolean>(false);
    imagesForm!: FormGroup;
    orgConfigData!: OrganizationConfig;
    subBannerFileType = SubBannerFileType;
    banners: BannerConfig[] = [
        { type: SubBannerFileType.Banner1, title: 'TopLeftImageText', imageTitle: 'ImageAd1Title', urlTitle: 'Url1Title' },
        { type: SubBannerFileType.Banner2, title: 'LowerLeftImageText', imageTitle: 'ImageAd2Title', urlTitle: 'Url2Title' },
        { type: SubBannerFileType.Banner3, title: 'TopSecondImageText', imageTitle: 'ImageAd3Title', urlTitle: 'Url3Title' },
        { type: SubBannerFileType.Banner4, title: 'LowerSecondImageText', imageTitle: 'ImageAd4Title', urlTitle: 'Url4Title' }
    ];

    ngOnInit(): void {
        /**
         * Initilize org config data from parent app,
         * so those can be saved on click on save button of banner form
         */
        // note camelCase: DB Model
        this.orgConfigData = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            LogoUrl: this.formValues.logoUrl, // eslint-disable-next-line @typescript-eslint/naming-convention
            PrimaryColor: this.formValues.primaryColor, // eslint-disable-next-line @typescript-eslint/naming-convention
            SecondaryColor: this.formValues.secondaryColor, // eslint-disable-next-line @typescript-eslint/naming-convention
            NotificationEmail: this.formValues.notificationEmail, // eslint-disable-next-line @typescript-eslint/naming-convention
            StoreName: this.formValues.storeName, // eslint-disable-next-line @typescript-eslint/naming-convention
            FaviconUrl: this.formValues.faviconUrl, // eslint-disable-next-line @typescript-eslint/naming-convention
            SupportEmail: this.formValues.supportEmail, // eslint-disable-next-line @typescript-eslint/naming-convention
            ExternalConsumerPortalURL: this.formValues?.externalConsumerPortalURL, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner1: this.formValues?.banner1, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner1ExternalUrl: this.formValues?.banner1ExternalUrl, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner2: this.formValues?.banner2, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner2ExternalUrl: this.formValues?.banner2ExternalUrl, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner3: this.formValues?.banner3, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner3ExternalUrl: this.formValues?.banner3ExternalUrl, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner4: this.formValues?.banner4, // eslint-disable-next-line @typescript-eslint/naming-convention
            Banner4ExternalUrl: this.formValues?.banner4ExternalUrl
        };
        this.initializeForm();
    }

    initializeForm(): void {
        /**
         * Created banner1, banner2.. as part of form, so in future instead of upload,
         * we can provide direct input box as well
         */
        this.imagesForm = this.fb.group({
            faviconUrl: [this.formValues?.faviconUrl ?? '', [AppFormValidators.imageUrlValidator]],
            logoUrl: [this.formValues?.logoUrl ?? '', [AppFormValidators.imageUrlValidator]],
            primaryColor: [this.formValues?.primaryColor ?? '', [Validators.required, AppFormValidators.hexValidator]],
            secondaryColor: [this.formValues?.secondaryColor ?? '', [Validators.required, AppFormValidators.hexValidator]],
            notificationEmail: [this.formValues?.notificationEmail ?? '', [Validators.required, AppFormValidators.emailValidator]],
            supportEmail: [this.formValues?.supportEmail ?? '', [Validators.required, AppFormValidators.emailValidator]],
            storeName: [this.formValues?.storeName ?? '', [Validators.required]],
            externalConsumerPortalURL: [this.formValues?.externalConsumerPortalURL ?? '', [AppFormValidators.urlValidator]],
            banners: this.fb.array(this.banners.map(banner => this.createBannerGroup(banner.type)))
        });
    }

    /**
     * Creates banner group and assign validators to it
     * @param type
     * @returns FormGroup
     */
    private createBannerGroup(type: SubBannerFileType): FormGroup {
        return this.fb.group({
            [`${type}`]: [this.orgConfigData?.[type] || '', [AppFormValidators.imageUrlValidator]],
            [`${type}ExternalUrl`]: [this.orgConfigData?.[`${type}ExternalUrl`] || '', [AppFormValidators.urlValidator]]
        });
    }

    get bannersArray(): FormArray {
        return this.imagesForm.get('banners') as FormArray;
    }

    handleFile(file: File, fileType: FileType, subFileType: SubBannerFileType | null = null) {
        this.uploading$.next(true);
        this.subscriptions.push(
            this.uploadService
                .uploadFile(file)
                .pipe(
                    take(1),
                    tap({
                        next: response => {
                            if (response.url) {
                                this.uploadSuccess.emit({
                                    fileType,
                                    url: response.url,
                                    subFileType
                                });
                                if (!isEmpty(response.url)) {
                                    switch (fileType) {
                                        case FileType.Banner:
                                            this.setBannersValues(subFileType as SubBannerFileType, response.url);
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                this.uploading$.next(false);
                            } else {
                                throw new Error(this.translate.instant('FileNotUploadedProperly'));
                            }
                        },
                        error: (e: HttpErrorResponse) => {
                            this.notification.error('somethingWentWrong');
                            this.logger.logError(e);
                            this.uploading$.next(false);
                        }
                    })
                )
                .subscribe()
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    /**
     * Set banners image url to respected index to
     * @param subFileType
     * @param url
     */
    setBannersValues(subFileType: SubBannerFileType, url: string) {
        this.banners.map((banner, index) => {
            if (banner.type === subFileType) {
                this.bannersArray.at(index).get(banner.type)?.setValue(url);
            }
        });
    }

    /**
     * It emits the changed data to parent add to save
     * @returns
     */
    onSubmit(): void {
        if (!this.imagesForm.valid) {
            return;
        }

        this.banners.map((banner, index) => {
            const controlGroup = this.bannersArray.at(index);
            this.orgConfigData = {
                ...this.orgConfigData,
                [`${banner.type}`]: controlGroup.get(`${banner.type}`)?.value,
                [`${banner.type}ExternalUrl`]: controlGroup.get(`${banner.type}ExternalUrl`)?.value
            };
        });
        this.submitForm.emit(this.orgConfigData);
    }
}
