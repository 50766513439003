<div class="base-input-wrapper" [ngClass]="styleInput">
    <mat-form-field appearance="outline" [ngClass]="{ 'infix-nolabel': !label }">
        <mat-label *ngIf="!!label">{{ label }}</mat-label>
        <input
            matInput
            [type]="type"
            [placeholder]="placeholder"
            (input)="updateNewValue($event)"
            (blur)="touched()"
            [(ngModel)]="model"
            [required]="required"
            [email]="type === 'email' ? true : false"
            [type]="type"
            porAddFeature
            [featureName]="featureName" />
        <mat-icon *ngIf="icon" matPrefix>{{ icon }}</mat-icon>
        <mat-error *ngIf="formField?.hasError('email')">enter email properly*</mat-error>
        <mat-error *ngIf="formField?.hasError('required')">required*</mat-error>
    </mat-form-field>
</div>
