<div class="selected-item-actions">
    <div class="header-section">
        <div class="header">
            <h1 class="theme-heading">{{ 'SelectedItem' | translate }}</h1>
            <por-base-button [featureName]="'selected-item-actions-close'" (click)="onCloseButtonClick()" icon="close"></por-base-button>
        </div>
    </div>
    <div class="content">
        <div class="line-items-table">
            <table class="table">
                <thead>
                    <th>{{ 'contract' | translate }}</th>
                    <th>{{ 'Quantity' | translate }}</th>
                    <th>{{ 'itemName' | translate }}</th>
                    <th>{{ 'ItemId' | translate }}</th>
                    <th>{{ 'HireDate' | translate }}</th>
                    <th>{{ 'status' | translate }}</th>
                    <th>{{ 'Request' | translate }}</th>
                </thead>
                <tbody>
                    <td>{{ lineItem.AdditionalFields.ContractId }}</td>
                    <td>{{ lineItem.Quantity }}</td>
                    <td [innerHTML]="lineItem.Name"></td>
                    <td>{{ lineItem.Id }}</td>
                    <td>{{ lineItem.StartDateTime | formatDate }}</td>
                    <td>{{ lineItem.Status }}</td>
                    <td></td>
                </tbody>
            </table>
        </div>
        <div class="row mt-3">
            <div class="col text-align-center">
                <div class="d-flex justify-content-center w-75 m-d-block">
                    <div class="col-3">
                        <button class="btn-link" (click)="renderRequestReturn()" porAddFeature featureName="request-calloff-button">
                            <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-return-4960178.svg" porAddFeature featureName="request-calloff-button" />
                            <label class="d-flex justify-content-center color-black" porAddFeature featureName="request-calloff-button">{{ 'RequestOffHire' | translate }}</label>
                        </button>
                    </div>
                    <div class="col-3">
                        <button class="btn-link" (click)="renderRequestService()" porAddFeature featureName="request-service-button">
                            <img class="actions-image" src="{{ uiUrl }}/assets/images/noun-service-2474061.svg" porAddFeature featureName="request-service-button" />
                            <label class="d-flex justify-content-center" porAddFeature featureName="request-service-button">{{ 'Request Service' | translate }}</label>
                        </button>
                    </div>
                    <div class="col-3" *ngIf="isRental(lineItem.Classification)">
                        <button class="btn-link" (click)="renderSendDocumentLink()" porAddFeature featureName="send-document-link">
                            <img class="actions-image" src="{{ uiUrl }}/assets/images/mail-signal-1684972-1442449.svg" porAddFeature featureName="send-document-link" />
                            <label class="d-flex justify-content-center" porAddFeature featureName="send-document-link">{{ 'SendDocument' | translate }}</label>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<por-base-modal [id]="'rental-request-page'" [modalWidth]="'modal-lg'">
    <ng-container [ngSwitch]="enabledTab$ | async">
        <ng-container *ngSwitchCase="'RequestReturnView'">
            <por-close-item-out
                [customerId]="customerId"
                [items]="itemOutCP"
                [uiUrl]="uiUrl"
                [contractDetail]="contractDetail"
                (isFormReturn)="handleRentalFormReturn()"
                (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-close-item-out>
        </ng-container>
        <ng-container *ngSwitchCase="'RequestServiceView'">
            <por-service-request
                [customerId]="customerId"
                [items]="itemOutCP"
                [uiUrl]="uiUrl"
                [contractDetail]="contractDetail"
                (isFormReturn)="handleRentalFormReturn()"
                (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-service-request>
        </ng-container>
        <ng-container *ngSwitchCase="'SendDocumentsView'">
            <por-send-document-link
                [sendLinkRequestData]="sendLinkRequestData"
                (isFormReturn)="handleRentalFormReturn()"
                (sendLinkRequestInfo)="handleSendDocumentFormSubmission($event)"></por-send-document-link>
        </ng-container>
    </ng-container>
</por-base-modal>
