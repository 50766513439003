import { createAction, props } from '@ngrx/store';
import { GetDocumentParam } from '../../models/contract-model';
import { DocumentData } from '../reducers/documents.reducer';

export enum DocumentActionTypes {
    LoadDocument = '[Document] Load',
    LoadDocumentFinished = '[Document] Load Finished',
    LoadDocumentEmpty = '[Document] Empty',
    CancelLoadDocument = '[Documents] Cancel Load Documents',
    LoadLinkAndOpen = '[Documents] Load Link And Open',
    LoadLinkAndOpenFinished = '[Documents] Load Link And Open Finished',
    LoadEsignAndOpen = '[Documents] Load Esgin And Open',
    LoadEsignAndOpenFinished = '[Documents] Load Esign And Open Finished'
}

export const loadDocument = createAction(DocumentActionTypes.LoadDocument, props<{ contractdetails: GetDocumentParam }>());
export const loadDocumentFinished = createAction(DocumentActionTypes.LoadDocumentFinished, props<{ documentData: DocumentData }>());
export const emptyDocumentData = createAction(DocumentActionTypes.LoadDocumentEmpty);
export const cancelLoadDocuments = createAction(DocumentActionTypes.CancelLoadDocument);
export const loadLinkAndOpen = createAction(DocumentActionTypes.LoadLinkAndOpen);
export const loadLinkAndOpenFinished = createAction(DocumentActionTypes.LoadLinkAndOpenFinished);
export const loadEsignAndOpen = createAction(DocumentActionTypes.LoadEsignAndOpen);
export const loadEsignAndOpenFinished = createAction(DocumentActionTypes.LoadEsignAndOpenFinished);
