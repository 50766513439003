import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from './app.state';

const selectAppState = createFeatureSelector<AppState>('appState');

export const selectIsMobile = createSelector(selectAppState, (state: AppState) => state.isMobile);

export const selectIsLoading: MemoizedSelector<AppState, boolean> = createSelector(selectAppState, (state: AppState) => state.isLoading);

export const selectConsumerPortalState = createSelector(selectAppState, (state: AppState) => state.consumerPortalState);

export const selectIsAuthenticated: MemoizedSelector<AppState, boolean> = createSelector(selectAppState, (state: AppState) => state.isAuthenticated);

export const selectIsRedirected: MemoizedSelector<AppState, boolean> = createSelector(selectAppState, (state: AppState) => state.isRedirected);

export const selectLoaded: MemoizedSelector<AppState, boolean> = createSelector(selectAppState, (state: AppState) => state.isAdminLoading);

export const selectVersionRedirection = createSelector(selectAppState, (state: AppState) => state.versionRedirection);

export const selectActiveTab = createSelector(selectAppState, (state: AppState) => state.activeTab.parentTab);

export const selectSubActiveTab = createSelector(selectAppState, (state: AppState) => state.activeTab?.childTab);

export const selectIsAdmin = createSelector(selectAppState, (state: AppState) => state.isAdmin);
