import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import isEmpty from 'lodash-es/isEmpty';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiHeaders } from '../../../models/api-query.model';
import { ItemOutCP } from '../../../models/item-out-model';
import { AppFacadeService } from '../../../services/app-facade.service';
import { ConsumerPortalApiService } from '../../../services/consumer-portal-api.service';
import { clearItemsOut, fetchItemsOut, loadItemOuts, noRecordFoundItemsOut } from './../items-out-actions/items-out-action.actions';
import { getPageNumberItemsOut } from './itemsout.selectors';

@Injectable()
export class ItemsOutEffects {
    constructor(private readonly store: Store, private readonly actions$: Actions, private readonly consumerPortalApi: ConsumerPortalApiService, private readonly appFacadeService: AppFacadeService) {}

    fetchItemsOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchItemsOut),
            tap(() => this.appFacadeService.setLoading(true)),
            withLatestFrom(this.store.pipe(select(getPageNumberItemsOut)), this.appFacadeService.getPageSizeValues()),
            switchMap(([action, currentPageNumber, pageSizeValues]) => {
                const { selectedPageSize, previousPageSize } = pageSizeValues;
                const { customerId, startDate, endDate, search, status } = action;
                let pageNumber = currentPageNumber;
                if (previousPageSize !== selectedPageSize) {
                    this.store.dispatch(clearItemsOut());
                    pageNumber = 1;
                }
                const finalFilter = this.buildFilters({ search, startDate, endDate, status });
                let headers: ApiHeaders = {};
                if (finalFilter.length > 0) {
                    headers = {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        'x-filter': JSON.stringify(finalFilter)
                    };
                }
                headers = {
                    ...headers,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'x-paging': JSON.stringify({
                        page: pageNumber,
                        pageSize: selectedPageSize
                    })
                };
                return this.consumerPortalApi.get<ItemOutCP>(`customer/${customerId}/contracts/itemsout`, { headers }).pipe(
                    map(itemsOut => {
                        if (!itemsOut || itemsOut.length === 0) {
                            return noRecordFoundItemsOut({ loading: true });
                        }
                        const items: ItemOutCP[] =
                            itemsOut?.map(finalItem => ({
                                ...finalItem,
                                itemName: finalItem?.itemName?.replace('(', ' (')
                            })) || [];
                        return loadItemOuts({ items });
                    }),
                    catchError(() => of(noRecordFoundItemsOut({ loading: true }))),
                    finalize(() => this.appFacadeService.setLoading(false))
                );
            })
        )
    );

    private buildFilters({ search, startDate, endDate, status }: { search?: string; startDate?: string; endDate?: string; status?: string }) {
        const finalFilter = [];

        if (this.appFacadeService.featureToggleService.isAvailable('textSearchMultipleFilters') && search && !isEmpty(search)) {
            finalFilter.push({
                field: '',
                type: 'OR',
                value: [
                    { field: 'Identifiers.ParentId', type: 'LIKE', value: search },
                    { field: 'Name', type: 'LIKE', value: search },
                    { field: 'SerialNumber', type: 'LIKE', value: search }
                ]
            });
        }

        if (startDate) {
            finalFilter.push({ field: 'StartDateTime', type: '>=', value: new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)) });
        }
        if (endDate) {
            finalFilter.push({ field: 'EndDateTime', type: '<=', value: new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)) });
        }

        if (status) {
            finalFilter.push({ field: 'Status', type: '==', value: status });
        }

        return finalFilter;
    }
}
