import { setAdminLoading } from '../actions/loaded.actions';
import { ReducerType, initialState } from '../state/app.state';
import { createReducer, on } from '@ngrx/store';

export const loadedReducer: ReducerType = createReducer(
    initialState,
    on(setAdminLoading, (state, { isAdminLoaded }) => {
        return { ...state, isAdminLoading: isAdminLoaded };
    })
);
