<div class="filter-wrapper" porClickOutSide (clickedOutside)="closeFilter()">
    <form class="mobile-form" *ngIf="onSmallScreen">
        <div class="box" *ngIf="onSmallScreen">
            <span class="filter-txt theme-paragraph">{{ 'Filters' | translate }}</span>
            <div class="text-search search-mobile">
                <por-search-box
                    class="amount-control"
                    label="{{ 'Text Search' | translate }}"
                    [disabled]="disableSearch ? disableSearch : isProcessing"
                    [placeholder]="searchPlaceholder ? searchPlaceholder : ('quick search Contract#, PO#, and location' | translate)"
                    (searched)="setSearch($event)"></por-search-box>
            </div>

            <por-base-button featureName="filter-alt-icon-button" class="filter-btn" (onclick)="toggleMobileFilter = !toggleMobileFilter">
                <mat-icon [matBadgeHidden]="selectedFilters.length === 0" matBadge="{{ selectedFilters.length }}">filter_alt</mat-icon>
            </por-base-button>
        </div>
        <div class="filter" [ngClass]="{ active: toggleMobileFilter && onSmallScreen }">
            <div class="box">
                <div *ngIf="onSmallScreen" class="mobFilter-clear">
                    <span class="theme-paragraph">{{ 'Filters' | translate }}</span>
                    <por-base-button featureName="clear-all" (onclick)="clearCheckedFilters()" class="theme-button">
                        {{ 'Clear All' | translate }}
                        <mat-icon>close</mat-icon>
                    </por-base-button>
                </div>
                <div class="selectors-list">
                    <ul *ngIf="multiFilters.length > 0">
                        <form [formGroup]="multiCheckForm" *ngIf="enableForms">
                            <li *ngFor="let filter of multiFilters">
                                <apx-form-field>
                                    <apx-checkbox
                                        [checked]="true"
                                        name="multiCheckControlsDesktop"
                                        (valueChanged)="setMultiFilters($event, filter.value)"
                                        formControlName="{{ filter.value }}"
                                        porAddFeature
                                        featureName="checkbox-{{ filter.label }}">
                                        <span class="theme-paragraph">{{ filter.label }}</span>
                                    </apx-checkbox>
                                </apx-form-field>
                            </li>
                        </form>
                    </ul>
                </div>
                <div class="single-filter-wrapper" *ngIf="singleFilters.length > 0">
                    <ul class="single-filters">
                        <apx-radio-group (valueChanged)="setSingleFilter($event)" [value]="singleFilter">
                            <apx-radio-button class="apx-input" *ngFor="let filter of singleFilters" value="{{ filter.value }}" porAddFeature featureName="radio-{{ filter.label }}">
                                {{ filter.label }}
                            </apx-radio-button>
                        </apx-radio-group>
                        <por-base-button featureName="clear-single-filter" class="clear-single-filter" (onclick)="clearSingleFilter()"><mat-icon>add_circle_outline</mat-icon></por-base-button>
                    </ul>
                </div>
            </div>
            <div class="box dateSearch-box">
                <div class="date-range">
                    <div class="head">
                        <label class="theme-paragraph">{{ dateLabel ? dateLabel : ('filterbyDate' | translate) }}</label>
                        <por-base-button featureName="on-small-screen" *ngIf="onSmallScreen" (onclick)="clearFilter()" class="clearDate-btn theme-button">
                            {{ 'clear_dates' | translate }}
                        </por-base-button>
                    </div>
                    <div class="selectDate-box">
                        <por-date-filter (dateChanged)="dateChanged($event)" featureName="clear-dates" [startDateLabel]="startDateLabel" [endDateLabel]="endDateLabel"></por-date-filter>
                    </div>
                </div>
            </div>

            <por-base-button featureName="apply-filter" *ngIf="onSmallScreen" (onclick)="submit()" class="theme-button apply-btn">{{ 'apply' | translate }}</por-base-button>
        </div>
    </form>

    <form class="desktop-form" *ngIf="!onSmallScreen">
        <div class="desktop-formWrapper">
            <div class="text-search">
                <label class="theme-paragraph">{{ 'Text Search' | translate }}</label>
                <div class="text-searchBox">
                    <por-search-box
                        class="amount-control theme-button"
                        [placeholder]="searchPlaceholder ? searchPlaceholder : ('(quick search Contract#, PO#, and location)' | translate)"
                        (searched)="setSearch($event)"
                        [disabled]="disableSearch ? disableSearch : isProcessing"
                        featureName="text-search-box"></por-search-box>
                </div>
            </div>

            <div class="selectors-list">
                <ul *ngIf="multiFilters.length > 0">
                    <form [formGroup]="multiCheckForm" *ngIf="enableForms">
                        <li *ngFor="let filter of multiFilters">
                            <apx-form-field>
                                <apx-checkbox
                                    (valueChanged)="setMultiFilters($event, filter.value)"
                                    name="multiCheckControlsDesktop"
                                    formControlName="{{ filter.value }}"
                                    porAddFeature
                                    featureName="checkbox-{{ filter.label }}">
                                    <span class="theme-paragraph">{{ filter.label }}</span>
                                </apx-checkbox>
                            </apx-form-field>
                        </li>
                    </form>
                </ul>
            </div>

            <div class="single-filter-wrapper" *ngIf="singleFilters.length > 0">
                <ul class="single-filters">
                    <apx-radio-group (valueChanged)="setSingleFilter($event)" [value]="singleFilter">
                        <apx-radio-button class="apx-input" *ngFor="let filter of singleFilters" value="{{ filter.value }}" porAddFeature featureName="radio-{{ filter.label }}">
                            {{ filter.label }}
                        </apx-radio-button>
                    </apx-radio-group>
                    <por-base-button featureName="clear-single-filter" class="clear-single-filter" (onclick)="clearSingleFilter()"><mat-icon>add_circle_outline</mat-icon></por-base-button>
                </ul>
            </div>
        </div>

        <div class="second-section">
            <div class="box dateSearch-box">
                <div class="date-range">
                    <div class="selectDate-box">
                        <por-date-filter (dateChanged)="dateChanged($event)" featureName="select-date-box" [startDateLabel]="startDateLabel" [endDateLabel]="endDateLabel"></por-date-filter>
                    </div>
                </div>
            </div>
            <div class="export-wraper" *ngIf="versionToggle.isAdvanceVersion() && enableExport">
                <por-base-button featureName="filter-csv-button" [disabled]="!exportButtonEnabled" [class]="'theme-button csv-button'" (onclick)="exportCSV.emit()">
                    <span class="actions-image">
                        <svg
                            class="fill-current"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 50 50"
                            style="enable-background: new 0 0 50 50"
                            xml:space="preserve">
                            <g id="Group_14758" transform="translate(3.856)">
                                <path id="Path_11207" d="M33.3,13.2V1l12.2,12.2L33.3,13.2z" />
                                <path
                                    id="Path_11208"
                                    d="M31.9,16c-0.8,0-1.4-0.6-1.4-1.4V1H6.9C6.1,1,5.5,1.7,5.5,2.4v18.3h27.2
                                c2.3,0,4.2,1.9,4.2,4.2v14.5c0,2.3-1.9,4.2-4.2,4.2H5.5v4.1c0,0.8,0.6,1.4,1.4,1.4H44c0.8,0,1.4-0.6,1.4-1.4V16H31.9z" />
                            </g>
                            <path
                                id="Path_11209"
                                d="M36.6,23.6H2.5c-0.8,0-1.4,0.6-1.4,1.4c0,0,0,0,0,0v14.5c0,0.8,0.6,1.4,1.4,1.4h34.1
                            c0.8,0,1.4-0.6,1.4-1.4V25C38,24.2,37.3,23.6,36.6,23.6z M14.2,35.6c-0.8,1-2,1.5-3.2,1.5c-2.7,0-4.9-2.2-4.9-4.9
                            c0-2.7,2.2-4.9,4.9-4.9c1,0,1.9,0.3,2.7,0.8c0.5,0.4,0.7,1.1,0.3,1.6c-0.4,0.5-1.1,0.7-1.6,0.3c-1.2-0.8-2.7-0.5-3.5,0.7
                            C8,32,8.3,33.5,9.5,34.3c0.4,0.3,0.9,0.4,1.4,0.4c0.5,0,0.9-0.1,1.3-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.5,1.2-0.6,1.7-0.1
                            C14.5,34.4,14.6,35.1,14.2,35.6L14.2,35.6L14.2,35.6z M23,34.5C22.6,36,21.2,37,19.7,37c-1.3,0-2.5-0.5-3.3-1.4
                            c-0.4-0.5-0.4-1.2,0.1-1.7c0,0,0,0,0,0c0.5-0.4,1.2-0.4,1.7,0.1c0.4,0.4,1,0.6,1.6,0.6c0.4,0,0.8-0.2,0.9-0.6c0-0.3-0.4-0.5-0.7-0.6
                            c-1.1-0.4-2.1-0.8-2.1-0.9c-0.1,0-0.1-0.1-0.2-0.1C16.8,32,16.3,31,16.5,30c0.2-1.1,1-2,2-2.2c1.3-0.4,2.8,0,3.9,0.8l0,0
                            c0.5,0.4,0.6,1.2,0.1,1.7c-0.4,0.5-1.2,0.6-1.7,0.1l0,0c-0.5-0.4-1.1-0.5-1.7-0.4c-0.2,0-0.3,0.2-0.4,0.3l0,0c0,0.1,0,0.2,0.1,0.2
                            c0.3,0.1,1,0.4,1.8,0.7C22.2,31.6,23.2,33,23,34.5L23,34.5z M32.2,28.9l-2.4,7.3c-0.2,0.5-0.7,0.9-1.3,0.9l0,0
                            c-0.6,0-1.1-0.4-1.3-0.9l-2.5-7.3c-0.2-0.6,0.1-1.3,0.7-1.5s1.3,0.1,1.5,0.7l1.6,4.5l1.5-4.5c0.2-0.6,0.9-1,1.5-0.7
                            C32.1,27.6,32.5,28.3,32.2,28.9L32.2,28.9L32.2,28.9z" />
                        </svg>
                    </span>
                    <span>{{ 'Export' | translate }}</span>
                </por-base-button>
            </div>
        </div>
    </form>
</div>
