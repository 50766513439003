import { appReducer } from './app-wide/app/app.reducers';
import { configReducer } from './app-wide/config/config.reducers';
import { currencyReducer } from './app-wide/currencycode/currencycode.reducers';
import { dialogReducer } from './app-wide/dialog/dialog.reducers';
import { eventLogsReducer } from './app-wide/event-logs/event-logs.reducers';
import { PaginationReducer } from './app-wide/pagination/pagination.reducers';
import { redirectionReducer } from './app-wide/redirection/redirection.reducers';
import { contractReducer } from './domains/contract/contract.reducers';
import { customerSummaryReducer } from './domains/customer/customer.reducers';
import { documentReducer } from './domains/document/document.reducers';
import { invoicesReducer } from './domains/invoices/invoices.reducers';
import { itemsOutReducer } from './domains/itemsout/itemsout.reducers';
import { paymentReducer } from './domains/payment/payment.reducers';
import { productRecuder } from './domains/product/product.reducers';
import { quickLinkDataReducer } from './domains/quicklink/quicklink.reducers';

type Store = {
    customer: typeof customerSummaryReducer;
    contracts: typeof contractReducer;
    itemsOut: typeof itemsOutReducer;
    invoices: typeof invoicesReducer;
    config: typeof configReducer;
    docData: typeof documentReducer;
    eventLogs: typeof eventLogsReducer;
    quickLinkData: typeof quickLinkDataReducer;
    redirection: typeof redirectionReducer;
    currency: typeof currencyReducer;
    payment: typeof paymentReducer;
    dialog: typeof dialogReducer;
    product: typeof productRecuder;
    pagination: typeof PaginationReducer;
    appState: typeof appReducer;
};

const store: Store = {
    customer: customerSummaryReducer,
    contracts: contractReducer,
    itemsOut: itemsOutReducer,
    invoices: invoicesReducer,
    config: configReducer,
    docData: documentReducer,
    eventLogs: eventLogsReducer,
    quickLinkData: quickLinkDataReducer,
    redirection: redirectionReducer,
    currency: currencyReducer,
    payment: paymentReducer,
    dialog: dialogReducer,
    product: productRecuder,
    pagination: PaginationReducer,
    appState: appReducer
};
export default store;
