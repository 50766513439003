/* eslint-disable @typescript-eslint/naming-convention */
import { DateFormatType } from './enums/date-format-type.enum';
export class DateUtility {
    /**
     * Accepts a descriptive format type and returns the corresponding format for display purposes
     * @param formatType
     * @returns string
     */
    static getDateDisplayFormat(formatType: DateFormatType, locale = 'en-GB'): string {
        return (
            {
                [DateFormatType.MonthNameDayYearTime]: 'MMM d, y, h:mm a',
                [DateFormatType.MonthNameDayYear]: 'MMM d, y',
                [DateFormatType.DayMonthNameYear]: {
                    'en-GB': 'd MMM, yyyy',
                    'en-US': 'MMM d, yyyy'
                }[locale],
                [DateFormatType.DayOfWeek]: 'cccc',
                [DateFormatType.ShortTime]: 'h:mm a',
                [DateFormatType.StandardDate]: {
                    'en-GB': 'dd/MM/yyyy',
                    'en-US': 'MM/dd/yyyy',
                    'fr-CA': 'yyyy-MM-dd',
                    'de-DE': 'dd.MM.yy'
                }[locale],
                [DateFormatType.StandardDateTime]: {
                    'en-GB': 'dd/MM/yyyy H:mm ',
                    'en-US': 'MM/dd/yyyy h:mm a',
                    'fr-CA': 'yyyy-MM-dd h:mm a',
                    'de-DE': 'dd.MM.yy  h:mm a'
                }[locale],
                [DateFormatType.ShortDate]: {
                    'en-GB': 'd/M/yy',
                    'en-US': 'M/d/yy'
                }[locale],
                [DateFormatType.ShorterDate]: {
                    'en-GB': 'dd/MM',
                    'en-US': 'MM/dd',
                    'fr-CA': 'MM-dd',
                    'de-DE': 'dd.MM'
                }[locale],
                [DateFormatType.ShortDayOfWeek]: 'ccc',
                [DateFormatType.Short]: {
                    'en-GB': 'd/M/yy, h:mm a',
                    'en-US': 'M/d/yy, h:mm a'
                }[locale]
            }[formatType] || 'yyyy-MM-dd'
        );
    }
}
