import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { Invoice } from '../../models/invoice-model';
import { TranslateService } from '@ngx-translate/core';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { AppMediatorService } from '../../services/app-mediator.service';
import { AppFacadeService } from '../../services/app-facade.service';
import { GetDocumentParam } from '../../models/contract-model';
import { Subscription } from 'rxjs';
import { DocumentData } from '../../store/domains/document/document.reducers';
import { ProductDocumentType } from '../../enums/product-document-type.enum';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Component({
    selector: 'por-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ required: true }) invoiceDetail!: Invoice;
    @Input() uiUrl: string | undefined;
    @Output() readonly closePanel = new EventEmitter<boolean>(false);
    subscriptions: Subscription[] = [];

    dateFormat: string;

    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly translateService: TranslateService,
        private readonly appMediatorService: AppMediatorService,
        readonly featureToggleService: FeatureToggleService,
        readonly appFacadeService: AppFacadeService
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        if (this.featureToggleService.isAvailable('isInvoicePdfDisplayed')) {
            const organizationType: string = this.appMediatorService.localStorageService?.getCurrentUser?.organizationType as string;

            const contractdetails: GetDocumentParam = {
                customerId: this.invoiceDetail?.CustomerId ?? '0',
                contractId: '0',
                orgType: organizationType,
                documentValue: this.invoiceDetail.Id
            };
            /**
             * Download Invoice
             */
            this.appFacadeService.downloadDocument(contractdetails, ProductDocumentType.Invoice);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
        this.appFacadeService.cancelLoadDocuments();
        this.appFacadeService.emptyDocument();
    }

    onCloseButtonClick() {
        this.closePanel.emit(true);
    }

    openDocumentUrl(documentType: string): void {
        this.subscriptions.push(
            this.appFacadeService.openPdfdocument(documentType).subscribe((response: DocumentData[]) => {
                if (response.length > 0) {
                    this.appFacadeService.pdf.openPdfinNewTab(response[0].url);
                }
            })
        );
    }
}
