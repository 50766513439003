import { Action } from '@ngrx/store';
import { consumerPortalDefaults } from '../../../input-config/consumer-portal-config';
import { ConsumerPortalConfig } from '../../models';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { ConsumerPortalState } from '../../types/state.type';
import { Redirection } from '../../types/redirection.type';

export interface AppState {
    config: ConsumerPortalConfig;
    isLoading: boolean;
    isAuthenticated: boolean;
    currencyCode: {
        customerCurrencyCode: string | null;
        contractsCurrencyCode: string | null;
    };
    activeTab: ComponentsToLoad;
    paymentSectionEnabled: boolean;
    isAdminLoading: boolean;
    consumerPortalState: ConsumerPortalState;
    isRedirected: boolean;
    versionRedirection: Redirection | null;
}

export const initialState: AppState = {
    config: consumerPortalDefaults,
    isLoading: false,
    isAuthenticated: false,
    currencyCode: {
        customerCurrencyCode: null,
        contractsCurrencyCode: null
    },
    activeTab: ComponentsToLoad.AccountSummary,
    paymentSectionEnabled: false,
    isAdminLoading: false,
    versionRedirection: null,
    consumerPortalState: 'None',
    isRedirected: false
};

export type ReducerType = (state: AppState | undefined, action: Action) => AppState;
