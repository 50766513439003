import { createAction, props } from '@ngrx/store';
import { DialogContentType } from '../../enums/dialog-type.enum';

export enum DialogActionTypes {
    ShowDialog = '[Dialog] Show',
    SetDialogDisplayed = '[Dialog] Set Dialog Displayed'
}

export const showDialog = createAction(DialogActionTypes.ShowDialog, props<{ dialogContentType: DialogContentType }>());

export const setDialogDisplayed = createAction(DialogActionTypes.SetDialogDisplayed, props<{ isDialogDisplayed: boolean }>());
