import { ConsumerPortalConfig } from '../../models';
import { AppState, ReducerType, initialState } from '../state/app.state';
import { setConfig } from './../actions/config.actions';
import { createReducer, on } from '@ngrx/store';

export const configReducer: ReducerType = createReducer(
    initialState,
    on(setConfig, (state: AppState, { config }: { config: ConsumerPortalConfig }) => {
        return { ...state, config: { ...config } };
    })
);
