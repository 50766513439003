import { DialogContentType } from '../../../enums/dialog-type.enum';

export interface DialogState {
    isDialogDisplayed: boolean;
    dialogContentType?: DialogContentType;
}

export const initialDialogState: DialogState = {
    isDialogDisplayed: false
};
