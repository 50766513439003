import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig } from '../models/consumer-login-config';
import { Verify } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@por/shared/core';
import { ModalStateEnum } from '@por/ecom-shared';

@Component({
    selector: 'por-verify',
    templateUrl: './verify-component.component.html',
    styleUrls: ['./verify-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyComponentComponent implements OnInit {
    @Output()
    readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    readonly verificationData: EventEmitter<Verify> = new EventEmitter<Verify>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;

    @Input() userEmail!: string;

    @Input() isDisableButton = false;

    verificationForm = this.fb.group({
        code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    constructor(private readonly fb: FormBuilder, private readonly translateService: TranslateService) {}

    get code() {
        return this.verificationForm.get('code');
    }
    modal = '';
    ngOnInit(): void {
        this.translateService.use(Language.EN_GB);

        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
        }

        this.verificationForm.setValue({
            code: ''
        });
    }

    verify() {
        const code = this.verificationForm.value.code as string;

        if (!this.verificationForm.valid) {
            return false;
        }
        this.verificationData.emit({
            email: this.userEmail,
            code: code
        });
        return true;
    }

    setTemplate(templateName: string) {
        this.selectedTemplate.emit(templateName);
    }

    openModal() {
        this.modal = ModalStateEnum.OPEN;
    }

    closeModal(event: boolean) {
        if (event) {
            this.modal = ModalStateEnum.CLOSE;
        }
    }
}
