import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateFormatType } from '../enums/date-format-type.enum';
import { DateUtility } from '../date.utility';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
    private readonly dateFormat: string;

    constructor(private readonly datePipe: DatePipe, @Inject(LOCALE_ID) private readonly locale: string) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, this.locale);
    }

    transform(value: string | number | Date, isSyrinx: boolean, dateFormat?: DateFormatType): string {
        if (!value) {
            return '';
        }
        const offsetMinutes = new Date(value).getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
        const offsetRemainingMinutes = Math.abs(offsetMinutes) % 60;
        const sign = offsetMinutes > 0 ? '-' : '+';
        const formattedOffset = !isSyrinx ? `${sign}${this.padWithZero(offsetHours)}:${this.padWithZero(offsetRemainingMinutes)}` : '';
        const date = this.datePipe.transform(value, dateFormat ? DateUtility.getDateDisplayFormat(dateFormat, this.locale) : this.dateFormat, 'UTC' + formattedOffset);
        return date ? date : '';
    }
    padWithZero(number: number): string {
        return number < 10 ? '0' + number : number.toString();
    }
}
