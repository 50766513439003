import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { SendLinkRequest, SendLinkResponse } from '../models/send-link-request';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(private readonly logger: LoggerService, private readonly consumerPortalApi: ConsumerPortalApiService) {}

    /**
     * Ref: https://wiki.pointofrental.com/e/en/Departments/Engineering/Consumer-Portal/Quick-Link
     * contractId = lineItemId for Product/Stock QT
     */
    send(data: SendLinkRequest): Observable<SendLinkResponse> {
        const params = {
            controller: `${data.customerId}/utility/contracts/${data.contractId}/send`,
            method: 'POST',
            body: {
                ...data,
                contractId: data.lineItemId
            }
        };

        return this.consumerPortalApi.post<SendLinkResponse>(params).pipe(
            tap({
                next: (res: SendLinkResponse) => {
                    return res;
                },
                error: (err: HttpErrorResponse) => {
                    this.logger.logError(err);
                    this.logger.alertDevError(err);
                    return of(true);
                }
            })
        );
    }
}
