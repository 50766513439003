import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AccessRequestResponse, AuthRecord } from '../models/access-response';
import { DEMO_ENV_CONFIG } from '../../app-config';
import { isEmpty } from 'lodash';
import { ConsumerPortalEnviromentConfig } from '../models/env-model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private readonly http: HttpClient, @Inject(DEMO_ENV_CONFIG) private readonly appConfig: ConsumerPortalEnviromentConfig) {}

    private readonly isLoggedIn = new BehaviorSubject<boolean>(false);
    private readonly auth = new BehaviorSubject<AuthRecord | null>(null);
    private readonly baseUrl = this.appConfig.loginApiUrl;

    public isLoggedIn$ = this.isLoggedIn.asObservable();
    public auth$ = this.auth.asObservable();

    login({ orgId, email, orgType, apiKey }: { orgId: string; email: string; orgType: string; apiKey: string }): Observable<AccessRequestResponse | unknown> {
        return this.http
            .post(
                this.generateAuthUrl('/auth/tokens/access'),
                {
                    /* eslint-disable @typescript-eslint/naming-convention  */
                    /**
                     * Note camelCase : DB Model
                     */
                    OrganizationId: Number(orgId),
                    Email: email,
                    OrganizationType: orgType
                },
                {
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .pipe(
                map(res => {
                    const response = res as AccessRequestResponse;
                    this.isLoggedIn.next(true);
                    this.auth.next(response.Records);
                    return response;
                })
            );
    }

    generateAuthUrl(url: string) {
        if (isEmpty(url)) {
            return url;
        }
        return this.baseUrl + url;
    }
}
