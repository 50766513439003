import { Directive, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[porScrollTo]'
})
export class ScrollToDirective implements OnChanges {
    @Input() porScrollTo!: boolean; // Trigger to scroll when set to true
    @Output() readonly scrollComplete: EventEmitter<void> = new EventEmitter<void>(); // Output event when scroll completes

    constructor(private readonly el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['porScrollTo'] && this.porScrollTo) {
            this.scrollToElement();
        }
    }

    private scrollToElement(): void {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // Emit the event after scrolling completes
        this.scrollComplete.emit();
    }
}
