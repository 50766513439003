import { Observable } from 'rxjs';
import { Searchable } from '../models/searchable.model';
import { ApiHeaders, ApiQuery } from '../models/api-query.model';
import { ApiResult } from '@por/shared/core';
import { ApiRequest } from '../models/api-request.model';

export type VerbType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'DELETE_WITH_BODY';
export interface ApiSendParams {
    verb: VerbType;
    controller: string;
    method?: string | null;
    body?: Searchable | unknown;
    headers?: ApiHeaders;
    headerParams?: ApiQuery;
}

export interface ApiMethodArgs {
    noMethod?: boolean;
}

/**
 * Specialized App-wide service
 * Provided in the App Module
 * Override provider as necessary to provide a specialized implementation
 * i.e. { provide: ApiService, useClass: OtherApiService }
 * */
// @Injectable()
export abstract class ApiService {
    abstract readonly defaultPageSize: number;

    /**
     * Requesting multiple entities:
     * @param controller
     * @param apiQuery
     * @param camelCase
     */
    abstract get<T extends Searchable>(controller: string, apiQuery?: ApiQuery, camelCase?: boolean): Observable<T[]>;

    abstract getData<T extends Searchable>(controller: string, apiQuery?: ApiQuery): Observable<T[]>;
    /**
     * Requesting a single entity:
     * @param controller
     * @param id
     * @param apiQuery
     * @param camelCase
     */
    abstract getById<T extends Searchable>(controller: string, id: string, apiQuery?: ApiQuery, camelCase?: boolean): Observable<T | null>;

    /**
     * Requesting a list of items by post endpoint using array of parameters i.e. menu items //TODO Review if necessary
     * @param controller
     * @param method
     * @param body
     * @param apiQuery
     * @param camelCase
     */
    abstract getByPost<T extends Searchable>(controller: string, method: string, body: ApiRequest, apiQuery?: ApiQuery, camelCase?: boolean): Observable<T[]>;

    abstract search<T extends Searchable>(controller: string, apiQuery?: ApiQuery, camelCase?: boolean): Observable<ApiResult<T>>;

    abstract add<T>(controller: string, method: string, entity: T, apiQuery?: ApiQuery, camelCase?: boolean): Observable<T>;

    abstract save<T extends Searchable>(controller: string, entity: T, apiQuery?: ApiQuery, camelCase?: boolean): Observable<T>;

    /**
     * Used for getting general resources
     * @param url
     * @param params
     */
    protected abstract find<T>(url: string, params: ApiQuery): Observable<ApiResult<T>>;

    /**
     * Used for saving/updating resources
     * @param args
     */
    protected abstract send<T>(args: ApiSendParams, params: ApiQuery): Observable<T>;

    /** *******************************
     * Map Record(s) response to camelCase
     * @param rec
     **********************************/
    toCamelCase(rec: object[] | object) {
        let origKey: string | number, newKey: string, val: object;
        const newRecord: Record<string, string | object> = {};
        if (rec instanceof Array) {
            return rec.map(value => {
                if (typeof value === 'object') {
                    value = this.toCamelCase(value);
                }
                return value;
            });
        } else {
            for (origKey in rec) {
                // eslint-disable-next-line no-prototype-builtins
                if (rec.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                    val = rec[origKey as keyof typeof rec];

                    if (val instanceof Array || val?.constructor === Object) {
                        val = this.toCamelCase(val);
                    }
                    newRecord[newKey] = val;
                }
            }
        }
        return newRecord;
    }
}
