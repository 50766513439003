export enum DateFormatType {
    /** Example: 'Nov 24, 2022, 3:38 PM' */
    MonthNameDayYearTime = 'monthNameDayYearTime',
    /** Example: 'Nov 24, 2022' */
    MonthNameDayYear = 'monthNameDayYear',
    /** Example: '24 Nov, 2022' */
    DayMonthNameYear = 'dayMonthNameYear',
    /** Example: 'Thursday' or 'Saturday' */
    DayOfWeek = 'dayOfWeek',
    /** Example: 'Thu' or 'Sat' */
    ShortDayOfWeek = 'shortDayOfWeek',
    /** Example: '6:00 PM' or '12:00 am' */
    ShortTime = 'shortTime',
    /** Example: '1/4/2022' or '6/12/2022' */
    ShortDate = 'shortDate',
    /** Example: '01/04' or '6/12' */
    ShorterDate = 'shorterDate',
    /** Example: '01/04/2022' or '21/02/2022' */
    StandardDate = 'standardDate',
    /** Example: '1/4/2022, 3:38 PM' or '21/2/2022, 3:38 PM' */
    Short = 'short',
    StandardDateTime = "StandardDateTime"
    /** Example: '1/4/2022 3:38 PM' or '21/2/2022 3:38 PM' */
}
