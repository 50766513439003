import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringify'
})
export class StringifyPipe implements PipeTransform {
    transform(value: unknown, indentation: number = 2): string {
        // change value typed from any to unknown
        return JSON.stringify(value, null, indentation);
    }
}
