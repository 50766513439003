import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, finalize, Observable, Subscription, tap, throwError } from 'rxjs';
import { VersionToggleService } from './version-toggle';
import { ErrorDailogBoxComponent } from '../shared/components/error-dailog-box/error-dailog-box.component';
import { ErrorDetail, ErrorResponse, RequestBody } from '../shared/models/request-body.interface';
import { ErrorType } from '../models/error.model';
import { AuthUiService } from './auth-ui.service';
import { ErrorService } from './error.service';
import { AppFacadeService } from './app-facade.service';
import { AppMediatorService } from './app-mediator.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiResultMessage, ApiResultMessageType } from '@por/shared/core';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
    constructor(
        private readonly versionToggle: VersionToggleService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly appRef: ApplicationRef,
        private readonly injector: Injector,
        private readonly errorService: ErrorService,
        private readonly auth: AuthUiService,
        private readonly appfacade: AppFacadeService,
        private readonly appMediatorService: AppMediatorService,
        private readonly translateService: TranslateService
    ) {}
    private currentDialogRef: ComponentRef<ErrorDailogBoxComponent> | null = null;

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap(event => {
                // Handle successful HTTP responses
                if (event instanceof HttpResponse) {
                    const bodyMessage: ApiResultMessage = event.body?.Message ? event.body.Message : null;
                    if (bodyMessage && bodyMessage.Type === ApiResultMessageType.Error) {
                        this.handleError(
                            {
                                status: bodyMessage.Status ? bodyMessage.Status : event.status,
                                url: event.url || '',
                                error: {
                                    message: bodyMessage.Message ? bodyMessage.Message : event.statusText
                                }
                            },
                            request
                        );
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.handleError(
                    {
                        status: error?.status ?? 0,
                        url: request?.url || '',
                        error: {
                            message: error?.error?.message ?? 'Error in the BackendInterceptor!'
                        }
                    },
                    request
                );
                return throwError(() => error);
            }),
            finalize(() => {
                this.appfacade.setLoading(false);
            })
        );
    }

    private createDialogConfig(body: RequestBody, errorStatus: string): ErrorDetail {
        return {
            errormessage: 'An error occurred while processing your request',
            quickLink: this.versionToggle.isQuickLinkVersion() ? window.location.href : '',
            orgId: body?.OrganizationId || this.appMediatorService.localStorageService.getCurrentUser?.organizationId || '',
            customerId: body?.CustomerId || this.appMediatorService.localStorageService.getCurrentUser?.customerId || '',
            date: new Date().toString(),
            contractId: body?.ContractId || this.appMediatorService.localStorageService.getCurrentUser?.contractId || '',
            errorStatus: errorStatus || ''
        };
    }

    private showDialog(config: ErrorDetail, allowClose: boolean = false): void {
        if (this.currentDialogRef) {
            this.cleanupDialog(this.currentDialogRef);
            this.currentDialogRef = null;
        }

        // Create the component manually
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ErrorDailogBoxComponent);
        const componentRef = componentFactory.create(this.injector);
        this.currentDialogRef = componentRef;

        componentRef.instance.data = config;
        componentRef.instance.allowClose = allowClose;

        // Use object destructuring to set properties
        Object.assign(componentRef.instance, config);

        // Attach the component to the application
        this.appRef.attachView(componentRef.hostView);

        // Append the component to the body
        const domElem = (componentRef.hostView as EmbeddedViewRef<ErrorDailogBoxComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        // Subscribe to retry action
        const retrySubscription = componentRef.instance.retry.subscribe(() => {
            window.location.reload(); // Reload the window on Retry
        });

        // Subscribe to closeDialog action
        const closeSubscription = componentRef.instance.closeDialog.subscribe(() => {
            this.cleanupDialog(componentRef, retrySubscription, closeSubscription);
            this.currentDialogRef = null;
        });

        // Ensure all subscriptions are unsubscribed when dialog is destroyed
        const cleanupCallback = () => {
            this.cleanupDialog(componentRef, retrySubscription, closeSubscription);
            this.currentDialogRef = null;
        };

        retrySubscription.add(cleanupCallback);
        closeSubscription.add(cleanupCallback);
    }

    private cleanupDialog(componentRef: ComponentRef<ErrorDailogBoxComponent>, ...subscriptions: Subscription[]): void {
        // Detach and destroy the component
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();

        // Unsubscribe to prevent memory leaks
        subscriptions.forEach(sub => sub.unsubscribe());
    }

    private handleError(error: ErrorResponse, request: HttpRequest<unknown>) {
        let dialogConfig: ErrorDetail = this.createDialogConfig(request.body as RequestBody, error.status.toString());
        dialogConfig = {
            ...dialogConfig,
            errorStatus: error.status.toString(),
            apiMessage: error.error.message
        };
        const isNetworkError = error.status === 0;
        const isOffline = !navigator.onLine;
        /**
         * First check for Offline, then check for api connectivity.
         */
        if (isOffline) {
            dialogConfig = {
                ...dialogConfig,
                errormessage: this.translateService.instant('ApplicationOffline')
            };
            this.showDialog(dialogConfig);
        } else if (isNetworkError) {
            dialogConfig = {
                ...dialogConfig,
                errormessage: this.translateService.instant('NetworkError')
            };
            this.showDialog(dialogConfig);
        }
        const isAuthError = [401].includes(error.status);
        const isInternalError = [500, 404, 403].includes(error.status);
        const isBadRequestError = [400].includes(error.status);
        const isRefreshTokenError = error?.url?.includes('tokens/refresh');

        /**
         * Check for SQS message, because when There is no expert server tied to your organization
         * GAPI returns message with 403 status code
         */
        const isSQSError = [502, 504].includes(error.status) || error.error.message.includes('SQS');
        const excludeUrls: string[] = ['getpdf', 'REST/v1/Locations'];
        const isExcludedUrl: boolean = excludeUrls.some(url => request.url.includes(url));

        if ((([401, 403].includes(error.status) && !isSQSError) || (!error?.status && isRefreshTokenError)) && !this.versionToggle.isQuickLinkVersion()) {
            this.errorService.setError(ErrorType.AuthFailed);
            this.auth.logout(); // Trigger logout after retry
        } else if (isAuthError && this.versionToggle.isQuickLinkVersion()) {
            window.location.reload();
        } else if (isSQSError) {
            dialogConfig = {
                ...dialogConfig,
                errormessage: this.translateService.instant('SQSIssue')
            };
            /**
             * For QL, SQS issue = dead end, but for CP, user still can perform password recovery, signup etc.
             * Admin still can change config options, so QL, showdialog but can't close and show retry button
             * rest version, show dialog but with close button
             */
            this.showDialog(dialogConfig, this.versionToggle.isQuickLinkVersion() ? false : true);
        } else if (isBadRequestError && !isExcludedUrl) {
            dialogConfig = {
                ...dialogConfig,
                apiMessage: error.error.message || this.translateService.instant('BadRequest')
            };
            this.showDialog(dialogConfig, true);
        } else if ((isInternalError || !isAuthError) && !isExcludedUrl) {
            this.showDialog(dialogConfig);
        }
    }
}
