import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig } from '../models/consumer-login-config';
import { User } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@por/shared/core';
import { AppFormValidators } from '../validators/app.validators';

@Component({
    selector: 'por-forgot-password',
    templateUrl: './forgot-password-component.component.html',
    styleUrls: ['./forgot-password-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponentComponent implements OnInit {
    @Output()
    readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    readonly forgotPasswordData: EventEmitter<User> = new EventEmitter<User>();

    @Output()
    readonly loading$: EventEmitter<boolean> = new EventEmitter<boolean>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;

    @Input() isDisableButton = false;

    resetPasswordForm = this.fb.group({
        username: ['', [Validators.required, AppFormValidators.emailValidator, Validators.maxLength(128)]]
    });

    constructor(private readonly fb: FormBuilder, private readonly translateService: TranslateService) {}

    async ngOnInit() {
        this.translateService.use(Language.EN_GB);

        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
        }

        this.resetPasswordForm.setValue({
            username: ''
        });
    }

    get username(): FormControl {
        return this.resetPasswordForm.get('username') as FormControl;
    }

    submit(): boolean {
        const email: string = this.resetPasswordForm.value.username as string;

        if (!this.resetPasswordForm.valid) {
            return false;
        }
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        this.forgotPasswordData.emit({
            Email: email,
            StoreName: this.inputs?.storeName ?? ''
        });
        this.loading$.emit(true);
        return true;
    }

    setTemplate(templateName: string): void {
        this.selectedTemplate.emit(templateName);
    }
}
