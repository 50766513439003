import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger.service';
interface BingWindow extends Window {
    onBingLoaded: () => void;
}

declare let window: BingWindow;
@Injectable()
export class BingMapsLoader {
    private static promise: Promise<unknown>;

    public static load(url: string) {
        // First time 'load' is called?
        if (!BingMapsLoader.promise) {
            // Make promise to load
            BingMapsLoader.promise = new Promise(resolve => {
                // Set callback for when bing maps is loaded.
                window['onBingLoaded'] = () => {
                    // resolve('Bing Maps API loaded');
                    new LoggerService().logInfo('Bing Maps API loaded');
                };
                resolve(url);
            });
        }

        // Always return promise. When 'load' is called many times, the promise is already resolved.
        return BingMapsLoader.promise;
    }
}
