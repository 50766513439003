export * from './customer-portal-ui.module';
export * from './customer-portal-admin-ui.module';
export * from './models';
export * from './services';
export * as cpTypes from './models/consumer-portal-config';
export { CustomerPortalComponent } from './components/customer-portal/customer-portal.component';
export { CustomerPortalAdminComponent } from './admin/components/admin/admin.component';
export * from './languages';
export * from './constants/default.const';

import * as enUSTranslations from '../assets/en-US.json';
import * as enGBTranslations from '../assets/en-GB.json';
import * as frCATranslations from '../assets/fr-CA.json';

export const ConsumerPortalLanguage = {
    enUSTranslations,
    enGBTranslations,
    frCATranslations
};

import { LicenseManager } from 'ag-grid-enterprise';
// ag-Grid License
const agGridLicenseKey =
    'Using_this_{AG_Grid}_Enterprise_key_{AG-056131}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Point_of_Rental_Software}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{LAUNCHPAD}_only_for_{17}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{LAUNCHPAD}_need_to_be_licensed___{LAUNCHPAD}_has_been_granted_a_Deployment_License_Add-on_for_{Unlimited}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_April_2025}____[v3]_[01]_MTc0MzgwNzYwMDAwMA==f258f9259e8cdfd84f8220d29d8cf68d';
LicenseManager.setLicenseKey(agGridLicenseKey);
