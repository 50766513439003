<div>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
    <mat-icon>more_horizontal</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="actionCall()">
      <mat-icon>rotate_left</mat-icon>
      <span>{{ 'Request Call Off' | translate }}</span>
    </button>
  </mat-menu>
</div>
