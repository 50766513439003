import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ModalService } from '../../services';

@Component({
    selector: 'por-items-out-action-component',
    templateUrl: './items-out-action-component.html',
    styleUrls: ['./items-out-action-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsOutActionComponent implements ICellRendererAngularComp, AfterViewInit {
    public params?: {
        data?: {
            id?: string;
            customerId?: string;
        };
        value?: string;
    };
    customerId!: string;

    constructor(private readonly modal: ModalService, private readonly cdref: ChangeDetectorRef) {}
    ngAfterViewInit(): void {
        this.cdref.detectChanges();
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        if (this.params?.data?.customerId) {
            this.customerId = this.params?.data?.customerId;
        }
    }

    refresh(params: ICellRendererParams): boolean {
        if (params.value !== this.params?.value) {
            this.params = params;
        }
        return true;
    }
    actionCall() {
        this.modal.open(this.params?.data?.id ?? '');
    }
}
