<ng-container *ngIf="appFacadeService.isDialogDisplayed() | async" class="payment-success-dialog-container">
    <ng-container *ngIf="(appFacadeService.getDialogContentType() | async) === dialogContentType.PaymentSuccess">
        <por-app-confirm-dialog (confirmed)="appFacadeService.toggleDialog(false)" [message]="dialogMessage">
            <ng-container *ngIf="appFacadeService.getLastPaymentTransactionData() | async as transactionData">
                <p>
                    <ng-container [ngSwitch]="transactionData?.TransactionResultEnum">
                        <ng-container *ngSwitchCase="transactionResultEnum.PENDING">
                            <span class="title">{{ 'PaymentPending' | translate }}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="transactionResultEnum.DECLINED">
                            <span class="title">{{ 'PaymentDeclined' | translate }}</span>
                            <hr />
                        </ng-container>
                        <ng-container *ngSwitchCase="transactionResultEnum.CANCELLED">
                            <span class="title">{{ 'PaymentCancelled' | translate }}</span>
                            <hr />
                        </ng-container>
                        <ng-container *ngSwitchCase="transactionResultEnum.ERROR">
                            <span class="title">{{ 'PaymentErrorHeading' | translate }}</span>
                            <hr />
                        </ng-container>
                        <ng-container *ngSwitchCase="transactionResultEnum.APPROVEDWITHWARNING">
                            <span class="title">{{ 'PaymentSuccessWithWarning' | translate }}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="transactionResultEnum.APPROVED">
                            <span class="title">{{ 'PaymentReceived' | translate }}</span>
                        </ng-container>
                    </ng-container>
                </p>
                <ng-container *ngIf="(appFacadeService.isPaymentApproved() | async) === true">
                    <p class="thanks-msg">{{ 'ThankYouForYourPayment' | translate }}</p>
                    <hr />
                    <p>
                        <span>{{ 'PaymentAmount' | translate }}:</span>
                        <span>
                            &nbsp;
                            {{ (transactionData?.Amount_Processed ?? 0) / 100 | formatCurrency : (appFacadeService.getPaymentCurrency() | async) }}
                        </span>
                    </p>
                </ng-container>
                <p>
                    <span>{{ 'DateTime' | translate }}:</span>
                    <span>&nbsp;{{ transactionData?.Created ?? '' | formatDate : appFacadeService.featureToggleService.isSyrinx() : dateFormat }}</span>
                </p>
                <p>
                    <span>{{ 'PaymentType' | translate }}:</span>
                    <span>&nbsp;{{ appMediatorService.porPayService.getPaymentMethod(transactionData?.PaymentMethodEnum) }}</span>
                </p>
            </ng-container>
            <p *ngIf="appFacadeService.getLastPaymentTransactionId() | async as transactionId">
                <span>{{ 'Reference#' | translate }}:</span>
                <span>&nbsp;{{ transactionId }}</span>
            </p>
            <p *ngIf="appFacadeService.getLastPaymentTransactionStatus() | async as status">
                <span>{{ 'Description' | translate }}:</span>
                <span>&nbsp;{{ status?.Description }}</span>
            </p>
        </por-app-confirm-dialog>
    </ng-container>
</ng-container>
