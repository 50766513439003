import { createAction, props } from '@ngrx/store';
import { ItemOutCP } from '../../../models/item-out-model';

export enum ItemsOutActionTypes {
    LoadItemOuts = '[ItemsOutAction] Load ItemsOutActions',
    SetNoMoreRecord = '[ItemsOutAction] Set No Record Found ItemsOut',
    ClearItemsOutInStore = '[ItemsOutAction] Clear ItemsOut',
    UpdateRowAction = '[ItemsOutAction] UPDATE_ROW',
    FetchItemsOutAction = '[ItemsOutAction] Fetch ItemsOut'
}

export const loadItemOuts = createAction(ItemsOutActionTypes.LoadItemOuts, props<{ items: ItemOutCP[] }>());

export const noRecordFoundItemsOut = createAction(ItemsOutActionTypes.SetNoMoreRecord, props<{ loading: boolean }>());

export const clearItemsOut = createAction(ItemsOutActionTypes.ClearItemsOutInStore);

export const updateRow = createAction(ItemsOutActionTypes.UpdateRowAction, props<{ nodeId: string; colId: string; value: string | Date | number[] | number }>());

export const fetchItemsOut = createAction(
    ItemsOutActionTypes.FetchItemsOutAction,
    props<{ customerId: number | string; pageNumber?: number; startDate?: string; endDate?: string; search?: string; status?: string; pageSize?: number }>()
);
