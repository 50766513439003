import { createReducer, on } from '@ngrx/store';
import { Invoice } from '../../../models/invoice-model';
import { clearInvoiceDetailInStore, clearInvoicesinStore, failLoadInvoiceDetails, loadInvoicesAction, setInvoiceDetailData, setInvoicesLoading, setNoMoreRecordInvoices } from './invoices.actions';

export const invoicesFeatureKey = 'invoices';

export interface InvoicesState {
    pageNumber: number;
    invoices: Invoice[];
    loading: boolean;
    noMoreRecord: boolean;
    invoiceDetail: Invoice | null;
}

export const initialState: InvoicesState = {
    pageNumber: 1,
    invoices: [],
    loading: false,
    noMoreRecord: false,
    invoiceDetail: null
};

export const invoicesReducer = createReducer(
    initialState,
    on(loadInvoicesAction, (state: InvoicesState, { invoices }: { invoices: Invoice[] }) => {
        if (invoices && invoices.length > 0) {
            return {
                ...state,
                invoices: [...state.invoices, ...invoices],
                pageNumber: state.pageNumber + 1
            };
        }
        return state;
    }),
    on(setInvoicesLoading, (state: InvoicesState, { loading }: { loading: boolean }) => {
        return { ...state, loading };
    }),
    on(setNoMoreRecordInvoices, (state: InvoicesState, { record }: { record: boolean }) => {
        return { ...state, noMoreRecord: record };
    }),
    on(clearInvoicesinStore, (state: InvoicesState) => {
        return { ...state, invoices: [], pageNumber: 1 };
    }),
    on(failLoadInvoiceDetails, (state: InvoicesState, { error }) => {
        return { ...state, invoiceDetail: null, error };
    }),
    on(setInvoiceDetailData, (state: InvoicesState, { data }) => {
        return { ...state, invoiceDetail: data };
    }),
    on(clearInvoiceDetailInStore, (state: InvoicesState) => {
        return { ...state, invoiceDetail: null };
    })
);
