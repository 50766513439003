<div class="ads-container">
    <form class="form-wrapper mt-2" [formGroup]="imagesForm" (ngSubmit)="onSubmit()">
        <ng-container *ngIf="appFacadeService.versionToggleService.isAdvanceVersion()" [ngTemplateOutlet]="adBanners" [ngTemplateOutletContext]="{ imagesForm: imagesForm }"></ng-container>
        <por-base-button (onclick)="onSubmit()" class="theme-button apply-btn" [disabled]="imagesForm.invalid" [featureName]="'admin-ad-save-button-data'">
            {{ 'Save' | translate }}
        </por-base-button>
    </form>
</div>
<ng-template #adBanners let-imagesForm="imagesForm">
    <hr />
    <h2>{{ 'ImagesAdTitle' | translate }}</h2>
    <div class="ads-wrapper-two" [formGroup]="imagesForm">
        <div class="left-section">
            <p>{{ 'adsectionInstruction1' | translate }}</p>
            <p>
                <strong>{{ 'AdsSizeInstructions1' | translate }}</strong>
            </p>
            <p>{{ 'AdsSizeInstructions2' | translate }}</p>
        </div>
        <div class="right-section">
            <div class="droppers-section">
                <div formArrayName="banners">
                    <div class="form-group-container" *ngFor="let bannerGroup of bannersArray.controls; let i = index" [formGroupName]="i">
                        <por-ad-drag-drop
                            (uploadSuccess)="handleFile($event, fileType.Banner, banners[i].type)"
                            [uiTitle]="banners[i].title | translate"
                            [title]="banners[i].imageTitle | translate"
                            [allowDelete]="true"
                            [previewDimension]="{ width: '324px', height: '144px' }"
                            (removeFile)="setBannersValues(banners[i].type, '')"
                            [currentImage]="bannersArray.at(i).value[banners[i].type]"></por-ad-drag-drop>
                        <div class="form-group form-group-space">
                            <apx-form-field
                                [label]="banners[i].urlTitle | translate"
                                [error]="bannersArray.at(i).get(banners[i].type + 'ExternalUrl')?.invalid && (bannersArray.at(i).get(banners[i].type + 'ExternalUrl')?.dirty || bannersArray.at(i).get(banners[i].type + 'ExternalUrl')?.touched) ?
                                bannersArray.at(i).get(banners[i].type + 'ExternalUrl')?.errors?.['invalidUrl'] ? ('InvalidUrl' | translate) :
                                ''
                                : '' ">
                                <input apxInput type="url" [placeholder]="'EnterUrl' | translate" [formControlName]="banners[i].type + 'ExternalUrl'" />
                            </apx-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
