import { Pipe, PipeTransform } from '@angular/core';
import size from 'lodash-es/size';

@Pipe({
  name: 'count',
})
export class CountPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: null | string | object | undefined, ...args: unknown[]): unknown {
    return size(value);
  }
}
