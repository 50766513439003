<div class="hamburger {{classes}}" porClickOutside>
  <img class="img-burger" (click)="openPanel()" [src]="btnimg" *ngIf="btnimg;else showIcon" />
  <ng-template #showIcon>
    <por-base-button
      [featureName]="'csv-button'" 
      [class]="'theme-button csv-button'"
      (onclick)="openPanel()"
      [icon]="(isOpened$ | async) ? 'close' : icon"
      iconClass="mat-18"
    ></por-base-button>
  </ng-template>
  <div class="{{panelClass}} hamburger-items" [ngClass]="{ active: (isOpened$ | async) }">
    <div class="hamburger-mob-heading" *ngIf="logo.length > 0 && closeOnPanel" >
    <a (click)="openHomepage()" href="javascript:void(0)"><img *ngIf="logo.length > 0" [src]="logo" /></a>
    <por-base-button [featureName]="'close-on-panel'" (click)="closePanel()" *ngIf="closeOnPanel" icon="close"></por-base-button>
  </div>
   <ng-content></ng-content>
  </div>
</div>
