import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
    selector: 'por-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent {
    @Input() progress: number | undefined = 0;
}
