import { cleanAccountSummary, loadCustomerFinished, loadCustomerSummary, loadCustomerSummaryFinished } from './../actions/customer-summary.actions';
import { createReducer, on } from '@ngrx/store';
import { loadCustomer } from '../actions/customer-summary.actions';
import { Customer, CustomerSummary } from '../../models/consumer';

export const customerFeatureKey = 'customer';

export interface CustomerState {
    information: Customer;
    customerSummary: CustomerSummary;
}

export const initialState: CustomerState = {
    information: {},
    customerSummary: {}
};

export const customerSummaryReducer = createReducer(
    initialState,
    on(loadCustomer, (state: CustomerState, { customer }: { customer?: Customer }) => {
        return customer?.Id ? { ...state, information: { ...customer } } : state;
    }),
    on(loadCustomerFinished, (state: CustomerState, { customer }: { customer?: Customer }) => {
        return customer?.Id ? { ...state, information: { ...customer } } : state;
    }),
    on(loadCustomerSummary, (state: CustomerState, { summary }: { summary?: CustomerSummary }) => {
        return summary ? { ...state, customerSummary: { ...summary } } : state;
    }),
    on(loadCustomerSummaryFinished, (state: CustomerState, { summary }: { summary?: CustomerSummary }) => {
        return summary ? { ...state, customerSummary: { ...summary } } : state;
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(cleanAccountSummary, (state: CustomerState) => {
        return initialState;
    })
);
