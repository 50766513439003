import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AppFacadeService } from '../../../services/app-facade.service';
import { CPEnvironment } from '../../../models';
import { ConsumerPortalEnviromentConfig } from '../../../models/environment-consumer-portal';

@Component({
    selector: 'por-multipayment',
    templateUrl: './por-multipayment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PorMultipaymentComponent {
    constructor(readonly appFacadeService: AppFacadeService, @Inject(CPEnvironment) private readonly appConfig: ConsumerPortalEnviromentConfig) {}

    porPayURL = this.appConfig.porPayScriptURL;

    closeDialog(): void {
        this.appFacadeService.setDialogEnabled(false);
        this.appFacadeService.paymentClose();
        return;
    }
}
