<div class="form-container por-verify">
    <h2 class="theme-header">{{ 'Verification' | translate }}</h2>
    <form [formGroup]="verificationForm" (ngSubmit)="verify()" novalidate>
        <apx-form-field
            class="apx-input-container d-inline-flex mb-sm"
            [error]="code?.invalid && (code?.dirty || code?.touched) ? 
            code?.errors?.['required'] ? ('Please enter verfication code.' | translate) : 
            code?.errors?.['minlength'] ? ('Code length should be 6.' | translate) :'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="lock"></span>
                <input apxInput formControlName="code" type="text" name="code" placeholder="{{ 'Enter your verfication code.' | translate }}" class="theme-input" maxlength="6" />
            </apx-input-group>
        </apx-form-field>

        <button class="btn-primary btn-full-width theme-button" type="submit" apxButton variant="primary" [disabled]="verificationForm.pristine || verificationForm.invalid">
            {{ 'Submit' | translate }}
        </button>
    </form>
    <p class="left-align theme-paragraph">
        <a porAddFeature featureName="Not-Received-Button" href="#" (click)="openModal('custom-modal-2')">{{ 'VerificationCodeNotReceived' | translate }}</a>
    </p>
    <p class="left-align theme-paragraph">
        {{ 'Already have an account?' | translate }}
        <a porAddFeature featureName="verify-signin-button" href="#" (click)="setTemplate('signin')" class="theme-anchor">{{ 'Sign in here' | translate }}</a>
    </p>
</div>
<por-custom-modal [id]="'custom-modal-2'">
    <por-verification-code-help-text [email]="userEmail"></por-verification-code-help-text>
</por-custom-modal>
