import isEmpty from 'lodash-es/isEmpty';
import { LATLONG } from '../../models/map-configuration.model';
import { AppFacadeService } from '../../services/app-facade.service';
import { NotificationService } from '../../services/notification.service';
import { OnInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ChangeDetectionStrategy } from '@angular/core';
import { Subscription, Observable, BehaviorSubject, combineLatest, tap, timer } from 'rxjs';
import filter from 'lodash-es/filter';
import size from 'lodash-es/size';
import { ConfigurationService } from '../../services/configuration.service';
import { TrackService } from '../../services/track.service';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';
import { TrackingResponse } from '../../models/tracking-result.model';
import { BingMapResult } from '../../models/bing-map-result.model';
import { AppMediatorService } from '../../services/app-mediator.service';

@Component({
    selector: 'por-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingComponent implements OnInit, OnDestroy, AfterViewInit {
    mapReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
    contract$: Observable<unknown> = new Observable();
    subscriptions: Subscription[] = [];
    isPickUpOrder = false;
    pushpins: LATLONG[] = [];
    customerId!: string;
    trackingDetails: Observable<TrackingResponse[]> = this.trackingService.trackingDetails.asObservable();
    noTrackingOrders$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    orderExistsWithNoAddress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    inputs: ConsumerPortalConfig | undefined;
    @Input() config: string | undefined;
    @Input() width: string | undefined;
    @Input() height: string | undefined;

    @Input() mapHeight: string | undefined;
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    loaded$ = this.trackingService.loaded$.asObservable();

    constructor(
        private readonly appFacadeService: AppFacadeService,
        private readonly notification: NotificationService,
        private readonly configService: ConfigurationService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly trackingService: TrackService,
        private readonly translateService: TranslateService,
        private readonly appMediatorService: AppMediatorService,
        private readonly logger: LoggerService
    ) {}
    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerPortalConfig;
            this.logger.logAssert(this.inputs?.customerId, 'customerId is not defined');
            this.customerId = (this.inputs?.customerId as string) || '0';
        }

        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            this.elementLoaded.emit(false);
            return;
        }
        this.subscriptions.push(this.trackingService.getContractTrackingDetails(this.customerId, this.inputs?.bingMapApiUrl, this.inputs?.bingMapApiKey));
        this.generatePushpins();
        this.appFacadeService.setLoading(true);
        this.subscriptions.push(
            combineLatest(this.loaded$, this.trackingDetails).subscribe(data => {
                if (data[0]) {
                    this.checkContracts(data[1]?.length > 0 ? data[1] : []);
                }
            })
        );
        this.subscriptions.push(
            this.mapReady.subscribe(loaded => {
                if (loaded) {
                    this.subscriptions.push(
                        // Wait 2 seconds after pushpins are generated to hide loader
                        timer(2000).subscribe(() => {
                            this.appFacadeService.setLoading(false);
                        })
                    );
                }
            })
        );
        this.elementLoaded.emit(true);
    }

    checkContracts(contracts: TrackingResponse[]) {
        this.orderExistsWithNoAddress$.next(false);
        this.noTrackingOrders$.next(false);

        if (contracts?.length === 0) {
            this.noTrackingOrders$.next(true);
            this.appFacadeService.setLoading(false);
        } else if (contracts?.length > 0) {
            for (let index = 0; index < contracts?.length; index++) {
                if (isEmpty(contracts[index]?.lat) && isEmpty(contracts[index]?.long)) {
                    setTimeout(() => {
                        this.orderExistsWithNoAddress$.next(true);
                    }, 200);
                    break;
                }
            }
        }
    }
    generatePushpins() {
        if (this.customerId) {
            this.subscriptions.push(
                this.trackingService.trackingDetails.subscribe((contracts: TrackingResponse[]) => {
                    let fetchedLatLong: Array<boolean | Observable<object> | null> = [];
                    // creating an array for lat long api calling
                    contracts.map((contract, index) => {
                        if (contract?.latLongResult) {
                            fetchedLatLong[index] = contract?.latLongResult;
                        }
                    });

                    fetchedLatLong = fetchedLatLong.filter(ob => ob);

                    this.subscriptions.push(
                        combineLatest(filter(fetchedLatLong, size))
                            .pipe(
                                tap({
                                    error: () => {
                                        this.appFacadeService.setLoading(false);
                                        this.mapReady.next(false);
                                        this.checkContracts(contracts);
                                    },
                                    next: (res: unknown) => {
                                        const resResponse: BingMapResult[] = res as BingMapResult[];
                                        contracts = contracts.map((contract: TrackingResponse) => {
                                            if (contract?.latLongResult) {
                                                contract.lat = resResponse?.[0]?.resourceSets?.[0]?.resources?.[0]?.point?.coordinates?.[0].toString();
                                                contract.long = resResponse?.[0]?.resourceSets?.[0]?.resources?.[0]?.point?.coordinates?.[1].toString();
                                                resResponse.shift();
                                            }
                                            return contract;
                                        });
                                        contracts = this.trackingService.handleDuplicates(contracts);
                                        this.checkContracts(contracts);

                                        contracts.map(contract => {
                                            this.mapReady.next(true);
                                            if (contract?.lat && contract?.long) {
                                                if (this.pushpins.length <= contracts.length - 1) {
                                                    this.pushpins.push({
                                                        lat: Number(contract?.lat),
                                                        long: Number(contract?.long),
                                                        found: contract?.found ?? false,
                                                        title: contract?.itemName ?? '',
                                                        description: contract?.address ?? ''
                                                    });
                                                }
                                            }
                                        });
                                    }
                                })
                            )
                            .subscribe()
                    );
                })
            );
        }
    }
}
