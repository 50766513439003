export * from './customer-portal-ui.module';
export * from './customer-portal-admin-ui.module';
export * from './models';
export * from './services';
export * as cpTypes from './models/consumer-portal-config';
export { CustomerPortalComponent } from './components/customer-portal/customer-portal.component';
export { CustomerPortalAdminComponent } from './admin/components/admin/admin.component';
export * from './languages';
export * from './constants/default.const';

import * as enUSTranslations from '../assets/en-US.json';
import * as enGBTranslations from '../assets/en-GB.json';
import * as frCATranslations from '../assets/fr-CA.json';

export const ConsumerPortalLanguage = {
    enUSTranslations,
    enGBTranslations,
    frCATranslations
};

import { LicenseManager as LicenseManagerModule } from '@ag-grid-enterprise/core';
// ag-Grid License
const agGridLicenseKey =
    'CompanyName=Point of Rental Software,LicensedApplication=LAUNCHPAD,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=17,LicensedProductionInstancesCount=-1,AssetReference=AG-014125,ExpiryDate=17_March_2022_[v2]_MTY0NzQ3NTIwMDAwMA==73e50cacb64ea89b775984d1c7fa7390';
LicenseManagerModule.setLicenseKey(agGridLicenseKey);
