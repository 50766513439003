import { ConsumerPortalAdminConfig } from '../models/consumer-portal-admin-config';
import { consumerPortalAdminDefaults } from '../../input-config/consumer-portal-admin-config';
import { ConsumerPortalConfig, EnabledFeatures } from '../models/consumer-portal-config';
import { LoggerService } from '../services/logger.service';
import { Inject, Injectable } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { BehaviorSubject, catchError, map, of, switchMap } from 'rxjs';
import { AuthKeys } from '../enums/local-storage-keys.enum';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { OrganizationConfig } from '../models/organization-config.model';
import { ErrorService } from './error.service';
import { ErrorType } from '../models/error.model';
import { AppFacadeService } from './app-facade.service';
import { FeatureToggleService } from './feature-toggle.service';
import { consumerPortalDefaults } from '../../input-config/consumer-portal-config';
import { SiteSettingService } from './site-setting.service';
import { DOCUMENT } from '@angular/common';
import { AppMediatorService } from './app-mediator.service';
@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    config: ConsumerPortalConfig | ConsumerPortalAdminConfig | undefined;

    private readonly adminInputs: BehaviorSubject<ConsumerPortalAdminConfig> = new BehaviorSubject(consumerPortalAdminDefaults);
    adminInputs$ = this.adminInputs.pipe(
        map(inputs => {
            const cachedAuthDetails = this.appMediatorService.localStorageService.getCurrentUser?.Auth;
            const newTokenInitialized = this.appMediatorService.localStorageService.getCurrentUser?.initialInputConfigAccessToken !== inputs.Auth?.AccessToken;

            // AccessToken has to be checked for backward compatibility
            let accessToken;
            if (!cachedAuthDetails?.accessToken && !inputs.Auth?.accessToken) {
                accessToken = cachedAuthDetails?.AccessToken ?? inputs.Auth?.AccessToken;
            } else {
                accessToken = cachedAuthDetails?.accessToken ?? inputs.Auth?.accessToken;
            }

            let refreshToken = cachedAuthDetails?.RefreshToken ?? inputs.Auth?.RefreshToken;
            if (newTokenInitialized) {
                accessToken = inputs.Auth?.AccessToken ?? inputs.Auth?.accessToken;
                refreshToken = inputs.Auth?.RefreshToken;
            }

            const loggedInUserConfig = {
                ...inputs,
                /* eslint-disable @typescript-eslint/naming-convention */
                /**
                 * Note camelCase : DB Model
                 */
                Auth: {
                    accessToken: accessToken,
                    RefreshToken: refreshToken,
                    // Always set InitialLogin to false in the localstorage
                    initialLogin: false,
                    OrganizationType: inputs.organizationType,
                    OrganizationId: inputs.organizationId,
                    Email: inputs.Auth?.Email
                },
                initialInputConfigAccessToken:
                    this.appMediatorService.localStorageService.getCurrentUser?.initialInputConfigAccessToken !== inputs.Auth?.AccessToken
                        ? inputs.Auth?.accessToken ?? inputs.Auth?.AccessToken ?? ''
                        : this.appMediatorService.localStorageService.getCurrentUser?.initialInputConfigAccessToken ?? '',
                newTokenInitialized: this.appMediatorService.localStorageService.getCurrentUser?.initialInputConfigAccessToken !== inputs.Auth?.AccessToken
            };

            this.appMediatorService.localStorageService.persistUser(AuthKeys.User, loggedInUserConfig);
            return loggedInUserConfig;
        }),
        switchMap(inputs => this.getOrgConfig(inputs))
    );

    private readonly orgConfiguration: BehaviorSubject<OrganizationConfig> = new BehaviorSubject<OrganizationConfig>({});
    orgConfig$ = this.orgConfiguration.asObservable();

    /**
     * Local variables moved from Consumer portal component to service
     */
    inputs: ConsumerPortalConfig | undefined; // this variable contents inputs config provided through index.html
    loginConfig!: string;
    faviconEl: HTMLLinkElement | null = this.document.querySelector('#appIcon');
    titleEl: HTMLTitleElement | null = this.document.querySelector('title');

    cpInputs$ = this.appFacadeService.getConfig();

    constructor(
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly appMediatorService: AppMediatorService,
        private readonly logger: LoggerService,
        private readonly errorService: ErrorService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly featureToggleService: FeatureToggleService,
        private readonly siteSetting: SiteSettingService,
        private readonly appFacadeService: AppFacadeService
    ) {}

    getOrgConfig(inputs: ConsumerPortalAdminConfig) {
        return this.consumerPortalApi.getPublicOrganizationConfigurations(inputs.organizationId).pipe(
            switchMap((orgConfig?: OrganizationConfig | null) => {
                let result = inputs;
                if (orgConfig) {
                    result = {
                        ...inputs,
                        styling: {
                            ...inputs?.styling,
                            themeColors: {
                                // top level theme colors
                                primaryColor: orgConfig.PrimaryColor ? orgConfig.PrimaryColor : consumerPortalAdminDefaults.styling?.themeColors?.primaryColor,
                                secondaryColor: orgConfig.SecondaryColor ? orgConfig.SecondaryColor : consumerPortalAdminDefaults.styling?.themeColors?.secondaryColor
                            },
                            // each component theme colors - apply to each component if required
                            header: {
                                ...inputs?.styling?.header,
                                primaryColor: orgConfig.PrimaryColor,
                                secondaryColor: orgConfig.SecondaryColor
                            },
                            footer: {
                                ...inputs?.styling?.footer,
                                primaryColor: orgConfig.PrimaryColor,
                                secondaryColor: orgConfig.SecondaryColor
                            }
                        },
                        header: {
                            ...inputs?.header,
                            logoUrl: orgConfig.LogoUrl ? orgConfig.LogoUrl : consumerPortalAdminDefaults.header?.logoUrl
                        }
                    };
                }

                this.orgConfiguration.next(orgConfig || {});
                return of(result);
            }),
            catchError(error => {
                this.logger.logError(error);
                this.logger.alertDevError(error);
                this.errorService.setError(ErrorType.InvalidSubDomainOrgId);
                return of(inputs);
            }),
            catchError(i => of(i)) // In case of error, return the original inputs
        );
    }

    getCpOrgConfig(inputs: ConsumerPortalConfig) {
        if (inputs.organizationId) {
            return this.consumerPortalApi.getPublicOrganizationConfigurations(inputs.organizationId).pipe(
                switchMap((orgConfig?: OrganizationConfig | null) => {
                    let result = inputs;
                    if (orgConfig) {
                        result = {
                            ...inputs,
                            styling: {
                                ...inputs?.styling,
                                themeColors: {
                                    // top level theme colors
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                accountSummary: {
                                    ...inputs?.styling?.accountSummary,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                contracts: {
                                    ...inputs?.styling?.contracts,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                itemsOut: {
                                    ...inputs?.styling?.itemsOut,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                tracking: {
                                    ...inputs?.styling?.tracking,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                invoices: {
                                    ...inputs?.styling?.invoices,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                login: {
                                    ...inputs?.styling?.login,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                header: {
                                    ...inputs?.styling?.header,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                },
                                footer: {
                                    ...inputs?.styling?.footer,
                                    primaryColor: orgConfig.PrimaryColor,
                                    secondaryColor: orgConfig.SecondaryColor
                                }
                            },
                            header: {
                                ...inputs?.header,
                                logoUrl: orgConfig.LogoUrl ? orgConfig.LogoUrl : consumerPortalDefaults.header?.logoUrl,
                                faviconUrl: orgConfig.FaviconUrl,
                                title: orgConfig.StoreName,
                                depotName: orgConfig?.DepotName,
                                exterConsumerPortalUrl: orgConfig?.ExternalConsumerPortalURL
                            },
                            AdImageURL: {
                                ...inputs?.AdImageURL,
                                Banner1: orgConfig.Banner1,
                                Banner1ExternalUrl: orgConfig.Banner1ExternalUrl,
                                Banner2: orgConfig.Banner2,
                                Banner2ExternalUrl: orgConfig.Banner2ExternalUrl,
                                Banner3: orgConfig.Banner3,
                                Banner3ExternalUrl: orgConfig.Banner3ExternalUrl,
                                Banner4: orgConfig.Banner4,
                                Banner4ExternalUrl: orgConfig.Banner4ExternalUrl
                            },
                            enabledFeatures: {
                                ...inputs?.enabledFeatures,
                                [this.featureToggleService.getType() as keyof EnabledFeatures]: {
                                    ...inputs?.enabledFeatures?.[this.featureToggleService.getType() as keyof EnabledFeatures],
                                    secureUrlLoginRequired: orgConfig.SecureUrlLoginRequired,
                                    callOffAbility: orgConfig?.CallOffAbility,
                                    trackingTab: orgConfig.TrackingTab,
                                    requestService: orgConfig.RequestServiceAbility,
                                    multiplePaymentAbility: orgConfig.MultiplePaymentAbility
                                }
                            },
                            licVersion: orgConfig.version,
                            storeName: orgConfig.StoreName
                        } as unknown as ConsumerPortalConfig;
                    }
                    return of(result);
                }),
                catchError(error => {
                    this.logger.logError(error);
                    this.logger.alertDevError(error);
                    this.errorService.setError(ErrorType.InvalidSubDomainOrgId);
                    return of(inputs);
                }),
                catchError(i => of(i)) // In case of error, return the original inputs
            );
        }
        return of(inputs);
    }

    setAdminConfigurations(adminConfig?: string, adminAppConfig?: ConsumerPortalAdminConfig) {
        if (adminConfig && !isEmpty(adminConfig)) {
            const inputConfig = JSON.parse(adminConfig) as ConsumerPortalAdminConfig;
            const consolidatedInputs: ConsumerPortalAdminConfig = {
                ...consumerPortalAdminDefaults,
                ...adminAppConfig,
                ...inputConfig
            };
            this.config = consolidatedInputs;
            this.consumerPortalApi.setConsumerPortalApiRoute(consolidatedInputs.apiUrl);
            this.adminInputs.next(consolidatedInputs);
            return;
        }

        if (adminAppConfig) {
            this.config = adminAppConfig;
            this.consumerPortalApi.setConsumerPortalApiRoute(adminAppConfig.apiUrl);
        }
    }

    setConsumerPortalConfigurations(cpConfig?: string, cpAppConfig?: ConsumerPortalConfig) {
        this.appFacadeService.setConfig(this.consolidateConsumerPortalConfig(cpConfig, cpAppConfig));
        return;
    }

    consolidateConsumerPortalConfig(cpConfig?: string, cpAppConfig?: ConsumerPortalConfig): ConsumerPortalConfig {
        let consolidatedInputs: ConsumerPortalConfig = {
            ...consumerPortalDefaults,
            ...cpAppConfig
        };
        if (cpConfig && !isEmpty(cpConfig)) {
            const inputConfig = JSON.parse(cpConfig) as ConsumerPortalConfig;
            consolidatedInputs = {
                ...consolidatedInputs,
                ...inputConfig
            };
        }
        /**
         * Set enabled Features
         */
        this.featureToggleService.setFeatures(
            this.featureToggleService.mergeFeatures(consumerPortalDefaults?.enabledFeatures, consolidatedInputs?.enabledFeatures),
            consolidatedInputs?.organizationType
        );
        this.config = consolidatedInputs;
        this.consumerPortalApi.setConsumerPortalApiRoute(consolidatedInputs.apiUrl);
        this.siteSetting.setStylingForSite(consolidatedInputs);
        this.loginConfig = JSON.stringify({
            ...this.inputs,
            apiUrl: consolidatedInputs?.apiUrl,
            styling: consolidatedInputs?.styling,
            logoUrl: consolidatedInputs?.header?.logoUrl,
            faviconUrl: consolidatedInputs?.header?.faviconUrl,
            storeName: consolidatedInputs?.header?.title,
            depotName: consolidatedInputs?.header?.depotName,
            organizationId: !isEmpty(consolidatedInputs.organizationId) ? consolidatedInputs.organizationId : this.inputs?.organizationId,
            license: consolidatedInputs?.licVersion,
            loginApiUrl: consolidatedInputs?.loginApiUrl
        });
        // Change the favicon icon dynamically
        if (this.faviconEl && consolidatedInputs?.header?.faviconUrl) {
            this.faviconEl.href = consolidatedInputs?.header?.faviconUrl;
        }
        // Change the title dynamically
        if (this.titleEl) {
            this.titleEl.text = consolidatedInputs?.header?.title ?? '';
        }

        return consolidatedInputs;
    }

    saveOrganizationConfiguration(organizationConfig: OrganizationConfig) {
        const orgId = this.adminInputs.value.organizationId;
        return this.consumerPortalApi.patchOrganizationConfiguration(orgId, organizationConfig).pipe(
            switchMap(c => {
                this.orgConfiguration.next((c as OrganizationConfig) || {});
                return of(c);
            }),
            catchError(error => {
                this.logger.logError(error);
                this.logger.alertDevError(error);
                this.errorService.setError(ErrorType.InvalidSubDomainOrgId);
                return of(this.orgConfiguration.value);
            })
        );
    }

    setMatIconFontFamily() {
        let head = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');

        const cssId = 'mat-icon'; // you could encode the css path itself to generate id..
        if (!document.getElementById(cssId)) {
            head = document.getElementsByTagName('head')[0];
            link = document.createElement('link');
            link.id = cssId;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
            link.media = 'all';
            head.appendChild(link);
        }

        const roboto = 'roboto-font'; // you could encode the css path itself to generate id..
        if (!document.getElementById(roboto)) {
            head = document.getElementsByTagName('head')[0];
            link = document.createElement('link');
            link.id = roboto;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = 'https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap';
            link.media = 'all';
            head.appendChild(link);
        }

        const monstrret = 'monstrret'; // you could encode the css path itself to generate id..
        if (!document.getElementById(roboto)) {
            head = document.getElementsByTagName('head')[0];
            link = document.createElement('link');
            link.id = monstrret;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap';
            link.media = 'all';
            head.appendChild(link);
        }
    }

    setInputsConfig(inputs: ConsumerPortalConfig | undefined) {
        this.inputs = inputs;
    }
}
