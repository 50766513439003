import { Action } from '@ngrx/store';
import { ComponentsToLoad, SubComponentsToLoad } from '../../../enums/components-to-load.enum';
import { ConsumerPortalState } from '../../../types/state.type';
import { Redirection } from '../../../types/redirection.type';

export interface AppState {
    isLoading: boolean;
    isAuthenticated: boolean;
    activeTab: {
        parentTab: ComponentsToLoad;
        childTab: SubComponentsToLoad;
    };
    isAdminLoading: boolean;
    consumerPortalState: ConsumerPortalState;
    isRedirected: boolean;
    versionRedirection: Redirection | null;
    isMobile: boolean;
    errorMessage?: string;
}

export const initialState: AppState = {
    isLoading: false,
    isAuthenticated: false,
    activeTab: {
        parentTab: ComponentsToLoad.AccountSummary,
        childTab: SubComponentsToLoad.Default
    },
    isAdminLoading: false,
    versionRedirection: null,
    consumerPortalState: 'None',
    isRedirected: false,
    isMobile: false,
    errorMessage: ''
};

export type ReducerType = (state: AppState | undefined, action: Action) => AppState;
