import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppFacadeService } from '../../services/app-facade.service';

@Component({
    selector: 'por-contract-instructions',
    templateUrl: './contract-instructions.component.html',
    styleUrls: ['./contract-instructions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractInstructionsComponent {
    overlayStates = {
        payMultipleContract: false,
        paySingleContract: false,
        viewContractDetail: false
    };

    constructor(readonly appFacadeService: AppFacadeService) {}

    toggleOverlay(overlay: keyof typeof this.overlayStates) {
        this.overlayStates[overlay] = !this.overlayStates[overlay];
    }
}
