import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { AppFacadeService } from '../../services/app-facade.service';
import { Subscription } from 'rxjs';
import { AdBanner } from '../../models/ad-banner';
@Component({
    selector: 'por-ad-images',
    templateUrl: './ad-images.component.html',
    styleUrls: ['./ad-images.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdImagesComponent implements OnInit, OnDestroy {
    @Input() customerId: string | undefined;
    @Input() loading: boolean | undefined;
    @Input() layout: string | undefined;

    inputs: ConsumerPortalConfig | undefined | null;
    banners: AdBanner[] = [];
    subscriptions: Subscription[] = [];

    constructor(private readonly appFacadeService: AppFacadeService, @Inject('Window') private readonly window: Window) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.appFacadeService.getConfig().subscribe((config: ConsumerPortalConfig) => {
                this.inputs = config;
                this.banners.push({
                    banner: this.inputs?.AdImageURL?.Banner1,
                    externalUrl: this.inputs?.AdImageURL?.Banner1ExternalUrl,
                    source: 'Banner1',
                    order: this.layout === 'columns2' ? 1 : 1
                });
                this.banners.push({
                    banner: this.inputs?.AdImageURL?.Banner2,
                    externalUrl: this.inputs?.AdImageURL?.Banner2ExternalUrl,
                    source: 'Banner2',
                    order: this.layout === 'columns2' ? 3 : 2
                });
                this.banners.push({
                    banner: this.inputs?.AdImageURL?.Banner3,
                    externalUrl: this.inputs?.AdImageURL?.Banner3ExternalUrl,
                    source: 'Banner3',
                    order: this.layout === 'columns2' ? 2 : 3
                });
                this.banners.push({
                    banner: this.inputs?.AdImageURL?.Banner4,
                    externalUrl: this.inputs?.AdImageURL?.Banner4ExternalUrl,
                    source: 'Banner4',
                    order: this.layout === 'columns2' ? 4 : 4
                });
                this.reorderBanners();
            })
        );
    }

    reorderBanners(): void {
        this.banners = this.banners.sort((a, b) => {
            return a.order - b.order;
        });
    }

    openExternalUrl(url: string | undefined, sourceType: string): void {
        if (!url) {
            return;
        }
        // Note camelCase : DB Model
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.appFacadeService.saveEventLogs({ CustomerId: this.customerId ?? '', SourceType: sourceType });
        this.window.open(url, '_blank');
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }
}
