import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { clearInvoicesinStore, fetchInvoices } from '../store/domains/invoices/invoices.actions';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { FilterOutput } from '../shared/models/filters.model';
import isEmpty from 'lodash-es/isEmpty';
import { InvoiceTotalAmountDue } from '../models/invoice-model';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {
    constructor(private readonly store: Store, private readonly consumerPortalApi: ConsumerPortalApiService) {}

    getInvoices(customerId: number | string, startDate?: string, endDate?: string, search?: string) {
        this.store.dispatch(fetchInvoices({ customerId, startDate, endDate, search }));
    }

    filter($e: FilterOutput, customerId: string) {
        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? $e.dates.startDate : undefined;
        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? $e.dates.endDate : undefined;
        this.store.dispatch(clearInvoicesinStore());
        this.getInvoices(customerId, startDate, endDate, $e.search);
    }

    getInvoiceTotalAmountDue(customerId: number | string): Observable<InvoiceTotalAmountDue[]> {
        const url = `customer/${customerId}/invoices/total-due`;
        return this.consumerPortalApi.get(url);
    }
}
