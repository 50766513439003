import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { DeviceType } from '../../../enums/device-type.enum';
import { DialogContentType } from '../../../enums/dialog-type.enum';
import { PaymentSourceEnums, PaymentTransactionType } from '../../../enums/payment-method.enum';
import { CreateTransactionResponse } from '../../../payment/models/create-transaction-response-model';
import { PaymentData, PaymentMetaData, PaymentMinMaxBoundary } from '../../../payment/models/payment-data.model';
import { PaymentAppPayOutput, VerifyTransaction } from '../../../payment/models/paymentOutput';
import { Processors } from '../../../payment/models/processors.interface';
import { PorTransactionService } from '../../../por-transaction.service';
import { LoggerService, NotificationService } from '../../../services';
import { AppFacadeService } from '../../../services/app-facade.service';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { ContractService } from '../../../services/contract.service';
import { selectIsMobile } from '../../app-wide/app/app.selectors';
import { AppState } from '../../app-wide/app/app.state';
import { ConfigActionTypes } from '../../app-wide/config/config.actions';
import { selectConfig, selectCustomerId, selectRouteParams } from '../../app-wide/config/config.selectors';
import { showDialog } from '../../app-wide/dialog/dialog.actions';
import { getContractsTotal, getSelectedContracts, getSelectedContractsModels } from '../contract/contract.selectors';
import { getCustomer } from '../customer/customer.selectors';
import { closePayment, ContractActionTypes, setSelectedContracts } from './../contract/contract.actions';
import {
    createTransaction,
    crossAppEventEmitted,
    failCreateTransaction,
    failLoadPaymentBoundaries,
    failVerifyPayment,
    loadPaymentProcessorAvailability,
    loadPaymentProcessorAvailabilityFailure,
    loadPaymentProcessorAvailabilitySuccess,
    PaymentActionTypes,
    sendPaymentToRMS,
    sendPaymentToRMSFailure,
    sendPaymentToRMSSuccess,
    setConfigData,
    setDialogEnabled,
    setMultiPaymentBoundaries,
    setPaymentAppInput,
    setPaymentSectionEnabled,
    setTransactionLoaded,
    successCreateTransaction,
    successVerifyPayment
} from './../payment/payment.actions';
import { selectMultipaymentBoundaries, selectPaymentAppOutput } from './payment.selectors';

@Injectable()
export class PaymentEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly appFacadeService: AppFacadeService,
        private readonly appMediatorService: AppMediatorService,
        private readonly translateService: TranslateService,
        private readonly porTransactionService: PorTransactionService,
        private readonly notification: NotificationService,
        private readonly logger: LoggerService,
        private readonly contractService: ContractService,
        private readonly store: Store<AppState>
    ) {}
    syncFailedPaymentWithRMS$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendPaymentToRMS),
            withLatestFrom(this.store.select(selectConfig)),
            switchMap(([action, config]) =>
                this.appMediatorService.porPayService.syncPaymentWithRMS(config.organizationId ?? '').pipe(
                    map(() => {
                        return sendPaymentToRMSSuccess();
                    }),
                    catchError(() => of(sendPaymentToRMSFailure()))
                )
            )
        )
    );

    loadPaymentBoundaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContractActionTypes.MultiPaymentContinue),
            tap(() => {
                this.appFacadeService.setLoading(true);
            }),
            withLatestFrom(this.store.select(getSelectedContracts)),
            withLatestFrom(this.store.select(getContractsTotal)),
            withLatestFrom(this.store.select(selectCustomerId)),
            switchMap(([[[action, selectedContracts], total], customerId]) =>
                this.appMediatorService.porPayService.getPaymentBoundaryMultiContract(customerId, selectedContracts, total).pipe(
                    map((paymentBoundaries: PaymentMinMaxBoundary[]) => {
                        this.appFacadeService.setLoading(false);
                        return setMultiPaymentBoundaries({ paymentBoundaries });
                    }),
                    catchError(error => {
                        this.appFacadeService.setLoading(false);
                        return of(failLoadPaymentBoundaries({ error: error.message }));
                    })
                )
            )
        )
    );

    onSetMultiboundaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.SetMultiPaymentBoundaries),
            withLatestFrom(this.store.select(selectMultipaymentBoundaries)),
            switchMap(([action, paymentBoundaries]) => {
                if (paymentBoundaries) {
                    return of(createTransaction({ amount: null, transactionType: PaymentTransactionType.Multiple }));
                }
                return of();
            })
        )
    );

    resetPaymentBoundaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContractActionTypes.PaymentWindowClosed),
            map(() => setMultiPaymentBoundaries({ paymentBoundaries: null }))
        )
    );

    setConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigActionTypes.SetConfig),
            map(action => setConfigData({ config: action }))
        )
    );

    createTransaction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.CreateTransaction),
            tap(() => {
                this.appFacadeService.setLoading(true);
            }),
            withLatestFrom(
                this.store.select(getSelectedContracts),
                this.store.select(getSelectedContractsModels),
                this.store.select(getContractsTotal),
                this.store.select(selectConfig),
                this.appFacadeService.getCurrency(),
                this.store.select(selectCustomerId),
                this.store.select(selectIsMobile)
            ),
            switchMap(([action, selectedContracts, selectedContractsModels, total, config, currencyCode, customerId, isMobile]) => {
                const { amount, transactionType } = action;
                const toPay = amount ? amount : total ? total.toString() : '0';
                const amountToPay: string = this.appMediatorService.porPayService.formatCurrencyForPorPay(toPay);
                setTransactionLoaded({ transactionLoaded: false });
                const metadata: PaymentMetaData = {
                    source: this.getPaymentSource(config),
                    actionType: transactionType,
                    averageContractValue: parseFloat(toPay) / selectedContracts.length, // Will safely handle toPay = 0
                    deviceType: isMobile ? DeviceType.Mobile : DeviceType.Desktop,
                    paymentAllocation: selectedContracts.length
                };

                return this.appMediatorService.porPayService
                    .createPayment(
                        customerId,
                        currencyCode,
                        Number(amountToPay),
                        config.organizationId ?? '',
                        selectedContractsModels[0]?.depotId ?? '',
                        selectedContracts,
                        Number(amountToPay),
                        metadata
                    )
                    .pipe(
                        switchMap((res: unknown) => {
                            this.appFacadeService.setLoading(false);
                            return of(successCreateTransaction({ res }));
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return [
                                setTransactionLoaded({ transactionLoaded: true }),
                                failCreateTransaction({ error: [this.translateService.instant('CreateTransactionFailed'), error?.message, error?.error?.token, error?.error?.message].join(' / ') }),
                                setDialogEnabled({ dialogEnabled: true }),
                                crossAppEventEmitted({
                                    event: {
                                        error: {
                                            code: error?.status,
                                            message: error?.error?.message ?? error?.message
                                        }
                                    }
                                })
                            ];
                        })
                    );
            })
        )
    );

    successTransaction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.SuccessCreateTransaction),
            switchMap((action: { type: string; res: unknown }) => {
                const response: CreateTransactionResponse = action.res as CreateTransactionResponse;
                if (response.token && response.token === 'NO_PAYMENT_METHOD_AVAILABLE') {
                    return [
                        setTransactionLoaded({ transactionLoaded: true }),
                        failCreateTransaction({ error: this.translateService.instant('CreateTransactionFailed') + this.translateService.instant('NoPaymentMethodAvailable') }),
                        setDialogEnabled({ dialogEnabled: true })
                    ];
                }

                return this.appMediatorService.porPayService.getPaymentConfiguration(response?.apiKey, response?.transactionId).pipe(
                    switchMap(configuration => {
                        return [setPaymentAppInput({ paymentAppInput: JSON.stringify(configuration) }), setTransactionLoaded({ transactionLoaded: true })];
                    })
                );
            })
        )
    );

    paymentFinished$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActionTypes.PaymentFinished),
            withLatestFrom(this.store.select(getSelectedContractsModels)),
            withLatestFrom(this.store.select(getCustomer)),
            withLatestFrom(this.store.select(selectConfig)),
            withLatestFrom(this.store.select(selectPaymentAppOutput)),
            withLatestFrom(this.store.select(selectCustomerId)),
            withLatestFrom(this.store.select(selectRouteParams)),
            switchMap(([[[[[[action, selectedContractsModels], customer], config], paymentAppOutput], customerId], routeParams]) => {
                this.logger.logInfo(paymentAppOutput?.detail);
                const detail: PaymentAppPayOutput = paymentAppOutput?.detail as PaymentAppPayOutput;

                if (detail?.ResponseCode === 200) {
                    this.notification.success(detail?.Message);
                    this.notification.success(this.translateService.instant('Please wait till we verify the received payment') + '.');

                    this.appFacadeService.setLoading(true);
                    const paymentSource = this.getPaymentSource(config);
                    const paymentData: PaymentData = this.appMediatorService.porPayService.getPaymentData(customer, config.organizationId ?? '', detail, selectedContractsModels, paymentSource);

                    return this.appMediatorService.porPayService.verifyPayment(customerId, paymentData).pipe(
                        switchMap((res: VerifyTransaction) => {
                            this.logger.logWarning(res);
                            this.appFacadeService.setLoading(false);
                            this.porTransactionService.setTransactionSuccess(true);
                            if (routeParams === undefined) {
                                this.contractService.loadContracts({ customerId });
                            }
                            return [
                                successVerifyPayment({ lastPaymentDetail: res.data ?? detail }),
                                closePayment(),
                                setPaymentSectionEnabled({ enabled: false }),
                                setSelectedContracts({ selectedContracts: [] }),
                                showDialog({ dialogContentType: DialogContentType.PaymentSuccess }),
                                crossAppEventEmitted({
                                    event: {
                                        response: detail
                                    }
                                })
                            ];
                        }),
                        catchError(error => {
                            this.logger.logError(error, '', true);
                            this.appFacadeService.setLoading(false);
                            return [
                                failVerifyPayment({ error: 'Failed to verify payment' }),
                                setDialogEnabled({ dialogEnabled: true }),
                                crossAppEventEmitted({
                                    event: {
                                        error: {
                                            code: error?.status,
                                            message: 'Failed to verify payment'
                                        }
                                    }
                                })
                            ];
                        })
                    );
                } else if (detail?.ResponseCode === 400 && detail?.Error === 'Validation failed') {
                    this.logger.logError(paymentAppOutput?.detail, '', true);
                    return [
                        setPaymentAppInput({ paymentAppInput: '' }),
                        failCreateTransaction({ error: this.translateService.instant('PaymentWebComponentFailed') + paymentAppOutput?.detail?.Message }),
                        setDialogEnabled({ dialogEnabled: true }),
                        crossAppEventEmitted({
                            event: {
                                error: {
                                    code: detail?.ResponseCode,
                                    message: detail?.Error
                                }
                            }
                        })
                    ];
                } else {
                    this.logger.logError(paymentAppOutput?.detail, '', true);
                    return [
                        setPaymentAppInput({ paymentAppInput: '' }),
                        failCreateTransaction({ error: this.translateService.instant('PaymentWebComponentFailed') + (paymentAppOutput?.detail?.Message ? paymentAppOutput?.detail?.Message : '') }),
                        setDialogEnabled({ dialogEnabled: true }),
                        crossAppEventEmitted({
                            event: {
                                response: detail,
                                error: {
                                    code: detail?.ResponseCode,
                                    message: detail?.Message
                                }
                            }
                        })
                    ];
                }
            })
        )
    );

    loadPaymentProcessorAvailability$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPaymentProcessorAvailability),
            switchMap(({ customerId }) =>
                this.appMediatorService.porPayService.isPaymentProcessorAvailable(customerId).pipe(
                    map((processors: boolean | Processors[]) => loadPaymentProcessorAvailabilitySuccess({ processors })),
                    catchError(() => of(loadPaymentProcessorAvailabilityFailure()))
                )
            )
        )
    );

    private getPaymentSource(config: { sourceApp?: string }): PaymentSourceEnums {
        let paymentSource = PaymentSourceEnums.ConsumerPortal;

        if (config.sourceApp?.length) {
            paymentSource = config.sourceApp as PaymentSourceEnums;
        } else if (this.appFacadeService.versionToggleService.isQuickLinkVersion()) {
            paymentSource = PaymentSourceEnums.QuickLink;
        }

        return paymentSource;
    }
}
