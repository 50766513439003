import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoicesState, invoicesFeatureKey } from './invoices.reducers';

export const invoiceSelector = createFeatureSelector<InvoicesState>(invoicesFeatureKey);

export const getPageNumberInvoices = createSelector(invoiceSelector, (state: InvoicesState) => state.pageNumber);

export const getInvoices = createSelector(invoiceSelector, (state: InvoicesState) => state.invoices);

export const getNoMoreRecordInvoices = createSelector(invoiceSelector, (state: InvoicesState) => state.noMoreRecord);

export const selectInvoiceDetail = createSelector(invoiceSelector, (state: InvoicesState) => {
    if (state.invoiceDetail) {
        return state.invoiceDetail;
    }
    return null;
});
