import { Pipe, PipeTransform } from '@angular/core';
import { ItemOutCP } from '../models/item-out-model';
import { LowerCasePipe } from '@angular/common';

@Pipe({
    name: 'filterLineItemStatus'
})
export class FilterLineItemStatusPipe implements PipeTransform {
    constructor(private readonly lowecase: LowerCasePipe) {}
    transform(lineItems: ItemOutCP[] | null, lineItemStatus: string[] | string | undefined) {
        if (!lineItemStatus || lineItems?.length === 0) {
            return lineItems;
        }

        if (lineItemStatus instanceof Array) {
            const lowecaseLineItemStatus = lineItemStatus.map(item => item.toLowerCase());
            return lineItems?.filter((item: ItemOutCP) => {
                return lowecaseLineItemStatus.includes(this.lowecase.transform(item.lineItemStatus));
            });
        }

        return lineItems?.filter((item: ItemOutCP) => {
            return this.lowecase.transform(item.lineItemStatus) === this.lowecase.transform(lineItemStatus.toString());
        });
    }
}
