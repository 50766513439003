import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationConfig } from '../../../../models/organization-config.model';

@Component({
    selector: 'por-admin-webpay',
    templateUrl: './admin-webpay.component.html',
    styleUrls: ['./admin-webpay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminWebpayComponent {
    @Input() config!: OrganizationConfig;
}
