import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig } from '../models/consumer-login-config';
import { User } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@por/shared/core';
import { encrypt } from '@por/consumer-login/shared-lib';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'por-signin',
    templateUrl: './signin-component.component.html',
    styleUrls: ['./signin-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponentComponent implements OnInit {
    @Output()
    readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    readonly loginData: EventEmitter<User> = new EventEmitter<User>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;
    @Input() isDisableButton = false;
    @Input() quickLinkUrl = '';
    loginForm!: FormGroup;

    constructor(private readonly fb: FormBuilder, private readonly translateService: TranslateService, @Inject(DOCUMENT) private readonly document: Document) {}

    async ngOnInit(): Promise<void> {
        this.translateService.use(Language.EN_GB);
        this.initializeForm();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
        }
        this.loginForm.setValue({
            username: '',
            password: ''
        });
    }
    initializeForm() {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }
    get username(): FormControl {
        return this.loginForm.get('username') as FormControl;
    }

    get password(): FormControl {
        return this.loginForm.get('password') as FormControl;
    }

    login(): boolean {
        const email: string = this.loginForm.value.username as string;
        const password: string = this.loginForm.value.password as string;

        if (!this.loginForm.valid) {
            return false;
        } else {
            /* eslint-disable @typescript-eslint/naming-convention */
            /**
             * Note camelCase: DB model
             */
            this.loginData.emit({
                Email: email,
                Password: encrypt(password)
            });

            return true;
        }
    }

    loginCancel(): boolean {
        this.loginForm.patchValue({
            password: ''
        });

        return true;
    }

    setTemplate(templateName: string): void {
        this.selectedTemplate.emit(templateName);
    }

    onBackQL(): void {
        this.document.defaultView?.open(this.quickLinkUrl, '_self');
    }
}
