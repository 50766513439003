<ng-container *ngIf="appFacadeService.isDialogDisplayed() | async" class="payment-success-dialog-container">
    <ng-container *ngIf="(appFacadeService.getDialogContentType() | async) === dialogContentType.PaymentSuccess">
        <por-app-confirm-dialog (confirmed)="appFacadeService.toggleDialog(false)" [message]="dialogMessage">
            <p>
                <ng-container [ngSwitch]="(appFacadeService.getLastPaymentDetails() | async)?.TransactionData?.TransactionResultEnum">
                    <ng-container *ngSwitchCase="transactionResultEnum.PENDING">
                        <span class="title">{{ 'PaymentPending' | translate }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="transactionResultEnum.DECLINED">
                        <span class="title">{{ 'PaymentDeclined' | translate }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="transactionResultEnum.CANCELLED">
                        <span class="title">{{ 'PaymentCancelled' | translate }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="transactionResultEnum.ERROR">
                        <span class="title">{{ 'PaymentErrorHeading' | translate }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="transactionResultEnum.APPROVEDWITHWARNING">
                        <span class="title">{{ 'PaymentSuccessWithWarning' | translate }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="transactionResultEnum.APPROVED">
                        <span class="title">{{ 'PaymentReceived' | translate }}</span>
                    </ng-container>
                </ng-container>
            </p>
            <p class="thanks-msg">{{ 'ThankYouForYourPayment' | translate }}</p>
            <hr />
            <p>
                <span>{{ 'PaymentAmount' | translate }}:</span>
                <span>
                    &nbsp;
                    {{
                        ((appFacadeService.getLastPaymentDetails() | async)?.TransactionData?.Amount_Processed ?? 0) / 100
                            | formatCurrency : (appFacadeService.getLastPaymentDetails() | async)?.TransactionData?.CurrencyCode ?? appFacadeService.getDefaultCurrency()
                    }}
                </span>
            </p>
            <p>
                <span>{{ 'DateTime' | translate }}:</span>
                <span>&nbsp;{{ (appFacadeService.getLastPaymentDetails() | async)?.TransactionData?.Created ?? '' | formatDate : dateFormat }}</span>
            </p>
            <p>
                <span>{{ 'PaymentType' | translate }}:</span>
                <span>&nbsp;{{ appMediatorService.porPayService.getPaymentMethod((appFacadeService.getLastPaymentDetails() | async)?.TransactionData?.PaymentMethodEnum) }}</span>
            </p>
            <p>
                <span>{{ 'Reference#' | translate }}:</span>
                <span>&nbsp;{{ (appFacadeService.getLastPaymentDetails() | async)?.TransactionId }}</span>
            </p>
            <p>
                <span>{{ 'Description' | translate }}:</span>
                <span>&nbsp;{{ (appFacadeService.getLastPaymentDetails() | async)?.TransactionStatus?.Description }}</span>
            </p>
        </por-app-confirm-dialog>
    </ng-container>
</ng-container>
