import { Component } from '@angular/core';
import { AppFacadeService } from '../../../services/app-facade.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ContractCP } from '../../../models/contract-model';

@Component({
    selector: 'por-contract-selection-checkbox-renderer',
    templateUrl: './contract-selection-checkbox-renderer.html'
})
export class ContractSelectionCheckboxRendererComponent implements ICellRendererAngularComp {
    constructor(readonly appFacadeService: AppFacadeService) {}

    public params!: ICellRendererParams;
    public contract!: ContractCP;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.contract = { ...params.data };
    }

    onCheckboxChange(isChecked: boolean): void {
        if (this.params.context && this.params.context.componentParent) {
            this.params.context.componentParent.onSelectionChanged(isChecked, this.contract.contractId);
        }
    }

    refresh(): boolean {
        return false;
    }
}
