import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/state/app.state';
import * as ConfigActions from '../store/actions/config.actions';
import * as ConfigSelectors from '../store/selector/config.selectors';
import { ConsumerPortalConfig } from '../models';
import { combineLatest, map, Observable, take } from 'rxjs';
import { setLoading } from '../store/actions/loder.actions';
import { selectIsLoading } from '../store/selector/loader.selector';
import { PendoService } from '../pendo/services/pendo.service';
import { DocumentData } from '../store/reducers/documents.reducer';
import { selectAreDocumentsExist, selectAreDocumentsExistAfterLoading, selectDocumentDataByType, selectIsDocumentExists, selectIsDocumentLoading } from '../store/selector/document.selectors';
import { PdfService } from './pdf.service';
import { emptyDocumentData, loadDocument, cancelLoadDocuments, loadLinkAndOpen, loadEsignAndOpen } from '../store/actions/document.actions';
import { VersionToggleService } from './version-toggle';
import { ContractCP, GetDocumentParam } from '../models/contract-model';
import { EventLogsRequest } from '../models/event-logs.interface';
import { saveEventLogsData } from '../store/actions/event-logs.action';
import { FeatureToggleService } from './feature-toggle.service';
import { ComponentsToLoad } from '../enums/components-to-load.enum';
import { setActiveTab, setPaymentSectionEnabled } from '../store/actions/active-tab.actions';
import { selectActiveTab, selectPaymentSectionEnabled } from '../store/selector/active-tab.selectors';
import { selectContractCurrency, selectCustomerCurrency } from '../store/selector/currency-code.selectors';
import { setAuthenticated, setRedirected } from '../store/actions/auth.actions';
import { selectIsAuthenticated, selectIsRedirected } from '../store/selector/auth.selector';
import { selectLoaded } from '../store/selector/loaded.selectors';
import { setAdminLoading } from '../store/actions/loaded.actions';
import { setSelectedContracts, openContract, unsetSelectedContracts, continueMultipayment, closePayment, setPaymentOpen } from '../store/actions/contract.actions';
import {
    getContractsTotal,
    getCreditContractsTotal,
    getPaymentOpen,
    getSelectedContracts,
    getSelectedContractsModels,
    getSelectedContractsTotal,
    getSelectedCreditContracts,
    getSelectedNonCreditContracts,
    isContractSelectable,
    isContractSelected,
    selectTotalPaidByContractId
} from '../store/selector/contracts.selectors';
import { selectIsPaymentProcessorAvailable } from '../store/selector/processors.selectors';
import { loadPaymentProcessorAvailability } from '../store/actions/processors.actions';
import { Processors } from '../payment/models/processors.interface';
import { Redirection } from '../types/redirection.type';
import { loadVersionRedirection, loadVersionRedirectionFailure, setVersionRedirection } from '../store/actions/versionredirection.actions';
import { selectVersionRedirection } from '../store/selector/version.selector';
import { ConsumerPortalState } from '../types/state.type';
import { selectConsumerPortalState } from '../store/selector/view-state.selectors';
import { selectIsConfigSet, selectRedirection, selectRedirectionSessionData } from '../store/selector/redirection.selectors';
import { LoginSession } from '../models/login-session.model';
import { Customer } from '../models/consumer';
import { getCustomer } from '../store/selector/customer.selectors';
import { startRedirection } from '../store/actions/redirection.action';
import { loadCustomer } from '../store/actions/customer-summary.actions';
import { PaymentMinMaxBoundary } from '../payment/models/payment-data.model';
import { selectDialogEnabled, selectErrorString, selectLastPaymentDetail, selectMultipaymentBoundaries, selectPaymentAppInput, selectTransactionLoaded } from '../store/selector/payment.selectors';
import { onPaymentFinished, setDialogEnabled, setTransactionLoaded, successVerifyPayment } from '../store/actions/payment.actions';
import { PaymentAppPayOutput, PayOutputEvent } from '../payment/models/paymentOutput';
import { selectDialogContentType, selectIsDialogDisplayed } from '../store/selector/dialog.selectors';
import { setDialogDisplayed, showDialog } from '../store/actions/dialog.actions';
import { DialogContentType } from '../enums/dialog-type.enum';
/**
 * This service is used to manage Consumer Portal Config to store
 */
@Injectable({
    providedIn: 'root'
})
export class AppFacadeService {
    constructor(
        private readonly store: Store<AppState>,
        public readonly pendo: PendoService,
        public readonly pdf: PdfService,
        public readonly versionToggleService: VersionToggleService,
        public readonly featureToggleService: FeatureToggleService
    ) {}

    setConfig(config: ConsumerPortalConfig): void {
        this.store.dispatch(ConfigActions.setConfig({ config }));
    }

    getConfig(): Observable<ConsumerPortalConfig> {
        return this.store.pipe(select(ConfigSelectors.selectConfig));
    }

    setLoading(isLoading: boolean): void {
        this.store.dispatch(setLoading(isLoading));
    }

    getLoader(): Observable<boolean> {
        return this.store.pipe(select(selectIsLoading));
    }

    getPdfdocument(docType: string): Observable<DocumentData[]> {
        return this.store.pipe(select(selectDocumentDataByType(docType)));
    }

    isDocumentExists(docType: string): Observable<boolean> {
        return this.store.pipe(select(selectIsDocumentExists(docType)));
    }

    isDocumentLoading(docType: string): Observable<boolean> {
        return this.store.pipe(select(selectIsDocumentLoading(docType)));
    }

    checkIfDocumentsExistAfterLoading(): Observable<boolean> {
        return this.store.pipe(select(selectAreDocumentsExistAfterLoading));
    }

    checkIfDocumentsExist(): Observable<boolean> {
        return this.store.pipe(select(selectAreDocumentsExist));
    }

    emptyDocument() {
        this.store.dispatch(emptyDocumentData());
    }

    loadDocuments(contractdetails: GetDocumentParam) {
        this.store.dispatch(loadDocument({ contractdetails }));
    }

    openPdfdocument(docType: string): Observable<DocumentData[]> {
        return this.store.pipe(take(1), select(selectDocumentDataByType(docType)));
    }

    cancelLoadDocuments() {
        this.store.dispatch(cancelLoadDocuments());
    }

    /**
     * Dispatch action to save event logs
     * @param data
     */
    saveEventLogs(data: EventLogsRequest) {
        this.store.dispatch(saveEventLogsData({ payload: data }));
    }

    /**
     * Set active tab to state
     * @param activeTab
     */
    setActiveTab(activeTab: ComponentsToLoad): void {
        if (activeTab === ComponentsToLoad.Logout) {
            this.updateLocation(activeTab);
            return;
        }
        this.store.dispatch(setActiveTab({ activeTab }));
    }

    /**
     * Get the active tab
     * @returns Observable<ComponentsToLoad>
     */
    getActiveTab(): Observable<ComponentsToLoad> {
        return this.store.pipe(select(selectActiveTab));
    }

    /**
     * Update location history on address bar
     * @param tab
     * @returns
     */
    updateLocation(tab: ComponentsToLoad | string): void {
        const currentState = history.state || window.location.href;
        if (this.versionToggleService.isConsumerQuickLinkVersion()) {
            return;
        }
        if (tab === ComponentsToLoad.Logout) {
            /**
             * Set to default view when logout performed
             */
            this.setActiveTab(ComponentsToLoad.AccountSummary);
            history.replaceState(currentState, '', `#`);
            return;
        }
        history.replaceState(currentState, '', `#${tab}`);
    }

    getCurrency(): Observable<string | null> {
        return combineLatest([this.store.pipe(select(selectContractCurrency)), this.store.pipe(select(selectCustomerCurrency))]).pipe(
            map(([contractCurrency, customerCurrency]) => contractCurrency || customerCurrency)
        );
    }

    setAuthenticated(isAuthenticated: boolean): void {
        this.store.dispatch(setAuthenticated(isAuthenticated));
    }

    getAuthenticated(): Observable<boolean> {
        return this.store.pipe(select(selectIsAuthenticated));
    }

    /**
     * Set payment section enabled to state
     * @param setPaymentSectionEnabled
     */
    setPaymentSectionEnabled(action: boolean): void {
        return this.store.dispatch(setPaymentSectionEnabled({ enabled: action }));
    }

    /**
     * Get payment section enabled or not
     * @returns Observable<boolean>
     */
    isPaymentSectionEnabled(): Observable<boolean> {
        return this.store.pipe(select(selectPaymentSectionEnabled));
    }

    setAdminLoaded(isLoaded: boolean): void {
        this.store.dispatch(setAdminLoading(isLoaded));
    }

    getAdminLoaded(): Observable<boolean> {
        return this.store.pipe(select(selectLoaded));
    }

    setSelectedContracts(selectedRows: string[]): void {
        this.store.dispatch(setSelectedContracts({ selectedContracts: selectedRows }));
    }

    unsetSelectedContracts(contractIds: string[]): void {
        this.store.dispatch(unsetSelectedContracts({ unselectContracts: contractIds }));
    }

    isContractAvailableInState(contractId: string): Observable<boolean> {
        return this.store.pipe(select(isContractSelected(contractId)));
    }

    getContractsTotal(): Observable<number> {
        return this.store.pipe(select(getContractsTotal));
    }

    getSelectedCreditContractsTotal(): Observable<number> {
        return this.store.pipe(select(getCreditContractsTotal));
    }

    getSelectedContractsTotal(): Observable<number> {
        return this.store.pipe(select(getSelectedContractsTotal));
    }

    getSelectedCreditContracts(): Observable<ContractCP[]> {
        return this.store.pipe(select(getSelectedCreditContracts));
    }

    getSelectedNonCreditContracts(): Observable<ContractCP[]> {
        return this.store.pipe(select(getSelectedNonCreditContracts));
    }

    /**
     * Dispatch action to get payment processors
     * @param customerId
     */
    getPaymentProcessors(customerId: string) {
        this.store.dispatch(loadPaymentProcessorAvailability({ customerId }));
    }

    /**
     * Determined Payment Processors are available to take payment or not
     * @returns Observable<boolean>
     */
    isPaymentProcessorsAvailable(): Observable<boolean | Processors[] | null> {
        return this.store.pipe(select(selectIsPaymentProcessorAvailable));
    }

    getSelectedContracts(): Observable<string[]> {
        return this.store.pipe(select(getSelectedContracts));
    }

    getSelectedContractsModels(): Observable<ContractCP[]> {
        return this.store.pipe(select(getSelectedContractsModels));
    }

    setRedirected(isRedirected: boolean) {
        this.store.dispatch(setRedirected(isRedirected));
    }

    getRedirected(): Observable<boolean> {
        return this.store.pipe(select(selectIsRedirected));
    }

    getloadVersion(orgId: string): void {
        this.store.dispatch(loadVersionRedirection({ orgId }));
    }

    setVersionRedirection(redirectionVersion: Redirection): void {
        this.store.dispatch(setVersionRedirection({ redirectionVersion }));
    }

    loadVersionRedirectionFailure(error: string): void {
        this.store.dispatch(loadVersionRedirectionFailure({ error }));
    }

    getVersionRedirection(): Observable<Redirection | null> {
        return this.store.select(selectVersionRedirection);
    }

    getStates(): Observable<ConsumerPortalState> {
        return this.store.pipe(select(selectConsumerPortalState));
    }

    getRedirection(): Observable<Redirection | null> {
        return this.store.select(selectRedirection);
    }

    getRedirectionSessionData(): Observable<LoginSession | null> {
        return this.store.select(selectRedirectionSessionData);
    }

    getIsRedirection(): Observable<boolean> {
        return this.store.select(selectIsConfigSet);
    }

    getCustomer(): Observable<Customer> {
        return this.store.pipe(select(getCustomer));
    }

    startRedirection(toLoad: ComponentsToLoad, orgId: string, cpInput: ConsumerPortalConfig): void {
        this.store.dispatch(
            startRedirection({
                redirection: { toLoad, orgId: orgId || '' },
                cpInput
            })
        );
    }

    loadCustomerById(customerId: string): void {
        const customer: Customer = { id: customerId, name: '' };
        this.store.dispatch(loadCustomer({ customer }));
    }

    loadLinkAndOpen(): void {
        this.store.dispatch(loadLinkAndOpen());
    }

    loadEsignAndOpen(): void {
        this.store.dispatch(loadEsignAndOpen());
    }

    openContract(selectedContractId: string): void {
        this.store.dispatch(openContract({ selectedContractId }));
    }

    multiPaymentContinue(): void {
        this.store.dispatch(continueMultipayment());
    }

    paymentClose(): void {
        this.store.dispatch(closePayment());
    }

    setPaymentOpen(open: boolean): void {
        this.store.dispatch(setPaymentOpen({ paymentOpen: open }));
    }

    isPaymentOpen(): Observable<boolean> {
        return this.store.pipe(select(getPaymentOpen));
    }

    getMultipaymentBoundaries(): Observable<PaymentMinMaxBoundary[] | null> {
        return this.store.pipe(select(selectMultipaymentBoundaries));
    }

    isContractSelectable(contractId: string): Observable<boolean | null> {
        return this.store.pipe(select(isContractSelectable(contractId)));
    }

    getTotalPaid(contractId: string): Observable<number | string> {
        return this.store.select(selectTotalPaidByContractId(contractId));
    }

    getTransactionLoaded(): Observable<boolean> {
        return this.store.pipe(select(selectTransactionLoaded));
    }

    getPaymentAppInput(): Observable<string | undefined> {
        return this.store.pipe(select(selectPaymentAppInput));
    }

    setTransactionLoaded(loaded: boolean): void {
        this.store.dispatch(setTransactionLoaded({ transactionLoaded: loaded }));
    }

    onPaymentFinished(paymentAppOutput: PayOutputEvent): void {
        this.store.dispatch(onPaymentFinished({ paymentAppOutput }));
    }

    isDialogEnabled(): Observable<boolean> {
        return this.store.pipe(select(selectDialogEnabled));
    }

    setDialogEnabled(enabled: boolean): void {
        this.store.dispatch(setDialogEnabled({ dialogEnabled: enabled }));
    }

    getPaymentError(): Observable<string | undefined> {
        return this.store.pipe(select(selectErrorString));
    }

    isContractSelectableAndAvailable(contractId: string): Observable<boolean> {
        const isFeatureAvailable = this.featureToggleService.isAvailable('multiplePaymentAbility');
        const isContractSelectable$ = this.isContractSelectable(contractId);

        return isContractSelectable$.pipe(map(isSelectable => isFeatureAvailable && isSelectable === true));
    }

    getLastPaymentDetails(): Observable<PaymentAppPayOutput | undefined> {
        return this.store.pipe(select(selectLastPaymentDetail));
    }

    isDialogDisplayed(): Observable<boolean> {
        return this.store.pipe(select(selectIsDialogDisplayed));
    }

    getDialogContentType(): Observable<DialogContentType | undefined> {
        return this.store.pipe(select(selectDialogContentType));
    }

    openDialog(dialogContentType: DialogContentType): void {
        this.store.dispatch(showDialog({ dialogContentType }));
    }

    toggleDialog(enabled: boolean): void {
        this.store.dispatch(setDialogDisplayed({ isDialogDisplayed: enabled }));
    }

    onSuccessVerifyPayment(detail: PaymentAppPayOutput): void {
        this.store.dispatch(successVerifyPayment({ lastPaymentDetail: detail }));
    }
}
