<div class="portalnav-wrap por-ac-itemsout">
    <div class="portalnav" [ngStyle]="{ height: height, width: width }" *ngIf="appFacadeService.getSubActiveTab() | async as tab">
        <div [hidden]="tab !== 'Default'">
            <ng-container [ngTemplateOutlet]="myRentals"></ng-container>
        </div>
        <div [hidden]="tab !== 'ContractDetail'">
            <ng-container [ngTemplateOutlet]="contractDetailTab"></ng-container>
        </div>
        <div [hidden]="tab !== 'ProductDetail'">
            <ng-container [ngTemplateOutlet]="productDetailTab"></ng-container>
        </div>
    </div>
</div>

<ng-template #myRentals>
    <div class="wrapper contract-headingSection">
        <h1 class="theme-heading">{{ 'Items Out Report' | translate }}</h1>
    </div>
    <div id="mainportalpage" class="wrapper">
        <div class="button-group">
            <por-filters
                [singleFilters]="singleFilters"
                [singleFiltersDefaultValue]="singleFiltersDefaultValue"
                [isProcessing]="(appFacadeService.getLoader() | async) || false"
                (filterOutput)="filter($any($event))"
                (getSingleFilter)="filterByStatus($event)"
                [searchPlaceholder]="filterComponentProps.searchPlaceHolder"
                [dateLabel]="filterComponentProps.dateLabel"
                [startDateLabel]="filterComponentProps.startDateLabel"
                [startDateLabelMobile]="filterComponentProps.startDateLabel"
                [endDateLabel]="'itemsoutEndDateFilter'"
                [disableSearch]="!isSearchSupported"
                (exportCSV)="exportCSV()"
                [uiUrl]="uiUrl"
                [enableExport]="$any(itemsOut$ | async | filterLineItemStatus : lineItemStatusFilter | count) > 0"></por-filters>
        </div>
        <div class="content-wrapper">
            <div class="grid-top-content">
                <div class="button-wrapper">
                    <por-base-button [class]="'theme-button m-order-2'" (click)="getSelectedRowData()" [disabled]="selectionDisabled" [featureName]="'selection-complete-on-itemout-page'">
                        {{ 'MakeRequest' | translate }}
                    </por-base-button>
                </div>
                <div class="instructions">
                    <p>{{ 'itemOutInstruction1' | translate }}</p>
                    <p>{{ 'itemOutInstruction2' | translate }}</p>
                    <p>{{ 'itemOutInstruction3' | translate }}</p>
                </div>
            </div>
            <div class="ag-grid-wrapper">
                <ag-grid-angular
                    *ngIf="gridReadyObservable$ | async"
                    style="width: 100%; height: 100%"
                    class="ag-theme-alpine ag-grid"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowData]="itemsOut$ | async | filterLineItemStatus : lineItemStatusFilter"
                    [rowHeight]="rowHeight"
                    [headerHeight]="headerHeight"
                    [animateRows]="true"
                    (gridReady)="onGridReady($event)"
                    [isRowSelectable]="isRowSelectable"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    [isFullWidthRow]="isFullWidthRow"
                    [fullWidthCellRenderer]="fullWidthCellRenderer"
                    [context]="context"
                    (selectionChanged)="onSelectionChanged()"
                    (rowDataUpdated)="onRowDataUpdated()">
                    [getRowId]="getRowId">
                </ag-grid-angular>
            </div>
        </div>
        <div class="middle">
            <por-load-more [hasMoreRecord]="(hasMoreRecord$ | async) ?? false" [featureName]="'load-more-items-out'" (loadMore)="loadMore()"></por-load-more>
        </div>
    </div>
</ng-template>
<ng-template #contractDetailTab>
    <div class="contract-detail wrapper" *ngIf="appFacadeService.getContractDetail() | async as contractDetail">
        <por-contract-detail
            *ngIf="contractDetail"
            (closePanel)="onClosePanelContractDetail()"
            [customerId]="customerId"
            [contractDetail]="[contractDetail]"
            [uiUrl]="inputs?.uiUrl"></por-contract-detail>
    </div>
</ng-template>
<ng-template #productDetailTab>
    <div class="product-detail wrapper" *ngIf="(appFacadeService.getProductDetail() | async)?.productDetail">
        <por-product-detail [uiUrl]="inputs?.uiUrl" (closePanel)="onClosePanelProductDetail()" />
    </div>
</ng-template>
<por-base-modal [id]="'rental-request-page'" [modalWidth]="'modal-xl'" (onclose)="onClosingRequestModal()">
    <por-rental-request-modal
        [customerId]="customerId"
        [items]="selectedRows"
        [totalItems]="totalRowsSelected"
        [uiUrl]="inputs?.uiUrl"
        (callOffRequestInfo)="handleRentalFormSubmission($event)"
        *ngIf="!selectionDisabled"></por-rental-request-modal>
</por-base-modal>

<por-payment-success-dialog></por-payment-success-dialog>
<por-request-sent-successfully-dialog></por-request-sent-successfully-dialog>
