import { createReducer, on } from '@ngrx/store';
import { LOAD_MORE_DROP_DOWN_DEFAULT_VALUE } from '../../../constants/default.const';
import { changePageSizeValue } from './pagination.actions';

export const paginationFeatureKey = 'pagination';

export interface PaginationState {
    pageSizeValue: {
        selectedPageSize: number;
        previousPageSize: number;
    };
}

export const initialState: PaginationState = {
    pageSizeValue: {
        selectedPageSize: LOAD_MORE_DROP_DOWN_DEFAULT_VALUE,
        previousPageSize: LOAD_MORE_DROP_DOWN_DEFAULT_VALUE
    }
};

export const PaginationReducer = createReducer(
    initialState,
    on(changePageSizeValue, (state, { value }) => ({
        ...state,
        pageSizeValue: {
            selectedPageSize: Number(value),
            previousPageSize: state.pageSizeValue.selectedPageSize
        }
    }))
);
