import { createReducer, on } from '@ngrx/store';
import { setContractCurrency, setCustomerCurrency } from '../actions/currencycode.actions';

export const currencyFeatureKey = 'currency';
export interface CurrencyState {
    contractsCurrencyCode: string | null;
    customerCurrencyCode: string | null;
}
export const initialCurrencyState: CurrencyState = {
    contractsCurrencyCode: null,
    customerCurrencyCode: null
};

export const currencyReducer = createReducer(
    initialCurrencyState,
    on(setCustomerCurrency, (state, { currencyCode }) => ({
        ...state,
        customerCurrencyCode: currencyCode
    })),
    on(setContractCurrency, (state, { currencyCode }) => {
        return {
            ...state,
            contractsCurrencyCode: currencyCode
        };
    })
);
