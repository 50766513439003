import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentsToLoad } from '../enums/components-to-load.enum';
import { AuthKeys } from '../enums/local-storage-keys.enum';
import { RefreshResponse } from '../models/auth.model';
import { ConsumerPortalConfig } from '../models/consumer-portal-config';
import { ErrorType } from '../models/error.model';
import { LoginSession } from '../models/login-session.model';
import { clearContractinStore } from '../store/domains/contract/contract.actions';
import { cleanAccountSummary } from '../store/domains/customer-summary/customer-summary.actions';
import { clearInvoicesinStore } from '../store/domains/invoices/invoices.actions';
import { clearItemsOut } from '../store/domains/items-out-actions/items-out-action.actions';
import { AppFacadeService } from './app-facade.service';
import { AppMediatorService } from './app-mediator.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { ErrorService } from './error.service';
import { VersionToggleService } from './version-toggle';

@Injectable({
    providedIn: 'root'
})
export class AuthUiService {
    defaultPageSize!: number;
    accessToken = '';
    xApiKey = '';

    private refreshAuthTimer: Observable<number> = new Observable<number>();
    private authData?: LoginSession;
    private readonly _clearRefreshAuthTimer = new Subject();
    // Always use CONSTANT_CASE
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly REFRESH_TOKEN_TIMER_MS = 1500000;

    get user(): LoginSession {
        return this.appMediatorService.localStorageService.getCurrentUser?.Auth as LoginSession;
    }

    constructor(
        private readonly store: Store,
        private readonly errorService: ErrorService,
        private readonly consumerPortalApiService: ConsumerPortalApiService,
        private readonly appFacadeService: AppFacadeService,
        private readonly appMediatorService: AppMediatorService,
        private readonly versionToggle: VersionToggleService
    ) {}

    initAuth() {
        if (this.user) {
            this.appFacadeService.setAuthenticated(false);
        }
    }

    logout() {
        [AuthKeys.AcceessToken, AuthKeys.User, AuthKeys.RefreshToken].map((key: string) => {
            this.appMediatorService.localStorageService.removeItem(key, this.appFacadeService.versionToggleService.isConsumerQuickLinkVersion());
        });
        [clearContractinStore, cleanAccountSummary, clearInvoicesinStore, clearItemsOut].map((action: unknown) => {
            if (typeof action === 'function') {
                this.store.dispatch(action());
            } else {
                this.store.dispatch(action as Action);
            }
        });
        /**
         * Reset/Clear Selected Contracts on logout
         */
        this.appFacadeService.setSelectedContracts([]);

        this.returnToLogin();
    }

    returnToLogin() {
        // It's important to first route to logout page for Quick Link Version to return false in case of logout
        // becasuse Quick Link Version is determined by the page URL
        this.appFacadeService.updateLocation(ComponentsToLoad.Logout);
        this.clearRefreshAuthTimer();
        this.appFacadeService.setAuthenticated(false);
    }

    activateRefreshAuthTimer(data: LoginSession) {
        this.clearRefreshAuthTimer();
        // TODO: Remove the need of authData
        this.authData = data;
        // Only set startDue as zero(0) if this is the first time the user logs in
        // because first time a token is exist and it is valid for 5 minutes.
        const startDue = !this.authData.initialLogin ? 0 : this.REFRESH_TOKEN_TIMER_MS;
        // run after x mins and then every x minutes
        this.refreshAuthTimer = timer(startDue, this.REFRESH_TOKEN_TIMER_MS).pipe(
            switchMap(i => this.getRefreshToken(this.authData).pipe(map(() => i))),
            takeUntil(this._clearRefreshAuthTimer)
        );

        return this.refreshAuthTimer;
    }

    clearRefreshAuthTimer() {
        this._clearRefreshAuthTimer.complete();
    }

    private getRefreshToken(data?: LoginSession) {
        return this.consumerPortalApiService
            .post({
                controller: 'auth/tokens/refresh',
                method: 'POST',
                body: this.authData
            })
            .pipe(
                map((response: unknown) => response as RefreshResponse),
                tap({
                    next: (response: RefreshResponse) => {
                        if (response) {
                            if (this.authData) {
                                this.authData.RefreshToken = response?.refreshToken ?? undefined;
                                this.authData.accessToken = response?.accessToken ?? undefined;
                                this.authData.AccessToken = response?.accessToken ?? undefined;
                            }

                            const userDetails: ConsumerPortalConfig = {
                                ...(this.appMediatorService.localStorageService.getCurrentUser as ConsumerPortalConfig),
                                organizationType: response?.organizationType ?? '',
                                /* eslint-disable @typescript-eslint/naming-convention */
                                /**
                                 * Note camelCase : DB Model
                                 */
                                Auth: {
                                    ...this.user,
                                    accessToken: response?.accessToken,
                                    RefreshToken: response?.refreshToken
                                }
                            };
                            this.appMediatorService.localStorageService.persistUser(AuthKeys.User, userDetails);
                            if (!data?.initialLogin && userDetails.Auth?.accessToken) {
                                this.appFacadeService.setAuthenticated(true);
                            }
                            if (data?.isAdmin && userDetails.Auth?.accessToken) {
                                this.appFacadeService.setAdmin(true);
                            }
                        }
                    },
                    error: error => {
                        // Authentication failed

                        if ((error.status === [401, 403, 404].includes(error.status) || (!error?.status && error?.url?.includes('tokens/refresh'))) && !this.versionToggle.isQuickLinkVersion()) {
                            this.errorService.setError(ErrorType.AuthFailed);
                            this.logout();
                        }
                    }
                })
            );
    }
}
