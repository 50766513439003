import { createAction, props } from '@ngrx/store';
import { ContractCP } from '../../models/contract-model';

export enum ContractActionTypes {
    LoadContractAction = '[ContractActions] Store contracts to state',
    SetNoMoreRecord = '[ContractActions] Set setNoMoreRecord',
    LoadContractActions = '[ContractActions] Load ContractActions',
    ClearContractInStore = '[ContractActions] Clear ContractinStore',
    ClearIncreasePageNumber = '[ContractActions] Clear increasePageNumber',
    OpenContract = '[ContractActions] Open Contract',
    ContractsSelected = '[ContractActions] Set Selected Contracts',
    ContractsUnSelected = '[ContractActions] Set UnSelected/Remove Contracts from Selected Contracts',
    MultiPaymentContinue = '[ContractActions] Click Continue on Multi Payment',
    PaymentWindowClosed = '[ContractActions] Close Payment Window',
    ResetLatestToShow = '[ContractActions] Reset latestToShow',
    IncreasePageNumber = '[ContractActions] Increase PageNumber',
    SetPaymentOpen = '[ContractActions] Set Payment Open',
    FetchContractAction = '[ContractActions] Fetch Contract from api'
}

export const loadContractAction = createAction(ContractActionTypes.LoadContractAction, props<{ contracts: ContractCP[] }>());

export const setNoMoreRecord = createAction(ContractActionTypes.SetNoMoreRecord, props<{ record: boolean }>());

export const contractsLoading = createAction(ContractActionTypes.LoadContractActions, props<{ loading: boolean }>());

export const clearContractinStore = createAction(ContractActionTypes.ClearContractInStore);

export const increasePageNumberContract = createAction(ContractActionTypes.IncreasePageNumber);

export const openContract = createAction(ContractActionTypes.OpenContract, props<{ selectedContractId: string }>());

export const setSelectedContracts = createAction(ContractActionTypes.ContractsSelected, props<{ selectedContracts: string[] }>());

export const unsetSelectedContracts = createAction(ContractActionTypes.ContractsUnSelected, props<{ unselectContracts: string[] }>());

export const continueMultipayment = createAction(ContractActionTypes.MultiPaymentContinue);

export const closePayment = createAction(ContractActionTypes.PaymentWindowClosed);

export const setPaymentOpen = createAction(ContractActionTypes.SetPaymentOpen, props<{ paymentOpen: boolean }>());

export const resetContractsLatestToShow = createAction(ContractActionTypes.ResetLatestToShow);

export const resetPageNumberContract = createAction(ContractActionTypes.ClearIncreasePageNumber);

export const fetchContracts = createAction(
    ContractActionTypes.FetchContractAction,
    props<{
        customerId: string;
        status?: string[];
        startDate?: string;
        endDate?: string;
        search?: string;
    }>()
);
