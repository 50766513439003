<div class="jw-modal">
  <div class="jw-modal-body" [ngClass]="modalWidth">
    <div class="close">
      <por-base-button featureName="modal-close-btn" theme="primary" (onclick)="close()" class="p-0"
        ><mat-icon>close</mat-icon></por-base-button
      >
    </div>
    <ng-content></ng-content>
  </div>
</div>
<div class="jw-modal-background"></div>
