<div class="documents m-d-block">
    <div class="col-3" *ngIf="appFacadeService.isDocumentExists('InspectionCertificate') | async">
        <por-document-button
            [label]="'InspectionCertifications' | translate"
            [featureToken]="['displayInspectionDocument']"
            [visible]="(appFacadeService.isDocumentExists('InspectionCertificate') | async) ?? false"
            [loading]="(appFacadeService.isDocumentLoading('InspectionCertificate') | async) ?? false"
            [featureName]="'inspection-certificate-btn'"
            [uiUrl]="uiUrl"
            [imgUrl]="'inspection-certificate-150245.svg'"
            (onclick)="openDocumentUrl('InspectionCertificate')" />
    </div>
    <div class="col-3" *ngIf="appFacadeService.isDocumentExists('ServiceCertificate') | async">
        <por-document-button
            [label]="'ServiceCertificates' | translate"
            [featureToken]="['displayServiceCertificate']"
            [visible]="(appFacadeService.isDocumentExists('ServiceCertificate') | async) ?? false"
            [loading]="(appFacadeService.isDocumentLoading('ServiceCertificate') | async) ?? false"
            [featureName]="'service-certificate-btn'"
            [uiUrl]="uiUrl"
            [imgUrl]="'service-certificate-3504413.svg'"
            (onclick)="openDocumentUrl('ServiceCertificate')" />
    </div>
    <div class="col-3" *ngIf="appFacadeService.isDocumentExists('SafetyInstruction') | async">
        <por-document-button
            [label]="'instructions' | translate"
            [visible]="(appFacadeService.isDocumentExists('SafetyInstruction') | async) ?? false"
            [loading]="(appFacadeService.isDocumentLoading('SafetyInstruction') | async) ?? false"
            [featureName]="'instructions-btn'"
            [uiUrl]="uiUrl"
            [imgUrl]="'instructions.svg'"
            (onclick)="openDocumentUrl('SafetyInstruction')" />
    </div>
    <div class="text-align-center doc-notfound" *ngIf="(appFacadeService.checkIfDocumentsExistAfterLoading() | async) === false">
        <div>{{ 'ProductDetailsNoDocumentsFound' | translate }}</div>
    </div>
</div>
