import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig } from '../models/consumer-login-config';
import { User } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@por/shared/core';
import { encrypt } from '@por/consumer-login/shared-lib';
import { ModalService } from '@por/ecom-shared';

@Component({
    selector: 'por-change-password',
    templateUrl: './change-password-component.component.html',
    styleUrls: ['./change-password-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponentComponent implements OnInit {
    @Output()
    readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    readonly changePasswordData: EventEmitter<User> = new EventEmitter<User>();

    @Output()
    readonly loading$: EventEmitter<boolean> = new EventEmitter<boolean>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;

    @Input() isDisableButton = false;

    @Input() userEmail!: string;

    changePasswordForm = this.fb.group({
        code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
        password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]]
    });
    constructor(private readonly fb: FormBuilder, private readonly translateService: TranslateService, private readonly modalService: ModalService) {}

    get password(): FormControl {
        return this.changePasswordForm.get('password') as FormControl;
    }

    get code(): FormControl {
        return this.changePasswordForm.get('code') as FormControl;
    }

    ngOnInit(): void {
        this.translateService.use(Language.EN_GB);

        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
        }

        this.changePasswordForm.setValue({
            password: '',
            code: ''
        });
    }

    submit(): boolean {
        if (!this.changePasswordForm.valid) {
            return false;
        }
        const password: string = this.changePasswordForm.value?.password as string;
        const code: string = this.changePasswordForm.value?.code as string;
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        this.changePasswordData.emit({
            Email: this.userEmail,
            Password: encrypt(password),
            code: code
        });
        this.loading$.emit(true);
        return true;
    }

    setTemplate(templateName: string) {
        this.selectedTemplate.emit(templateName);
    }

    openModal(id: string) {
        this.modalService.open(id);
    }
}
