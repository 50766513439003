import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { AppFacadeService } from '../../services/app-facade.service';
import { NotificationService } from '../../services/notification.service';
import { ModalService } from '../../services/modal.service';
import { debounceTime, delay, tap, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener, ChangeDetectionStrategy } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
    ApplyColumnStateParams,
    ColDef,
    ColumnApi,
    CsvExportParams,
    GetRowIdFunc,
    GetRowIdParams,
    GridApi,
    GridReadyEvent,
    IsFullWidthRowParams,
    IsRowSelectable,
    ProcessCellForExportParams,
    RowNode,
    ValueGetterParams
} from 'ag-grid-community';
import { ConfigurationService } from '../../services/configuration.service';
import { ItemOutService } from '../../services/item-out.service';
import { clearItemsOut, updateRow } from '../../store/domains/items-out-actions/items-out-action.actions';
import { getAllItemsOut, getNoMoreRecordSelector } from '../../store/domains/itemsout/itemsout.selectors';
import { TranslateService } from '@ngx-translate/core';
import { formatDateType } from '../../types';
import { AgGridService } from '../../services/ag-grid.service';
import { Filter, FilterOutput } from '../../shared/models/filters.model';
import { ItemOutCP } from '../../models/item-out-model';
import { LineItemStatus } from '../../enums/line-item-status.enum';
import { ItemsOutFullWidthRowComponent } from '../items-out-full-width-row/items-out-full-width-row.component';
import { ItemsOutDetailRendererComponent } from './renderer/items-out-detail-renderer/items-out-detail-renderer.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { RequestCellRendererComponent } from './renderer/request-cell-renderer';
import { CallOffRequest } from '../../models/calloff-request.model';
import { RequestActionService } from '../../services/request-action.service';
import { RequestActionsToLoad } from '../../enums/request-actions.enum';
import { ReturnRequestType } from '../../enums/return-request-type.enum';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { AppMediatorService } from '../../services/app-mediator.service';
import { ContractDetailRendererComponent } from './renderer/items-out-contract-detail-renderer/contract-detail-renderer.component';
import { SubComponentsToLoad } from '../../enums/components-to-load.enum';
@Component({
    selector: 'por-items-out',
    templateUrl: './items-out.component.html',
    styleUrls: ['./items-out.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsOutComponent implements OnInit, OnDestroy {
    public context: unknown;
    constructor(
        readonly appFacadeService: AppFacadeService,
        public itemOutService: ItemOutService,
        private readonly store: Store,
        private readonly notification: NotificationService,
        private readonly configService: ConfigurationService,
        private readonly datePipe: FormatDatePipe,
        private readonly translateService: TranslateService,
        private readonly modal: ModalService,
        private readonly agGrid: AgGridService,
        private readonly appMediatorService: AppMediatorService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly featureService: FeatureToggleService,
        private readonly requestActionService: RequestActionService
    ) {
        store.dispatch(clearItemsOut());
        this.context = {
            componentParent: this
        };
    }
    public fullWidthCellRenderer = ItemsOutFullWidthRowComponent;
    public isSearchSupported = this.featureService.isAvailable('textSearchMultipleFilters');

    public getScreenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
    itemsOut$: Observable<ItemOutCP[]> = this.store.select(getAllItemsOut);
    headerHeight = 35;
    rowHeight = 65;
    uiUrl: string | undefined;
    filters: FilterOutput | undefined;
    /* Single Filter Can be Enum: "Reserved" "Out" "Off Rent" "Returned" "Sold" */
    singleFilters: Filter[] = [
        {
            label: this.translateService.instant('Current'),
            value: LineItemStatus.Out
        },
        {
            label: this.translateService.instant('PastYear'),
            value: LineItemStatus.OffRent
        },
        {
            label: this.translateService.instant('Reserved'),
            value: LineItemStatus.Reserved
        }
    ];
    hasMoreRecord$: Observable<boolean> = this.store.select(getNoMoreRecordSelector);
    @Input() customerId!: string;
    private readonly subscriptions: Subscription[] = [];
    gridApi!: GridApi;
    columnApi!: ColumnApi;
    public columnDefs: ColDef[] = [];
    inputs: ConsumerPortalConfig | undefined;
    isFullWidth = false;
    gridReady$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    gridReadyObservable$: Observable<boolean> = this.gridReady$.pipe(delay(1000));
    lineItemStatusFilter: string | string[] | undefined;
    public filterComponentProps: {
        searchPlaceHolder: string;
        dateLabel: string;
    } = {
        searchPlaceHolder: this.translateService.instant('searchItems'),
        dateLabel: this.translateService.instant('selectDueDateRange')
    };

    @Input() config: string | undefined;
    @Input() width: number | undefined;
    @Input() height: string | undefined;
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    @Output() readonly appOutput: EventEmitter<ItemOutCP[]> = new EventEmitter<ItemOutCP[]>();

    public defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        wrapText: false,
        headerComponentParams: {
            template: this.getHeaderTemplate()
        },
        suppressMenu: true
    };

    public rowSelection: 'single' | 'multiple' = 'multiple';
    selectedRows: ItemOutCP[] = [];
    selectionDisabled = true;
    totalRowsSelected = 0;

    public isFullWidthRow: (params: IsFullWidthRowParams) => boolean = () => {
        return this.isFullWidth;
    };

    formatDate = (params: formatDateType) => {
        const date = this.datePipe.transform(params.value);
        return `<span class="theme-table-row">${date}</span>`;
    };

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        this.autoSizeAll();
        this.agGrid.setHorizontalScrollBar();
        const columnState: ApplyColumnStateParams = {
            state: [
                {
                    colId: 'dueDate',
                    sort: 'desc'
                }
            ]
        };
        this.columnApi.applyColumnState(columnState);
        document.querySelectorAll('.ag-header-container .ag-checkbox-input').forEach(e => {
            e.setAttribute('data-testid', 'por-cp-header-checkbox');
        });
    }
    onRowDataUpdated() {
        document.querySelectorAll('.ag-cell-wrapper[aria-hidden="true"]').forEach(el => el.removeAttribute('aria-hidden'));
        document.querySelectorAll('.ag-checkbox[aria-hidden="true"]').forEach(el => el.removeAttribute('aria-hidden'));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth.next(window.innerWidth);
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        this.subscriptions.push(
            this.getScreenWidth.pipe(debounceTime(1000)).subscribe((width: number) => {
                this.setResponsiveGrid(width);
            })
        );
        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
        }

        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            this.elementLoaded.emit(false);
            return;
        }
        this.itemOutService.getItemsOut({
            customerId: this.customerId
        });

        this.elementLoaded.emit(true);
        this.subscriptions.push(
            this.itemsOut$.subscribe(itemsOut => {
                if (!isEmpty(itemsOut)) {
                    this.appOutput.emit(itemsOut);
                    this.autoSizeAll();
                }
            })
        );

        // checking window size
        this.subscriptions.push(
            this.breakpointObserver
                .observe(['(max-width: 992px)'])
                .pipe(
                    tap((value: BreakpointState) => {
                        if (value.matches) {
                            this.filterComponentProps = {
                                searchPlaceHolder: this.translateService.instant('searchItems'),
                                dateLabel: this.translateService.instant('filterbyDate')
                            };
                        } else {
                            this.filterComponentProps = {
                                searchPlaceHolder: this.translateService.instant('search contract#, item name, and serial#'),
                                dateLabel: this.translateService.instant('selectDueDateRange')
                            };
                        }
                    }),
                    distinctUntilChanged()
                )
                .subscribe()
        );
        this.uiUrl = this.inputs?.uiUrl;

        this.subscriptions.push(
            this.appFacadeService.getContractDetail().subscribe(data => {
                if (data) {
                    this.appFacadeService.setSubActiveTab(SubComponentsToLoad.ContractDetail);
                }
            })
        );
    }

    openCallOff(id: string) {
        this.modal.close(id + '-view');
        this.modal.open(id);
    }

    autoSizeAll() {
        this.columnApi?.autoSizeAllColumns();
        this.gridApi?.sizeColumnsToFit();
    }

    public isRowSelectable: IsRowSelectable = (params: RowNode<ItemOutCP>) => {
        let isSelactable = !!params.data && params.data.lineItemStatus === LineItemStatus.Out;
        if (params.data) {
            this.subscriptions.push(
                this.appFacadeService.isLineItemCalledOff(params.data.id).subscribe(isCalledOff => {
                    isSelactable = isSelactable && !isCalledOff;
                })
            );
        }

        return isSelactable;
    };

    getSelectedRowData() {
        if (!this.isFullWidth) {
            this.selectedRows = this.gridApi.getSelectedRows();
        }
        this.openRentalRequestModal();
    }

    onSelectionChanged() {
        if (!this.isFullWidth) {
            this.selectionDisabled = true;
            this.totalRowsSelected = this.gridApi.getSelectedRows().length;
            if (this.totalRowsSelected > 0) {
                this.selectionDisabled = false;
            }
        }
    }

    setResponsiveGrid(width: number) {
        this.gridReady$.next(false);
        this.selectedRows = [];
        this.totalRowsSelected = 0;
        this.selectionDisabled = true;

        if (width > 1024) {
            this.columnDefs = [
                {
                    field: 'select',
                    headerName: this.translateService.instant('select'),
                    minWidth: 90,
                    width: 90,
                    cellClass: 'theme-table-row align-center',
                    headerClass: 'theme-table-header align-center',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    cellStyle: { textAlign: 'center' }
                },
                {
                    field: 'contractId',
                    minWidth: 130,
                    headerName: this.translateService.instant('contract'),
                    cellRenderer: ContractDetailRendererComponent,
                    width: 130,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'quantity',
                    headerName: this.translateService.instant('Quantity'),
                    width: 65,
                    minWidth: 65,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'itemName',
                    headerName: this.translateService.instant('itemName'),
                    cellRenderer: ItemsOutDetailRendererComponent,
                    width: 360,
                    minWidth: 360,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header',
                    cellStyle: { textAlign: 'left' }
                },
                {
                    field: 'Item #',
                    headerName: this.translateService.instant('Item#'),
                    valueGetter: (params: ValueGetterParams) => {
                        return this.itemOutService.getLineItemKeyName(params.data);
                    },
                    width: 165,
                    minWidth: 165,
                    autoHeight: true,
                    wrapText: true,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'startDate',
                    headerName: this.translateService.instant('startDate'),
                    cellRenderer: this.formatDate,
                    width: 150,
                    minWidth: 150,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'status',
                    headerName: this.translateService.instant('status'),
                    width: 120,
                    minWidth: 120,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                },
                {
                    field: 'requestDateTime',
                    headerName: this.translateService.instant('requests'),
                    cellRenderer: RequestCellRendererComponent,
                    width: 130,
                    minWidth: 130,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header',
                    autoHeight: true
                },
                {
                    field: 'info',
                    headerName: this.translateService.instant('location'),
                    width: 110,
                    minWidth: 110,
                    autoHeight: true,
                    wrapText: true,
                    cellClass: 'theme-table-row',
                    headerClass: 'theme-table-header'
                }
            ];
            this.headerHeight = 35;
            this.isFullWidth = false;
            this.rowHeight = 65;
        }
        if (width < 1024) {
            this.columnDefs = [];
            this.headerHeight = 0;
            this.isFullWidth = true;
            this.rowHeight = 330;
        }
        this.gridReady$.next(true);
    }
    onClosingRequestModal() {
        this.onSelectionChanged();
        if (this.totalRowsSelected === 1) {
            this.gridApi.deselectAll();
        }
        /**
         * Reset default view whenever rental request modal closes
         */
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }

    loadMore() {
        if (!this.customerId) {
            this.notification.error(this.translateService.instant('customerIdNotConfigured'));
            return;
        }
        this.itemOutService.getItemsOut({
            customerId: this.customerId,
            status: this.filters?.singleFilter,
            startDate: this.filters?.dates?.startDate,
            endDate: this.filters?.dates?.endDate,
            search: this.filters?.search
        });
    }

    exportCSV() {
        const params: CsvExportParams = {
            skipHeader: false,
            skipGroups: true,
            fileName: 'Items-out.csv',
            processCellCallback: (param: ProcessCellForExportParams) => {
                return this.processCellCSV(param);
            }
        };
        this.gridApi.exportDataAsCsv(params);
    }

    processCellCSV(params: ProcessCellForExportParams): string {
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Always use CONSTANT_CASE
         */
        const DATE_NODES_INDEX: number[] = [5];
        const REQUEST_NODE_INDEX: number[] = [7];

        if (params.accumulatedRowIndex && DATE_NODES_INDEX.includes(params.accumulatedRowIndex)) {
            return this.datePipe.transform(params.value);
        }
        if (params.accumulatedRowIndex && REQUEST_NODE_INDEX.includes(params.accumulatedRowIndex)) {
            const requestDate = this.datePipe.transform(params.value);
            let requestStr = this.translateService.instant('CallOff');
            if ((params.node?.data?.requestType as string) === (ReturnRequestType.Service as string)) {
                requestStr = this.translateService.instant('RequestService');
            }
            return requestDate ? `${requestStr} ${requestDate}` : '';
        }
        return params.value;
    }

    loadProductDetail(item: ItemOutCP) {
        this.appFacadeService.loadProductDetail(item.customerId, item.contractId, item.productId ?? '0', item.stockId ?? '0', ['Id', 'Name', 'SerialNumber', 'Images', 'Description'], () => {
            this.appFacadeService.setSubActiveTab(SubComponentsToLoad.ProductDetail);
        });
    }

    loadRequestmodal(item: ItemOutCP) {
        this.selectedRows = [item];
        this.totalRowsSelected = this.selectedRows.length;
        this.selectionDisabled = false;
        this.openRentalRequestModal();
    }

    setMobileCheckboxandItems(check: boolean, itemout: ItemOutCP) {
        this.selectedRows = this.selectedRows.filter((item: ItemOutCP) => {
            return item.id !== itemout.id;
        });

        if (check) {
            this.selectedRows = [...this.selectedRows, itemout];
        }
        this.totalRowsSelected = this.selectedRows.length;
        this.selectionDisabled = this.selectedRows.length > 0 ? false : true;
    }

    private openRentalRequestModal() {
        if (this.selectedRows.length > 0) {
            this.modal.open('rental-request-page');
        }
    }

    filterByStatus($e: string) {
        if ($e === LineItemStatus.OffRent) {
            return (this.lineItemStatusFilter = [LineItemStatus.OffRent, LineItemStatus.Returned]);
        }
        return (this.lineItemStatusFilter = $e);
    }

    filter($e: FilterOutput) {
        this.store.dispatch(clearItemsOut());
        this.filters = $e;
        if (this.filters) {
            this.lineItemStatusFilter = $e.singleFilter;
            setTimeout(() => {
                // Waiting for page number to update if(not used settimeout does not update instantly)
                this.itemOutService.filter($e, this.customerId);
            }, 300);
        }
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return params.data.id;
    };

    handleRentalFormSubmission($event: CallOffRequest) {
        this.modal.close('rental-request-page');
        this.selectedRows.map(row => {
            this.store.dispatch(updateRow({ nodeId: row.id, colId: 'requestDateTime', value: $event.requestDate }));
            this.store.dispatch(updateRow({ nodeId: row.id, colId: 'requestType', value: $event.requestType }));
            this.store.dispatch(updateRow({ nodeId: row.id, colId: 'callOffQty', value: this.itemOutService.callOffLineItemQty(row.id, $event) }));
            this.store.dispatch(updateRow({ nodeId: row.id, colId: 'actualQuantity', value: this.itemOutService.lineItemActualQty(row.id, $event) }));
        });
    }

    getHeaderTemplate() {
        return `<div class="ag-cell-label-container" role="presentation" data-testid="por-cp-items-out-header">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
            <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" aria-hidden="true"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                <span ref="eText" class="ag-header-cell-text"></span>
                <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
                <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
                <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
                <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
                <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
            </div>
        </div>`;
    }

    loadContractDetail(contractId: string) {
        this.appFacadeService.fetchContractDetail(false, this.customerId, contractId);
        /**
         * dispatch action to make contract select for contract detail
         */
        this.appFacadeService.openContract(contractId);
    }

    onClosePanelContractDetail() {
        this.appFacadeService.setSubActiveTab(SubComponentsToLoad.Default);
        /**
         * dispatch action to make contract unselected/reset for contract detail
         */
        this.appFacadeService.openContract('');
        /**
         * For payment we always needs latest data, so clear previous state data
         */
        this.appFacadeService.clearContractDetail();
    }

    onClosePanelProductDetail() {
        this.appFacadeService.setSubActiveTab(SubComponentsToLoad.Default);
        this.appFacadeService.resetProductDetail();
    }
}
