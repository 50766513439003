import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { SelectOptions } from '../../shared/components/base-select/base-select.component';

@Component({
    selector: 'por-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent implements OnInit {
    @Input() options!: SelectOptions[];
    selectedLanguage: string;
    @Input() selectedLanguageOption!: string;

    @Output() readonly selectedLocale = new EventEmitter<string>();

    constructor(@Inject(LOCALE_ID) locale: string) {
        this.selectedLanguage = locale;
    }

    ngOnInit(): void {
        this.selectedLanguage = this.selectedLanguageOption ?? this.selectedLanguage;
        this.emitLanguage();
    }

    handleSelect(value: string | number) {
        this.selectedLanguage = value.toString();
        this.emitLanguage();
    }

    emitLanguage() {
        this.selectedLocale.emit(this.selectedLanguage);
    }
}
