import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription, delay } from 'rxjs';
import { DateFilterOutput } from '../../models/date-filter-output.model';

@Component({
    selector: 'por-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFilterComponent implements OnInit, OnDestroy {
    constructor(private readonly datePipe: DatePipe) {}
    startDate: string | null = null;
    endDate: string | null = null;
    dateUpdated = new BehaviorSubject<DateFilterOutput>({
        startDate: null,
        endDate: null
    });
    subscriptions: Subscription[] = [];
    @Output() readonly dateChanged = new EventEmitter<DateFilterOutput>();
    @Input() featureName = '';
    @Input() startDateLabel = 'start';
    @Input() endDateLabel = 'end';

    formatDate(date: Date | null) {
        return date
            ? `${date.getFullYear()}-${date.getMonth().toString().length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
                  date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
              }`
            : null;
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.dateUpdated.pipe(delay(200)).subscribe(() => {
                const startDate: Date | null = this.startDate ? new Date(this.startDate) : null;
                const endDate: Date | null = this.endDate ? new Date(this.endDate) : null;
                this.dateChanged.emit({
                    startDate: this.formatDate(startDate),
                    endDate: this.formatDate(endDate)
                });
            })
        );
    }

    updateDate() {
        this.dateUpdated.next({
            startDate: this.startDate,
            endDate: this.endDate
        });
    }

    clearDates() {
        this.startDate = null;
        this.endDate = null;
        this.dateUpdated.next({
            startDate: null,
            endDate: null
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
