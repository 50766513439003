import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { distinctUntilChanged, Subscription, tap } from 'rxjs';
import { Filter, FilterOutput } from '../../models/filters.model';
import { DateFilterOutput } from '../../models/date-filter-output.model';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { FormControl, FormGroup } from '@angular/forms';
import { VersionToggleService } from '../../../services/version-toggle';

@Component({
    selector: 'por-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(private readonly breakpointObserver: BreakpointObserver, readonly versionToggle: VersionToggleService, private readonly cdn: ChangeDetectorRef) {}

    @Input() enableExport = true;
    @Input() exportButtonEnabled = true;
    onSmallScreen = false;
    selectedFilters: string[] = [];
    multiCheckForm = new FormGroup({});
    enableForms = false;
    search = '';
    dates: DateFilterOutput = { startDate: null, endDate: null };
    singleFilter = '';
    @Input() searchPlaceholder: string | undefined;
    @Input() uiUrl: string | undefined;

    @Input() dateLabel!: string;
    @Input() disableSearch = false;
    @Output() readonly exportCSV = new EventEmitter();
    @Input() isProcessing = false;
    @ViewChild(DateFilterComponent) dateFilterComp!: DateFilterComponent;

    toggleMobileFilter = false;
    subscriptions: Subscription[] = [];
    @Output() readonly filterOutput = new EventEmitter<FilterOutput>();
    @Output() readonly getSingleFilter = new EventEmitter<string>();
    @Input() filters: Filter[] = [];
    @Input() initFilters?: string[];
    @Input() multiFilters: Filter[] = [];
    @Input() singleFilters: Filter[] = [];
    @Input() singleFiltersDefaultValue?: string;
    @Input() startDateLabel = 'start';
    @Input() endDateLabel = 'end';
    @Input() startDateLabelMobile = 'startDate';
    @Input() endDateLabelMobile = 'End Date';

    ngOnInit(): void {
        // checking window size
        this.subscriptions.push(
            this.breakpointObserver
                .observe(['(max-width: 992px)'])
                .pipe(
                    tap((value: BreakpointState) => (this.onSmallScreen = value.matches)),
                    distinctUntilChanged()
                )
                .subscribe()
        );
    }

    ngAfterViewInit(): void {
        this.multiFilters.map(fltr => {
            if (fltr) {
                const value = this.initFilters?.includes(fltr.value) ? true : false;
                this.multiCheckForm.addControl(`${fltr.value}`, new FormControl({ value, disabled: fltr.disabled ?? false }));
            }
        });
        this.enableForms = true;

        if (this.initFilters) {
            this.selectedFilters = [...this.initFilters];
            this.enableForms = false;
            setTimeout(() => {
                this.enableForms = true;
                this.cdn.detectChanges();
            });
        } else {
            this.cdn.detectChanges();
        }

        if (this.singleFiltersDefaultValue) {
            this.singleFilter = this.singleFiltersDefaultValue;
        }
    }

    clearFilter() {
        this.dateFilterComp.clearDates();
        this.toggleMobileFilter = false;
        setTimeout(() => {
            this.emitOutput();
        }, 500);
    }

    clearSingleFilter() {
        this.singleFilter = '';
        this.emitOutput();
    }

    setMultiFilters($e: { value: boolean }, value: string) {
        const formData = this.multiCheckForm.getRawValue();
        this.enableForms = false;
        let model = {};
        if (value === 'showAll' && $e.value) {
            if (formData['showAll' as keyof typeof formData]) {
                Object.keys(formData).map(key => {
                    model = {
                        ...model,
                        [key]: false,
                        showAll: true
                    };
                });
            } else {
                model = {
                    ...formData,
                    showAll: false
                };
            }
        } else {
            model = {
                ...formData,
                showAll: false
            };
        }
        this.selectedFilters = [];
        Object.keys(model).map(key => {
            if (model[key as keyof typeof model] && key !== 'showAll' && !this.selectedFilters.includes(key)) {
                this.selectedFilters.push(key);
            }
        });

        setTimeout(() => {
            this.enableForms = true;
            this.cdn.detectChanges();
            this.multiCheckForm.setValue(model);
            if (!this.onSmallScreen) {
                this.emitOutput();
            }
        });
    }

    setSingleFilter($event: { value: string | undefined }) {
        if ($event.value) {
            this.singleFilter = $event.value;
            this.getSingleFilter.emit(this.singleFilter);
            this.emitOutput();
        }
    }

    setSearch(search: string) {
        this.search = search;
        this.emitOutput();
    }

    dateChanged({ startDate, endDate }: DateFilterOutput) {
        this.dates = {
            startDate,
            endDate
        };
        if (!this.onSmallScreen) {
            this.emitOutput();
        }
    }

    emitOutput() {
        if (this.isProcessing) {
            return;
        }

        this.filterOutput.emit({
            multifilters: this.selectedFilters,
            singleFilter: this.singleFilter,
            search: this.search,
            dates: {
                startDate: this.dates.startDate || '',
                endDate: this.dates.endDate || ''
            }
        });
    }

    submit() {
        if (this.onSmallScreen) {
            this.toggleMobileFilter = false;
            this.emitOutput();
        }
    }

    clearCheckedFilters() {
        this.selectedFilters = [];
        this.toggleMobileFilter = false;
        this.singleFilter = '';
        const formData = this.multiCheckForm.value;
        let model = {};
        Object.keys(formData).map(key => {
            model = {
                ...model,
                [key]: false
            };
        });
        this.multiCheckForm.setValue(model);
        this.emitOutput();
    }

    closeFilter() {
        if (this.onSmallScreen) {
            this.toggleMobileFilter = false;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
