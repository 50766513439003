import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from './material-modules';
import { NotificationService } from './services/notification.service';
import { ModalService } from './services/modal.service';
import { BingMapApiService } from './services/bing-map-api.service';
import { HelpersService } from './services/helpers.service';
import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule, DatePipe, LowerCasePipe, CurrencyPipe } from '@angular/common';
import { BingMapsLoader } from './services';
import store from './store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { AccountSummaryComponent } from './components/account-summary/account-summary.component';
import { CloseItemOutComponent } from './components/close-item-out/close-item-out.component';
import { ContractsComponent } from './components/contracts/contracts.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { ItemsOutComponent } from './components/items-out/items-out.component';
import { PageSidebarComponent } from './components/page-sidebar/page-sidebar.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { TrackService } from './services/track.service';
import { CustomerPortalComponent } from './components/customer-portal/customer-portal.component';
import { ConsumerLoginLanguage, ConsumerLoginModule } from '@por/consumer-login/ui-lib';
import { SharedModule } from './shared/shared.module';
import { AppDateAdapter, APP_DATE_FORMATS } from './adaptors/format-date-picker';
import { LoggerService } from './services/logger.service';
import { devModules } from './store-dev-tools';
import { ConsumerRedirectionComponent } from './components/consumer-redirection/consumer-redirection.component';
import { BackendInterceptor } from './services/backend.interceptor';
import { RedirectionService } from './services/redirection.service';
import { BusyIndicatorComponentComponent } from './components/busy-indicator-component/busy-indicator-component.component';
import { loginDefaults } from '../input-config/login-config';
export { CONSUMER_PORTAL_APP_CONFIG } from './models/consumer-portal-config';
import { TranslateLoader, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { UIMissingTranslationHandler, UITranslateLoader } from '@por/shared/ui/translations';
import * as EnUSTranslations from '../assets/en-US.json';
import * as EnGBTranslations from '../assets/en-GB.json';
import * as FrCATranslations from '../assets/fr-CA.json';
import { Language } from '@por/shared/core';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ItemsOutActionComponent } from './components/items-out-action/items-out-action-component';
import { PaymentModule } from './payment/payment.module';
import { OrganizationIdService } from './services/organization-id.service';
import { SubdomainRedirectionComponent } from './components/subdomain-redirection/subdomain-redirection.component';
import { ContractFullWidthRowComponent } from './components/contract-full-width-row/contract-full-width-row.component';
import { ItemsOutFullWidthRowComponent } from './components/items-out-full-width-row/items-out-full-width-row.component';
import { InvoicesFullWidthRowComponent } from './components/invoices-full-width-row/invoices-full-width-row.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { RentalRequestModalComponent } from './components/rental-request-modal/rental-request-modal.component';
import { ContractPaymentHistoryModalComponent } from './components/contract-detail/contract-payment-history-modal/contract-payment-history-modal.component';
import { ApxFormsModule, ApxInputModule, ApxRadioModule, ApxButtonModule, ApxIconModule } from '@por/apex';
import { ContractDetailComponent } from './components/contract-detail/contract-detail.component';
import { FilterLineItemStatusPipe } from './pipes/filter-line-item-status.pipe';
import { FormLineItemNamePipe } from './pipes/form-line-item-name.pipe';
import { ItemsOutDetailRendererComponent } from './components/items-out/renderer/items-out-detail-renderer/items-out-detail-renderer.component';
import { PendoModule } from './pendo/pendo.module';
import { MapActionsService } from './services/map-action.service';
import { InvoiceDetailComponent } from './components/invoice-detail/invoice-detail.component';
import { RequestCellRendererComponent } from './components/items-out/renderer/request-cell-renderer';
import { BasicConsumerPortalComponent } from './basic/basic-consumer-portal/basic-consumer-portal.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { ServiceRequestComponent } from './components/service-request/service-request.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { QuickLinkComponent } from './quick-link/quick-link.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DEFAULT_LOCALE } from './constants/default.const';
import { CustomerEffects } from './store/effects/customer.effects';
import { BookAgainContractModalComponent } from './components/clone-contract-modal/book-again-contract-modal/book-again-contract-modal.component';
import { BookAgainContractRequestFormComponent } from './components/clone-contract-modal/book-again-contract-request-form/book-again-contract-request-form.component';
import { StringifyPipe } from './pipes/stringify.pipe';
import { SelectedItemActionsComponent } from './components/selected-item-actions/selected-item-actions.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { DocumentEffects } from './store/effects/document.effects';
import { SendDocumentComponent } from './components/send-document-link/send-document-link.component';
import { UtilityService } from './services/utility.service';
import { AmountRendererComponent } from './components/invoices/renderer/amount/amount-renderer.component';
import { PorContactDetailRendererComponent } from './components/contract-detail/contract-detail-renderer.component';
import { EventLogsEffects } from './store/effects/event-logs.effects';
import { RedirectionEffects } from './store/effects/redirection.effects';
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { AdImagesComponent } from './components/ad-images/ad-images.component';

export const LOGIN_CONFIG = new InjectionToken('App Config');
// Always use CONSTANT_CASE
// eslint-disable-next-line @typescript-eslint/naming-convention
const PENDO_API_KEY = '1e51ccfc-25db-41f0-66eb-d755188cde5e';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InvoicesEffects } from './store/effects/invoices.effects';
import { OverlayModule } from '@angular/cdk/overlay';
import { ContractInstructionsComponent } from './components/contract-instructions/contract-instructions.component';
import { ProcessorsEffects } from './store/effects/processors.effects';
import { MultiPaymentContractPanelComponent } from './components/multi-payment-contract-panel/multi-payment-contract-panel.component';
import { StatesEffects } from './store/effects/view-state.effects';
import { VersionRedirectionEffects } from './store/effects/versionredirection.effects';
import { PaymentEffects } from './store/effects/payment.effects';
import { ContractSelectionCheckboxRendererComponent } from './components/contracts/renderer/contract-selection-checkbox-renderer.component';
import { ContractEffects } from './store/effects/contracts.effects';
import { ItemsOutEffects } from './store/effects/itemsout.effects';
import { PaymentSuccessDialogComponent } from './components/dialogs/payment-success-dialog/payment-success-dialog.component';
import { FormatAddressPipe } from './shared/pipes/format-address.pipe';
import { RequestSentSuccessfullyDialogComponent } from './components/dialogs/request-sent-successfully-dialog/request-sent-successfully-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        StoreModule.forRoot({
            ...store
        }),
        ...devModules,
        FormsModule,
        MatMenuModule,
        ReactiveFormsModule,
        PendoModule.forRoot({
            apiKey: PENDO_API_KEY
        }),
        MatNativeDateModule,
        NgxSkeletonLoaderModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        AgGridModule,
        CommonModule,
        NgxSkeletonLoaderModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule,
        SharedModule,
        ConsumerLoginModule,
        PaymentModule,
        ApxFormsModule,
        ApxInputModule,
        ApxRadioModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new UITranslateLoader({
                        [Language.EN_US]: {
                            ...EnUSTranslations,
                            ...ConsumerLoginLanguage.enUSTranslations
                        },
                        [Language.EN_GB]: {
                            ...EnGBTranslations,
                            ...ConsumerLoginLanguage.enGBTranslations
                        },
                        [Language.FR_CA]: {
                            ...FrCATranslations
                        }
                    });
                }
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: UIMissingTranslationHandler
            },
            defaultLanguage: DEFAULT_LOCALE
        }),
        ApxButtonModule,
        ApxIconModule,
        MatSnackBarModule,
        EffectsModule.forRoot([
            CustomerEffects,
            DocumentEffects,
            EventLogsEffects,
            RedirectionEffects,
            ProcessorsEffects,
            VersionRedirectionEffects,
            StatesEffects,
            InvoicesEffects,
            PaymentEffects,
            ItemsOutEffects,
            ContractEffects
        ]),
        BsDropdownModule.forRoot(),
        OverlayModule
    ],
    declarations: [
        AccountSummaryComponent,
        AdImagesComponent,
        ContractsComponent,
        ItemsOutComponent,
        CloseItemOutComponent,
        TrackingComponent,
        InvoicesComponent,
        PageSidebarComponent,
        HeaderComponent,
        FooterComponent,
        CustomerPortalComponent,
        ConsumerRedirectionComponent,
        BusyIndicatorComponentComponent,
        LanguageSelectorComponent,
        ItemsOutActionComponent,
        SubdomainRedirectionComponent,
        ContractFullWidthRowComponent,
        ItemsOutFullWidthRowComponent,
        InvoicesFullWidthRowComponent,
        HamburgerComponent,
        RentalRequestModalComponent,
        ContractPaymentHistoryModalComponent,
        ContractDetailComponent,
        PorContactDetailRendererComponent,
        FilterLineItemStatusPipe,
        FormLineItemNamePipe,
        ItemsOutDetailRendererComponent,
        InvoiceDetailComponent,
        FormatDatePipe,
        RequestCellRendererComponent,
        BasicConsumerPortalComponent,
        ServiceRequestComponent,
        ProductDetailComponent,
        QuickLinkComponent,
        BookAgainContractModalComponent,
        BookAgainContractRequestFormComponent,
        StringifyPipe,
        SelectedItemActionsComponent,
        SendDocumentComponent,
        AmountRendererComponent,
        ContractInstructionsComponent,
        MultiPaymentContractPanelComponent,
        ContractSelectionCheckboxRendererComponent,
        PaymentSuccessDialogComponent,
        RequestSentSuccessfullyDialogComponent
    ],
    exports: [LanguageSelectorComponent, BusyIndicatorComponentComponent],
    providers: [
        TrackService,
        HelpersService,
        BingMapApiService,
        ModalService,
        NotificationService,
        BingMapsLoader,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BackendInterceptor,
            multi: true
        },
        Title,
        LoggerService,
        DatePipe,
        RedirectionService,
        LowerCasePipe,
        OrganizationIdService,
        MapActionsService,
        {
            provide: LOGIN_CONFIG,
            useValue: loginDefaults
        },
        { provide: 'Window', useValue: window },
        CurrencyPipe,
        FormatNumberPipe,
        FormatDatePipe,
        UtilityService,
        FormatCurrencyPipe,
        FormatAddressPipe
    ]
})
export class CustomerPortalUiModule {}
