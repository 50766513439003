import { Pipe, PipeTransform } from "@angular/core";
import { GapiPhone } from "../../models/gapi-phone.model";

@Pipe({
  name: "formatNumberCustomer",
})
export class FormatNumberCustomerPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value?: GapiPhone[], ...args: unknown[]): unknown {
    if (!value?.length) {return;}
    const billingContact = value.filter((contact: GapiPhone) => contact?.Type === 'Billing');
    return `${billingContact?.[0]?.Number ? billingContact?.[0]?.Number : ""}`;
  }
}
