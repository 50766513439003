import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig } from '../models/consumer-login-config';

@Component({
    selector: 'por-busy-indicator',
    templateUrl: './busy-indicator-component.component.html',
    styleUrls: ['./busy-indicator-component.component.scss']
})
export class BusyIndicatorComponentComponent implements OnInit {
    @Output()
    readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;

    ngOnInit(): void {
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
        }
    }

    setTemplate(templateName: string) {
        this.selectedTemplate.emit(templateName);
    }
}
