import { Language } from '@por/shared/core';

export const DEFAULT_LOCALE = Language.EN_US;

export const DEFAULT_CURRENCY = 'USD';

export const DEFAULT_GBP_CURRENCY = 'GBP';

export const MULTI_CONTRACT_MAX_SELECTION_ALLOWED = 50;

export const LOAD_MORE_DROP_DOWN_DEFAULT_VALUE = 25;

export const AVOID_LINE_ITEM_NAMES: string[] = ['insurance', 'collection', 'diesel', 'delivery', 'cc-fee'];
