import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractDetail, LineItems } from '../../models/contract-model';
import { RequestActionService } from '../../services/request-action.service';
import { RequestActionsToLoad } from '../../enums/request-actions.enum';
import { CallOffRequest } from '../../models/calloff-request.model';
import { ItemOutCP } from '../../models/item-out-model';
import { ModalService } from '../../services/modal.service';
import { SendLinkRequest } from '../../models/send-link-request';
import { LineItemClassification } from '../../enums/LineItemClassification.enum';

@Component({
    selector: 'por-selected-item-actions',
    templateUrl: './selected-item-actions.component.html',
    styleUrls: ['./selected-item-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedItemActionsComponent implements OnInit {
    @Input() lineItem!: LineItems;
    @Input() uiUrl: string | undefined;
    @Input() customerId!: string;
    @Input() contractDetail?: ContractDetail | null;
    @Output() readonly closePanel = new EventEmitter<boolean>(false);
    itemOutCP: ItemOutCP[] = [];
    sendLinkRequestData: SendLinkRequest = {} as SendLinkRequest;

    constructor(private readonly requestActionService: RequestActionService, private readonly modal: ModalService) {}
    enabledTab$ = this.requestActionService.activeTab$;

    ngOnInit(): void {
        /**
         * Ref: https://wiki.pointofrental.com/e/en/Departments/Engineering/Consumer-Portal/Quick-Link
         */
        this.sendLinkRequestData = {
            id: this.lineItem.StockId ?? this.lineItem.ProductId ?? '',
            contractId: this.lineItem.AdditionalFields.ContractId,
            itemName: this.lineItem.Name,
            serialNumber: this.lineItem.SerialNumber ?? '',
            customerId: this.customerId,
            lineItemId: this.lineItem.Id
        };
        this.itemOutCP.push({
            contractId: this.lineItem.AdditionalFields.ContractId,
            contractName: this.lineItem.AdditionalFields.ContractId,
            customerId: this.customerId,
            customerName: '',
            dueDate: this.lineItem.EndDateTime,
            id: this.lineItem.Id,
            info: '',
            itemName: this.lineItem.Name,
            itemsOut: this.lineItem.Status === 'Out' ? true : false,
            quantity: this.lineItem.Quantity,
            startDate: this.lineItem.StartDateTime,
            status: this.lineItem.Status,
            lineItemStatus: this.lineItem.Status,
            jobNumber: this.lineItem.AdditionalFields ? this.lineItem.AdditionalFields?.CustomerJobNumber : '',
            purchaseOrder: this.lineItem?.PONumber
        });
    }

    renderRequestReturn(): void {
        this.openRequestModal();
        this.requestActionService.setActiveTab(RequestActionsToLoad.RequestReturnView);
    }

    renderRequestService(): void {
        this.openRequestModal();
        this.requestActionService.setActiveTab(RequestActionsToLoad.RequestServiceView);
    }

    renderSendDocumentLink(): void {
        this.openRequestModal();
        this.requestActionService.setActiveTab(RequestActionsToLoad.SendDocumentsView);
    }

    handleRentalFormReturn(): void {
        this.closeRequestModal();
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }
    // below event is used as use as output decorator
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleRentalFormSubmission($event: CallOffRequest): void {
        this.closeRequestModal();
    }

    // below event can use as use as output decorator
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleSendDocumentFormSubmission($event: SendLinkRequest): void {
        this.closeRequestModal();
    }

    onCloseButtonClick(): void {
        this.closePanel.emit();
    }

    private openRequestModal(): void {
        this.modal.open('rental-request-page');
    }

    private closeRequestModal(): void {
        this.modal.close('rental-request-page');
    }

    isRental(classification: string): boolean {
        return classification === LineItemClassification.RENTAL;
    }
}
