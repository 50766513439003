import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Consumer } from '../../../../models/consumer-model';
@Component({
    selector: 'por-lists',
    templateUrl: './lists.component.html',
    styleUrls: ['./lists.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumersListsComponent {
    @Input() consumers: Consumer[] | null = [];
    @Input() noMoreRecord = false;
    @Output() readonly loadMore = new EventEmitter();
    @Output() readonly generateLink = new EventEmitter();

    generateQuickLink(id: string) {
        this.generateLink.emit(id);
    }
}
