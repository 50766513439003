import { LowerCasePipe } from '@angular/common';
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[porAddFeature]'
})
export class AddFeatureClassDirective implements OnInit {
    constructor(private readonly renderer: Renderer2, private readonly hostElement: ElementRef, private readonly lower: LowerCasePipe) {}

    @Input() featureName!: string;

    ngOnInit(): void {
        if (this.featureName) {
            this.renderer.setAttribute(this.hostElement.nativeElement, 'data-testid', `por-cp-${this.lower.transform(this.featureName).replace(/ /g, '-')}`);
        }
    }
}
