<div class="form-container por-signup">
    <h2 class="theme-header">{{ 'Register/Sign Up' | translate }}</h2>
    <form [formGroup]="signupForm" (ngSubmit)="signup()" novalidate>
        <apx-form-field
            label="Name"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="name.invalid && (name.dirty || name.touched) ? 
            name.errors?.['required'] ? ('PleaseEnterYourName' | translate) : 
            name.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" class="icon">
                <defs>
                    <clipPath id="clip-User-icon">
                        <rect width="20" height="20" />
                    </clipPath>
                </defs>
                <g id="User-icon" clip-path="url(#clip-User-icon)">
                    <g id="Layer_10" transform="translate(-1.731 -1)">
                        <circle id="Ellipse_36" data-name="Ellipse 36" cx="3.169" cy="3.169" r="3.169" transform="translate(8.305 4)" />
                        <path
                            id="Path_11071"
                            data-name="Path 11071"
                            d="M5.731,23.073V21.621a2.89,2.89,0,0,1,1.106-2.308,7.7,7.7,0,0,1,4.634-1.447,7.706,7.706,0,0,1,4.638,1.448,2.89,2.89,0,0,1,1.107,2.308v1.454A1.016,1.016,0,0,1,16.2,24.092H6.75a1.018,1.018,0,0,1-1.019-1.018Z"
                            transform="translate(0 -6.472)" />
                    </g>
                </g>
            </svg>
            <apx-input-group>
                <input
                    porAddFeature
                    featureName="signup-name-input"
                    apxInput
                    type="text"
                    formControlName="name"
                    autocomplete="on"
                    name="name"
                    placeholder="{{ 'EnterYourName' | translate }}"
                    class="theme-input" />
            </apx-input-group>
        </apx-form-field>
        <apx-form-field
            label="Company Name"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="companyName.invalid && (companyName.dirty || companyName.touched) ? 
            companyName.errors?.['required'] ? ('CompanyNameRequired' | translate) : 
            companyName.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" class="icon">
                <defs>
                    <clipPath id="clip-Company-icon">
                        <rect width="20" height="20" />
                    </clipPath>
                </defs>
                <g id="Company-icon" clip-path="url(#clip-Company-icon)">
                    <g id="building_1_" data-name="building (1)" transform="translate(0 1)">
                        <path
                            id="Path_11072"
                            data-name="Path 11072"
                            d="M5.425,2a.713.713,0,0,0-.713.713V15.542H4.356a.356.356,0,1,0,0,.713h3.92V14.117a.713.713,0,0,1,.713-.713h1.425a.713.713,0,0,1,.713.713v2.138h3.92a.356.356,0,0,0,0-.713h-.356V2.713A.713.713,0,0,0,13.978,2ZM7.207,4.138a.356.356,0,0,0-.356.356v.713a.356.356,0,0,0,.356.356H7.92a.356.356,0,0,0,.356-.356V4.495a.356.356,0,0,0-.356-.356ZM6.851,6.633a.356.356,0,0,1,.356-.356H7.92a.356.356,0,0,1,.356.356v.713A.356.356,0,0,1,7.92,7.7H7.207a.356.356,0,0,1-.356-.356Zm.356,1.782a.356.356,0,0,0-.356.356v.713a.356.356,0,0,0,.356.356H7.92a.356.356,0,0,0,.356-.356V8.771a.356.356,0,0,0-.356-.356Zm-.356,2.495a.356.356,0,0,1,.356-.356H7.92a.356.356,0,0,1,.356.356v.713a.356.356,0,0,1-.356.356H7.207a.356.356,0,0,1-.356-.356ZM9.346,4.138a.356.356,0,0,0-.356.356v.713a.356.356,0,0,0,.356.356h.713a.356.356,0,0,0,.356-.356V4.495a.356.356,0,0,0-.356-.356ZM8.989,6.633a.356.356,0,0,1,.356-.356h.713a.356.356,0,0,1,.356.356v.713a.356.356,0,0,1-.356.356H9.346a.356.356,0,0,1-.356-.356Zm.356,1.782a.356.356,0,0,0-.356.356v.713a.356.356,0,0,0,.356.356h.713a.356.356,0,0,0,.356-.356V8.771a.356.356,0,0,0-.356-.356Zm-.356,2.495a.356.356,0,0,1,.356-.356h.713a.356.356,0,0,1,.356.356v.713a.356.356,0,0,1-.356.356H9.346a.356.356,0,0,1-.356-.356Zm2.495-6.771a.356.356,0,0,0-.356.356v.713a.356.356,0,0,0,.356.356H12.2a.356.356,0,0,0,.356-.356V4.495a.356.356,0,0,0-.356-.356Zm-.356,2.495a.356.356,0,0,1,.356-.356H12.2a.356.356,0,0,1,.356.356v.713A.356.356,0,0,1,12.2,7.7h-.713a.356.356,0,0,1-.356-.356Zm.356,1.782a.356.356,0,0,0-.356.356v.713a.356.356,0,0,0,.356.356H12.2a.356.356,0,0,0,.356-.356V8.771a.356.356,0,0,0-.356-.356Zm-.356,2.495a.356.356,0,0,1,.356-.356H12.2a.356.356,0,0,1,.356.356v.713a.356.356,0,0,1-.356.356h-.713a.356.356,0,0,1-.356-.356Z"
                            fill-rule="evenodd" />
                        <path id="Path_11073" data-name="Path 11073" d="M12.425,21.138V19H11v2.138Z" transform="translate(-2.011 -4.883)" />
                    </g>
                </g>
            </svg>
            <apx-input-group>
                <input
                    porAddFeature
                    featureName="signup-company-name-input"
                    apxInput
                    type="text"
                    formControlName="companyName"
                    autocomplete="on"
                    name="companyName"
                    placeholder="{{ 'EnterYourCompanyName' | translate }}"
                    class="theme-input" />
            </apx-input-group>
        </apx-form-field>
        <div class="form-field">
            <label for="">{{ 'Phone Number' | translate }}</label>
            <por-intl-tel-input
                [cssClass]="'country-code'"
                [preferredCountries]="['us']"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [selectFirstCountry]="true"
                [maxLength]="15"
                [phoneValidation]="true"
                [separateDialCode]="true"
                formControlName="phoneNumber"
                porAddFeature
                featureName="signup-phone-number-input"
                [styleInput]="'phone-input-style'"></por-intl-tel-input>
        </div>
        <apx-form-field
            label="Email"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="username.invalid && (username.dirty || username.touched) ? 
            username.errors?.['required'] ? ('Please enter username.' | translate) : 
            username.errors?.['email'] ? ('Please enter valid email.' | translate) : 
            username.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="email"></span>
                <input
                    porAddFeature
                    featureName="signup-email-input"
                    apxInput
                    formControlName="username"
                    type="email"
                    autocomplete="on"
                    name="username"
                    placeholder="{{ 'Enter your email' | translate }}"
                    class="theme-input" />
            </apx-input-group>
        </apx-form-field>
        <apx-form-field
            label="Password"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="password.invalid && (password.dirty || password.touched) ? 
            password.errors?.['required'] ? ('Please enter password.' | translate) : 
            password.errors?.['minlength'] ? ('Password minimum length should be 6.' | translate) : 
            password.errors?.['maxlength'] ? ('max_50_chars_permitted' | translate):'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="lock"></span>
                <input
                    porAddFeature
                    featureName="signup-password-input"
                    apxInput
                    formControlName="password"
                    type="password"
                    name="password"
                    autocomplete="on"
                    placeholder="{{ 'Enter your password' | translate }}"
                    class="theme-input"
                    maxlength="50" />
            </apx-input-group>
        </apx-form-field>
        <button
            porAddFeature
            featureName="signup-signup-button"
            class="btn-primary btn-full-width theme-button"
            type="submit"
            apxButton
            variant="primary"
            [disabled]="signupForm.pristine || signupForm.invalid || isDisableButton">
            {{ 'Sign Up' | translate }}
        </button>
    </form>
    <p class="left-align theme-paragraph">
        {{ 'Already have an account?' | translate }}
        <a porAddFeature featureName="signup-signin-button" href="#" (click)="setTemplate('signin')" class="theme-anchor">{{ 'Sign in here' | translate }}</a>
    </p>
</div>
