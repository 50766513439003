import { Component, Input } from '@angular/core';

@Component({
    selector: 'por-base-mat-badge',
    templateUrl: './base-mat-badge.component.html',
    styleUrls: ['./base-mat-badge.component.scss']
})
export class BaseMatBadgeComponent {
    @Input() badgeContent: number | null = 0; // Default to 0
    @Input() isHidden = false;
    @Input() badgeColor: 'primary' | 'accent' | 'warn' = 'primary';
}
