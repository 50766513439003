import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService, ApiSendParams } from '../abstract/api.service';
import { ApiHeaders, ApiQuery } from '../models/api-query.model';
import { ApiResult } from '@por/shared/core';
import { Searchable } from '../models/searchable.model';
import { OrganizationConfig } from '../models/organization-config.model';
import { ApiRequest } from '../models/api-request.model';
import { LocalStorageKeys } from '../enums/local-storage-keys.enum';
import { DEFAULT_LOCALE } from '../constants/default.const';
import { LocalStorageService } from './local-storage.service';

/**
 * Specialized App-wide service
 * Provided in the App Module
 * Override provider as necessary to provide a specialized implementation
 * i.e. { provide: ApiService, useClass: OtherApiService }
 * */
@Injectable({ providedIn: 'root' })
export class ConsumerPortalApiService extends ApiService {
    defaultPageSize = 25;
    apiRoute = '';

    constructor(protected http: HttpClient, private readonly localStorageService: LocalStorageService, @Inject('Window') private readonly window: Window) {
        super();
    }

    get requestAuthHeaders() {
        let headers = {};
        const accessToken = this.localStorageService.getCurrentUser?.Auth?.accessToken ?? this.localStorageService.getCurrentUser?.Auth?.AccessToken;
        if (accessToken) {
            headers = {
                /* eslint-disable @typescript-eslint/naming-convention */
                /**
                 * Note camelCase: DB Modal
                 */
                Authorization: accessToken,
                OrganizationSubDomain: !this.window?.location?.href.includes('localhost') ? this.window?.location?.hostname : ''
            };
        }

        return headers;
    }

    get localStorageApiUrl() {
        return this.localStorageService.getCurrentUser?.apiUrl;
    }

    setConsumerPortalApiRoute(apiUrl?: string | undefined) {
        if (apiUrl) {
            this.apiRoute = apiUrl;
            return;
        }

        if (apiUrl) {
            this.apiRoute = this.localStorageApiUrl ?? '';
            return;
        }
    }

    // Requesting multiple entities:
    get<T extends Searchable>(controller: string, apiQuery?: ApiQuery): Observable<T[]> {
        const params: ApiSendParams = {
            verb: 'GET',
            controller,
            headers: apiQuery?.headers
        };
        return this.send<ApiResult<T>>(params).pipe(map((result: ApiResult<T>) => (result && result.Records ? result.Records : [])));
    }

    getData<T extends Searchable>(
        controller: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apiQuery: ApiQuery
    ): Observable<T[]> {
        const params: ApiSendParams = {
            verb: 'GET',
            controller
        };
        return this.send<ApiResult<T>>(params).pipe(map((result: ApiResult<T>) => (result && result.Records ? result.Records : [])));
    }

    // Requesting a single entity:
    getById<T extends Searchable>(
        controller: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apiQuery: ApiQuery
    ): Observable<T | null> {
        const params: ApiSendParams = {
            verb: 'GET',
            controller
        };
        return this.send<ApiResult<T>>(params).pipe(map((result: ApiResult<T>) => (result && result.Records && result.Records.length ? result.Records[0] : null)));
    }

    // Requesting a list of items by post endpoint using array of parameters i.e. menu items //TODO Review if necessary
    getByPost<T extends Searchable>(controller: string, method: string, body: string | null | JSON | ApiRequest): Observable<T[]> {
        const params: ApiSendParams = {
            verb: 'POST',
            controller,
            method,
            body
        };
        return this.send<ApiResult<T>>(params).pipe(map((result: ApiResult<T>) => (result && result.Records && result.Records.length ? (this.toCamelCase(result.Records) as T[]) : [])));
    }

    search<T extends Searchable>(controller: string, apiQuery: ApiQuery): Observable<ApiResult<T>> {
        const url = this.apiRoute + '/' + controller;
        return this.find(url, apiQuery);
    }

    add<T>(controller: string, method: string, entity: T): Observable<T> {
        return this.send({
            verb: 'POST',
            controller,
            method,
            body: entity
        }) as Observable<T>;
    }

    save<T extends Searchable>(controller: string, entity: T): Observable<T> {
        return this.send({
            verb: 'PUT',
            controller,
            method: null,
            body: entity
        }) as Observable<T>;
    }

    patch<T extends Searchable>(controller: string, apiQuery: ApiQuery, entity: T): Observable<T[]> {
        return this.send<ApiResult<T>>({
            verb: 'PATCH',
            controller,
            headers: apiQuery?.headers,
            body: entity
        }).pipe(map((result: ApiResult<T>) => (result && result.Records ? result.Records : [])));
    }

    /**
     * Used for getting general resources
     * @param url
     * @param params
     */
    protected find<T>(url: string, params: ApiQuery): Observable<T> {
        // Use JSON content:
        const headers = new HttpHeaders({
            ...params?.headers
        });

        return this.http.get<T>(url, { headers }).pipe(
            map((res: T) => {
                return res;
            }),
            catchError(error => {
                throw new Error(error.message);
            })
        );
    }

    /**
     * Used for saving/updating resources
     * @param verb
     * @param controller
     * @param method
     * @param params
     * @param body
     * @param headers
     */
    protected send<T>(args: ApiSendParams): Observable<T> {
        const { verb, controller, headers } = args;
        const headersToSend = new HttpHeaders({
            ...this.requestAuthHeaders,
            ...headers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'content-language': this.localStorageService.selectedContentLanguage ? this.localStorageService.selectedContentLanguage : DEFAULT_LOCALE
        });

        const { body } = args;
        try {
            // Create URL with route and query string parameters:
            const url = this.apiRoute + '/' + controller;

            // no such default case applied
            /* eslint-disable-next-line default-case */
            switch (verb) {
                case 'GET':
                    return this.http.get<T>(url, { headers: headersToSend }).pipe(map(res => res));
                case 'PUT':
                    return this.http.put<T>(url, body, { headers: headersToSend });
                case 'PATCH':
                    return this.http.patch<T>(url, body, { headers: headersToSend });
                case 'DELETE':
                    return this.http.delete<T>(url, { headers: headersToSend });
                case 'DELETE_WITH_BODY':
                    return this.http.request<T>('DELETE', url, {
                        headers: headersToSend,
                        body
                    });
                case 'POST':
                    return this.http.post<T>(url, body, { headers: headersToSend }).pipe(map(res => res));
            }
        } catch (ex) {
            throw new Error(ex as string);
        }
    }

    post<T>({ controller, method, body, headers, camelCase = true }: { controller: string; method: string; body: unknown; headers?: ApiHeaders; camelCase?: boolean }): Observable<T> {
        return this.send<ApiResult<T>>({
            verb: 'POST',
            controller,
            method,
            body,
            headers
        }).pipe(map((result: ApiResult<T>) => (result && result.Records && result.Records ? ((camelCase ? this.toCamelCase(result.Records) : result.Records) as T) : ([] as T))));
    }

    getUsers(email: string) {
        return this.get(`customer/find?email=${encodeURIComponent(email)}`, {
            headers: {
                ...this.requestAuthHeaders
            }
        });
    }

    getPublicOrganizationConfigurations(orgId: string): Observable<OrganizationConfig | null> {
        return this.get(`public/organizations/config/${orgId}`).pipe(
            map(res => {
                const result = res as OrganizationConfig;
                if (result.OrganizationName) {
                    this.localStorageService.setOrganizationName(result.OrganizationName);
                }
                this.localStorageService.setItem(LocalStorageKeys.ContentLang, result?.Locale ?? DEFAULT_LOCALE);
                return result;
            })
        );
    }

    getOrganizationConfiguration(orgId: string): Observable<OrganizationConfig | null> {
        return this.get(`organization-config/${orgId}`, {
            headers: {
                ...this.requestAuthHeaders
            }
        }).pipe(
            map(res => {
                return res as OrganizationConfig;
            })
        );
    }

    patchOrganizationConfiguration(orgId: string | number, organizationConfig: OrganizationConfig): Observable<OrganizationConfig | null> {
        return this.patch(
            `organization-config/${orgId}`,
            {
                headers: {
                    ...this.requestAuthHeaders
                }
            },
            organizationConfig
        ).pipe(
            map(res => {
                return res as OrganizationConfig;
            })
        );
    }
}
