import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationConfig } from '../../../../models/organization-config.model';
import { ConsumerPortalAdminConfig } from '../../../../models/consumer-portal-admin-config';

@Component({
    selector: 'por-admin-header',
    templateUrl: './admin-header.component.html',
    styleUrls: ['./admin-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminHeaderComponent {
    @Input() config!: OrganizationConfig;
    @Input() adminConfig!: ConsumerPortalAdminConfig;
    @Input() showTabs = true;
}
