<h2>{{ 'adminWysiwyg' | translate }}</h2>
<div class="porHeader wrapper por-ac-header" [ngClass]="{ 'basic-header': versionService.isBasicVersion() }">
    <a href="javascript:void(0)" class="logo">
        <img class="header-logo" [src]="logoUrl || adminConfig.header?.logoUrl" />
    </a>
    <div class="cf sectnav mobile-responsive" *ngIf="versionService.isAdvanceVersion()">
        <ul class="por-menu">
            <li *ngFor="let url of navigations" class="navigations theme-list-item">
                <por-base-button [ngClass]="{ 'is-active': url?.isActive }" [theme]="url?.isActive ? 'primary' : 'secondary'" [featureName]="url?.text || ''">
                    <span>{{ url?.text | uppercase }}</span>
                </por-base-button>
            </li>
        </ul>
    </div>
    <div class="person-icon">
        <img src="{{ adminConfig.uiUrl }}/assets/images/CP_Profile icon_ final.svg" />
    </div>
</div>
<div class="summary-section" *ngIf="versionService.isAdvanceVersion()">
    <div class="bg-secondary">
        <h3 class="theme-heading color-primary">4</h3>
        <span class="theme-paragraph">{{ 'Quotes' | translate }}</span>
    </div>
    <div class="bg-secondary">
        <h3 class="theme-heading color-primary">4</h3>
        <span class="theme-paragraph">{{ 'Open' | translate }}</span>
    </div>
    <div class="bg-secondary">
        <h3 class="theme-heading color-primary">1</h3>
        <span class="theme-paragraph">{{ 'Reservations' | translate }}</span>
    </div>
    <div class="bg-secondary">
        <h3 class="theme-heading color-primary">1</h3>
        <span class="theme-paragraph">{{ 'Returned' | translate }}</span>
    </div>
</div>
<div class="basic-version-section" *ngIf="versionService.isBasicVersion()">
    <div class="btns">
        <button
            class="split-button theme-button text-button-primary-text border-button-primary-border bg-button-primary-default focus:bg-button-primary-hover cursor-pointer hover:bg-button-primary-hover flex text-button font-roboto font-medium justify-center items-center px-sm py-xs focus:outline-none border border-solid rounded-btn h-btn bg-primary border-primary"
            [disabled]="true">
            <span class="actions-image">
                <svg
                    class="fill-current"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    style="enable-background: new 0 0 100 100"
                    xml:space="preserve">
                    <g id="credit-card-payment" transform="translate(3 2)">
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path id="Path_11200-2" d="M77.1,36.8c1.2,0,2.3,0.2,3.4,0.5l9.8-9.8l-4.6-4.6L71.9,36.8H77.1z" />
                        </g>
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path
                                id="Path_11201-2"
                                d="M96.9,34.1l-2.5-2.5l-8.9,8.9c2,2.1,3.1,4.9,3.1,7.8v2.3l8.3-8.3C99.1,40,99.1,36.3,96.9,34.1
                            C96.9,34.1,96.9,34.1,96.9,34.1z" />
                        </g>
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path id="Path_11202-2" d="M81.7,18.8L65.2,2.4c-2.2-2.2-5.9-2.2-8.1,0c0,0,0,0,0,0L22.7,36.8h41L81.7,18.8z" />
                        </g>
                        <path id="Path_11203" d="M12.6,58.7h8.8v6.5h-8.8V58.7z" />
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path
                                id="Path_11204-2"
                                d="M77.1,42.5H6.2c-3.2,0-5.7,2.6-5.7,5.7V93c0,3.2,2.6,5.7,5.7,5.7c0,0,0,0,0,0h70.8
                            c3.2,0,5.7-2.6,5.7-5.7l0,0V48.2C82.8,45.1,80.2,42.5,77.1,42.5z M9.8,55h20.2v18H9.8V55z M21,86.3H9.7v-5.7H21V86.3z M38.6,86.3
                            H27.2v-5.7h11.4V86.3z M56.1,86.3H44.8v-5.7h11.3V86.3z M73.6,86.3H62.3v-5.7h11.4L73.6,86.3z" />
                        </g>
                    </g>
                </svg>
            </span>
            <span class="line-splitter">{{ 'Make a payment' | translate }}</span>
        </button>
        <button
            class="theme-button text-button-primary-text border-button-primary-border bg-button-primary-default focus:bg-button-primary-hover cursor-pointer hover:bg-button-primary-hover flex text-button font-roboto font-medium justify-center items-center px-sm py-xs focus:outline-none border border-solid rounded-btn h-btn bg-secondary border-secondary"
            [disabled]="true">
            {{ 'Load More' | translate }}
        </button>
    </div>
</div>
