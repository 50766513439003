import { setLoading } from '../actions/loder.actions';
import { ReducerType, initialState } from '../state/app.state';
import { createReducer, on } from '@ngrx/store';

export const loaderReducer: ReducerType = createReducer(
    initialState,
    on(setLoading, (state, { isLoading }) => {
        return { ...state, isLoading };
    })
);
