import { ConsumerPortalAdminConfig } from '../lib/models/consumer-portal-admin-config';

export const consumerPortalAdminDefaults: ConsumerPortalAdminConfig = {
    organizationId: '',
    organizationType: 'expert',
    styling: {
        themeColors: {
            primaryColor: '#000000',
            secondaryColor: '#83BE42'
        }
    },
    header: {
        logoUrl: '/assets/images/ecom-default-logo.svg'
    },
    apiUrl: 'https://dev-consumer-portal-api.rentamation.com/v1',
    uiUrl: 'https://dev-consumer-portal-ui.rentamation.com'
};
