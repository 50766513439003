import { createReducer, on } from '@ngrx/store';
import { paymentInitialState, PaymentState } from './payment.state';
import { continueMultipayment, setPaymentOpen } from '../contract/contract.actions';
import {
    crossAppEventEmitted,
    failCreateTransaction,
    failLoadPaymentBoundaries,
    failVerifyPayment,
    loadPaymentProcessorAvailabilityFailure,
    loadPaymentProcessorAvailabilitySuccess,
    onPaymentFinished,
    setConfigData,
    setDialogEnabled,
    setMultiPaymentBoundaries,
    setPaymentAppInput,
    setPaymentSectionEnabled,
    setTransactionLoaded,
    successVerifyPayment
} from './payment.actions';

export const paymentReducer = createReducer(
    paymentInitialState,
    on(continueMultipayment, (state: PaymentState) => {
        return { ...state, ...paymentInitialState };
    }),
    on(setMultiPaymentBoundaries, (state: PaymentState, { paymentBoundaries }) => {
        return { ...state, paymentBoundaries };
    }),
    on(setConfigData, (state: PaymentState, { config }) => {
        return { ...state, customerId: config?.customerId, organizationId: config?.organizationId };
    }),
    on(setTransactionLoaded, (state: PaymentState, { transactionLoaded }) => {
        return { ...state, transactionLoaded };
    }),
    on(setPaymentAppInput, (state: PaymentState, { paymentAppInput }) => {
        return { ...state, paymentAppInput };
    }),
    on(onPaymentFinished, (state: PaymentState, { paymentAppOutput }) => {
        return { ...state, paymentAppOutput };
    }),
    on(setDialogEnabled, (state: PaymentState, { dialogEnabled }) => {
        return { ...state, dialogEnabled };
    }),
    on(failLoadPaymentBoundaries, (state: PaymentState, { error }) => {
        return { ...state, error };
    }),
    on(failCreateTransaction, (state: PaymentState, { error }) => {
        return { ...state, error };
    }),
    on(setPaymentOpen, (state: PaymentState, { paymentOpen }) => {
        return { ...state, dialogEnabled: paymentOpen };
    }),
    on(successVerifyPayment, (state: PaymentState, { lastPaymentDetail }) => {
        return { ...state, lastPaymentDetail };
    }),
    on(setPaymentSectionEnabled, (state: PaymentState, { enabled }) => {
        return { ...state, ...paymentInitialState, paymentSectionEnabled: enabled };
    }),
    on(crossAppEventEmitted, (state, { event }) => ({
        ...state,
        crossAppEvent: event
    })),
    on(failVerifyPayment, (state: PaymentState, { error }) => {
        return { ...state, error };
    }),
    on(loadPaymentProcessorAvailabilitySuccess, (state, { processors }) => ({
        ...state,
        isPaymentProcessorAvailable: processors
    })),
    on(loadPaymentProcessorAvailabilityFailure, state => ({
        ...state,
        isPaymentProcessorAvailable: false
    }))
);
