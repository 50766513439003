import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestActionsToLoad } from '../enums/request-actions.enum';

@Injectable({
    providedIn: 'root'
})
export class RequestActionService {
    private readonly activeTab = new BehaviorSubject(RequestActionsToLoad.DefaultView);
    activeTab$ = this.activeTab.asObservable();

    setActiveTab(tab: RequestActionsToLoad) {
        this.activeTab.next(tab);
    }
}
