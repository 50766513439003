<div class="main-dnd">
    <h3 class="left-section-title">{{ title }}</h3>
    <div class="wrapper-dnd">
        <div class="left">
            <por-drag-n-drop (fileDeleted)="handleFileDelete()" (uploadStarted)="uploading$.next(true)" (uploadSuccess)="handleFile($any($event))"></por-drag-n-drop>
        </div>
        <div class="right">
            <p *ngIf="uiTitle">{{ uiTitle }}</p>
            <div class="loader" *ngIf="(uploading$ | async) === true; else image"></div>
            <ng-template #image>
                <p class="new-image-para" *ngIf="imageRendering">{{ 'loadingNewImage' | translate }}</p>
                <div class="img-container" [ngClass]="{ banners: allowDelete }" *ngIf="currentImage" [ngStyle]="{ height: previewDimension.height, width: previewDimension.width }">
                    <img src="{{ currentImage }}" (load)="handleImageRender()" (error)="handleImageRender()" />
                </div>
                <por-base-button *ngIf="currentImage && allowDelete" (click)="clearFile()" [featureName]="'admin-ad-delete-button-data'">
                    {{ 'Remove' | translate }}
                </por-base-button>
            </ng-template>
        </div>
    </div>
</div>
