import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacadeService } from '../../../services/app-facade.service';
import { QuickLinkService } from '../../../services/quicklink.service';
import { ConfigurationService } from '../../../services/configuration.service';
import {
    redirectionFailure,
    redirectionSuccess,
    startRedirection,
    setRedirectionLoginSession,
    redirectToConsumerPortal,
    redirectToSubdomain,
    redirectConfigSet,
    redirectToPayment,
    processRoute
} from './redirection.actions';
import { ComponentsToLoad } from '../../../enums/components-to-load.enum';
import { QuickLink } from '../../../models/quicklink.model';
import { LoginSession } from '../../../models/login-session.model';
import { LoggerService } from '../../../services/logger.service';
import { ErrorType } from '../../../models/error.model';
import { ErrorService } from '../../../services/error.service';
import { setQuickLinkData } from '../../domains/quicklink/quicklink.actions';
import { ConsumerPortalConfig } from '../../../models/consumer-portal-config';
import { AuthKeys } from '../../../enums/local-storage-keys.enum';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { Action } from '@ngrx/store';
import { setConfig } from '../config/config.actions';

@Injectable()
export class RedirectionEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly appFacadeService: AppFacadeService,
        private readonly quickLinkService: QuickLinkService,
        private readonly configService: ConfigurationService,
        private readonly logger: LoggerService,
        private readonly errorService: ErrorService,
        private readonly appMediatorService: AppMediatorService
    ) {}

    startRedirection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startRedirection),
            tap(() => this.appFacadeService.setLoading(true)),
            tap(() => this.appMediatorService.localStorageService.removeSessionItem(AuthKeys.User)), // Clear session storage, so that new data get available on interceptor
            switchMap(({ redirection, cpInput }) => {
                if (redirection.toLoad !== ComponentsToLoad.QuickLink) {
                    return of(redirectionSuccess({ redirection, cpInput }));
                }

                // Handle QuickLink
                return this.quickLinkService.getConsumerData().pipe(
                    concatMap(quicklinkData => {
                        const authUser = quicklinkData as unknown as QuickLink;
                        // Set Consumer Portal Configurations
                        cpInput = this.configService.consolidateConsumerPortalConfig(undefined, {
                            ...cpInput,
                            // Note camelCase: DB Model
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Auth: authUser,
                            customerId: authUser?.CustomerId,
                            contractId: authUser.ContractId,
                            organizationId: authUser?.OrganizationId,
                            organizationType: authUser.OrganizationType
                        });

                        // Dispatch multiple actions as a flattened stream
                        return [
                            setQuickLinkData({ quickLinkData: authUser }),
                            setConfig({ config: cpInput }),
                            setRedirectionLoginSession({
                                session: {
                                    ...authUser,
                                    customerId: authUser.CustomerId,
                                    initialLogin: true
                                } as LoginSession
                            }),
                            redirectionSuccess({
                                redirection: {
                                    ...redirection,
                                    toLoad: ComponentsToLoad.ConsumerPortal,
                                    orgId: authUser.OrganizationId
                                },
                                cpInput
                            })
                        ];
                    }),
                    catchError(() => of(redirectionFailure({ error: 'FailedExtractQuickLink' })))
                );
            })
        )
    );

    redirectionSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(redirectionSuccess),
            switchMap(({ redirection, cpInput }) => {
                // Routed to subdomain
                if (redirection.orgId === '' && redirection.toLoad === ComponentsToLoad.Subdomain) {
                    return of(redirectToSubdomain());
                }

                if (redirection.orgId === '' && redirection.toLoad === ComponentsToLoad.ConsumerPortal) {
                    return of(redirectToConsumerPortal());
                }

                if (redirection.orgId && redirection.toLoad === ComponentsToLoad.Payment) {
                    return of(redirectToPayment());
                }

                // Routed to consumer portal, after getting orgId from subdomain
                return this.configService.getCpOrgConfig({ ...cpInput, organizationId: redirection.orgId }).pipe(
                    concatMap(orgConfig => {
                        // Update OrgConfig
                        const config: ConsumerPortalConfig = this.configService.consolidateConsumerPortalConfig(undefined, { ...cpInput, ...orgConfig });
                        return [setConfig({ config }), redirectConfigSet({ isConfigSet: true }), redirectToConsumerPortal()];
                    }),
                    catchError(error => {
                        // If org config not found in the database, then use default and redirect to consumer portal
                        this.logger.logError(error);
                        this.logger.alertDevError(error);
                        this.errorService.setError(ErrorType.InvalidSubDomainOrgId);
                        return of(redirectToConsumerPortal());
                    })
                );
            })
        )
    );

    processRoute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processRoute),
            mergeMap(({ cpInput, orgId }) => {
                if ((cpInput?.routeTo?.route as ComponentsToLoad) !== ComponentsToLoad.Payment) {
                    // Call the service methods which perform the dispatching internally
                    this.appFacadeService.getloadVersion(orgId);
                    this.appFacadeService.loadLinkAndOpen();
                    this.appFacadeService.loadEsignAndOpen();

                    // Conditionally dispatch other actions
                    if (cpInput?.routeTo?.route && Object.values(ComponentsToLoad).includes(cpInput?.routeTo.route as ComponentsToLoad)) {
                        this.appFacadeService.updateLocation(cpInput?.routeTo.route as ComponentsToLoad);

                        if (cpInput.routeTo.route === ComponentsToLoad.Contracts && cpInput?.routeTo?.routeParams && typeof cpInput?.routeTo?.routeParams === 'string') {
                            this.appFacadeService.setActiveTab(cpInput?.routeTo.route as ComponentsToLoad);
                            this.appFacadeService.openContract(cpInput.routeTo?.routeParams);
                        }
                    }

                    // Return a stream of actions to dispatch (if necessary)
                    return EMPTY as Observable<Action>;
                }

                // If no actions need to be dispatched, return EMPTY
                return of();
            })
        )
    );
}
