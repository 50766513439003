import { Injectable } from '@angular/core';
import { AuthKeys, LocalStorageKeys } from '../enums/local-storage-keys.enum';
import { ConsumerPortalConfig } from '../models/consumer-portal-config';
import { ConsumerPortalAdminConfig } from '../models/consumer-portal-admin-config';
import { QuickLinkService } from './quicklink.service';
import { AppDataConfig } from '../models/app-data-config';
import { DEFAULT_LOCALE } from '../constants/default.const';
import { Language } from '@por/shared/core';
import { OrganizationType } from '../models/organization-type';

@Injectable({
    providedIn: 'root'
})
export abstract class LocalStorageService {
    setItem(key: string, value: unknown) {
        // Save QuickLink data in session storage
        if (QuickLinkService.isQuickLink) {
            sessionStorage.setItem(key, JSON.stringify(value));
            return;
        }

        localStorage.setItem(key, JSON.stringify(value));
    }
    getItem<T extends string | object | null>(key: string): T | null | undefined {
        let value = localStorage.getItem(key);
        if (QuickLinkService.isQuickLink) {
            value = sessionStorage.getItem(key);
        }

        if (value && value !== 'undefined') {
            return JSON.parse(value) as T;
        }
        return null;
    }
    removeItem(key: string, isConsumerQuickLink: boolean) {
        if (QuickLinkService.isQuickLink || isConsumerQuickLink) {
            sessionStorage.removeItem(key);
            return;
        }

        localStorage.removeItem(key);
    }
    clearAll() {
        if (QuickLinkService.isQuickLink) {
            sessionStorage.clear();
            return;
        }

        localStorage.clear();
    }
    // set Consumer Portal Config to the local storage under 'user' top level key
    persistUser<T>(type: AuthKeys, value: T): void {
        this.setItem(type, value);
    }
    get selectedContentLanguage(): string {
        let storedLang = this.getItem<string>(LocalStorageKeys.ContentLang);
        if (this.getAppData?.organizationType === OrganizationType.SYRINX && DEFAULT_LOCALE === null) {
            storedLang = Language.EN_GB;
        }
        return storedLang !== null && storedLang !== undefined ? storedLang : DEFAULT_LOCALE;
    }

    get getOrganizationType(): string {
        return this.getAppData?.organizationType ?? '';
    }
    get getOrganizationName(): string {
        return this.getAppData?.organizationName ?? '';
    }
    get getCurrentUser() {
        return this.getItem<ConsumerPortalConfig | ConsumerPortalAdminConfig>(AuthKeys.User);
    }
    get getAppData() {
        return this.getItem<AppDataConfig>(AuthKeys.AppData);
    }
    setOrganizationType(orgType: string) {
        const user = this.getCurrentUser;
        const updatedUserWithOrgType = {
            ...user,
            organizationType: orgType
        };
        this.persistUser(AuthKeys.AppData, updatedUserWithOrgType);
    }
    setOrganizationName(orgName: string) {
        const appData = this.getAppData;
        const updatedAppData = {
            ...appData,
            organizationName: orgName
        };
        this.persistUser(AuthKeys.AppData, updatedAppData);
    }
    cleanItemsStartWith(prefix: string) {
        Object.keys(localStorage)
            .filter(x => x.startsWith(prefix))
            .forEach(x => {
                if (QuickLinkService.isQuickLink) {
                    sessionStorage.removeItem(x);
                    return;
                }

                localStorage.removeItem(x);
            });
    }
    /**
     * To get Sesssion Storage Items directly without checking for quicklink, this method implemented
     * to get/check that session storage have secured pdf url or not
     * @param key
     * @returns
     */
    getSessionStorageItem<T extends string | object | null>(key: string): T | null | undefined {
        const value = sessionStorage.getItem(key);

        if (value && value !== 'undefined') {
            return JSON.parse(value) as T;
        }
        return null;
    }

    removeSessionItem(key: string) {
        sessionStorage.removeItem(key);
    }
}
