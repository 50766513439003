import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MULTI_CONTRACT_MAX_SELECTION_ALLOWED } from '../../../constants/default.const';
import { ContractFilterStatus } from '../../../enums/contract-filter-status.enum';
import { ContractCP } from '../../../models/contract-model';
import { isCreditContract } from '../../../utils/contract-utils';
import { selectRouteParamsTotal } from '../../app-wide/config/config.selectors';
import { ContractState, contractsFeatureKey } from './contract.reducers';

export const contractSelector = createFeatureSelector<ContractState>(contractsFeatureKey);

export const getPageNumber = createSelector(contractSelector, (state: ContractState) => state.pageNumber);

export const getContracts = createSelector(contractSelector, (state: ContractState) => state.contracts.filter(contract => contract.latestToShow));

export const getNoMoreRecord = createSelector(contractSelector, (state: ContractState) => state.noMoreRecord);

export const getSelected = createSelector(contractSelector, (state: ContractState) => state.selectedContractId);

export const getSelectedContracts = createSelector(contractSelector, (state: ContractState) => state.selectedContracts);

export const getPaymentOpen = createSelector(contractSelector, (state: ContractState) => state.paymentOpen);

export const getSelectedContractsModels = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) =>
    state.contracts.filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
);

export const getContractsTotal = createSelector(contractSelector, getSelectedContracts, selectRouteParamsTotal, (state: ContractState, selectedContracts: string[], routeParamTotal) => {
    let total = 0;
    if (routeParamTotal) {
        total = routeParamTotal;
    } else {
        state.contracts
            .filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
            .forEach((contract: ContractCP) => {
                if (isCreditContract(contract)) {
                    total -= Math.abs(Number(contract.AmountDue));
                } else {
                    total += Number(contract.AmountDue);
                }
            });
    }

    return Math.round((total + Number.EPSILON) * 100) / 100;
});

export const getCreditContractsTotal = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) => {
    let total = 0;

    state.contracts
        .filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
        .forEach((contract: ContractCP) => {
            if (isCreditContract(contract)) {
                total -= Math.abs(Number(contract.AmountDue));
            }
        });

    return Math.round((total + Number.EPSILON) * 100) / 100;
});

export const getSelectedContractsTotal = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) => {
    let total = 0;

    state.contracts
        .filter((contract: ContractCP) => selectedContracts.includes(contract.contractId))
        .forEach((contract: ContractCP) => {
            if (!isCreditContract(contract)) {
                total += Number(contract.AmountDue);
            }
        });

    return Math.round((total + Number.EPSILON) * 100) / 100;
});

export const getSelectedCreditContracts = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) =>
    state.contracts.filter((contract: ContractCP) => selectedContracts.includes(contract.contractId) && isCreditContract(contract))
);

export const getSelectedNonCreditContracts = createSelector(contractSelector, getSelectedContracts, (state: ContractState, selectedContracts: string[]) =>
    state.contracts.filter((contract: ContractCP) => selectedContracts.includes(contract.contractId) && !isCreditContract(contract))
);

export const isContractSelected = (contractId: string) =>
    createSelector(getSelectedContracts, (selectedContracts: string[]) => {
        return selectedContracts.includes(contractId);
    });

export const isContractSelectable = (contractId: string) =>
    createSelector(contractSelector, getSelectedContracts, isContractSelected(contractId), (state: ContractState, selectedContracts: string[], isSelected: boolean) => {
        const selectingContract = state.contracts.find((contract: ContractCP) => contract.contractId === contractId);

        // Allow already selected contracts to remain selectable
        if (isSelected) {
            return true;
        }
        if (selectedContracts.length === MULTI_CONTRACT_MAX_SELECTION_ALLOWED) {
            return false;
        }
        if (Number(selectingContract?.AmountDue) === 0) {
            return false;
        }

        if (selectingContract?.status !== ContractFilterStatus.Closed) {
            return false;
        }

        if (selectingContract?.paymentPending) {
            return false;
        }

        return true;
    });

export const selectContractById = (contractId: string) => createSelector(getContracts, (contracts: ContractCP[]) => contracts.find(contract => contract.contractId === contractId));

export const selectTotalPaidByContractId = (contractId: string) =>
    createSelector(selectContractById(contractId), (contract: ContractCP | undefined) => {
        if (contract) {
            const grandTotal: string | undefined = contract.GrandTotal;
            const amountDue = contract.AmountDue;

            if (typeof grandTotal !== 'number' || typeof amountDue !== 'number') {
                return '';
            }

            const totalPaid: number = grandTotal - amountDue;
            return totalPaid;
        }
        return '';
    });

export const selectContractError = createSelector(contractSelector, (state: ContractState) => state.error);

export const selectContractDetail = createSelector(contractSelector, getSelected, (state: ContractState, selectedContractId) => {
    if (selectedContractId && state.contractDetail && state.contractDetail.Id === selectedContractId) {
        return state.contractDetail;
    }
    return null;
});

export const isMaxContractSelected = createSelector(getSelectedContracts, (selectedContracts: string[]) => {
    return selectedContracts.length === MULTI_CONTRACT_MAX_SELECTION_ALLOWED;
});
