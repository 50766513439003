import { FormBuilder } from '@angular/forms';
import { Component, Output, EventEmitter, OnDestroy, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { AppFormValidators } from '../../admin/validators/app.validators';
import { RequestActionService } from '../../services/request-action.service';
import { RequestActionsToLoad } from '../../enums/request-actions.enum';
import { SendLinkRequest, SendLinkResponse, UtilityRequestType } from '../../models/send-link-request';
import { Subscription, take } from 'rxjs';
import { AppFacadeService } from '../../services/app-facade.service';
import { LoggerService, NotificationService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../services/utility.service';
import { AppMediatorService } from '../../services/app-mediator.service';
import { isEmpty } from 'lodash';
import { IntlTelInputComponent } from '../../shared/components/intl-tel-input/intl-tel-input.component';

@Component({
    selector: 'por-send-document-link',
    templateUrl: './send-document-link.component.html',
    styleUrls: ['./send-document-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendDocumentComponent implements OnDestroy {
    constructor(
        private readonly appFacadeService: AppFacadeService,
        private readonly fb: FormBuilder,
        private readonly notification: NotificationService,
        private readonly translateService: TranslateService,
        private readonly requestActionService: RequestActionService,
        private readonly utilityService: UtilityService,
        private readonly appMediatorService: AppMediatorService,
        private readonly logger: LoggerService
    ) {}

    @Input() sendLinkRequestData!: SendLinkRequest;

    @Output()
    readonly isFormReturn: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    readonly sendLinkRequestInfo: EventEmitter<SendLinkRequest> = new EventEmitter<SendLinkRequest>();

    @ViewChild(IntlTelInputComponent, { read: ElementRef }) intlTelInput!: ElementRef;
    sendForm = this.fb.group(
        {
            email: ['', [AppFormValidators.emailValidator]],
            phone: ['', [AppFormValidators.phoneValidatorInternational]]
        },
        { validator: AppFormValidators.atLeastOneRequired(['email', 'phone']) }
    );

    subscriptions: Subscription[] = [];

    get email() {
        return this.sendForm.get('email');
    }

    get phone() {
        return this.sendForm.get('phone');
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    sendDocumentLinks() {
        const emailControl = this.sendForm.get('email');
        const phoneControl = this.sendForm.get('phone');

        if (!emailControl?.value && !phoneControl?.value) {
            this.notification.error(this.translateService.instant('pleaseFillRequired'));
            this.appFacadeService.setLoading(false);
            return false;
        }

        const requestTypes: UtilityRequestType[] = [];

        if (!isEmpty(this.sendForm.value.email)) {
            requestTypes.push(UtilityRequestType.Email);
        }
        if (!isEmpty(this.sendForm.value.phone)) {
            requestTypes.push(UtilityRequestType.SMS);
        }

        let phoneNumber = '';
        if (!isEmpty(this.sendForm.value.phone)) {
            const phoneValue = this.sendForm.value.phone;
            phoneNumber = phoneValue?.e164Number || phoneValue?.number || '';
        }

        if (requestTypes.length > 0 && this.sendForm.valid) {
            this.sendLink(requestTypes, phoneNumber);
        }
        return true;
    }

    sendLink(types: UtilityRequestType[], phoneValue: string) {
        this.appFacadeService.setLoading(true);
        this.subscriptions.push(
            this.utilityService
                .send({
                    email: this.sendForm.value?.email,
                    phone: phoneValue,
                    id: this.sendLinkRequestData.id,
                    contractId: this.sendLinkRequestData.contractId,
                    itemName: this.sendLinkRequestData.itemName,
                    serialNumber: this.sendLinkRequestData.serialNumber,
                    organizationName: this.appMediatorService.localStorageService.getOrganizationName,
                    customerId: this.sendLinkRequestData.customerId ?? this.appMediatorService.localStorageService.getCurrentUser?.customerId ?? '',
                    types: types,
                    lineItemId: this.sendLinkRequestData.lineItemId
                })
                .pipe(take(1))
                .subscribe({
                    next: (res: SendLinkResponse) => {
                        this.appFacadeService.setLoading(false);
                        this.notification.success(this.translateService.instant('DocumentLinkSubmitted'));
                        this.sendForm.reset();
                        this.sendLinkRequestInfo.emit(res.sendLinkRequest);
                    },
                    error: err => {
                        this.appFacadeService.setLoading(false);
                        this.logger.alertDevError(err);
                        this.notification.error(this.translateService.instant('somethingWentWrong'));
                    }
                })
        );
    }

    return() {
        this.isFormReturn.emit(true);
        this.sendForm.reset();
    }

    handleRentalFormReturn() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }
}
