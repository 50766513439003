import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { IBaseModel } from '../../interfaces/ibase.model';

/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: DB attributes
export abstract class BaseEntity implements IBaseModel {
    @PrimaryGeneratedColumn()
    Id!: number;

    @Column({ default: false })
    Hidden!: boolean;
  
    @Column({ default: false })
    Disabled!: boolean;
  
    @Column({ default: false })
    Archived!: boolean;
  
    @Column()
    CreatedDateTime?: Date | undefined;
  
    @Column()
    UpdatedDateTime?: Date | undefined;
}
