import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsumerPortalAdminConfig } from '../../../../models/consumer-portal-admin-config';
import { AppMediatorService } from '../../../../services/app-mediator.service';

@Component({
    selector: 'por-admin-overview',
    templateUrl: './admin-overview.component.html',
    styleUrls: ['./admin-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminOverviewComponent implements OnInit {
    @Input() adminConfig!: ConsumerPortalAdminConfig;

    constructor(private readonly translateService: TranslateService, private readonly appMediatorService: AppMediatorService) {}

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
    }
}
