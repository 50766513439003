import { map, take, tap } from 'rxjs/operators';
import { Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Component, Input, OnDestroy, OnInit, Inject, LOCALE_ID, Output, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppFacadeService } from '../../services/app-facade.service';
import { NotificationService } from '../../services/notification.service';
import { ItemOutService } from '../../services/item-out.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services';
import { CallOffRequestResponse, ItemOutCP } from '../../models/item-out-model';
import { RequestActionService } from '../../services/request-action.service';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { Customer } from '../../models/consumer';
import { getCustomer } from '../../store/selector/customer.selectors';
import { AuthUiService } from '../../services/auth-ui.service';
import { CallOffRequest } from '../../models/calloff-request.model';
import { ReturnRequestType } from '../../enums/return-request-type.enum';
import { AppFormValidators } from '../../admin/validators/app.validators';
import { AppMediatorService } from '../../services/app-mediator.service';
import { NgxMatTimepickerComponent } from 'ngx-mat-timepicker';
import { ContractDetail } from '../../models/contract-model';
import { FormatAddressPipe } from '../../shared/pipes/format-address.pipe';
import { DialogContentType } from '../../enums/dialog-type.enum';

@Component({
    selector: 'por-service-request',
    templateUrl: './service-request.component.html',
    styleUrls: ['./service-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRequestComponent implements OnInit, OnDestroy {
    dateFormat: string;
    isStepValid = false;
    minDate = new Date(); // Set it to the current date to disable past dates.
    @Input() uiUrl: string | undefined;

    @Output()
    readonly isFormReturn: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    readonly callOffRequestInfo: EventEmitter<CallOffRequest> = new EventEmitter<CallOffRequest>();
    @ViewChild('timepicker') timepicker: NgxMatTimepickerComponent | undefined;
    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly appFacadeService: AppFacadeService,
        private readonly store: Store,
        private readonly itemOutService: ItemOutService,
        private readonly notification: NotificationService,
        private readonly translateService: TranslateService,
        private readonly logger: LoggerService,
        private readonly requestActionService: RequestActionService,
        private readonly appMediatorService: AppMediatorService,
        private readonly auth: AuthUiService,
        private readonly fb: FormBuilder,
        private readonly formatAddressPipe: FormatAddressPipe
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    enabledTab$ = this.requestActionService.activeTab$;
    returnForm = this.fb.group({
        customerName: ['', [Validators.required, Validators.maxLength(255)]],
        customerPhone: ['', [Validators.required, Validators.maxLength(30)]],
        email: ['', [Validators.required, AppFormValidators.emailValidator, Validators.maxLength(255)]],
        jobSiteName: ['', [Validators.required, Validators.maxLength(255)]],
        jobSitePhone: ['', [Validators.required, Validators.maxLength(30)]],
        requestedDate: ['', [Validators.required, AppFormValidators.futureDateValidator]],
        requestedTime: ['', [Validators.required]],
        gateCombo: ['', [Validators.nullValidator, Validators.maxLength(255)]],
        address: ['', [Validators.required, Validators.maxLength(255)]],
        comments: ['', [Validators.nullValidator]]
    });
    subscriptions: Subscription[] = [];
    @Input() items!: ItemOutCP[];
    @Input() customerId!: string;
    @Input() contractDetail?: ContractDetail | null;
    customer$!: Observable<Customer>;
    customerInfo!: Customer | null;
    currentTime!: string;
    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        this.returnForm.setValue({
            customerName: '',
            customerPhone: '',
            email: '',
            jobSiteName: '',
            jobSitePhone: '',
            requestedDate: '',
            requestedTime: '',
            gateCombo: '',
            address: this.getDeliveryAddress(),
            comments: ''
        });
        this.customer$ = this.store.pipe(select(getCustomer));

        this.subscriptions.push(
            this.customer$
                .pipe(
                    take(1),
                    tap(customer => {
                        this.customerInfo = customer;
                    })
                )
                .subscribe()
        );
        this.currentTime = this.formatTime(new Date());
    }
    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    get customerName() {
        return this.returnForm.get('customerName');
    }

    get customerPhone() {
        return this.returnForm.get('customerPhone');
    }

    get email() {
        return this.returnForm.get('email');
    }

    get jobSiteName() {
        return this.returnForm.get('jobSiteName');
    }

    get jobSitePhone() {
        return this.returnForm.get('jobSitePhone');
    }

    get requestedDate() {
        return this.returnForm.get('requestedDate');
    }

    get requestedTime() {
        return this.returnForm.get('requestedTime');
    }

    get gateCombo() {
        return this.returnForm.get('gateCombo');
    }

    get address() {
        return this.returnForm.get('address');
    }

    get comments() {
        return this.returnForm.get('comments');
    }

    checkServiceRequest() {
        if (this.requestedDate?.valid && this.requestedTime?.valid) {
            return true;
        }
        return false;
    }

    checkSiteInfo() {
        if (this.address?.valid && this.gateCombo?.valid && this.jobSiteName?.valid && this.jobSitePhone?.valid) {
            return true;
        }

        return false;
    }

    sendReqeustForService() {
        this.appFacadeService.setLoading(true);

        if (!this.returnForm.valid) {
            this.notification.error(this.translateService.instant('pleaseFillRequired'));
            this.appFacadeService.setLoading(false);
            return false;
        }

        if (!this.customerId) {
            this.logger.logError('Customer Id Not Found in your request');
            return;
        }

        this.subscriptions.push(
            this.itemOutService
                .calloffrent({
                    items: this.items,
                    customerId: this.customerId,
                    customerName: this.returnForm.value?.customerName,
                    accountName: this.customerInfo?.CompanyName ?? this.customerInfo?.Name,
                    ...this.returnForm.value,
                    returnType: ReturnRequestType.Service
                })
                .subscribe({
                    next: (res: CallOffRequestResponse) => {
                        this.appFacadeService.setLoading(false);
                        this.notification.success(this.translateService.instant('RequestServiceSubmitted'));
                        this.returnForm.reset();
                        this.callOffRequestInfo.emit(res.callOffRequest);
                        this.appFacadeService.openDialog(DialogContentType.RequestSentSuccessfully);
                    },
                    error: err => {
                        this.appFacadeService.setLoading(false);
                        this.logger.alertDevError(err);
                        this.notification.error(this.translateService.instant('somethingWentWrong'));
                    }
                })
        );
        return true;
    }

    getDeliveryAddress(): string {
        if (this.contractDetail?.DriverDelivery?.DeliveryAddress) {
            return this.formatAddressPipe.format(this.contractDetail?.DriverDelivery?.DeliveryAddress);
        }
        return '';
    }

    return() {
        this.isFormReturn.emit(true);
    }

    openTimePicker(): void {
        if (this.timepicker) {
            this.timepicker.open();
        }
    }

    formatTime(date: Date): string {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
}
