import * as CryptoJS from 'crypto-js';

export const sharedKey = 'jIc80JueiL4aZk9EGlEdJRtMOcDCsL09qPkrbBb0ZviMh8+SPEWtKyXwQFxJnCT9iMe+78PgexxxIITmhxvlXQ=='; // TODO: don't hard code this here

type EncryptOptions = { key?: string; useTimestamp?: boolean };

type DecryptOptions = { errorClass?: new (...args: unknown[]) => Error; errorMessage?: string; key?: string; parseJSON?: boolean };

export type Payload<T = string | object> = {
    message: T;
    timestamp?: number;
};

/**
 * Encrypts message with a given key using AES.
 * @param message The message to encrypt
 * @param options The options object
 * @param options.key The encryption key (defaults to shared key)
 * @param options.useTimestamp If true, a timestamp will be included in the encrypted payload (defaults to true)
 * @returns The encrypted message
 */
export function encrypt(message: string | object, options?: EncryptOptions): string {
    options = { useTimestamp: true, ...options };

    const payload: Payload = { message };

    if (options.useTimestamp) {
        payload.timestamp = Date.now();
    }

    return CryptoJS.AES.encrypt(JSON.stringify(payload), options.key ?? sharedKey).toString();
}

/**
 * Decrypts message with a given key using AES.
 * @param message The message to decrypt
 * @param options The options object
 * @param options.error The class of error to throw
 * @param options.key The decryption key (defaults to shared key)
 * @param options.parseJSON If true, the decrypted string will be parsed before being returned (defaults to false)
 * @returns The decrypted message
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function decrypt<T = string | object>(message: string, options?: DecryptOptions & { parseJSON?: false }): string;
/**
 * Decrypts message with a given key using AES.
 * @param message The message to decrypt
 * @param options The options object
 * @param options.error The class of error to throw
 * @param options.key The decryption key (defaults to shared key)
 * @param options.parseJSON If true, the decrypted string will be parsed before being returned (defaults to false)
 * @returns The decrypted message
 */
export function decrypt<T = string | object>(message: string, options?: DecryptOptions & { parseJSON?: true }): Payload<T>;

// If options.parseJSON is false (default), the first overload will be used (i.e. string will be returned).
// If options.parseJSON is true, the second overload will be used (i.e. Payload<T> will be returned).
export function decrypt<T = string | object>(message: string, options?: DecryptOptions): string | Payload<T> {
    try {
        const decrypted = CryptoJS.AES.decrypt(message, options?.key ?? sharedKey).toString(CryptoJS.enc.Utf8);
        // If options.parseJSON is set, return the parsed, decrypted string. Otherwise, just return the decrypted string.
        return options?.parseJSON ? JSON.parse(decrypted) : decrypted;
    } catch (err) {
        // If options.errorClass is set, throw a new error of that class. Otherwise, rethrow the caught error.
        throw options?.errorClass ? new options.errorClass(options?.errorMessage) : err;
    }
}
