<div class="form-container por-forgot-password">
    <h2 class="theme-header">{{ 'Forgot Password' | translate }}</h2>
    <p class="left-align">{{ 'please_enter_your_email_password_code_will_be_emailed_you' | translate }}.</p>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" novalidate>
        <apx-form-field
            label="Email"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="username.invalid && (username.dirty || username.touched) ? 
            username.errors?.['required'] ? ('Please enter username.' | translate) : 
            username.errors?.['email'] ? ('Please enter valid email.' | translate) : 
            username.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="email"></span>
                <input
                    apxInput
                    type="text"
                    formControlName="username"
                    type="email"
                    autocomplete="on"
                    name="username"
                    placeholder="{{ 'Enter your email' | translate }}"
                    class="theme-input"
                    porAddFeature
                    featureName="forgot-password-email-input" />
            </apx-input-group>
        </apx-form-field>
        <button
            class="btn-primary btn-full-width theme-button"
            type="submit"
            apxButton
            variant="primary"
            [disabled]="resetPasswordForm.pristine || resetPasswordForm.invalid || isDisableButton"
            porAddFeature
            featureName="send-reset-code-button">
            {{ 'Send Reset Code' | translate }}
        </button>
    </form>
    <p class="left-align theme-paragraph">
        {{ 'Already have an account?' | translate }}
        <a porAddFeature featureName="forgot-password-signin-button" href="#" (click)="setTemplate('signin')">{{ 'Sign in here' | translate }}</a>
    </p>
</div>
