import { Subscription } from 'rxjs';
import { EventEmitter, Input, OnDestroy, Output, Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerPortalConfig, Menu } from '../../models/consumer-portal-config';
import { ConfigurationService } from '../../services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { Navigation } from '../../models/navigation.model';
import { AppMediatorService } from '../../services/app-mediator.service';
import { AppFacadeService } from '../../services/app-facade.service';

@Component({
    selector: 'por-page-sidebar',
    templateUrl: './page-sidebar.component.html',
    styleUrls: ['./page-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSidebarComponent implements OnInit, OnDestroy {
    constructor(
        private readonly configService: ConfigurationService,
        private readonly translateService: TranslateService,
        private readonly appMediatorService: AppMediatorService,
        private readonly logger: LoggerService,
        readonly appFacadeService: AppFacadeService,
        @Inject('Window') private readonly window: Window
    ) {}

    @Output() readonly getActiveTab = new EventEmitter();
    @Output() readonly logout = new EventEmitter();
    inputs: ConsumerPortalConfig | undefined;
    @Input() config!: string;
    menu!: Menu;
    consumerPortalWidth!: number;
    contractId: string | undefined;
    navigations: Navigation[] = [];

    subscriptions: Subscription[] = [];
    customerId!: string;

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.configService.setMatIconFontFamily();
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
            const width = this.inputs?.consumerPortal?.width as string;
            if (width) {
                this.consumerPortalWidth = Number(width.replace('px', ''));
            }

            this.logger.logAssert(this.inputs?.customerId, 'customerId is not defined');
            this.customerId = (this.configService.config?.customerId as string) || '0';
            this.menu = this.configService.config?.menu as Menu;
            [
                {
                    tab: ComponentsToLoad.AccountSummary,
                    text: this.translateService.instant('Home'),
                    params: []
                },
                {
                    tab: ComponentsToLoad.Contracts,
                    text: this.translateService.instant('Contracts'),
                    params: []
                },
                this.appFacadeService.featureToggleService.isAvailable('invoices') && {
                    tab: ComponentsToLoad.Invoices,
                    text: this.translateService.instant('Invoices'),
                    params: []
                },
                {
                    tab: ComponentsToLoad.ItemsOut,
                    text: this.translateService.instant('Items Out'),
                    params: []
                },
                this.appFacadeService.featureToggleService.isAvailable('trackingTab') && {
                    tab: ComponentsToLoad.Tracking,
                    text: this.translateService.instant('Tracking'),
                    params: []
                }
            ].map(nav => {
                if (nav) {
                    const tab: string = nav.tab.toString();
                    if (this.menu && Object.prototype.hasOwnProperty.call(this.menu, tab)) {
                        this.navigations.push(nav);
                    }
                    if (!this.menu) {
                        this.navigations.push(nav);
                    }
                }
            });
        }

        this.appFacadeService.setActiveTab(ComponentsToLoad.AccountSummary);
        if (this.inputs?.contractId) {
            this.contractId = this.inputs?.contractId;
        }
        const path = this.window.location.hash;
        if (!isEmpty(path.replaceAll('#', ''))) {
            this.appFacadeService.setActiveTab(path.replaceAll('#', '') as ComponentsToLoad);
        }
    }

    toggleTab(tab: ComponentsToLoad | undefined) {
        if (tab) {
            this.appFacadeService.setActiveTab(tab);
            this.getActiveTab.emit(tab);
        }
    }
}
