import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumerPortalDemoComponent } from './components/consumer-portal-demo/consumer-portal-demo.component';
import { ApxInputModule, ApxFormsModule, ApxButtonModule } from '@por/apex';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UIMissingTranslationHandler, UITranslateLoader } from '@por/shared/ui/translations';
import * as EnUSTranslations from '../assets/en-US.json';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import * as EnGBTranslations from '../assets/en-GB.json';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Language } from '@por/shared/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LazyElementsModule } from '@angular-extensions/elements';
import { AuthService } from './services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CpDemoComponent } from './components/cp-demo/cp-demo.component';
import { AdminDemoComponent } from './components/admin-demo/admin-demo.component';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        LazyElementsModule,
        CommonModule,
        ApxButtonModule,
        ApxInputModule,
        ApxFormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new UITranslateLoader({ [Language.EN_US]: EnUSTranslations, [Language.EN_GB]: EnGBTranslations });
                }
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: UIMissingTranslationHandler },
            defaultLanguage: Language.EN_US
        })
    ],
    declarations: [ConsumerPortalDemoComponent, CpDemoComponent, AdminDemoComponent, LoaderComponent],
    exports: [ConsumerPortalDemoComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [AuthService]
})
export class ConsumerPortalDemoModule {}
