<ng-container [ngSwitch]="enabledTab$ | async">
    <div class="rental-requests-modal">
        <ng-container *ngSwitchCase="'DefaultView'">
            <h1 class="theme-heading">{{ 'ItemListForPreviousContract' | translate }} # {{contractDetail?.[0]?.Id}}</h1>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <th>{{ 'itemName' | translate }}</th>
                        <th>{{ 'ItemId' | translate }}</th>
                        <th>{{ 'Quantity' | translate }}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of contractDetail?.[0]?.LineItems; let i = index">
                            <tr *ngIf="i < displayedRowCount">
                                <td class="text-center">{{ item?.Name }}</td>
                                <td class="text-center">{{ item?.ProductId ?? item?.StockId }}</td>
                                <td class="text-center">{{ item?.Quantity }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div *ngIf="showLoadMoreButton()" class="text-align-center mt-3">
                <por-base-button (click)="displayedRowCount = displayedRowCount + 10" featureName="clone-contract-loadmore-button">{{ 'Load More' | translate }}</por-base-button>
            </div>
            <div class="row mt-3">
                <div class="col text-align-center">
                    <por-base-button theme="primary" (onclick)="openContractCloneModal()" [featureName]="'BookContractAgain'">
                        <span class="actions-image">
                            <svg class="fill-current" width="24pt" height="24pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="m341.48 75.188v59.492h62.16z" />
                                    <path
                                        d="m250.65 198.63v-70.988h-136.34c-6.8359 0-12.363 5.5547-12.363 12.363v291.02c0 6.8359 5.5547 12.391 12.363 12.391h202.45c6.8086 0 12.363-5.5547 12.363-12.391v-227.45h-73.523c-2.7383 0-4.9414-2.2266-4.9414-4.9414z" />
                                    <path
                                        d="m336.56 144.51c-2.7148 0-4.9141-2.2031-4.9141-4.9141v-70.988h-136.37c-6.8086 0-12.363 5.5547-12.363 12.363v36.84h72.68c0.64062 0 1.2812 0.12891 1.8672 0.38281 0.25781 0.10156 0.46094 0.30859 0.71875 0.46094 0.28125 0.17969 0.58984 0.30859 0.82031 0.53906l78.438 75.059c0.97266 0.92188 1.5117 2.2031 1.5117 3.5586v186.55h58.777c6.8086 0 12.363-5.5547 12.363-12.391l-0.003906-227.46z" />
                                    <path d="m260.5 193.71h62.133l-62.133-59.469z" />
                                </g>
                            </svg>
                        </span>
                        {{ 'BookThisAgain' | translate }}
                    </por-base-button>
                    <p class="sub-text">{{ 'RequestForModificationContract' | translate }}</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'RequestCloneContractView'">
            <por-book-again-contract-request-form
                [customerId]="customerId"
                [contractDetail]="contractDetail"
                [uiUrl]="uiUrl"
                (isFormSubmittedSuccess)="handleCloneContractFormSubmission()"
                (isFormReturn)="handleCloneContractFormReturn()"></por-book-again-contract-request-form>
        </ng-container>
    </div>
</ng-container>
