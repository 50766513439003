import { Injectable } from '@angular/core';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { NotificationService } from './notification.service';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Searchable } from '../models/searchable.model';
import { OrgDetail } from '../models/organization-detail.model';

type OrgDetailSearchable = Searchable & OrgDetail;

@Injectable({
    providedIn: 'root'
})
export class OrganizationIdService {
    constructor(private readonly notification: NotificationService, private readonly consumerPortalApiService: ConsumerPortalApiService, private readonly logger: LoggerService) {}

    getOrgIdFromUrl(url: string) {
        if (url.length) {
            return this.consumerPortalApiService.get<OrgDetailSearchable>(`public/organizations/url?url=${url}`).pipe(
                tap({
                    next: (data: OrgDetail[]) => {
                        return data;
                    },
                    error: (error: HttpErrorResponse) => {
                        if (error.status !== 400) {
                            this.logger.logInfo('Something went wrong when requesting getOrgIdFromUrl');
                            this.logger.logError(error?.error?.message);
                            this.logger.alertDevError(error);
                            error?.error?.message ? alert(error?.error?.message) : null;
                        }

                        return of(false);
                    }
                }),
                catchError(() => of(false))
            );
        } else {
            this.notification.error('Invalid Sub Domain URL');
            return of(false);
        }
    }
}
