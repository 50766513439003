import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly _snackBar: MatSnackBar) {}

  success(message: string) {
    this._snackBar.open(message, '', {
      duration: 3000
    });
  }
  error(message: string) {
    this._snackBar.open(message, '', {
      duration: 3000
    });
  }
}
