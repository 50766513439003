import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RedirectionState } from './redirection.state';

export const selectRedirectionState = createFeatureSelector<RedirectionState>('redirection');

export const selectRedirectionSessionData = createSelector(selectRedirectionState, (state: RedirectionState) => state.session);

export const selectRedirection = createSelector(selectRedirectionState, (state: RedirectionState) => state.redirection);

export const selectIsConfigSet = createSelector(selectRedirectionState, (state: RedirectionState) => state.isConfigSet);
