import { createAction, props } from '@ngrx/store';
import { PaymentTransactionType } from '../../../enums/payment-method.enum';
import { ConsumerPortalConfig } from '../../../models';
import { PaymentMinMaxBoundary } from '../../../payment/models/payment-data.model';
import { PaymentAppPayOutput, PayOutputEvent, PorPayEvent } from '../../../payment/models/paymentOutput';
import { Processors } from '../../../payment/models/processors.interface';

export enum PaymentActionTypes {
    SetMultiPaymentBoundaries = '[Payment] Set Multi Payment Boundaries',
    MultiPaymentBoundariesFail = '[Payment] Failed to load Payment Boundaries',
    SetConfigData = '[Payment] Set Config Data',
    SetTransactionLoaded = '[Payment] Set Transaction Loaded',
    SetPaymentAppInput = '[Payment] Set Payment App Input',
    CreateTransaction = '[Payment] Create Transaction',
    SuccessCreateTransaction = '[Payment] Create Transaction Success',
    FailedCreateTransaction = '[Payment] Create Transaction Failed',
    PaymentFinished = '[Payment] Payment Finished',
    FailVerifyPayment = '[Payment] Payment Finished Failed',
    SuccessVerifyPayment = '[Payment] Payment Finished Success',
    SetDialogEnabled = '[Payment] Set Dialog Enabled',
    PaymentCrossAppEvent = '[Payment] Send Cross App Event',
    SetPaymentSectionEnabled = '[Payment] Set Payment Section Enabled',
    LoadPaymentProcessors = '[Payment] Load Payment Processors',
    LoadPaymentProcessorsSuccess = '[Payment] Load Payment Processors Success',
    LoadPaymentProcessorsFailure = '[Payment] Load Payment Processors Failure'
}

export const setMultiPaymentBoundaries = createAction(PaymentActionTypes.SetMultiPaymentBoundaries, props<{ paymentBoundaries: PaymentMinMaxBoundary[] | null }>());

export const setConfigData = createAction(PaymentActionTypes.SetConfigData, props<{ config: ConsumerPortalConfig | null }>());

export const setTransactionLoaded = createAction(PaymentActionTypes.SetTransactionLoaded, props<{ transactionLoaded: boolean }>());

export const setDialogEnabled = createAction(PaymentActionTypes.SetDialogEnabled, props<{ dialogEnabled: boolean }>());

export const setPaymentAppInput = createAction(PaymentActionTypes.SetPaymentAppInput, props<{ paymentAppInput: string }>());

export const failLoadPaymentBoundaries = createAction(PaymentActionTypes.MultiPaymentBoundariesFail, props<{ error: string }>());

export const createTransaction = createAction(PaymentActionTypes.CreateTransaction, props<{ amount: string | null; transactionType: PaymentTransactionType | null }>());

export const successCreateTransaction = createAction(PaymentActionTypes.SuccessCreateTransaction, props<{ res: unknown }>());

export const failCreateTransaction = createAction(PaymentActionTypes.FailedCreateTransaction, props<{ error: string }>());

export const onPaymentFinished = createAction(PaymentActionTypes.PaymentFinished, props<{ paymentAppOutput: PayOutputEvent }>());

export const failVerifyPayment = createAction(PaymentActionTypes.FailVerifyPayment, props<{ error: string }>());

export const successVerifyPayment = createAction(PaymentActionTypes.SuccessVerifyPayment, props<{ lastPaymentDetail: PaymentAppPayOutput }>());

export const crossAppEventEmitted = createAction(PaymentActionTypes.PaymentCrossAppEvent, props<{ event: PorPayEvent }>());

export const setPaymentSectionEnabled = createAction(PaymentActionTypes.SetPaymentSectionEnabled, props<{ enabled: boolean }>());

export const loadPaymentProcessorAvailability = createAction(PaymentActionTypes.LoadPaymentProcessors, props<{ customerId: string }>());

export const loadPaymentProcessorAvailabilitySuccess = createAction(PaymentActionTypes.LoadPaymentProcessorsSuccess, props<{ processors: Processors[] | boolean }>());

export const loadPaymentProcessorAvailabilityFailure = createAction(PaymentActionTypes.LoadPaymentProcessorsFailure);
