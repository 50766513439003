import { ConsumerPortalEnviromentConfig } from './model';
declare let require: any;
export const environment: ConsumerPortalEnviromentConfig = {
    production: false,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    version: require('../../project.json').appVersion,
    uiUrl: 'https://dev-consumer-portal-ui.rentamation.com',
    apiUrl: 'https://dev-consumer-portal-api.rentamation.com/v1',
    loginApiUrl: 'https://dev-consumer-login-api.rentamation.com/v1',
    loginApiUrlInternal: 'https://dev-consumer-login-api.rentamation.com/v1',
    porPayScriptURL: 'https://dev-por-pay-ui.rentamation.com/main.js',
    defaultSubDomain: 'https://elite.dev-consumer-portal-ui.rentamation.com/',
    gapiApiBaseUrl: 'https://api.pointofrental.com/v1_dev',
    envName: 'devcloud',
    adminJSUrl: 'https://dev-ecom-consumer-portal-admin.rentamation.com'
};
