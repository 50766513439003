import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { MatDateFormats, NativeDateAdapter} from '@angular/material/core';
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
