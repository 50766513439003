export enum FileType {
    Logo = 'logo',
    Icon = 'favicon',
    Banner = 'banner' // Ad banners
}

export enum SubBannerFileType {
    Banner1 = 'Banner1',
    Banner2 = 'Banner2',
    Banner3 = 'Banner3',
    Banner4 = 'Banner4'
}

export interface BannerConfig {
    type: SubBannerFileType;
    title: string;
    imageTitle: string;
    urlTitle: string;
}
