import { DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateUtility } from '../../../date.utility';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ReturnRequestType } from '../../../enums/return-request-type.enum';
import { AppMediatorService } from '../../../services/app-mediator.service';

@Component({
    selector: 'por-request-cell-renderer',
    template: `
        <span [innerHTML]="cellValue"></span>
    `
})
export class RequestCellRendererComponent implements ICellRendererAngularComp {
    public cellValue!: string;
    dateFormat = '';
    constructor(@Inject(LOCALE_ID) locale: string, private readonly translateService: TranslateService, private readonly appMediatorService: AppMediatorService, private readonly date: DatePipe) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.cellValue = this.getValueToDisplay(params);
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
    }

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
        return true;
    }

    getValueToDisplay(params: ICellRendererParams) {
        const requestDate = this.date.transform(params.value, this.dateFormat);
        let requestStr = this.translateService.instant('CallOff');
        if ((params.data.requestType as string) === (ReturnRequestType.Service as string)) {
            requestStr = this.translateService.instant('RequestService');
        }
        return requestDate ? `${requestStr}<br>${requestDate}` : '';
    }
}
