import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { AppFacadeService } from '../../services/app-facade.service';

@Component({
    selector: 'por-hamburger',
    templateUrl: './hamburger.component.html',
    styleUrls: ['./hamburger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HamburgerComponent implements AfterViewInit {
    constructor(private readonly elementRef: ElementRef, @Inject(DOCUMENT) private readonly document: Document, private readonly appFacadeService: AppFacadeService) {}
    @Input() icon = 'person';
    @Input() panelClass = '';
    @Input() closeOnPanel = false;
    @Input() logo = '';
    @Input() classes = '';
    @Input() btnimg = '';
    isOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    @HostListener('document:click', ['$event.target'])
    public onPageClick(targetElement: HTMLElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpened$.next(false);
        }
    }

    ngAfterViewInit(): void {
        this.document.querySelectorAll('.nav-items')?.forEach(el => {
            el.addEventListener('click', () => {
                this.isOpened$.next(false);
            });
        });
    }

    openPanel() {
        this.isOpened$.next(!this.isOpened$.getValue());
    }
    closePanel() {
        this.isOpened$.next(false);
    }

    openHomepage() {
        this.appFacadeService.setActiveTab(ComponentsToLoad.AccountSummary);
        this.closePanel();
    }
}
