import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationErrorResult } from '../models/validators.model';

/**
 * Regex which will find characters that aren't associated with number values
 * Exceptions are numbers 0-9, period separater and minus
 */
export const nonNumericCharsRegex = /[^0-9\\.-]+/g;

/**
 * Checks if provided email is of the right format
 * @param control
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    const emailRgx = /^(([^<>+()\\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

    if (!control.value || control.value === '') {
        return null;
    }

    return emailRgx.test(control.value.trim())
        ? null
        : {
              email: {
                  message: 'FormMessages.InvalidEmail'
              }
          };
};

/**
 * Checks for basic phone validation.
 * Only numbers, + () and space and trailing letters for Extensions are allowed
 * @param ctrl
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const phoneValidator: ValidatorFn = (ctrl: AbstractControl): ValidationErrorResult | null => {
    const basePhoneRgx = /[- +()0-9]+/;

    if (!ctrl.value || ctrl.value === '') {
        return null;
    }

    return basePhoneRgx.test(ctrl.value.trim())
        ? null
        : {
              phone: {
                  message: 'FormMessages.InvalidPhone',
                  param: {
                      value: ctrl.value
                  }
              }
          };
};

/**
 * Checks for numeric values or spaces only
 * @param validatorKey - reference for getting the form message. Should be either the control key or name
 * @param params
 * @returns
 */
const numberValidator =
    (validatorKey: string) =>
    (ctrl: AbstractControl): ValidationErrorResult | null => {
        const numberRgx = /[0-9 ]+/;

        if (!ctrl.value || ctrl.value === '') {
            return null;
        }

        return numberRgx.test(ctrl.value.trim())
            ? null
            : {
                  [validatorKey]: {
                      message: 'FormMessages.InvalidNumber'
                  }
              };
    };
/**
 * Checks for null or number including decimals using isNan
 * @param validatorKey - reference for getting the form message. Should be either the control key or name
 * @returns
 */
const numberOrNullValidator = (validatorKey: string) => (control: AbstractControl) =>
    !control.value ? null : !isNaN(control.value - parseFloat(control.value)) ? null : { [validatorKey]: { message: 'FormMessages.InvalidNumber' } };

/**
 * Checks whether a given string is a valid monetary value
 * @param validatorKey
 * @returns
 */
const currencyValidator = (validatorKey: string) => (control: AbstractControl) => {
    if (!control.value) return null;
    const value = control.value.replace(nonNumericCharsRegex, ''); // strip out anything that isn't a number, period or minus, before checking if the number is valid
    return !isNaN(value - parseFloat(value)) ? null : { [validatorKey]: { message: 'FormMessages.InvalidCurrency' } };
};

/**
 * Checks for empty values (if value only has spaces)
 * @param control
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const noEmptyValueValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    const isEmpty = control.value.length > 0 && control.value.trim().length === 0;
    const isValid = !isEmpty;

    return isValid
        ? null
        : {
              emptyString: {
                  message: 'FormMessages.InvalidValue'
              }
          };
};

/**
 * Checks if option is required in dropdown or radio
 * @param ctrl
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const requiredOptionValidator: ValidatorFn = (ctrl: AbstractControl): ValidationErrorResult | null =>
    !ctrl.value || !ctrl.value?.name
        ? {
              requiredOption: {
                  message: 'FormMessages.OptionIsRequired'
              }
          }
        : null;

/**
 * Checks if the form group has any child controls
 * @param control
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const emptyGroupValidator: ValidationErrors | null = (ctrl: FormGroup) => {
    if (Object.keys(ctrl.controls).length === 0) {
        return {
            groupIsEmpty: true
        };
    }
    return null;
};

/**
 * Checks if provided value is in HEX format
 * @param control
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const hexValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    const hexRgx = /[0-9a-f]+/i;

    if (!control.value || control.value === '') {
        return null;
    }

    return hexRgx.test(control.value.trim())
        ? null
        : {
              hex: {
                  message: 'FormMessages.InvalidHex'
              }
          };
};

/**
 * Checks if provided value is URL and ends with image
 *
 * Regex breakdown:
 * ^(https?:\/\/)?: Matches the optional http:// or https:// at the beginning of the URL.
 * ([\da-z.-]+)\.([a-z.]{2,6}): Matches the domain name part of the URL.
 * (\/[^\/#?]+): Matches the path part of the URL (after the domain).
 * +\.: Matches the dot before the image extension.
 * (jpeg|jpg|gif|png)$: Matches the image extensions jpeg, jpg, gif, or png at the end of the URL.
 *
 * @param control
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const imageUrlValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    /**
     * image regular expression is have more than expected characters
     */
    // eslint-disable-next-line
    const imageRgx = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[^\/#?]+)+\.(jpeg|jpg|gif|png|svg)$/i;

    if (!control.value || control.value === '') {
        return null;
    }

    return imageRgx.test(control.value.trim())
        ? null
        : {
              image: {
                  message: 'FormMessages.InvalidHex'
              }
          };
};

/**
 * Checks if provided value has space
 * @param control
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const noSpaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    const regex = /^\S*$/i;

    if (!control.value || control.value === '') {
        return null;
    }

    return regex.test(control.value.trim())
        ? null
        : {
              noSpace: {
                  message: 'FormMessages.spaceNotAllowed'
              }
          };
};

const futureDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    if (!control.value || control.value === '') {
        return null;
    }
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
        return {
            futureDate: {
                message: 'FormMessages.InvalidFutureDate'
            }
        };
    }
    return null;
};

const subDomainValidator: ValidatorFn = (control: AbstractControl): ValidationErrorResult | null => {
    if (!control.value || control.value === '') {
        return null;
    }

    const regexSpaces = /^\S*$/i;
    const regexCom = /^(?:(?!\.com).)*$/i;
    const regexSpecialChars = /^[a-z0-9-]+$/i;
    if (!regexSpaces.test(control.value.trim())) {
        return {
            noSpace: {
                message: 'FormMessages.spaceNotAllowed'
            }
        };
    }
    if (!regexCom.test(control.value.trim())) {
        return {
            noCom: {
                message: 'FormMessages.InvalidSubdomain'
            }
        };
    }
    if (!regexSpecialChars.test(control.value.trim())) {
        return {
            badFormat: {
                message: 'FormMessages.InvalidSubdomain'
            }
        };
    }

    return null;
};

/** -----------------------------
 * Validator Exports
 ---------------------------------*/
export const AppFormValidators = {
    emailValidator,
    requiredOptionValidator,
    noEmptyValueValidator,
    phoneValidator,
    numberValidator,
    numberOrNullValidator,
    currencyValidator,
    emptyGroupValidator,
    hexValidator,
    imageUrlValidator,
    noSpaceValidator,
    futureDateValidator,
    subDomainValidator
};
