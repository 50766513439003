import { ContractDetail, LineItems, Payment } from './../../models/contract-model';
import { Component, OnInit, Input, OnDestroy, Inject, LOCALE_ID, Output, EventEmitter, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { ConfigurationService } from '../../services/configuration.service';
import { Consumed } from '../../enums/consumed-payment-verify';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { BehaviorSubject, map, Observable, Subscription, tap } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { ContractPdfResponse } from '../../models/pdf-response';
import { VersionToggleService } from '../../services/version-toggle';
import { AppFacadeService } from '../../services/app-facade.service';
import { ContractService } from '../../services/contract.service';
import { NotificationService } from '../../services/notification.service';
import { ProductDetail } from '../../models/product-detail';
import { GapiLicense } from '../../enums/gapi-license';
import { AppEventService } from '../../services/app-event.service';
import { OriginActionName } from '@por/shared/ui/cross-app';
import { AuthKeys } from '../../enums/local-storage-keys.enum';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ModalService } from '../../services/modal.service';
import { RequestActionsToLoad } from '../../enums/request-actions.enum';
import { RequestActionService } from '../../services/request-action.service';
import { PorTransactionService } from '../../por-transaction.service';
import { ContractDetailView } from '../../enums/contract-detail-view.enum';
import { AppMediatorService } from '../../services/app-mediator.service';
import { LineItemClassification } from '../../enums/LineItemClassification.enum';
import { ContractStatusEnum } from '../../enums/contract-status.enum';
import { ContractStatus } from '../../types/contract.type';
import { LinkField } from '../../enums/link-field';
import { DialogType } from '../../enums/dialog-type.enum';

@Component({
    selector: 'por-contract-detail',
    templateUrl: './contract-detail.component.html',
    styleUrls: ['./contract-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractDetailComponent implements OnInit, OnDestroy {
    @Input() contractDetail!: ContractDetail[] | null;
    @Input() customerId!: string;
    @Output() readonly reloadContract = new EventEmitter();
    @Output() readonly reloadContractList = new EventEmitter();
    @Output() readonly closePanel = new EventEmitter<boolean>(false);
    @Output() readonly returnToLogin = new EventEmitter<boolean>(false);
    @Output() readonly loginButtonVisibilityChange = new EventEmitter<boolean>();
    dateFormat: string;
    isDialogEnabled = false;
    isPaymentEnabled = false;
    isPending = false;
    organizationId!: string;
    @Input() uiUrl: string | undefined;
    pdfDownloadAvailable$ = new BehaviorSubject<boolean>(this.featureToggleService.isAvailable('pdfDownloadFeature'));
    subscription: Subscription[] = [];
    displayedRowCount = 10;
    productDetail!: ProductDetail;
    pdfDownloadUrl$ = new BehaviorSubject<ContractPdfResponse | null>(null);
    singleProductPageOpened = false;
    subscriptions: Subscription[] = [];
    isMobile = false;
    isContractCloneModal = false;
    isProductDetailAvailable = true;
    selectedLineItem!: LineItems;
    private readonly activeTemplate = new BehaviorSubject(ContractDetailView.DefaultView);
    activeTemplate$ = this.activeTemplate.asObservable();
    dialogType: DialogType = DialogType.confirm;
    contractId!: string;
    urlsByDocumentType: { [key: string]: string } = {};
    esignMsg = '';
    isSticky = false;
    sticky!: number;
    isPaymentProcessorAvailable$: Observable<boolean> = this.appFacadeService.isPaymentProcessorsAvailable().pipe(
        tap(processors => {
            if (processors === null) {
                // Dispatch the action to check availability if it's not set
                this.appFacadeService.getPaymentProcessors(this.customerId);
            }
        }),
        map(processors => {
            if (!processors) {
                return false;
            }
            return (
                Array.isArray(processors) &&
                processors.some(processor => {
                    return processor.RemoteDepotId === this.contractDetail?.[0].DepotId;
                })
            );
        })
    );

    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly translateService: TranslateService,
        readonly featureToggleService: FeatureToggleService,
        private readonly configService: ConfigurationService,
        readonly versionToggle: VersionToggleService,
        readonly appFacadeService: AppFacadeService,
        readonly contractService: ContractService,
        private readonly notification: NotificationService,
        private readonly appEventService: AppEventService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly modal: ModalService,
        private readonly requestActionService: RequestActionService,
        private readonly porTransactionService: PorTransactionService,
        private readonly appMediatorService: AppMediatorService
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDateTime, locale);
    }

    checkPaymentEnabled() {
        const amount: number = this.contractDetail?.[0]?.AmountDue ? this.contractDetail?.[0]?.AmountDue : 0;
        const consumed: Consumed | undefined = this.contractDetail?.[0]?.Payment?.[0]?.Consumed;
        // Allow payment if the amount due is greater than 0 and Consumer status is Posted or Error
        if (consumed !== Consumed.Waiting && consumed !== Consumed.Imported && amount > 0) {
            this.isPaymentEnabled = true;
        }
        if (this.contractDetail?.[0].Payment && this.contractDetail?.[0].Payment?.some(payment => payment.Consumed === Consumed.Waiting || payment.Consumed === Consumed.Imported)) {
            this.isPending = true;
        }
        /*
         *@setting pdfEnabled button  if details response has pdf properties
         */
        if (this.contractDetail?.[0]?.Pdf?.SecureURL) {
            this.pdfDownloadAvailable$.next(true);
        }

        if (this.configService.config?.organizationId) {
            this.organizationId = this.configService.config?.organizationId;
        }

        // This check should work only for some RMS systems, that's why We have feature toggle for this
        if (this.featureToggleService.features.checkPaymentLicense && !this.versionToggle.isLicenseActive(GapiLicense.AcquiringCCProccessing)) {
            this.isPaymentEnabled = false;
        }

        // Check if the capturePaymentFeature is not available globally
        const isCapturePaymentFeatureAvailable: boolean = this.featureToggleService.isAvailable('capturePaymentFeature');

        // Get the contract status, considering both possible properties
        const contractStatus: string | undefined = this.contractDetail?.[0]?.Status ?? this.contractDetail?.[0].ContractStatus;

        // Check if the canCapturePayment sub-feature is available for the current contract status, ignoring the global feature check
        const canCapturePayment: boolean = this.featureToggleService.isAvailable('capturePaymentFeature', {
            subFeature: 'canCapturePayment',
            state: contractStatus as string,
            ignoreFeatureCheck: true
        });
        // Enable payment if the global feature is not available, but the sub-feature is available for the current contract status
        if (!isCapturePaymentFeatureAvailable) {
            if (canCapturePayment) {
                this.isPaymentEnabled = true;
            } else {
                this.isPaymentEnabled = false;
            }
        }
    }
    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.checkPaymentEnabled();
        this.getPdfSecureUrl();
        this.subscriptions.push(
            this.porTransactionService.transactionSuccess$.subscribe(success => {
                if (success) {
                    this.isPending = true;
                    this.isPaymentEnabled = false;
                }
            })
        );
        this.subscriptions.push(
            this.breakpointObserver.observe(['(max-width: 992px)']).subscribe((result: BreakpointState) => {
                this.isMobile = result.matches;
            })
        );

        if (this.versionToggle.isQuickLinkPdfExternalPortal()) {
            this.pdfDownloadAvailable$.next(false);
        }

        this.isProductDetailAvailable = this.featureToggleService.isAvailable('productDetailFeature');
    }

    @HostListener('window:scroll', ['$event'])
    @HostListener('window:mousewheel', ['$event'])
    @HostListener('window:touchmove', ['$event'])
    scrollFunction(): void {
        const quickLinkContainer = document.querySelector('.quick-link-container');
        if (quickLinkContainer) {
            if (quickLinkContainer.scrollTop > 60) {
                this.isSticky = true;
            } else {
                this.isSticky = false;
            }
        }
    }

    ngOnDestroy(): void {
        this.contractDetail = null;
        this.enablePaymentPage(false);
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
        // Set the gloabl transaction success to false after component destroy
        this.porTransactionService.setTransactionSuccess(false);
    }

    enablePaymentPage(action: boolean) {
        this.loginButtonVisibilityChange.emit(!action);
        if (this.featureToggleService.isAvailable('capturePaymentFeature')) {
            this.appFacadeService.setPaymentSectionEnabled(action);
            return;
        }
        if (action) {
            this.appEventService.dispatch({
                actionName: OriginActionName.CapturePayment,
                data: {
                    contractId: this.contractDetail?.[0]?.Id
                }
            });
        }
    }

    onSuccessTransaction(paymentResponse: Payment): void {
        if (paymentResponse) {
            this.contractDetail?.[0]?.Payment?.push(paymentResponse);
        }
    }

    getPdfSecureUrl(): void {
        let documentFieldValue = '';
        const organizationType: string = this.appMediatorService.localStorageService?.getCurrentUser?.organizationType as string;

        if (organizationType === 'expert') {
            //To support the leagcy using pdf_contract document field value
            documentFieldValue = 'pdf,pdf_contract';
        }
        if (organizationType === 'syrinx') {
            documentFieldValue = 'Contract';
        }
        if (!(this.featureToggleService.isSyrinx() && this.versionToggle.isQuickLinkVersion())) {
            if (this.pdfDownloadAvailable$.value) {
                this.subscription.push(
                    this.appFacadeService.pdf
                        .downloadPdf(this.customerId, this.contractDetail?.[0]?.Id, documentFieldValue)
                        .pipe(
                            tap(data => {
                                return this.pdfDownloadUrl$.next(data);
                            })
                        )
                        .subscribe()
                );
            }
        }
    }

    tryOpenSecureUrl(secureUrl: string | undefined, linkField: LinkField): boolean {
        if (this.versionToggle.isQLButNotConsumerQL()) {
            /**
             * Organization has !SecureUrlLoginRequired, which means that real URL is returned and We want to open it in new tab
             */
            if (secureUrl && secureUrl !== 'fakeUrl') {
                this.appFacadeService.pdf.openPdfinNewTab(secureUrl);
                return true;
            }

            const user: ConsumerPortalConfig = this.appMediatorService.localStorageService.getCurrentUser as ConsumerPortalConfig;

            this.appMediatorService.localStorageService.persistUser(AuthKeys.User, {
                ...user,
                operations: {
                    contract: {
                        [linkField]: secureUrl
                    }
                }
            });
            this.appFacadeService.updateLocation(ComponentsToLoad.Logout);
            this.returnToLogin.emit(true);
            return true;
        }
        return false;
    }

    downloadPdf(secureUrl: string | undefined): void {
        // For QL open or redirect to the contract pdf url
        if (secureUrl && !this.tryOpenSecureUrl(secureUrl, LinkField.ContractPdfUrl)) {
            this.appFacadeService.pdf.openPdfinNewTab(secureUrl);
        }
    }

    openEsign() {
        const eSignUrl = (this.contractDetail && this.contractDetail[0]?.EsignUrl) ?? 'fakeUrl';

        // For QL open or redirect to the esign URL
        if (this.tryOpenSecureUrl(eSignUrl, LinkField.ContractESignUrl)) {
            return;
        }

        this.appFacadeService.setLoading(true);
        const columns = ['EsignUrl'];
        this.subscriptions.push(
            this.contractService
                .getContractsDetails(this.customerId, this.contractDetail?.[0]?.Id ?? '', columns)
                .pipe(
                    tap(data => {
                        if (!isEmpty(data)) {
                            const eSign = data?.[0].EsignUrl;
                            this.appFacadeService.setLoading(false);
                            if (eSign) {
                                window.location.href = eSign;
                            } else {
                                this.appFacadeService.setLoading(false);
                                this.esignMsg = this.translateService.instant('ESign Not Available');
                            }
                        }
                    })
                )
                .subscribe()
        );
    }

    loadProductDetailModal(productId: string | undefined, stockId: string | undefined) {
        if (productId || stockId) {
            this.appFacadeService.setLoading(true);
            const columns: string[] = ['Id', 'Name', 'SerialNumber', 'Images', 'Description'];
            this.subscriptions.push(
                this.contractService
                    .getProductDetails(this.customerId, productId ?? '0', stockId ?? '0', columns)
                    .pipe(
                        tap(data => {
                            if (!isEmpty(data)) {
                                this.productDetail = data[0];
                                this.setActiveTemplate(ContractDetailView.ProductDetail);
                            } else {
                                this.notification.error(this.translateService.instant('notFoundProductDetail'));
                            }

                            this.appFacadeService.setLoading(false);
                        })
                    )
                    .subscribe()
            );
        }
    }

    loadDocuments(item: LineItems): void {
        this.productDetail = item as unknown as ProductDetail;
        this.setActiveTemplate(ContractDetailView.ProductDetail);
        this.contractId = item?.AdditionalFields?.ContractId;
    }

    editContract(contractId: string) {
        this.appEventService.dispatch({
            actionName: OriginActionName.ContractEdit,
            data: {
                contractId: contractId
            }
        });
    }

    toggleDialog() {
        this.isDialogEnabled = true;
    }

    cancelContract(confirmation: boolean, contractId: string) {
        if (!confirmation) {
            this.isDialogEnabled = false;
            return;
        }

        this.appEventService.dispatch({
            actionName: OriginActionName.ContractCancel,
            data: {
                contractId: contractId
            }
        });
        this.reloadContractList.emit();
    }

    openContractCloneModal() {
        this.modal.open('clone-contract-request-modal');
        return;
    }

    onCloseContractCloneModal() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }

    closeContractCloneModal() {
        this.modal.close('clone-contract-request-modal');
        this.reloadContractList.emit();
    }

    openPaymentHistoryModal(): void {
        this.modal.open('contract-payment-history-modal');
        return;
    }

    hasPaymentHistory(): boolean {
        if (!this.contractDetail?.[0]?.Payment || !this.versionToggle.isAdvanceVersion()) {
            return false;
        }
        return this.contractDetail[0].Payment.length > 0;
    }

    onClosePaymentHistoryModal() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }

    onCloseButtonClick() {
        if (this.isPending !== true) {
            this.enablePaymentPage(false);
            this.closePanel.emit(true);
        } else {
            this.closePanel.emit(true);
            this.reloadContractList.emit();
        }
    }

    hasPickupDate() {
        if (!this.hasContractDetail()) {
            return;
        }

        const pickupDateString: string = this.contractDetail?.[0]?.DriverPickup?.DateTime as string;
        const deliveryDateString: string = this.contractDetail?.[0]?.DriverDelivery?.DateTime as string;

        if (!pickupDateString || !deliveryDateString) {
            return;
        }

        const pickupDate: Date = new Date(pickupDateString);
        const deliveryDate: Date = new Date(deliveryDateString);

        return pickupDate >= deliveryDate;
    }

    loadItemActions(lineItem: LineItems): void {
        this.selectedLineItem = lineItem;
        this.setActiveTemplate(ContractDetailView.SelectedItemActions);
    }

    private setActiveTemplate(tab: ContractDetailView): void {
        this.activeTemplate.next(tab);
    }

    setActiveTemplateTab(tab: string): void {
        // Convert the string to the corresponding enum value
        const enumValue: ContractDetailView = ContractDetailView[tab as keyof typeof ContractDetailView];
        // Call setActiveTemplate with the enum value
        this.setActiveTemplate(enumValue);
    }

    getContractTotal(): number | string {
        if (!this.hasContractDetail()) {
            return '';
        }

        const grandTotal: string | undefined = this.contractDetail?.[0]?.GrandTotal;
        const grandTaxTotal: string | undefined = this.contractDetail?.[0]?.GrandTaxTotal;

        if (typeof grandTotal !== 'number' || typeof grandTaxTotal !== 'number') {
            return '';
        }

        const result: number = grandTotal - grandTaxTotal;
        return result;
    }

    isSaleorRentalorNull(classification: string): boolean {
        return classification === LineItemClassification.RENTAL || classification === LineItemClassification.SALE || classification === null;
    }

    isRental(classification: string): boolean {
        return classification === LineItemClassification.RENTAL;
    }

    isShowLink(classification: string): boolean {
        return classification === LineItemClassification.RENTAL && this.featureToggleService.isAvailable('showDetailDocuments');
    }

    isEsignAvailable(): boolean {
        if (!this.hasContractDetail()) {
            return false;
        }

        // Check if EsignUrl is not null, not empty, and eSignState is "Requested" if it exists
        if (this.contractDetail && this.contractDetail[0] && (this.contractDetail[0].EsignUrl || false) && (!this.contractDetail[0].ESignState || this.contractDetail[0].ESignState === 'Requested')) {
            return true;
        }
        return false;
    }

    hasContractDetail(): boolean {
        if (this.contractDetail?.[0] || this.contractDetail?.length) {
            return true;
        }
        return false; // Return false if contractDetail is null or undefined
    }

    /**
     * Check the contract status is respected one or not
     * @param status
     * @returns boolean
     */
    isStatus(status: ContractStatus): boolean {
        // Get the contract status, considering both possible properties
        const contractStatus: string | undefined = this.contractDetail?.[0]?.Status ?? this.contractDetail?.[0].ContractStatus;
        switch (contractStatus && status) {
            case ContractStatusEnum.Quote: {
                return contractStatus === ContractStatusEnum.Quote;
            }
            case ContractStatusEnum.Open: {
                return contractStatus === ContractStatusEnum.Open;
            }
            case ContractStatusEnum.Closed: {
                return contractStatus === ContractStatusEnum.Closed;
            }
            case ContractStatusEnum.Reservation: {
                return contractStatus === ContractStatusEnum.Reservation;
            }
            case ContractStatusEnum.Returned: {
                return contractStatus === ContractStatusEnum.Returned;
            }
            case ContractStatusEnum.Voided: {
                return contractStatus === ContractStatusEnum.Voided;
            }
            default:
                return false;
        }
    }

    hideActionButtons(): boolean {
        if ((this.contractDetail?.[0]?.AmountDue != null && this.contractDetail?.[0].AmountDue > 0 && !this.isPending) || this.checkEsignButton()) {
            return true;
        } else {
            return false;
        }
    }

    checkEsignButton(): boolean {
        if (this.versionToggle.isQuickLinkVersion() && this.isEsignAvailable()) {
            return true;
        } else {
            return false;
        }
    }

    isNoterLineItem(item: LineItems): boolean {
        return item?.isNoter ? true : false;
    }
}
