<div class="portalnav wrapper por-ac-tracking'" [ngStyle]="{ height: height, width: width }" class="wrapper">
    <section class="route-delivery">
        <h2 class="theme-heading" *ngIf="(mapReady | async) && (noTrackingOrders$ | async) === false && (orderExistsWithNoAddress$ | async) === false">{{ 'Equipment Tracking' | translate }}</h2>
        <div *ngIf="(mapReady | async) && (noTrackingOrders$ | async) === false && (orderExistsWithNoAddress$ | async) === false" class="map-custom" [ngStyle]="{ height: mapHeight }">
            <por-google-map *ngIf="mapReady | async" [config]="inputs" [pushpins]="pushpins"></por-google-map>
        </div>

        <div [ngStyle]="{ height: mapHeight }" class="not-tracking-order" *ngIf="noTrackingOrders$ | async">
            <h2 class="theme-heading">{{ 'Sorry' | translate }} !</h2>
            <h3 class="theme-paragraph">
                {{ 'There are no open contracts to be able to map.' | translate }}
            </h3>
        </div>

        <div [ngStyle]="{ height: mapHeight }" class="not-tracking-order" *ngIf="orderExistsWithNoAddress$ | async">
            <h2 class="theme-heading">{{ 'Sorry' | translate }} !</h2>
            <h3 class="theme-paragraph">{{ 'MissingOpenContractLocationDetail' | translate }}.</h3>
        </div>
    </section>
</div>
