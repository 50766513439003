import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'por-document-button',
    templateUrl: './document-button.component.html',
    styleUrls: ['./document-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentButtonComponent {
    @Input() featureToken?: string[] = [];
    @Input() visible = false;
    @Input() loading = true;
    @Input() featureName = '';
    @Input() class = '';
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ required: true }) uiUrl!: string;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ required: true }) imgUrl!: string;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ required: true }) label!: string;
    @Output() readonly onclick = new EventEmitter();
    buttonClick(event: Event) {
        this.onclick.emit(event);
    }
}
