import { createReducer, on } from '@ngrx/store';
import { ProductDetail } from '../../../models/product-detail';
import { loadProduct, loadProductSuccess, resetProduct } from './product.actions';

export interface ProductState {
    productDetail?: ProductDetail[];
    customerId?: string;
    contractId?: string;
    documentValue?: string;
}

export const initialState: ProductState = {
    documentValue: undefined
};

export const productRecuder = createReducer(
    initialState,
    on(loadProductSuccess, (state, { productDetail }) => {
        return { ...state, productDetail };
    }),
    on(loadProduct, (state, { customerId, contractId, documentValue }) => {
        return { ...state, productDetail: undefined, customerId, contractId, documentValue };
    }),
    on(resetProduct, state => {
        return { ...state, productDetail: undefined, customerId: undefined, contractId: undefined, documentValue: undefined };
    })
);
