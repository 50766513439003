import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PaymentProps } from '../payment/models/payment-props.enum';

export function paymentFormValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
        const paymentTypeControl = form.get('paymentType');
        const amountControl = form.get('amount');

        if (!paymentTypeControl || !amountControl) {
            return null;
        }

        const paymentType = paymentTypeControl.value;

        if (!paymentType) {
            return null;
        }

        const amount = amountControl.value;

        if (
            paymentType === PaymentProps.TYPE_OTHER_AMOUNT
            && (amount <= 0)
        ) {
            return { amount: true };
        }

        return null;
    };
}