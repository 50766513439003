import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppFacadeService } from '../../services/app-facade.service';
import { VersionToggleService } from '../../services/version-toggle';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { selectIsConfigSet } from '../selector/redirection.selectors';
import { ConsumerPortalState } from '../../types/state.type';
import { setConsumerPortalState } from '../actions/view-state.actions';

@Injectable()
export class StatesEffects {
    constructor(
        private readonly appFacadeService: AppFacadeService,
        private readonly store: Store,
        private readonly versionToggleService: VersionToggleService,
        private readonly featureToggleService: FeatureToggleService
    ) {}

    states$ = createEffect(() =>
        combineLatest([this.appFacadeService.getAuthenticated(), this.appFacadeService.getRedirected(), this.store.select(selectIsConfigSet)]).pipe(
            distinctUntilChanged(),
            map(([isAuthenticated, redirected, config]) => {
                let consumerPortalState: ConsumerPortalState = 'None';
                if (config && isAuthenticated === false) {
                    if (this.versionToggleService.isQuickLinkVersion()) {
                        consumerPortalState = 'redirected';
                    } else {
                        if (this.featureToggleService.isAvailable('consumerLogin') === true) {
                            consumerPortalState = 'login';
                        } else {
                            consumerPortalState = 'embeded-login';
                        }
                    }
                } else if (config && isAuthenticated === true) {
                    if (this.versionToggleService.isQuickLinkVersion()) {
                        consumerPortalState = 'portal';
                    } else {
                        if (redirected) {
                            consumerPortalState = 'portal';
                        } else {
                            consumerPortalState = 'redirected';
                        }
                    }
                }
                if (!isAuthenticated) {
                    if (redirected === true) {
                        this.appFacadeService.setRedirected(false);
                    }
                    this.appFacadeService.setLoading(false);
                }

                return setConsumerPortalState({ consumerPortalState: consumerPortalState });
            })
        )
    );
}
