import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator, ValidationErrors } from '@angular/forms';
import { AppFormValidators } from '../../validators/app.validators';
import { PhoneNumber } from '../../models/phonenumber.model';
import { PrimitiveDataType } from '../../models/primitive.model';

@Component({
    selector: 'por-intl-tel-input',
    templateUrl: './intl-tel-input.component.html',
    styleUrls: ['./intl-tel-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IntlTelInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IntlTelInputComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntlTelInputComponent implements ControlValueAccessor, Validator, OnInit {
    @Input() cssClass = '';
    @Input() preferredCountries: string[] = ['us'];
    @Input() enableAutoCountrySelect = false;
    @Input() enablePlaceholder = true;
    @Input() searchCountryFlag = true;
    @Input() selectFirstCountry = true;
    @Input() maxLength = 15;
    @Input() phoneValidation = true;
    @Input() separateDialCode = true;
    @Input() styleInput = '';
    phone = new FormControl('', [AppFormValidators.phoneValidatorInternational]);

    private onChange!: (value: PrimitiveDataType) => void;
    private onTouched!: () => void;

    ngOnInit(): void {
        this.phone.valueChanges.subscribe(value => {
            if (this.onChange) {
                const e164Number = this.getE164Number(value);
                this.onChange(e164Number as unknown as PrimitiveDataType);
            }

            if (value && !this.phone.touched) {
                this.phone.markAsTouched();
            }
        });

        this.phone?.statusChanges.subscribe(status => {
            this.updateCssClass(status);
        });
    }

    writeValue(value: PhoneNumber | string): void {
        this.phone.setValue(value as unknown as string, { emitEvent: false });
    }

    registerOnChange(fn: (value: PrimitiveDataType) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.phone.disable();
        } else {
            this.phone.enable();
        }
    }

    validate(): ValidationErrors | null {
        return this.phoneValidation ? this.phone.errors : null;
    }

    updateCssClass(status: string) {
        const baseClasses = this.cssClass.split(' ').filter(c => c !== 'border-error' && c !== 'shadow-error');

        if (status === 'INVALID') {
            // Add 'border-error' and 'shadow-error' classes if the control is invalid and touched
            this.cssClass = [...baseClasses, 'border-error', 'shadow-error'].join(' ');
        } else {
            // Otherwise, just maintain the base classes without 'border-error' and 'shadow-error'
            this.cssClass = baseClasses.join(' ');
        }
    }

    private getE164Number(value: PhoneNumber | string | null): string | null {
        if (typeof value === 'string') {
            return value;
        }

        if (value && value.e164Number) {
            return value.e164Number;
        }
        return null;
    }
}
