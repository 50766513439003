<div class="instructions-wrapper">
    <ng-container *ngIf="appFacadeService.isPaymentProcessorsAvailable() | async">
        <p cdkOverlayOrigin #payMultipleContractTrigger="cdkOverlayOrigin" (click)="toggleOverlay('payMultipleContract')">
            {{ 'PayMultipleContract' | translate }}
            <span apxIcon icon="info"></span>
        </p>
        <p cdkOverlayOrigin #paySingleContractTrigger="cdkOverlayOrigin" (click)="toggleOverlay('paySingleContract')">
            {{ 'PaySingleContract' | translate }}
            <span apxIcon icon="info"></span>
        </p>
        <por-overlay
            [featureName]="'multiple-payment-info'"
            [isOpen]="overlayStates.payMultipleContract"
            [trigger]="payMultipleContractTrigger"
            (closeOverlayClick)="toggleOverlay('payMultipleContract')">
            <h3>
                {{ 'PayMultipleContract' | translate }}
                <span>({{ 'InOnePayment' | translate }})</span>
            </h3>
            <p>{{ 'PayMultipleContractText_1' | translate }}</p>
            <p>{{ 'PayMultipleContractText_2' | translate }}</p>
            <p>{{ 'PayMultipleContractText_3' | translate }}</p>
            <p>{{ 'PayMultipleContractText_4' | translate }}</p>
            <p>{{ 'MinimumPaymentAmount' | translate }} {{ '1' | formatCurrency }} .</p>
        </por-overlay>

        <por-overlay [featureName]="'single-payment-info'" [isOpen]="overlayStates.paySingleContract" [trigger]="paySingleContractTrigger" (closeOverlayClick)="toggleOverlay('paySingleContract')">
            <h3>{{ 'PaySingleContract' | translate }}</h3>
            <p>{{ 'PaySingleContractText_1' | translate }}</p>
            <p>{{ 'PaySingleContractText_2' | translate }}</p>
            <p>{{ 'MinimumPaymentAmount_2' | translate }} {{ '1' | formatCurrency }} .</p>
        </por-overlay>
    </ng-container>
    <p cdkOverlayOrigin #viewContractDetailTrigger="cdkOverlayOrigin" (click)="toggleOverlay('viewContractDetail')">
        {{ 'ViewContractDetails' | translate }}
        <span apxIcon icon="info"></span>
    </p>

    <por-overlay [featureName]="'view-contract-info'" [isOpen]="overlayStates.viewContractDetail" [trigger]="viewContractDetailTrigger" (closeOverlayClick)="toggleOverlay('viewContractDetail')">
        <h3>{{ 'ViewContractDetails' | translate }}</h3>
        <p>{{ 'ViewContractDetailText_1' | translate }}</p>
        <p>{{ 'ViewContractDetailText_2' | translate }}</p>
        <div class="list-item">{{ 'ViewContractDetailText_3' | translate }}</div>
        <div class="list-item">{{ 'ViewContractDetailText_4' | translate }}</div>
    </por-overlay>
</div>
