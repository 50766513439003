import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import isEmpty from 'lodash-es/isEmpty';
import { catchError, exhaustMap, finalize, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ApiHeaders } from '../../../models/api-query.model';
import { Invoice } from '../../../models/invoice-model';
import { AppFacadeService } from '../../../services/app-facade.service';
import { ConsumerPortalApiService } from '../../../services/consumer-portal-api.service';
import { fetchInvoices, loadInvoicesAction, setInvoicesLoading, setNoMoreRecordInvoices } from './invoices.actions';
import { getPageNumberInvoices } from './invoices.selectors';

@Injectable()
export class InvoicesEffects {
    constructor(private readonly actions$: Actions, private readonly consumerPortalApi: ConsumerPortalApiService, private readonly appFacadeService: AppFacadeService, private readonly store: Store) {}

    fetchInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchInvoices),
            tap(() => this.appFacadeService.setLoading(true)),
            withLatestFrom(this.store.pipe(select(getPageNumberInvoices))),
            exhaustMap(([action, pageNumber]) => {
                const { customerId, startDate, endDate, search } = action;

                return this.getInvoicesRecords(customerId, pageNumber, startDate, endDate, search).pipe(
                    switchMap(items => {
                        if (items.length <= 0) {
                            return of(setNoMoreRecordInvoices({ record: true }));
                        }

                        // Emit all accumulated actions
                        return of(loadInvoicesAction({ invoices: items }), setNoMoreRecordInvoices({ record: false }));
                    }),
                    catchError(() => {
                        // Handle the error by dispatching relevant actions
                        return of(setNoMoreRecordInvoices({ record: true }), setInvoicesLoading({ loading: false }));
                    }),
                    finalize(() => {
                        this.appFacadeService.setLoading(false);
                    })
                );
            })
        )
    );

    getInvoicesRecords(customerId: number | string, pageNumber: number = 1, startDate?: string | undefined, endDate?: string | undefined, search?: string | undefined) {
        const url = `customer/${customerId}/invoices?`;
        let headers: ApiHeaders = {};
        if (pageNumber && customerId) {
            headers = {
                /* eslint-disable @typescript-eslint/naming-convention */
                /**
                 * Note camelCase : DB Model
                 */
                ...headers,
                'x-paging': JSON.stringify({
                    page: pageNumber,
                    pageSize: 10
                })
            };
        }
        const filters = [];
        if (startDate) {
            filters.push({ field: 'InvoiceDate', type: '>=', value: startDate + ' 00:00:01' });
        }
        if (endDate) {
            filters.push({ field: 'InvoiceDate', type: '<=', value: endDate + ' 11:59:59' });
        }
        if (search && !isEmpty(search)) {
            filters.push({ field: 'InvoiceNumber', type: 'LIKE', value: search });
        }
        if (filters.length > 0) {
            headers = {
                ...headers,
                'x-filter': JSON.stringify(filters)
            };
        }
        return this.consumerPortalApi.get<Invoice>(url, { headers: headers });
    }
}
