<div class="payment-page">
    <div class="header">
        <h1>
            <span>{{ 'Online Payment Form' | translate }}</span>
        </h1>
        <por-base-button [featureName]="'payment-detail-close'" (click)="closeButtonPanel()" icon="close"></por-base-button>
    </div>
    <div *ngIf="isPaymentBoundaryAvailable$ | async as isAvailable; else noBoundaryFound">
        <form class="form-wrapper center-form" [formGroup]="form" (ngSubmit)="createTransaction()">
            <ng-container *ngIf="(appFacadeService.getTransactionLoaded() | async) === false">
                <div class="form-group totals">
                    <div class="totals-container">
                        <p>
                            <span class="label">{{ 'Contract #' | translate }}:</span>
                            <strong class="value">{{ contract.Name }}</strong>
                        </p>
                        <p>
                            <span class="label">{{ 'ContractAmount' | translate }}:</span>
                            <strong class="value" [innerHTML]="formatAmount(contract.AmountDue | formatCurrency)"></strong>
                        </p>
                        <p>
                            <span class="label">{{ 'Total Paid' | translate }}:</span>
                            <strong class="value" [innerHTML]="formatAmount(contractService.getContractTotalPaid(contract) | formatCurrency)"></strong>
                        </p>
                        <p *ngIf="isPaymentIncludeDeposit">
                            <span class="label">{{ 'Deposit' | translate }}:</span>
                            <strong class="value" [innerHTML]="formatAmount(depositAmount | formatCurrency)"></strong>
                        </p>
                        <p>
                            <span class="label">{{ 'TotalDue' | translate }}:</span>
                            <strong class="value" [innerHTML]="formatAmount(totalAmountDue | formatCurrency)"></strong>
                        </p>
                    </div>
                </div>
                <hr />
                <ng-container [ngTemplateOutlet]="paymentRadioBtns" [ngTemplateOutletContext]="{ templateForm: form }"></ng-container>
                <div class="form-group payment-btns" *ngIf="isAnyPaymentOptionVisible">
                    <div class="row">
                        <por-base-button
                            [paymentButtonStyle]="'payment-btn'"
                            type="submit"
                            theme="primary"
                            class="btn-success border-btn-green"
                            [disabled]="form.invalid | convertBoolean"
                            featureName="make-a-payment">
                            {{ 'Click to Make a Secure Payment' | translate }}
                        </por-base-button>
                    </div>
                </div>
            </ng-container>
        </form>

        <ng-container *ngIf="appFacadeService.getTransactionLoaded() | async">
            <div *ngIf="appFacadeService.getPaymentAppInput() | async as paymentAppInput">
                <por-payment-app-credit-card
                    id="paymentAppCreditCard"
                    *axLazyElement="porPayURL"
                    [paymentAppInput]="paymentAppInput"
                    (paymentAppOutput)="appFacadeService.onPaymentFinished($any($event))"></por-payment-app-credit-card>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #paymentRadioBtns let-form="templateForm">
    <div class="payment-form" [formGroup]="form">
        <div class="form-group radios" *ngIf="showPaymentRadioButtons(3)">
            <div class="row d-flex">
                <por-base-radio
                    [style]="'baseRadioStyle'"
                    [values]="[{ text: payMinimumAmountText, value: 3 }]"
                    formControlName="paymentType"
                    [model]="form.value?.paymentType"
                    [required]="true"></por-base-radio>
                <p class="strong-text">{{ minimumPaymentDue | formatCurrency }}</p>
            </div>
        </div>
        <div *ngIf="showPaymentRadioButtons(2)">
            <div class="form-group radios">
                <div class="form-group-wrapper row amount-wrapper">
                    <div class="col-xs-12">
                        <por-base-radio
                            [style]="'baseRadioStyle'"
                            [values]="[{ text: 'Pay Other Amount' | translate, value: 2 }]"
                            formControlName="paymentType"
                            [model]="form.value?.paymentType"
                            [required]="true"></por-base-radio>
                    </div>
                    <div class="col-xs-12">
                        <por-base-input
                            label="{{ 'Enter Amount' | translate }}"
                            class="amount-control"
                            formControlName="amount"
                            type="number"
                            [required]="false"
                            [model]="form.value?.amount"
                            (click)="selectOtherType()"
                            [styleInput]="'payment-input-style'"></por-base-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group radios">
            <div class="row d-flex" *ngIf="showPaymentRadioButtons(1)">
                <por-base-radio
                    [style]="'baseRadioStyle'"
                    [values]="[{ text: 'PayTotalAmountDue' | translate, value: 1 }]"
                    formControlName="paymentType"
                    [model]="form.value?.paymentType"
                    [required]="true"></por-base-radio>
                <p class="strong-text">{{ maxPaymentDue | formatCurrency }}</p>
            </div>
        </div>
        <p *ngIf="!isAnyPaymentOptionVisible">{{ 'MinimumAmountPayable' | translate }}</p>
    </div>
</ng-template>

<ng-template #noBoundaryFound>
    <p class="text-align-center" *ngIf="isNoPaymentBoundaryFound | async">{{ 'IssueWithPaymentBoundary' | translate }}</p>
</ng-template>
<ng-container *ngIf="appFacadeService.isDialogEnabled() | async">
    <por-app-confirm-dialog [message]="(appFacadeService.getPaymentError() | async) ?? ''" (confirmed)="appFacadeService.closePaymentDialog()"></por-app-confirm-dialog>
</ng-container>
