import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { Position } from '../header/header.component';

@Component({
    selector: 'por-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
    inputs: ConsumerPortalConfig | undefined;
    @Input() config: string | undefined;
    @Input() contractId: string | undefined;
    @Input() width: string | undefined;
    @Input() height: string | undefined;
    @Output() readonly elementLoaded = new EventEmitter<boolean>();
    @Output() readonly appOutput: EventEmitter<{ [key: string]: object }> = new EventEmitter<{ [key: string]: object }>();
    @Input() logo: string | undefined;
    @Input() footerText: string | undefined;
    @Input() position: Position | string = Position.Left;
    @ViewChild('footer') footer = {} as ElementRef;
    ngOnInit(): void {
        this.elementLoaded.emit(true);
    }
}
