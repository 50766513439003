import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formLineItemName'
})
export class FormLineItemNamePipe implements PipeTransform {

  transform(value: string| number | null): string| number| null {
    if(typeof value === 'string'){
      const lineItemNameArray:string[] = value.split('(')
      return `<span>${lineItemNameArray?.[0]}</span> <span>(${lineItemNameArray?.[1]}</span>`;
    }
    return value;
  }

}
