import { ModuleWithProviders, NgModule } from '@angular/core';
import { PendoService } from './services/pendo.service';
import { TranslateService } from '@ngx-translate/core';

@NgModule({})
export class PendoModule {
    static forRoot({ apiKey }: {apiKey: string}): ModuleWithProviders<PendoModule> {
      const providers = [];
      if(apiKey){
        providers.push( { provide: 'PENDO_API_KEY', useValue: apiKey });
      }
        return {
            ngModule: PendoModule,
            providers: [ TranslateService, PendoService, ...providers]
        };
    }
}
