<div class="form-container por-change-password">
    <h2 class="theme-header">{{ 'Change Password' | translate }}</h2>
    <form [formGroup]="changePasswordForm" (ngSubmit)="submit()" novalidate>
        <apx-form-field
            class="apx-input-container d-inline-flex mb-sm"
            [error]="code.invalid && (code.dirty || code.touched) ? 
            code.errors?.['required'] ? ('Please enter verfication code.' | translate) : 
            code.errors?.['minlength'] ? ('Code length should be 6.' | translate) :'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="lock"></span>
                <input
                    apxInput
                    formControlName="code"
                    type="text"
                    name="code"
                    placeholder="{{ 'Enter your verfication code.' | translate }}"
                    class="theme-input"
                    maxlength="6"
                    porAddFeature
                    featureName="change-password-code-input" />
            </apx-input-group>
        </apx-form-field>

        <apx-form-field
            class="apx-input-container d-inline-flex mb-sm"
            [error]="password.invalid && (password.dirty || password.touched) ? 
            password.errors?.['required'] ? ('Please enter password.' | translate) : 
            password.errors?.['minlength'] ? ('Password minimum length should be 6.' | translate) : 
            password.errors?.['maxlength'] ? ('max_50_chars_permitted' | translate):'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="lock"></span>
                <input
                    apxInput
                    formControlName="password"
                    type="password"
                    name="password"
                    placeholder="{{ 'Enter your new password' | translate }}"
                    class="theme-input"
                    maxlength="50"
                    porAddFeature
                    featureName="change-newpassword-input" />
            </apx-input-group>
        </apx-form-field>

        <button class="btn-primary btn-full-width theme-button" type="submit" apxButton variant="primary" [disabled]="changePasswordForm.pristine || changePasswordForm.invalid || isDisableButton">
            {{ 'Change Password' | translate }}
        </button>
    </form>
    <p class="left-align theme-paragraph">
        {{ 'Already have an account?' | translate }}
        <a porAddFeature featureName="change-password-signin-button" href="#" (click)="setTemplate('signin')" class="theme-anchor">{{ 'Sign in here' | translate }}</a>
    </p>
</div>
