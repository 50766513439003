import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'por-base-toggle',
    templateUrl: './base-toggle.component.html',
    styleUrls: ['./base-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseToggleComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseToggleComponent implements ControlValueAccessor, OnInit {
    @Input() model: boolean | null | undefined;
    @Input() parentForm!: FormGroup;
    @Input() color = 'primary';
    @Input() tooltip!: string;
    @Input() public formControlName!: string;
    @Output() readonly modelChange = new EventEmitter<boolean>();
    @Input() featureName = '';

    public value!: boolean;
    @Input() disabled = false;
    public changed!: (value: string) => void;
    public touched!: () => void;

    ngOnInit(): void {
        if (this.model === undefined || this.model === null) {
            this.model = false;
        }
    }
    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: () => void): void {
        this.changed = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    toggle(): void {
        this.model = !this.model;
        this.modelChange.emit(this.model);
    }
}
