import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ConsumerPortalAdminConfig } from '../../../../models/consumer-portal-admin-config';
import { VersionToggleService } from '../../../../services/version-toggle';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsToLoad } from '../../../../enums/components-to-load.enum';
import { Navigation } from '../../../../models/navigation.model';
import { FeatureToggleService } from '../../../../services/feature-toggle.service';
import { AppMediatorService } from '../../../../services/app-mediator.service';

@Component({
    selector: 'por-admin-wysiwyg',
    templateUrl: './admin-wysiwyg.component.html',
    styleUrls: ['./admin-wysiwyg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminWysiwygComponent implements OnInit {
    @Input() adminConfig!: ConsumerPortalAdminConfig;
    @Input() logoUrl!: string | null | undefined;

    navigations: Navigation[] = [];

    constructor(
        readonly versionService: VersionToggleService,
        private readonly translateService: TranslateService,
        private readonly featureToggleService: FeatureToggleService,
        private readonly appMediatorService: AppMediatorService
    ) {}

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        if (this.versionService.isAdvanceVersion()) {
            [
                {
                    tab: ComponentsToLoad.AccountSummary,
                    text: this.translateService.instant('Home'),
                    isActive: true
                },
                {
                    tab: ComponentsToLoad.Contracts,
                    text: this.translateService.instant('Contracts')
                },
                this.featureToggleService.isAvailable('invoices') && {
                    tab: ComponentsToLoad.Invoices,
                    text: this.translateService.instant('Invoices')
                },
                {
                    tab: ComponentsToLoad.ItemsOut,
                    text: this.translateService.instant('Items Out')
                },
                {
                    tab: ComponentsToLoad.Tracking,
                    text: this.translateService.instant('Tracking')
                }
            ].map(nav => {
                if (nav) {
                    this.navigations.push(nav);
                }
            });
        }
    }
}
