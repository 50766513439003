import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentState } from './payment.state';
import { PaymentAppPayOutput, TransactionData, TransactionResultEnum } from '../../../payment/models/paymentOutput';

export const selectPaymentState = createFeatureSelector<PaymentState>('payment');

export const selectMultipaymentBoundaries = createSelector(selectPaymentState, (state: PaymentState) => state.paymentBoundaries);

export const selectTransactionLoaded = createSelector(selectPaymentState, (state: PaymentState) => state.transactionLoaded);

export const selectPaymentAppInput = createSelector(selectPaymentState, (state: PaymentState) => state.paymentAppInput);

export const selectConfig = createSelector(selectPaymentState, (state: PaymentState) => {
    state.customerId, state.organizationId;
});

export const selectPaymentAppOutput = createSelector(selectPaymentState, (state: PaymentState) => state.paymentAppOutput);

export const selectErrorString = createSelector(selectPaymentState, (state: PaymentState) => state.error);

export const selectDialogEnabled = createSelector(selectPaymentState, (state: PaymentState) => state.dialogEnabled);

export const selectLastPaymentDetail = createSelector(selectPaymentState, (state: PaymentState) => state.lastPaymentDetail);

export const selectCrossAppEvent = createSelector(selectPaymentState, (state: PaymentState) => state.crossAppEvent);

export const selectPaymentSectionEnabled = createSelector(selectPaymentState, (state: PaymentState) => state.paymentSectionEnabled);

export const selectIsPaymentProcessorAvailable = createSelector(selectPaymentState, (state: PaymentState) => state.isPaymentProcessorAvailable);

export const selectLastPaymentDetailTransactionData = createSelector(selectLastPaymentDetail, (lastPaymentDetail: PaymentAppPayOutput | undefined) => lastPaymentDetail?.TransactionData);

export const selectLastPaymentDetailTransactionId = createSelector(selectLastPaymentDetail, (lastPaymentDetail: PaymentAppPayOutput | undefined) => lastPaymentDetail?.TransactionId);

export const selectLastPaymentDetailTransactionStatus = createSelector(selectLastPaymentDetail, (lastPaymentDetail: PaymentAppPayOutput | undefined) => lastPaymentDetail?.TransactionStatus);

export const selectIsPaymentApproved = createSelector(selectLastPaymentDetailTransactionData, (transactionData: TransactionData | undefined) => {
    if (transactionData && transactionData?.TransactionResultEnum) {
        const resultEnum: TransactionResultEnum = transactionData?.TransactionResultEnum;
        return resultEnum === TransactionResultEnum.APPROVED || resultEnum === TransactionResultEnum.APPROVEDWITHWARNING || resultEnum === TransactionResultEnum.PENDING;
    }
    return false;
});

export const selectTransactionCurrencyCode = createSelector(selectLastPaymentDetailTransactionData, (transactionData: TransactionData | undefined) => {
    return transactionData ? transactionData?.CurrencyCode : '';
});
