import { Payment } from '../../models/contract-model';

export interface PayOutputEvent extends CustomEvent {
    detail: PaymentAppPayOutput;
}

/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: Return from Payment App
export interface PaymentAppPayOutput {
    /**
     * The UUID that the Payment App uses to refer to the Payment Transaction that was created. This UUID can be used in other API calls.
     */
    TransactionId?: string;
    /**
     * A 3-digit response code referring to the result of the communication with the processor. It is modeled after the HTTP status codes.
     */
    ResponseCode?: 200 | 102 | 400 | 403 | 404 | 499 | 500;
    /**
     * A human-readable message that elaborates on the ResponseCode.
     */
    Message: string;
    /**
     * The standard ProcessorTypeEnum as defined by ProcessorTypeEnum.ts
     */
    ProcessorTypeEnum?: 1 | 2 | 3 | 8;
    /**
     * The full Payment Transaction data. If this conflicts with the definition on Transaction.table.json, use that one instead.
     */
    TransactionData?: {
        /**
         * The primary key for the Payment App Transaction table
         */
        TransactionId?: string;
        /**
         * Whether this Transaction is soft deleted or not
         */
        Hidden?: number;
        /**
         * The timestamp when this record was created
         */
        Created?: string;
        /**
         * The timestamp when this record was last updated
         */
        Updated?: string;
        /**
         * The Global OrganizationId that this Transaction was created for
         */
        OrganizationId?: number;
        /**
         * The ID that the credit card processor uses to identify this Transaction. This ID can be used to make actions against the credit card processor directly.
         */
        ProcessorTransactionId?: string;
        /**
         * The TerminalId that this Transaction was created on, if one exists
         */
        TerminalId?: string;
        /**
         * The CustomerId that corresponds to the Payment App Customer table
         */
        CustomerId?: number;
        /**
         * The integer representing the amount of the smallest denomination of currency. For example, 10000 Amount with a CurrencyCode of USD for $100.00
         */
        Amount_Requested?: number;
        /**
         * The integer representing the amount of the smallest denomination of currency. For example, 10000 Amount with a CurrencyCode of USD for $100.00
         */
        Amount_Processed?: number;
        /**
         * The 3-letter ISO 4217 uppercase currency code
         */
        CurrencyCode?: string;
        /**
         * The type of Payment Method 1: Credit Card. 4: ACH.
         */
        PaymentMethodEnum?: 1 | 4;
        /**
         * The type of this Transaction. Refer to Transaction.table.ts
         */
        TransactionTypeEnum?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        /**
         * The state of this Transaction. Initially created as 1 (PENDING) and moves to any of the other 4 states. Refer to Transaction.table.ts.
         */
        TransactionResultEnum?: TransactionResultEnum;
        /**
         * The last four digits of the input credit card (when available)
         */
        CCLastFourDigits?: string;
        /**
         * The expiration date of the input credit card (when available)
         */
        CCExpiration?: string;
        /**
         * The issuer of the input credit card (when available)
         */
        CCIssuer?: string;
        /**
         * The tokenization string of the input credit card (when available)
         */
        CCToken?: string;
        /**
         * The ISO 7816-5 application identifier
         */
        AID?: string;
        [k: string]: unknown;
        ApplicationFeeBasisPoints?: number | null;
        IsCustomerFacing: number;
    };
    FormData?: {
        [k: string]: unknown;
    };
    Error?: string;
    Customer?: CustomerData;
    TransactionStatus?: {
        StatusCode: number;
        Key: string;
        Description: string;
        Category: number;
        Message?: string;
    };
}

export enum TransactionResultEnum {
    PENDING = 1,
    DECLINED = 2,
    CANCELLED = 3,
    ERROR = 4,
    APPROVED = 5,
    APPROVEDWITHWARNING = 6
}
export interface CustomerData {
    Country?: string | null;
    Name?: string | null;
    Email?: string | null;
    Address1?: string | null;
    Address2?: string | null;
    City?: string | null;
    State?: string | null;
    PostalCode?: string | null;
    [k: string]: unknown;
}

export interface PorPayError {
    code?: string | number; // Error code
    message: string; // Human-readable error message
}

export interface PorPayEvent {
    response?: PaymentAppPayOutput | Payment; // Response object for a successful transaction
    error?: PorPayError; // Error object for failed transactions
}

export interface VerifyTransaction extends Payment {
    data?: PaymentAppPayOutput;
    token?: string;
}
