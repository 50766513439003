import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { ContractCP } from '../../models/contract-model';
import { RowLabelValue } from '../../models/row-label-value-model';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { FormatCurrencyPipe } from '../../pipes/format-currency.pipe';
import { AppFacadeService } from '../../services/app-facade.service';
import { ContractService } from '../../services/contract.service';

@Component({
    selector: 'por-contract-full-width-row',
    templateUrl: './contract-full-width-row.component.html',
    styleUrls: ['./contract-full-width-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractFullWidthRowComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    public contract!: ContractCP;

    public contractKV: RowLabelValue[] = [];
    public dateFormat = '';
    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly date: DatePipe,
        readonly featureService: FeatureToggleService,
        private readonly formatCurrency: FormatCurrencyPipe,
        readonly appFacadeService: AppFacadeService,
        private readonly contractService: ContractService
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.contract = { ...params.data };
        this.contractKV = [
            {
                label: 'contract',
                value: this.contract?.contractName
            },
            {
                label: 'status',
                value: this.contract?.status
            },
            {
                label: 'startDate',
                value: this.date.transform(this.contract?.openDate, this.dateFormat)
            },
            {
                label: 'closeDate',
                value: this.date.transform(this.contract?.closeDate, this.dateFormat)
            },
            {
                label: 'purchaseOrder',
                value: this.contract?.purchaseOrder
            },
            {
                label: 'location',
                value: this.contract?.info
            }
        ];

        if (this.featureService.isAvailable('amoutDueFieldVisible')) {
            this.contractKV.splice(1, 0, {
                label: 'amountDue',
                value: this.contractService.getAmountDueText(this.contract.AmountDue, this.contract.paymentPending)
            });
        }

        if (this.featureService.isAvailable('amountFieldVisible')) {
            this.contractKV.splice(1, 0, {
                label: 'amount',
                value: this.formatCurrency.transform(String(this.contract.GrandTotal))
            });
        }

        if (this.featureService.isAvailable('contractGridJobColumnAvailable')) {
            this.contractKV.push({
                label: 'jobNumber',
                value: this.contract?.jobNumber
            });
        }
    }

    refresh() {
        return true;
    }

    loadContractDetail() {
        this.params.context?.componentParent.loadContractDetailModal(this.contract?.contractId);
    }

    onCheckboxChange(isChecked: boolean): void {
        if (this.params.context && this.params.context.componentParent) {
            this.params.context.componentParent.onFullWidthRowSelectionChanged(isChecked, this.contract.contractId);
        }
    }
}
