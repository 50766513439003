<div id="mainportalpage" [ngStyle]="{ height: height, width: width }" class="wrapper">
    <div class="account-summary por-ac-summary" [ngClass]="{ loading: isLoading }">
        <ngx-skeleton-loader count="4" *ngIf="!(customerSummary$ | async | count); else showLeft" count="4" appearance="circle"></ngx-skeleton-loader>
        <ng-template #showLeft>
            <div class="account-summary-details">
                <div class="details-section">
                    <div *ngIf="customer$ | async | count">
                        <p class="theme-paragraph">
                            {{ (customer$ | async)?.Name }}
                        </p>
                    </div>
                </div>
                <div class="summary-section">
                    <div (click)="openContractsAndFilter('Quote')" porAddFeature featureName="account-summary-quotes-button">
                        <h3 class="theme-heading">
                            {{ (customerSummary$ | async)?.totalQuotes ?? 0 }}
                        </h3>
                        <span class="theme-paragraph">{{ 'Quotes' | translate }}</span>
                    </div>
                    <div (click)="openContractsAndFilter('Open')" porAddFeature featureName="account-summary-open-button">
                        <h3 class="theme-heading">
                            {{ (customerSummary$ | async)?.totalOpen ?? 0 }}
                        </h3>
                        <span class="theme-paragraph">{{ 'Open' | translate }}</span>
                    </div>
                    <div (click)="openContractsAndFilter('Reservation')" porAddFeature featureName="account-summary-reservation-button">
                        <h3 class="theme-heading">
                            {{ (customerSummary$ | async)?.totalReservation ?? 0 }}
                        </h3>
                        <span class="theme-paragraph">{{ 'Reservations' | translate }}</span>
                    </div>
                    <div (click)="openContractsAndFilter('Returned')" porAddFeature featureName="account-summary-returned-button">
                        <h3 class="theme-heading">
                            {{ (customerSummary$ | async)?.totalReturned ?? 0 }}
                        </h3>
                        <span class="theme-paragraph">{{ 'Returned' | translate }}</span>
                    </div>
                </div>
            </div>
        </ng-template>
        <por-ad-images [customerId]="customerId" [loading]="isLoading" [layout]="'columns2'"></por-ad-images>
    </div>
</div>
