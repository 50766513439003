import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fetchItemsOut } from '../store/actions/items-out-action.actions';
import { LoggerService } from '../services/logger.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import isEmpty from 'lodash-es/isEmpty';
import { FilterOutput } from '../shared/models/filters.model';
import { DatePipe } from '@angular/common';
import { CallOffRequestResponse, ItemOutCP } from '../models/item-out-model';
import { ReturnRequestType } from '../enums/return-request-type.enum';
import { DateUtility } from '../date.utility';
import { DateFormatType } from '../enums/date-format-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ItemOutService {
    dateFormat: string;

    constructor(
        private readonly store: Store,
        private readonly logger: LoggerService,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly datePipe: DatePipe,
        @Inject(LOCALE_ID) locale: string
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }
    getItemsOut({
        customerId,
        startDate,
        endDate,
        search,
        status
    }: {
        customerId: number | string;
        status?: string | undefined;
        startDate?: string | undefined;
        endDate?: string | undefined;
        search?: string;
    }) {
        this.store.dispatch(fetchItemsOut({ customerId, startDate, endDate, search, status }));
    }

    calloffrent({
        items,
        customerId,
        customerName,
        accountName,
        isSecure,
        customerPhone,
        email,
        jobSiteName,
        jobSitePhone,
        requestedDate,
        requestedTime,
        gateCombo,
        address,
        comments,
        returnType
    }: {
        items: ItemOutCP[];
        customerId: string;
        customerName?: string | null;
        accountName?: string;
        isSecure?: string | null;
        customerPhone?: string | null;
        email?: string | null;
        jobSiteName?: string | null;
        jobSitePhone?: string | null;
        requestedDate?: string | null;
        requestedTime?: string | null;
        gateCombo?: string | null;
        address?: string | null;
        comments?: string | null;
        returnType: ReturnRequestType;
    }): Observable<CallOffRequestResponse> {
        const itemsData = items.map((item: ItemOutCP) => {
            return {
                itemId: item.id,
                itemName: item.itemName,
                contractId: item.contractId,
                serialNumber: item.serialNumber,
                contractDate: this.datePipe.transform(item.startDate, this.dateFormat) as string
            };
        });
        let formattedDate = '';
        let formattedTime = '';
        if (requestedDate) {
            formattedDate = this.datePipe.transform(requestedDate, this.dateFormat) as string;
        }
        if (requestedTime) {
            const dateParts = requestedTime.split(':');
            const date = new Date();
            date.setHours(+dateParts[0], +dateParts[1]);

            formattedTime = this.datePipe.transform(date, 'HH:mm') as unknown as string;
        }

        const params = {
            controller: `customer/${customerId}/calloffrent`,
            method: 'POST',
            body: {
                itemsData,
                isSecure,
                customerId,
                name: customerName,
                customerName,
                phone: customerPhone,
                email,
                date: requestedDate,
                time: requestedTime,
                jobSiteName,
                jobSitePhone,
                gateCombo,
                address,
                comments: comments ? comments : '',
                returnType,
                accountName,
                formattedDate,
                formattedTime
            }
        };
        return this.consumerPortalApi.post<CallOffRequestResponse>({ ...params }).pipe(
            tap({
                next: (res: CallOffRequestResponse) => {
                    return res;
                },
                error: (err: HttpErrorResponse) => {
                    this.logger.logError(err);
                    this.logger.alertDevError(err);
                    return of(true);
                }
            })
        );
    }

    filter($e: FilterOutput, customerId: string) {
        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? (this.datePipe.transform($e.dates.startDate as string, 'Y-MM-dd') as string) : undefined;

        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? (this.datePipe.transform($e.dates.endDate as string, 'Y-MM-dd') as string) : undefined;

        return this.getItemsOut({
            customerId,
            startDate: startDate,
            endDate: endDate,
            search: $e.search,
            status: $e.singleFilter
        });
    }
}
