<div class="slide-toggle">
    <mat-slide-toggle
        (change)="toggle()"
        [matTooltip]="tooltip !== '' ? tooltip : ''"
        [checked]="model"
        color="primary"
        [name]="formControlName"
        [color]="color"
        porAddFeature
        [featureName]="featureName"></mat-slide-toggle>
</div>
