import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'por-amount-renderer',
    templateUrl: './amount-renderer.component.html'
})
export class AmountRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    cellValue!: string;

    constructor(private readonly cdref: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.cdref.detectChanges();
    }
    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.cellValue = this.getValueToDisplay(params);
    }

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
        return true;
    }

    getValueToDisplay(params: ICellRendererParams) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }
}
