<div class="portalnav-wrap por-ac-invoices">
    <div class="portalnav wrapper" [ngStyle]="{ height: height, width: width }" class="wrapper">
        <div class="wrapper heading-section">
            <h1 class="theme-heading" *ngIf="appFacadeService.getCustomer() | async as customer">{{ 'InvoicesListFor' | translate | uppercase }} {{ customer?.Name }}</h1>
        </div>
        <div id="mainportalpage" class="main-invoice-page">
            <ng-container *ngIf="appFacadeService.getSubActiveTab() | async as tab">
                <div [hidden]="tab !== 'Default'">
                    <ng-container [ngTemplateOutlet]="invoices"></ng-container>
                </div>
                <div [hidden]="tab !== 'InvoiceDetail'">
                    <ng-container [ngTemplateOutlet]="invoiceDetail"></ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #invoices>
    <div class="date-range">
        <por-filters
            [initFilters]="initialStatusFilters"
            [isProcessing]="(appFacadeService.getLoader() | async) || false"
            [searchPlaceholder]="filterComponentProps.searchPlaceHolder"
            [dateLabel]="filterComponentProps.dateLabel"
            (filterOutput)="filter($any($event))"
            (exportCSV)="exportCSV()"
            [uiUrl]="uiUrl"
            [enableExport]="$any(invoices$ | async | count) > 0"
            [startDateLabel]="'InvoiceStartDateLabel'"
            [endDateLabel]="'End Date'"></por-filters>
    </div>
    <div class="content-wrapper">
        <div class="amount-due-wrap" *ngIf="invoiceTotalAmountDue$ | async as invoiceTotalAmountDue" [ngStyle]="applyWidthStyle">
            <span>{{ 'TotalDue' | translate }} : {{ invoiceTotalAmountDue | formatCurrency }}</span>
        </div>

        <ag-grid-angular
            *ngIf="gridReadyObservable$ | async"
            style="width: 100%; height: 100%"
            class="ag-theme-alpine ag-grid"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="invoices$ | async"
            [animateRows]="true"
            [rowHeight]="rowHeight"
            [headerHeight]="headerHeight"
            (gridReady)="onGridReady($event)"
            [isFullWidthRow]="isFullWidthRow"
            [fullWidthCellRenderer]="fullWidthCellRenderer"
            [context]="context"
            [suppressRowClickSelection]="true"></ag-grid-angular>
    </div>
    <div class="middle">
        <por-load-more [hasMoreRecord]="(noMoreRecord$ | async) ?? false" [featureName]="'load-more-invoice'" (loadMore)="loadMore()"></por-load-more>
    </div>
</ng-template>
<ng-template #invoiceDetail>
    <div class="contract-details-page" *ngIf="appFacadeService.getInvoiceDetail() | async as invoiceDetail">
        <por-invoice-detail *ngIf="invoiceDetail" [invoiceDetail]="invoiceDetail" [uiUrl]="uiUrl" (closePanel)="closeInvoiceDetail()"></por-invoice-detail>
    </div>
</ng-template>
