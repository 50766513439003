import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { LinkField } from '../../../enums/link-field';
import { AuthKeys } from '../../../enums/local-storage-keys.enum';
import { Operations, OperationsContract } from '../../../models/auth.model';
import { GetUrlRequest } from '../../../models/get-url-request';
import { GetUrlResponse } from '../../../models/geturl-response';
import { LoginSession } from '../../../models/login-session.model';
import { LoggerService } from '../../../services';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { PdfService } from '../../../services/pdf.service';
import { cancelLoadDocuments, downloadDocument, loadDocument, loadDocumentFinished, loadEsignAndOpen, loadEsignAndOpenFinished, loadLinkAndOpen, loadLinkAndOpenFinished } from './document.actions';
import { DocumentData } from './document.reducers';

@Injectable()
export class DocumentEffects {
    loadDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDocument),
            exhaustMap(({ contractdetails }) => {
                const documentTypes = ['SafetyInstruction'];

                if (this.featureToggleService.isAvailable('displayServiceCertificate')) {
                    documentTypes.push('ServiceCertificate');
                }
                if (this.featureToggleService.isAvailable('displayInspectionDocument')) {
                    documentTypes.push('InspectionCertificate');
                }

                // Trigger downloadDocument actions concurrently
                return from(documentTypes).pipe(map(documentType => downloadDocument({ contractdetails, documentType })));
            })
        )
    );
    downloadDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadDocument),
            mergeMap(({ contractdetails, documentType }) => {
                let documentData: DocumentData = {
                    docType: documentType,
                    url: '',
                    contractId: contractdetails.contractId,
                    orgType: contractdetails.orgType,
                    isURLFound: false,
                    isLoading: true
                };

                return this.pdf.downloadPdf(contractdetails.customerId, contractdetails.contractId, documentType, contractdetails.documentValue).pipe(
                    takeUntil(this.actions$.pipe(ofType(cancelLoadDocuments))),
                    map(data => {
                        documentData = { ...documentData, url: data.URL, isURLFound: true, isLoading: false };
                        return loadDocumentFinished({ documentData });
                    }),
                    catchError(error => {
                        documentData = { ...documentData, url: '', isURLFound: false, docType: documentType, isLoading: false };
                        this.logger.logError(error, '', true);
                        return of(loadDocumentFinished({ documentData }));
                    })
                );
            })
        )
    );

    loadLinkAndOpen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadLinkAndOpen),
            switchMap(() => {
                const isSecurePdf = this.getSecureUrlRequest(LinkField.ContractPdfUrl);

                if (!isSecurePdf) {
                    return of(loadLinkAndOpenFinished());
                }

                return this.pdf.getUrl(isSecurePdf as GetUrlRequest).pipe(
                    map((data: GetUrlResponse) => {
                        if (data.SecureURL !== 'fakeUrl') {
                            this.pdf.openPdfinNewTab(data.SecureURL);
                        }
                        return loadLinkAndOpenFinished();
                    })
                );
            })
        )
    );

    loadEsignAndOpen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadEsignAndOpen),
            switchMap(() => {
                const isEsign = this.getSecureUrlRequest(LinkField.ContractESignUrl);

                if (!isEsign) {
                    return of(loadEsignAndOpenFinished());
                }

                return this.pdf.getUrl(isEsign as GetUrlRequest).pipe(
                    map((data: GetUrlResponse) => {
                        if (data.SecureURL !== 'fakeUrl') {
                            this.pdf.openPdfinNewTab(data.SecureURL);
                        }
                        return loadEsignAndOpenFinished();
                    })
                );
            })
        )
    );

    private getSecureUrlRequest(linkField: LinkField): GetUrlRequest | false {
        const operations = this.appMediatorService.localStorageService.getSessionStorageItem<Operations & LoginSession>(AuthKeys.User);
        if (operations !== undefined && operations?.operations?.contract?.[linkField as keyof OperationsContract] !== undefined) {
            const urlRequest: GetUrlRequest = {
                customerId: operations?.customerId ?? '',
                contractId: operations?.contractId ?? '',
                linkField: linkField
            };

            return urlRequest;
        }
        return false;
    }

    constructor(
        private readonly actions$: Actions,
        private readonly pdf: PdfService,
        private readonly logger: LoggerService,
        private readonly featureToggleService: FeatureToggleService,
        private readonly appMediatorService: AppMediatorService
    ) {}
}
