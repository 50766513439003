import { Customer } from '../../../models/consumer';
import { ContractCP } from '../../../models/contract-model';
import { PaymentMinMaxBoundary } from '../../../payment/models/payment-data.model';
import { PaymentAppPayOutput, PayOutputEvent, PorPayEvent } from '../../../payment/models/paymentOutput';
import { Processors } from '../../../payment/models/processors.interface';

export interface PaymentState {
    customerId?: string;
    organizationId?: string;
    contracts?: ContractCP[];
    customer?: Customer;
    paymentAppOutput?: PayOutputEvent;
    paymentAppInput?: string;
    transactionLoaded: boolean;
    paymentSectionEnabled: boolean;
    paymentBoundaries: PaymentMinMaxBoundary[] | null;
    dialogEnabled: boolean;
    error?: string;
    lastPaymentDetail?: PaymentAppPayOutput;
    crossAppEvent: PorPayEvent | null;
    isPaymentProcessorAvailable: boolean | null | Processors[];
}

export const paymentInitialState: PaymentState = {
    paymentBoundaries: null,
    transactionLoaded: false,
    paymentSectionEnabled: false,
    dialogEnabled: false,
    paymentAppInput: undefined,
    error: undefined,
    crossAppEvent: null,
    isPaymentProcessorAvailable: null
};
