import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class PorTransactionService {
    private readonly transactionSuccessSubject = new BehaviorSubject<boolean>(false);
    public transactionSuccess$ = this.transactionSuccessSubject.asObservable();

    setTransactionSuccess(value: boolean) {
        this.transactionSuccessSubject.next(value);
    }
}
