import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EventLogsService } from '../../../services/event-logs.service';
import { saveEventLogsData, saveEventLogsSuccess } from './event-logs.actions';

@Injectable()
export class EventLogsEffects {
    postData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveEventLogsData),
            mergeMap(action =>
                this.eventLogsService.post(action.payload).pipe(
                    map(response => saveEventLogsSuccess({ response })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    constructor(private readonly actions$: Actions, private readonly eventLogsService: EventLogsService) {}
}
