export enum QuickLinkType {
    Contract = 'Contract',
    Customer = 'Customer',
    Stock = 'Stock'
}

export enum QuickLinkAccessTo {
    ContractQL = 'Contract-Quick-Link',
    CustomerQL = 'Customer-Quick-Link',
    StockQL = 'Stock-Quick-Link'
}
