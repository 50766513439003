import { createReducer, on } from '@ngrx/store';
import { initialDialogState } from './dialog.state';
import { setDialogDisplayed, showDialog } from './dialog.actions';

export const dialogReducer = createReducer(
    initialDialogState,
    on(showDialog, (state, { dialogContentType }) => {
        return { ...state, dialogContentType, isDialogDisplayed: true };
    }),
    on(setDialogDisplayed, (state, { isDialogDisplayed }) => {
        return { ...state, isDialogDisplayed };
    })
);
