<div class="contract-details-page">
    <h1 class="theme-heading">{{ 'InvoiceDetails' | translate }}</h1>
    <ng-container *ngIf="invoiceDetail">
        <div>
            <div class="row totals">
                <div class="col-6">
                    <p>
                        {{ 'invoiceNumber' | translate }} : 
                        <strong>{{ invoiceDetail.InvoiceNumber }}</strong>
                    </p>
                </div>
                <div class="col-6">
                    <p>
                        {{ 'Contract #' | translate }} : 
                        <strong>{{ invoiceDetail.Identifiers.ContractId }}</strong>
                    </p>
                </div>
            </div>
            <div class="row totals">
                <div class="col-6">
                    <p>{{ 'Amount Due' | translate }} : 
                        <strong>{{ invoiceDetail.AmountDue | formatCurrency }}</strong>
                    </p>
                    
                </div>
                <div class="col-6">
                    
                    <p>{{ 'invoiceTax' | translate }} : 
                        <strong>{{ invoiceDetail.GrandTaxTotal | formatCurrency }}</strong>
                    </p>
                </div>
            </div>
            <div class="row totals">
                <div class="col-6">
                    <p>
                        {{ 'invoiceTotal' | translate }} : 
                        <strong>{{ invoiceDetail.GrandTotal | formatCurrency }}</strong>
                    </p>
                    
                </div>
                <div class="col-6">
                    <p>{{ 'invoiceDate' | translate }} : 
                        <strong>{{ invoiceDetail.InvoiceDate | date: dateFormat }}</strong>
                    </p>
                </div>
            </div>
        </div>
        
    </ng-container>
</div>
