import { createAction, props } from '@ngrx/store';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { Redirection } from '../../types/redirection.type';
import { LoginSession } from '../../models/login-session.model';

/**
 * It handles component redirection actions
 */
export const startRedirection = createAction('[Redirection] Start', props<{ redirection: Redirection; cpInput: ConsumerPortalConfig }>());

export const redirectionSuccess = createAction('[Redirection] Success', props<{ redirection: Redirection; cpInput: ConsumerPortalConfig }>());

export const redirectionFailure = createAction('[Redirection] Failure', props<{ error: string }>());

export const setRedirectionLoginSession = createAction('[Auth] Set Redirection Login Session', props<{ session: LoginSession }>());

export const redirectToSubdomain = createAction('[Redirection] Redirect to Subdomain');

export const redirectToConsumerPortal = createAction('[Redirection] Redirect to Consumer Portal');

export const redirectConfigSet = createAction('[Redirection] Redirect to Config set', props<{ isConfigSet: boolean }>());
