import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { Searchable } from '../models/searchable.model';
import { ApiQuery } from '../models/api-query.model';
import { QuickLink } from '../models/quicklink.model';
import { QuickLinkType } from '@por/consumer-login/shared-lib';

type QuickLinkSearchable = Searchable & QuickLink;
@Injectable({
    providedIn: 'root'
})
export class QuickLinkService {
    public customerQuickLink = false;
    private stockQuickLink = false;
    constructor(private readonly consumerPortalApi: ConsumerPortalApiService) {}

    static createUrlParamsObject() {
        if (!this.url.includes('?')) {
            return {};
        }

        const paramArray = this.url.split('?');
        const params = paramArray[1].split('&');
        const paramObj: { [key: string]: string } = {};
        params.map((param: string) => {
            const paramKey = param.split('=')[0];
            const paramValue = param.split('=')[1];
            paramObj[paramKey] = paramValue;
        });
        return paramObj;
    }

    static get isQuickLink() {
        return this.url.includes('token=');
    }

    get consumerQuickLinkVersion() {
        return this.customerQuickLink;
    }

    set consumerQuickLinkVersion(value: boolean) {
        this.customerQuickLink = value;
    }

    set stockQuickLinkVersion(value: boolean) {
        this.stockQuickLink = value;
    }

    get stockQuickLinkVersion() {
        return this.stockQuickLink;
    }

    static get url() {
        return window?.location?.href || '';
    }

    getConsumerData() {
        const authUser = QuickLinkService.createUrlParamsObject();
        const url = `tokens/quicklink/extract`;
        const apiQuery: ApiQuery = {
            headers: {
                // Note camelCase : DB Model
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: 'token' in authUser ? authUser['token'] : ''
            }
        };

        return this.consumerPortalApi.get<QuickLinkSearchable>(url, apiQuery).pipe(
            tap({
                next: (response: QuickLink[]) => {
                    const res = response as unknown as QuickLink;
                    if (res?.Type === 'Customer') {
                        this.consumerQuickLinkVersion = true;
                    }else if (res?.Type === QuickLinkType.Stock) {
                        this.stockQuickLinkVersion = true;
                    }

                    return response[0] as QuickLink;
                }
            })
        );
    }
}
