import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'por-base-input',
    templateUrl: './base-input.component.html',
    styleUrls: ['./base-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseInputComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseInputComponent implements ControlValueAccessor, OnChanges {
    @Input()
    public parentForm!: FormGroup;

    @Input()
    public formControlName!: string;

    @Output() readonly modelChange = new EventEmitter<string>();
    @Input() originalInput: unknown | string;
    @Input() label: string | undefined;
    @Input() placeholder = '';
    @Input() disabled = false;
    @Input() type = 'text';
    @Input() required = false;
    @Input() model?: string;
    @Input() icon: string | undefined;
    @Input() featureName = '';
    @Input() styleInput = '';
    public value = '';

    public changed!: (value: string) => void;
    public touched!: () => void;
    public isDisabled!: boolean;

    constructor() {
        // this.changed(this.model)
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.value = this.model ? this.model : '';
        if (_changes?.['disabled'] !== undefined) {
            if (this.parentForm) {
                _changes['disabled'] ? this.parentForm.disable() : this.parentForm.enable();
            }
        }
    }

    writeValue(value: string): void {
        if (this.model && this.model !== '') {
            this.value = this.model;
            return;
        }
        this.value = value;
    }

    registerOnChange(fn: () => void): void {
        this.changed = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onChange(event: Event): void {
        const value: string = (<HTMLInputElement>event.target).value;
        this.changed(value);
    }

    get formField(): FormControl {
        return this.parentForm?.get(this.formControlName) as FormControl;
    }

    resetField() {
        this.model = this.value;
    }

    updateNewValue(newValue?: Event) {
        if (newValue) {
            this.onChange(newValue);
        }
        this.modelChange.emit(this.model ? this.model : '');
        this.value = this.model ? this.model : '';
    }
    toggleViewButton() {
        this.type = this.type === 'password' ? 'text' : 'password';
    }
}
