import { createReducer, on } from '@ngrx/store';
import { initialState, ReducerType } from '../state/app.state';
import { setConsumerPortalState } from '../actions/view-state.actions';

export const loadstateReducer: ReducerType = createReducer(
    initialState,
    on(setConsumerPortalState, (state, { consumerPortalState }) => {
        return {
            ...state,
            consumerPortalState
        };
    })
);
