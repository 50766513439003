<div class="container">
    <div class="header-action buttons-grp">
        <por-base-button [disabled]="(advanceViewTab$ | async) === advanceSetupTabs.imageAds" [featureName]="'accountSetup'" (onclick)="advanceViewTab$.next(advanceSetupTabs.accountSetup)">
            <span>{{ 'accountSetup' | translate }}</span>
        </por-base-button>

        <por-base-button
            *ngIf="!featureToggleService.isSyrinx()"
            [disabled]="(advanceViewTab$ | async) === advanceSetupTabs.accountSetup"
            [featureName]="'imagesAds'"
            (onclick)="advanceViewTab$.next(advanceSetupTabs.imageAds)">
            <span *ngIf="featureToggleService.isSyrinx(); else adsLogosImagesTitle">{{ 'logo/Icon' | translate }}</span>
            <ng-template #adsLogosImagesTitle>
                <span>{{ 'Images/Ads' | translate }}</span>
            </ng-template>
        </por-base-button>
    </div>
</div>

<div class="wrapper holder" *ngIf="(advanceViewTab$ | async) === advanceSetupTabs.accountSetup">
    <div class="container">
        <h1>{{ 'adminConsumerPortalSetup' | translate }}</h1>
        <p>{{ 'adminConsumerPortalHeading' | translate }}</p>
        <p *ngIf="showWysiwyg">{{ 'adminConsumerPortalSubHeading' | translate }}</p>
        <form class="form-wrapper mt-2" [formGroup]="mandatoryForm" (ngSubmit)="onSubmit()">
            <div class="form-section">
                <h2>{{ 'adminChooseYourUrl' | translate }}</h2>
                <div class="form-group-container" [ngClass]="isChecked ? 'disabled' : ''">
                    <ng-container>
                        <Strong>
                            {{ 'adminChooseDefaultUrl' | translate }}
                            <br />
                        </Strong>
                        <p>
                            {{ 'adminChooseYourUrlHeading' | translate }}
                            <br />
                        </p>
                        <span class="url-preview-text">{{ subDomain ? subDomain.value : '___' }}.{{ domain }}</span>
                        <p>{{ 'adminChooseYourUrlSubHeading' | translate }}</p>
                    </ng-container>
                    <p class="validEntriesText">
                        {{ 'adminChooseYourUrlValidEntries' | translate }}
                        <br />
                        {{ 'adminChooseYourUrlValidEntries1' | translate }}
                        <br />
                        {{ 'adminChooseYourUrlValidEntries2' | translate }}
                        <br />
                        {{ 'adminChooseYourUrlValidEntries3' | translate }}
                    </p>
                    <div class="form-group form-group-space">
                        <span class="label-url">{{ 'adminChooseYourUrlPara1' | translate }}</span>
                        <apx-form-field
                            [classList]="'required flex-row spinner-holder'"
                            [error]="subDomain?.invalid && (subDomain?.dirty || subDomain?.touched) ?
							subDomain?.errors?.['required'] ? ('RequiredError' | translate) :
							subDomain?.errors?.['noCom'] ? ('NoComError' | translate) :
							subDomain?.errors?.['unavailable'] ? ('UnavailableDomainError' | translate) :
							subDomain?.errors?.['noSpace'] ? ('spaceNotAllowed' | translate) :
							subDomain?.errors?.['badFormat'] ? ('badFormatError' | translate) :
							''
							: '' ">
                            <input
                                apxInput
                                type="text"
                                placeholder="{{ 'adminChooseYourUrlInputPlaceholder' | translate }}"
                                id="subDomain"
                                formControlName="subDomain"
                                [ngModel]="mandatoryForm.value.subDomain" />
                            <div class="flex mt-xs px-xs" *ngIf="!subDomain?.invalid">
                                <div class="flex items-center justify-center text-success">
                                    <p class="p-none m-none">
                                        &nbsp;
                                        <span *ngIf="!loading">{{ 'adminChooseYourUrlDomainAvailable' | translate }}</span>
                                    </p>
                                </div>
                            </div>
                            <span apxIcon icon="spinner" class="spinner" *ngIf="loading"></span>
                        </apx-form-field>
                        <div class="hint-text url-preview">.{{ domain }}</div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div class="vanity-container">
                    <div class="vanity-checkbox-wrapper">
                        <por-base-checkbox [showLabel]="false" (checkboxChange)="getCheckboxChange($event)" [isChecked]="isChecked"></por-base-checkbox>
                        <span>{{ 'vanityUrl' | translate }}</span>
                    </div>
                    <div class="vanity-data" *ngIf="isChecked">
                        <p>{{ 'You can change the URL to your domain name,with a subdomain as the prefix.' }}</p>
                        <p class="validEntriesText">
                            {{ 'validEntry' | translate }}
                            <br />
                            1.{{ 'subdomainPrefix' | translate }}
                            <br />
                            2.{{ 'currentDomain' | translate }}
                        </p>
                        <div class="vanity-select">
                            <p>1.{{ 'selectPrefix' | translate }}</p>
                            <apx-form-field
                                class="mb-sm"
                                [classList]="'required'"
                                [error]="selectedOption.invalid && (selectedOption.dirty || selectedOption.touched) ?
                            selectedOption.errors?.['required'] ? ('RequiredError' | translate) :
                            ''
                            : '' ">
                                <apx-radio-group
                                    formControlName="selectedOption"
                                    (valueChange)="onOptionChange()"
                                    [ngClass]="{ 'other-selected': mandatoryForm.get('selectedOption')?.value === 'other' && !mandatoryForm.get('otherVanityUrl')?.value }">
                                    <apx-radio-button value="myportal">{{ 'myportal' | translate }}</apx-radio-button>
                                    <apx-radio-button value="portal">{{ 'portal' | translate }}</apx-radio-button>
                                    <apx-radio-button value="other">{{ 'other' | translate }}</apx-radio-button>
                                    <apx-form-field
                                        [classList]="'required'"
                                        [error]="otherVanityUrl.invalid && (otherVanityUrl.dirty || otherVanityUrl.touched) ?
                            otherVanityUrl.errors?.['required'] ? ('RequiredError' | translate) :
                            ''
                            : '' ">
                                        <input
                                            apxInput
                                            formControlName="otherVanityUrl"
                                            [ngModel]="mandatoryForm.value.otherVanityUrl"
                                            type="text"
                                            class="other-vanity-input"
                                            placeholder="Enter your own prefix" />
                                    </apx-form-field>
                                </apx-radio-group>
                            </apx-form-field>
                            <div class="form-field">
                                <p>{{ '2. Enter your domain name' }}</p>

                                <apx-form-field
                                    [classList]="'required'"
                                    [error]="vanityDomain.invalid && (vanityDomain.dirty || vanityDomain.touched) ?
                    vanityDomain.errors?.['required'] ? ('RequiredError' | translate) :
                    ''
                    : '' ">
                                    <input apxInput formControlName="vanityDomain" [ngModel]="mandatoryForm.value.vanityDomain" type="text" placeholder="Domain Name" />
                                </apx-form-field>
                            </div>
                        </div>
                        <div class="form-group">
                            <p>{{ 'adminUrlAppear' | translate }}:</p>
                            <apx-form-field [error]="fullUrl.errors?.['invalidUrl'] ? ('InvalidUrlError' | translate) :''" class="url-wrapper">
                                <div class="url-input-wrapper">
                                    <input apxInput formControlName="fullUrl" type="text" class="url-input" [ngModel]="mandatoryForm.value.fullUrl" readonly />
                                    <div *ngIf="fullUrl?.valid" class="flex mt-xs px-xs">
                                        <div class="flex items-center justify-center text-success">
                                            <p class="p-none m-none">
                                                <span>{{ 'adminChooseYourUrlDomainAvailable' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </apx-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h2>{{ 'adminLogoAndIconUpload' | translate }}</h2>
                <div class="ads-wrapper-two">
                    <div class="left-section">
                        <p>{{ 'adsectionInstruction1' | translate }}</p>
                        <p>
                            {{ 'adsectionInstruction2' | translate }}
                        </p>
                        <p>
                            {{ 'adminLogoUrlHintSize' | translate }}
                            <br />
                            {{ 'adminLogoUrlHint' | translate }}
                        </p>
                        <p>
                            {{ 'adminFaviconUrlHintSize' | translate }}
                            <br />
                            {{ 'adminFaviconUrlHint' | translate }}
                        </p>
                    </div>
                    <div class="right-section">
                        <div class="droppers-section">
                            <por-ad-drag-drop
                                (uploadSuccess)="handleFile($event, fileType.Logo)"
                                title="{{ 'logo' | translate }}"
                                [currentImage]="$any(mandatoryForm.value)?.logoUrl"></por-ad-drag-drop>
                            <por-ad-drag-drop
                                (uploadSuccess)="handleFile($event, fileType.Icon)"
                                title="{{ 'icon' | translate }}"
                                [currentImage]="$any(mandatoryForm.value)?.faviconUrl"></por-ad-drag-drop>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h2>{{ 'adminColorSelectorHeading' | translate }}</h2>
                <div class="form-group-container">
                    <div class="form-group form-group-space">
                        <apx-form-field
                            label="{{ 'Primary' | translate }}:"
                            [classList]="'required flex-row'"
                            [error]="primaryColor?.invalid && (primaryColor?.dirty || primaryColor?.touched) ?
                            primaryColor?.errors?.['required'] ? ('RequiredError' | translate) :
                            primaryColor?.errors?.['hex'] ? ('HexValid' | translate) :
                            ''
                            : '' ">
                            <div class="color-picker-input">
                                <input
                                    apxInput
                                    placeholder="{{ 'Pick or enter hex #' | translate }}"
                                    [ngModel]="mandatoryForm.value.primaryColor"
                                    [colorPicker]="selectedPrimaryColor"
                                    (colorPickerChange)="setColor('primaryColor', $event)"
                                    formControlName="primaryColor"
                                    cpOutputFormat="hex" />
                                <div class="selected-color-preview bg-primary"></div>
                            </div>
                        </apx-form-field>
                        <div class="hint-text ml-2">
                            {{ 'adminPrimaryColorHint' | translate }}
                        </div>
                    </div>
                    <div class="form-group form-group-space">
                        <apx-form-field
                            label="{{ 'Secondary' | translate }}:"
                            [classList]="'required flex-row'"
                            [error]="secondaryColor?.invalid && (secondaryColor?.dirty || secondaryColor?.touched) ?
                            secondaryColor?.errors?.['required'] ? ('RequiredError' | translate) :
                            secondaryColor?.errors?.['hex'] ? ('HexValid' | translate) :
                            ''
                            : '' ">
                            <div class="color-picker-input">
                                <input
                                    apxInput
                                    placeholder="{{ 'Pick or enter hex #' | translate }}"
                                    [ngModel]="mandatoryForm.value.secondaryColor"
                                    [colorPicker]="selectedSecondaryColor"
                                    (colorPickerChange)="setColor('secondaryColor', $event)"
                                    formControlName="secondaryColor"
                                    cpOutputFormat="hex" />
                                <div class="selected-color-preview bg-secondary"></div>
                            </div>
                        </apx-form-field>
                        <div class="hint-text ml-2">
                            {{ 'adminSecondaryColorHint' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <ng-container *ngIf="showWysiwyg">
                <por-admin-wysiwyg [adminConfig]="adminConfig" [logoUrl]="mandatoryForm.value.logoUrl"></por-admin-wysiwyg>
            </ng-container>
            <div class="form-section">
                <h2>{{ 'adminStoreName' | translate }}</h2>
                <div class="form-group-container">
                    <p>{{ 'adminStoreNameHeading' | translate }}</p>
                    <div class="form-group form-group-space">
                        <apx-form-field
                            [classList]="'required'"
                            [error]="storeName?.invalid && (storeName?.dirty || storeName?.touched) ?
                            storeName?.errors?.['required'] ? ('RequiredError' | translate) :
                            ''
                            : '' ">
                            <input apxInput type="text" placeholder="{{ 'adminStoreNameInputPlaceholder' | translate }}" formControlName="storeName" [ngModel]="mandatoryForm.value.storeName" />
                        </apx-form-field>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h2>{{ 'Language' | translate }}</h2>
                <div class="form-group-container">
                    <div class="form-group form-group-space">
                        <por-language-selector
                            (selectedLocale)="handleSelectedLocale($event)"
                            [options]="languageOptions"
                            [selectedLanguageOption]="mandatoryForm.value.locale ?? ''"></por-language-selector>
                        <div class="hint-text ml-2">{{ 'LanguageHintText' | translate }}</div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="versionService.isAdvanceVersion()">
                <div class="form-section">
                    <h2>{{ 'adminEnterRequestAction' | translate }}</h2>
                    <div class="form-group-container">
                        <div class="form-group form-group-space">
                            <apx-form-field
                                [classList]="'required'"
                                [error]="notificationEmail?.invalid && (notificationEmail?.dirty || notificationEmail?.touched) ?
                            notificationEmail?.errors?.['required'] ? ('RequiredError' | translate) :
                            notificationEmail?.errors?.['email'] ? ('EmailValid' | translate) :
                            ''
                            : '' ">
                                <input
                                    apxInput
                                    type="email"
                                    placeholder="{{ 'Enter email address' | translate }}"
                                    formControlName="notificationEmail"
                                    [ngModel]="mandatoryForm.value.notificationEmail" />
                            </apx-form-field>
                            <div class="hint-text ml-2">{{ 'adminNotificationEmailHint' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <h2>{{ 'AdminServiceRequestEmail' | translate }}</h2>
                    <div class="form-group-container">
                        <div class="form-group form-group-space">
                            <apx-form-field
                                [classList]="'required'"
                                [error]="serviceRequestEmail?.invalid && (serviceRequestEmail?.dirty || serviceRequestEmail?.touched) ?
                            serviceRequestEmail?.errors?.['email'] ? ('EmailValid' | translate) :
                            ''
                            : '' ">
                                <input
                                    apxInput
                                    type="email"
                                    placeholder="{{ 'Enter email address' | translate }}"
                                    formControlName="serviceRequestEmail"
                                    [ngModel]="mandatoryForm.value.serviceRequestEmail" />
                            </apx-form-field>
                            <div class="hint-text ml-2">{{ 'AdminServiceRequestEmailHint' | translate }}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="form-section">
                <h2>
                    {{ 'EnterSupportEmailAddress' | translate }}
                </h2>
                <div class="form-group-container">
                    <div class="form-group form-group-space">
                        <apx-form-field
                            [classList]="'required'"
                            [error]="supportEmail?.invalid && (supportEmail?.dirty || supportEmail?.touched) ?
                            supportEmail?.errors?.['required'] ? ('RequiredError' | translate) :
                            supportEmail?.errors?.['email'] ? ('EmailValid' | translate) :
                            ''
                            : '' ">
                            <input apxInput type="email" placeholder="{{ 'Enter email address' | translate }}" formControlName="supportEmail" [ngModel]="mandatoryForm.value.supportEmail" />
                        </apx-form-field>
                        <div class="hint-text ml-2">{{ 'SupportEmailHint' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="form-section radio-section" *ngIf="payMinimumAmountDueVisible">
                <h2>
                    {{ 'SettingForInitialDepositPaymentText' | translate }}
                </h2>
                <div class="form-group-container">
                    <por-base-radio
                        [style]="'radioButtonBlock'"
                        [values]="[
                            { text: 'MinimumPaymentDueAdmin' | translate, value: payMinimumAmountEnum.PayMinimumAmount.toString() },
                            { text: 'InitialPaymentDueAdmin' | translate, value: payMinimumAmountEnum.InitialPaymentDue.toString() },
                            { text: 'Other' | translate, value: payMinimumAmountEnum.Other.toString() }
                        ]"
                        formControlName="payMinimumAmountDueType"
                        (modelChange)="onPayMinimumAmountDueTypeChange($event)"
                        [model]="mandatoryForm.value.payMinimumAmountDueType" />
                </div>
                <div class="form-group-container radio-asigned">
                    <apx-form-field
                        [error]="payMinimumAmountDueText && payMinimumAmountDueText.invalid && (payMinimumAmountDueText.dirty || payMinimumAmountDueText.touched) ?
                        payMinimumAmountDueText.errors?.['required'] ? ('RequiredError' | translate) :
                        payMinimumAmountDueText.errors?.['noSpecialCharacters'] ? ('InvalidSpecialCharacters' | translate) :
                        ''
                        : '' ">
                        <mat-label class="mini-label">{{ 'EnterText' | translate }}</mat-label>
                        <input
                            apxInput
                            type="text"
                            placeholder="{{ 'InitialAmountToConfirmYourReservation' | translate }}"
                            formControlName="payMinimumAmountDueText"
                            [ngModel]="mandatoryForm.value.payMinimumAmountDueText"
                            [disabled]="mandatoryForm.get('payMinimumAmountDueType')?.value !== payMinimumAmountEnum.Other.toString()" />
                    </apx-form-field>
                </div>
            </div>
            <div class="form-section" *ngIf="versionService.isBasicExternalConsumerPortalURL()">
                <h2>
                    {{ 'ExternalConsumerPortalURL' | translate }}
                </h2>
                <div class="form-group-container">
                    <div class="form-group form-group-space">
                        <apx-form-field
                            [error]="externalConsumerPortalURL && externalConsumerPortalURL.invalid && (externalConsumerPortalURL.dirty || externalConsumerPortalURL.touched) ?
                            externalConsumerPortalURL.errors?.['invalidUrl'] ? ('InvalidUrl' | translate) :
                            ''
                            : '' ">
                            <input
                                apxInput
                                type="email"
                                placeholder="{{ 'EnterUrl' | translate }}"
                                formControlName="externalConsumerPortalURL"
                                [ngModel]="mandatoryForm.value.externalConsumerPortalURL" />
                        </apx-form-field>
                    </div>
                </div>
            </div>
            <div class="form-section" *ngIf="featureToggleService.isSyrinx()">
                <h2>
                    {{ 'DisplayCertificateConfig' | translate }}
                </h2>
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'DisplayServiceCertificates' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'displayServiceCertificate'"
                            [model]="mandatoryForm.value.displayServiceCertificate"
                            (modelChange)="onModelChange($event, 'displayServiceCertificate')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'DisplayServiceCertificatesHint' | translate }}</div>
                </div>
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'DisplayInspectionDocument' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'displayInspectionDocument'"
                            [model]="mandatoryForm.value.displayInspectionDocument"
                            (modelChange)="onModelChange($event, 'displayInspectionDocument')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'DisplayInspectionDocumentHint' | translate }}</div>
                </div>
            </div>

            <div class="form-section">
                <h2>
                    {{ 'AccessConfig' | translate }}
                </h2>
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'SecureUrlLoginRequired' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'secureUrlLoginRequired'"
                            [model]="mandatoryForm.value.secureUrlLoginRequired"
                            (modelChange)="onModelChange($event, 'secureUrlLoginRequired')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'SecureUrlLoginRequiredHint' | translate }}</div>
                </div>
                <div class="form-group-container d-inline-flex" *ngIf="false">
                    <div class="toogle-wrapper">
                        <label>{{ 'MultiplePaymentAbilityText' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'multiplePaymentAbility'"
                            [model]="mandatoryForm.value.multiplePaymentAbility"
                            (modelChange)="onModelChange($event, 'multiplePaymentAbility')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'MultiplePaymentAbilityHintText' | translate }}</div>
                </div>
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'QuotesPaymentAbilityText' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'quotesPaymentAbility'"
                            [model]="mandatoryForm.value.quotesPaymentAbility"
                            (modelChange)="onModelChange($event, 'quotesPaymentAbility')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'QuotesPaymentAbilityHintText' | translate }}</div>
                </div>
            </div>
            <div class="form-section" *ngIf="versionService.isAdvanceVersion()">
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'CallOffAbilityText' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'callOffAbility'"
                            [model]="mandatoryForm.value.callOffAbility"
                            (modelChange)="onModelChange($event, 'callOffAbility')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'CallOffAbilityHintText' | translate }}</div>
                </div>
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'TrackingTabText' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'trackingTab'"
                            [model]="mandatoryForm.value.trackingTab"
                            (modelChange)="onModelChange($event, 'trackingTab')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'TrackingTabHintText' | translate }}</div>
                </div>
                <div class="form-group-container d-inline-flex">
                    <div class="toogle-wrapper">
                        <label>{{ 'RequestServiceAbilityText' | translate }}:</label>
                        <por-base-toggle
                            [parentForm]="mandatoryForm"
                            [formControlName]="'requestServiceAbility'"
                            [model]="mandatoryForm.value.requestServiceAbility"
                            (modelChange)="onModelChange($event, 'requestServiceAbility')"></por-base-toggle>
                    </div>
                    <div class="hint-text ml-2">{{ 'RequestServiceAbilityHintText' | translate }}</div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-group-container">
                    <por-base-button
                        (onclick)="onSubmit()"
                        class="theme-button apply-btn"
                        [disabled]="mandatoryForm.invalid || loading || (uploading$ | async) || false"
                        [featureName]="'admin-save-button-data'">
                        {{ 'Save' | translate }}
                    </por-base-button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="wrapper holder" *ngIf="(advanceViewTab$ | async) === advanceSetupTabs.imageAds">
    <div class="container">
        <por-admin-ad-setting [formValues]="$any(mandatoryForm).value" (submitForm)="saveOrganizationConfig($event)" (uploadSuccess)="afterUploadFile($any($event))"></por-admin-ad-setting>
    </div>
</div>

<ng-container *ngIf="isDialogEnabled">
    <por-app-confirm-dialog [message]="'adminConsumerPortalSetupSuccess' | translate" (confirmed)="isDialogEnabled = false"></por-app-confirm-dialog>
</ng-container>
