<div class="wrapper">
    <h1>{{ 'AdminOverviewTitle' | translate }}</h1>
    <div class="container">
        <p>{{ 'AdminOverviewTxtSection1Para1' | translate }}</p>
        <ul class="list">
            <li>{{ 'Customizable' | translate }}</li>
            <li>{{ 'MobileResponsive' | translate }}</li>
            <li>{{ 'CustomerFriendly' | translate }}</li>
            <li>{{ 'EasyToUse' | translate }}</li>
        </ul>
        <p>{{ 'AdminOverviewTxtSection1Para2' | translate }}</p>
        <div class="cols">
            <div class="col">
                <h2>{{ 'ConsumerPortalBasicTitle' | translate }}</h2>
                <ul class="list">
                    <li>{{ 'IncludeWithLicense' | translate }}</li>
                    <li [innerHTML]="'YourCustReview' | translate"></li>
                    <li>{{ 'ViewContractPdfs' | translate }}</li>
                </ul>
                <div class="mt-2">
                    <p>{{ 'BasicLandingPageTitle' | translate }}</p>
                    <img src="{{ adminConfig.uiUrl }}/assets/images/BASIC-Landing-Page-Contract-List.svg" />
                </div>
                <div class="mt-1">
                    <p>{{ 'BasicContractDetailPageTitle' | translate }}</p>
                    <img src="{{ adminConfig.uiUrl }}/assets/images/BASIC-Contract-Details.svg" />
                </div>
            </div>
            <div class="col">
                <h2>{{ 'ConsumerPortalAdvancedTitle' | translate }}</h2>
                <ul class="list">
                    <li>{{ 'NominalMonthlyLicense' | translate }}</li>
                    <li>{{ 'BasicFeaturePlus' | translate }}</li>
                    <li [innerHTML]="'ViewHistoricalContract' | translate"></li>
                    <li>{{ 'InitialCallOffServiceRequest' | translate }}</li>
                    <li>{{ 'TrackingMapping' | translate }}</li>
                    <li>{{ 'NewFeatureComingMonthly' | translate }}</li>
                </ul>
                <div class="mt-2">
                    <p>{{ 'AdvHomePage' | translate }}</p>
                    <img src="{{ adminConfig.uiUrl }}/assets/images/Advanced-Home-Page.svg" />
                </div>
                <div class="mt-1">
                    <p>{{ 'AdvContracListPageWithHistory' | translate }}</p>
                    <img src="{{ adminConfig.uiUrl }}/assets/images/Advanced-Contract-List.svg" />
                </div>
                <div class="mt-1">
                    <p>{{ 'AdvRentalsPageWithMultipleSelection' | translate }}</p>
                    <img src="{{ adminConfig.uiUrl }}/assets/images/Advanced-My-Rentals.svg" />
                </div>
                <div class="mt-1">
                    <p>{{ 'AdvTrackingPage' | translate }}</p>
                    <img src="{{ adminConfig.uiUrl }}/assets/images/Advanced-Tracking.svg" />
                </div>
            </div>
        </div>
    </div>
</div>
