<div class="account-list" [ngClass]="{ hide: (loading$ | async) === true }" *ngIf="consumers$ | async as consumerData">
    <div class="account-list-header">
        <h3>{{ 'AccountList' | translate }}</h3>
        <div class="search">
            <por-search-box
                class="amount-control"
                label="{{ 'Text Search' | translate }}"
                [placeholder]="'Quick search Company Name, City, Account #' | translate"
                [value]="consumerData.search"
                (searched)="setSearch($any($event))"></por-search-box>
        </div>
    </div>
    <div class="wrapper">
        <div class="list-wrapper">
            <por-lists (generateLink)="generateQuickLink($event)" [noMoreRecord]="$any(consumerData.noMoreRecord)" (loadMore)="loadMore()" [consumers]="consumerData.records"></por-lists>
        </div>
    </div>
</div>

<div class="loading" *ngIf="(loading$ | async) === true">
    <span>
        {{ 'Loading' | translate }}
    </span>
</div>
