import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDotsToText'
})
export class AddDotsToTextPipe implements PipeTransform {

  transform(string: string | undefined,limit: number): string {
    if(!string){
      return '';
    }
    const dots = "...";
    if(string.length > limit)
    {
      string = string.substring(0,limit) + dots;
    }
   
      return string;
  }

}
