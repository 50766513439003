import {
  loadInvoicesAction,
  setInvoicesLoading,
  clearInvoicesinStore,
  setNoMoreRecordInvoices,
} from './../actions/invoices.actions';
import { createReducer, on } from '@ngrx/store';
import { Invoice } from '../../models/invoice-model';

export const invoicesFeatureKey = 'invoices';

export interface InvoicesState {
  pageNumber: number;
  invoices: Invoice[];
  loading: boolean;
  noMoreRecord: boolean;
}

export const initialState: InvoicesState = {
  pageNumber: 1,
  invoices: [],
  loading: false,
  noMoreRecord: false,
};

export const invoicesReducer = createReducer(
  initialState,
  on(
    loadInvoicesAction,
    (state: InvoicesState, { invoices }: { invoices: Invoice[] }) => {
      if (invoices && invoices.length > 0) {
        return {
          ...state,
          invoices: [...state.invoices, ...invoices],
          pageNumber: state.pageNumber + 1,
        };
      }
      return state;
    }
  ),
  on(setInvoicesLoading, (state: InvoicesState, { loading }: { loading: boolean }) => {
    return { ...state, loading };
  }),
  on(
    setNoMoreRecordInvoices,
    (state: InvoicesState, { record }: { record: boolean }) => {
      return { ...state, noMoreRecord: record };
    }
  ),
  on(clearInvoicesinStore, (state: InvoicesState) => {
    return { ...state, invoices: [], pageNumber: 1 };
  })
);
