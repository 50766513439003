import { MatButtonModule } from '@angular/material/button';

import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
    exports: [
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSelectModule,
        FormsModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDialogModule,
        MatBadgeModule,
        MatCheckboxModule,
        NgxMatTimepickerModule
    ]
})
export class MaterialModule {}
