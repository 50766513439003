<div class="quick-link-container">
    <por-app-loader></por-app-loader>
    <ng-container>
        <por-header [config]="config" (logout)="logoutUser()" [isLoginButtonVisible]="isLoginButtonVisible"></por-header>
    </ng-container>
    <ng-container *ngIf="quickLinkData$ | async as quickLinkData">
        <ng-container [ngSwitch]="quickLinkData.Type">
            <ng-container *ngSwitchCase="'Stock'" [ngTemplateOutlet]="productDetailTemplate"></ng-container>
            <ng-container *ngSwitchCase="'Contract'" [ngTemplateOutlet]="contractDetailTemplate"></ng-container>
        </ng-container>
    </ng-container>

    <div *ngIf="invalidRequest" class="align-center">
        <p>{{ 'QuickLinkError' | translate }}</p>
    </div>
</div>

<ng-template #contractDetailTemplate>
    <ng-container *ngIf="customer$ | async | count">
        <ng-container *ngIf="contractDetail$ | async as data">
            <div *ngIf="isDetailPageOpened">
                <por-contract-detail
                    [contractDetail]="data"
                    [uiUrl]="inputs?.uiUrl"
                    [customerId]="customerId ?? ''"
                    (returnToLogin)="returnToLogin()"
                    (loginButtonVisibilityChange)="loginButtonVisibilityChange($event)"></por-contract-detail>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #productDetailTemplate>
    <ng-container>
        <div class="product-detail">
            <ng-container *ngIf="productDetail$ | async as data">
                <por-product-detail *ngIf="isDetailPageOpened" [uiUrl]="inputs?.uiUrl" [productDetail]="data[0]" [customerId]="customerId ?? '0'" [contractId]="contractId"></por-product-detail>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
