import { Component, Input } from '@angular/core';
import { LoggerService } from '../../services/logger.service';

@Component({
    selector: 'por-cp-demo',
    templateUrl: './cp-demo.component.html',
    styleUrls: ['./cp-demo.component.scss']
})
export class CpDemoComponent {
    constructor(private readonly logger: LoggerService) {}
    @Input() url = '';
    @Input() config = '';

    handleOutPutError($e: any) {
        this.logger.logInfo({
            $e
        });
    }
}
