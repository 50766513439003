import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractDetail } from '../../../models/contract-model';
import { RequestActionService } from '../../../services/request-action.service';
import { RequestActionsToLoad } from '../../../enums/request-actions.enum';
import { LOAD_MORE_DROP_DOWN_DEFAULT_VALUE } from '../../../constants/default.const';
import { ItemOutService } from '../../../services/item-out.service';

@Component({
    selector: 'por-book-again-contract-modal',
    templateUrl: './book-again-contract-modal.component.html',
    styleUrls: ['./book-again-contract-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookAgainContractModalComponent implements OnInit {
    @Input() contractDetail!: ContractDetail[] | null;
    @Input() customerId!: string;
    @Input() uiUrl: string | undefined;
    enabledTab$ = this.requestActionService.activeTab$;
    displayedRowCount = LOAD_MORE_DROP_DOWN_DEFAULT_VALUE;

    @Output()
    readonly isContractFormSubmittedSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private readonly requestActionService: RequestActionService, readonly itemOutService: ItemOutService) {}

    ngOnInit(): void {
        /**
         * Reset default view whenever rental request modal opens
         */
        this.handleCloneContractFormReturn();
    }

    handleCloneContractFormReturn() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }

    openContractCloneModal() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.RequestCloneContractView);
    }

    handleCloneContractFormSubmission() {
        this.isContractFormSubmittedSuccess.emit(true);
    }

    showLoadMoreButton() {
        return this.contractDetail?.[0]?.LineItems?.length !== undefined && this.contractDetail?.[0]?.LineItems?.length > this.displayedRowCount;
    }

    handleLoadMore(count: number) {
        this.displayedRowCount += count;
    }
}
