import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppFacadeService } from '../../../services/app-facade.service';
import { DialogContentType } from '../../../enums/dialog-type.enum';

@Component({
    selector: 'por-request-sent-successfully-dialog',
    templateUrl: './request-sent-successfully-dialog.component.html',
    styleUrls: ['./request-sent-successfully-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestSentSuccessfullyDialogComponent {
    constructor(readonly appFacadeService: AppFacadeService) {}

    dialogContentType = DialogContentType;
    message = '';
}
