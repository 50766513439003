import { Language } from '../enums';

/**
 * Kvm with the value being a JSON.
 */
export type TranslationsMap = {
    [k in Language]?: TranslationFile;
};

export type TranslationFile = {
    [k: string]: string;
};
