import { ReducerType, initialState } from '../state/app.state';
import { setActiveTab, setPaymentSectionEnabled } from './../actions/active-tab.actions';
import { createReducer, on } from '@ngrx/store';

export const activeTabReducer: ReducerType = createReducer(
    initialState,
    on(setActiveTab, (state, { activeTab }) => ({
        ...state,
        activeTab
    }))
);

export const paymentComponentReducer: ReducerType = createReducer(
    initialState,
    on(setPaymentSectionEnabled, (state, { enabled }) => ({
        ...state,
        paymentSectionEnabled: enabled
    }))
);
