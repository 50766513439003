import { createReducer, on } from '@ngrx/store';
import { saveEventLogsData, saveEventLogsSuccess } from '../actions/event-logs.action';
import { EventLogsResponse } from '../../models/event-logs.interface';

export interface DataState {
    data: EventLogsResponse | null;
}

export const initialState: DataState = {
    data: null
};

export const eventLogsReducer = createReducer(
    initialState,
    on(saveEventLogsData, state => ({ ...state })),
    on(saveEventLogsSuccess, (state, { response }) => ({ ...state, data: response }))
);
