import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ProductDetail } from '../../../models/product-detail';
import { NotificationService } from '../../../services';
import { AppFacadeService } from '../../../services/app-facade.service';
import { ContractService } from '../../../services/contract.service';
import { loadProductFail, loadProductSuccess, ProductActionTypes } from './product.actions';

@Injectable()
export class ProductEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly appFacadeService: AppFacadeService,
        private readonly contractService: ContractService,
        private readonly notification: NotificationService,
        private readonly translateService: TranslateService
    ) {}

    fetchProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductActionTypes.LoadProduct),
            tap(() => this.appFacadeService.setLoading(true)),
            switchMap(action => {
                const { customerId, columns, productId, stockId, callback }: { customerId: string; columns: string[]; productId: string; stockId: string; callback?: () => void } = action;

                return this.contractService.getProductDetails(customerId, productId, stockId, columns).pipe(
                    switchMap((data: ProductDetail[]) => {
                        if (callback) {
                            callback();
                        }
                        return of(loadProductSuccess({ productDetail: data }));
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.notification.error(this.translateService.instant('notFoundProductDetail'));
                        return of(loadProductFail({ error }));
                    }),
                    finalize(() => {
                        this.appFacadeService.setLoading(false);
                    })
                );
            })
        )
    );
}
