import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'por-accordion-group',
    templateUrl: './accordion-group.component.html',
    styleUrls: ['accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionGroupComponent {
    @Input() opened = true;
    @Input() isValid = true;
    @Input() title!: string;

    toggleAccordian() {
        this.opened = !this.opened;
    }
}
