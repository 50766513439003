import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationConfig } from '../../../../models/organization-config.model';

@Component({
    selector: 'por-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSettingsComponent {
    @Input() config!: OrganizationConfig;
}
