import { Searchable } from './searchable.model';

/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: DB attributes
export class Auth {
    OrganizationId?: string;
    accessToken?: string;
    RefreshToken?: string;
    Email?: string;
}

export interface UserLogin extends Searchable {
    accessToken: string;
    RefreshToken: string;
    OrganizationType: string;
}
