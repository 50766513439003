<div id="apx" class="customer-portal-wrappper-cp" [ngClass]="appliedClass">
    <ng-container *ngIf="appFacadeService.getAuthenticated() | async">
        <ng-container *ngIf="isSyrnix(); else adminTabs">
            <ng-container class="holder" *ngIf="configService.adminInputs$ | async as adminInputs">
                <ng-container *ngTemplateOutlet="headerTemplate; context: { showTabs: false, adminInputs }"></ng-container>
                <por-admin-mandatory [showWysiwyg]="false" [config]="(orgConfig$ | async) || {}" [adminConfig]="adminInputs" (formSubmitted)="doScrollToTop = true"></por-admin-mandatory>
            </ng-container>
        </ng-container>
    </ng-container>

    <div class="consumer-portal-loading" *ngIf="(appFacadeService.getAuthenticated() | async) === false || (appFacadeService.getAdminLoaded() | async)" [ngClass]="appliedClass">
        <strong>{{ (errorMessage$ | async)?.message || ('Please wait' | translate) }}</strong>
    </div>

    <ng-template #adminTabs>
        <ng-container class="holder" *ngIf="configService.adminInputs$ | async as adminInputs">
            <ng-container *ngIf="versionLoaded$ | async">
                <ng-container *ngTemplateOutlet="headerTemplate; context: { showTabs: true, adminInputs }"></ng-container>
                <ng-container class="customer-portal-admin" [ngSwitch]="appFacadeService.getActiveTab() | async">
                    <ng-container *ngSwitchDefault>
                        <por-admin-overview [adminConfig]="adminInputs"></por-admin-overview>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminWebpay'">
                        <por-admin-webpay [config]="(orgConfig$ | async) || {}"></por-admin-webpay>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminSetup'">
                        <por-admin-mandatory
                            [showWysiwyg]="true"
                            [config]="(orgConfig$ | async) || {}"
                            [adminConfig]="adminInputs"
                            (formSubmitted)="doScrollToTop = true"
                            (classEmitter)="onClassReceived($event)"></por-admin-mandatory>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminAccountList'">
                        <por-admin-account-list></por-admin-account-list>
                    </ng-container>
                    <ng-container *ngSwitchCase="'AdminAnalytics'">
                        <por-analytics></por-analytics>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
    <ng-template #headerTemplate let-showTabs="showTabs" let-adminInputs="adminInputs">
        <por-admin-header [porScrollTo]="doScrollToTop" (scrollComplete)="resetScrollFlag()" [config]="(orgConfig$ | async) || {}" [adminConfig]="adminInputs" [showTabs]="showTabs"></por-admin-header>
    </ng-template>
</div>
