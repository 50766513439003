import { createAction, props } from '@ngrx/store';
import { Customer, CustomerSummary } from '../../models/consumer';

export enum CustomerSummaryActionTypes {
    LoadCustomer = '[Customer] Load',
    LoadCustomerFinished = '[Customer] Load Finished',
    LoadCustomerSummary = '[Customer] Load Summary',
    LoadCustomerSummaryFinished = '[Customer] Load Summary Finished',
    LoadCustomerSummaryError = '[Customer] Load Summary Error',
    CleanAccountSummary = '[Customer] Clean Account Summary'
}

export const loadCustomer = createAction(CustomerSummaryActionTypes.LoadCustomer, props<{ customer: Customer }>());

export const loadCustomerFinished = createAction(CustomerSummaryActionTypes.LoadCustomerFinished, props<{ customer: Customer }>());

export const loadCustomerSummary = createAction(CustomerSummaryActionTypes.LoadCustomerSummary, props<{ summary: CustomerSummary }>());

export const loadCustomerSummaryFinished = createAction(CustomerSummaryActionTypes.LoadCustomerSummaryFinished, props<{ summary: CustomerSummary }>());

export const cleanAccountSummary = createAction(CustomerSummaryActionTypes.CleanAccountSummary);
