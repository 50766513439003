<div class="contract-row-card card-wrapper">
  <div class="card">
    <div class="head-top">&nbsp;</div>
    <ul>
      <li class="row" *ngFor="let item of invoiceKV">
        <div class="col-2">
          <h5 class="title">{{ item.label | translate }}</h5>
        </div>
        <span>:</span>
        <div class="col-10">
          <data *ngIf="item?.clickable; else elseBlock">
            <span (click)="loadContractDetail()">{{ item?.value }}</span>
          </data>
          <ng-template #elseBlock>
            <data>{{ item?.value }}</data>
          </ng-template>
        </div>
      </li>
    </ul>
    <por-base-button (onclick)="loadInvoiceDetail()" [featureName]="'view-detail-invoice'">{{
      'view details' | translate
    }}</por-base-button>
  </div>
</div>
