import { Inject, Renderer2, RendererFactory2, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    private readonly renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private readonly document: Document) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    loadScript(src: string) {
        const script = this.renderer.createElement('script');
        script.type = `text/javascript`;
        script.src = src;
        this.renderer.appendChild(this.document.body, script);
        return script;
    }

    removeScript(script: HTMLScriptElement) {
        this.renderer.removeChild(this.document.body, script);
    }
}
