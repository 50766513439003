import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppFacadeService } from '../../../services/app-facade.service';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { DialogContentType } from '../../../enums/dialog-type.enum';

@Component({
    selector: 'por-payment-success-dialog',
    templateUrl: './payment-success-dialog.component.html',
    styleUrls: ['./payment-success-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSuccessDialogComponent {
    constructor(readonly appFacadeService: AppFacadeService, readonly appMediatorService: AppMediatorService) {}

    dialogContentType = DialogContentType;
    dialogMessage = '';
    dateFormat: DateFormatType = DateFormatType.StandardDateTime;
}
