import { Component, Input } from '@angular/core';
import { LoggerService } from '../../services/logger.service';

@Component({
    selector: 'por-admin-demo',
    templateUrl: './admin-demo.component.html',
    styleUrls: ['./admin-demo.component.scss']
})
export class AdminDemoComponent {
    constructor(private readonly logger: LoggerService) {}

    @Input() url = '';
    @Input() config = '';

    handleOutPutError($e: any) {
        this.logger.logInfo({
            $e
        });
    }
}
