<div class="cf sectnav mobile-responsive" [ngClass]="{ 'mobile-responsive': consumerPortalWidth < 815 }">
    <ul class="por-menu" *ngIf="appFacadeService.getActiveTab() | async as activeTab">
        <li *ngFor="let url of navigations" class="navigations theme-list-item">
            <por-base-button
                porAppPushState
                featureName="{{ url?.text }}-page"
                [tab]="url?.tab"
                [ngClass]="{ 'is-active': url?.tab === activeTab }"
                [theme]="url?.tab === activeTab ? 'primary' : 'secondary'"
                (click)="toggleTab(url?.tab)">
                <label porAddFeature featureName="{{ url?.text }}-page">{{ url?.text | uppercase }}</label>
            </por-base-button>
        </li>
    </ul>
</div>
