import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { BingMapApiService } from '../services/bing-map-api.service';
import { LoggerService } from '../services/logger.service';
import isEmpty from 'lodash-es/isEmpty';
import { AppFacadeService } from './app-facade.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { TrackingResponse, TrackingResult } from '../models/tracking-result.model';

@Injectable({
    providedIn: 'root'
})
export class TrackService {
    constructor(
        private readonly bingMapApi: BingMapApiService,
        private readonly logger: LoggerService,
        private readonly notification: NotificationService,
        private readonly appFacadeService: AppFacadeService,
        private readonly consumerPortalApi: ConsumerPortalApiService
    ) {}

    private readonly contractsSource: BehaviorSubject<[]> = new BehaviorSubject([]);
    contracts: Observable<[]> = this.contractsSource.asObservable();
    trackingDetails = new BehaviorSubject<TrackingResult[]>([]);
    loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getContractTrackingDetails(
        customerId: string | undefined,
        bingMapApiUrl: string | undefined, // will come as part of configuration for Consumer Portal
        bingMapApiKey: string | undefined
    ): Subscription {
        if (!bingMapApiUrl && !bingMapApiKey && !isEmpty(bingMapApiUrl) && !isEmpty(bingMapApiKey)) {
            this.notification.error('Invalid API key or Api URL');
            return of(false).subscribe();
        }
        const columns = ['Id', 'Name', 'Status', 'ContractStatus', 'Addresses', 'LineItems'];
        // Note camelCase : DB Model
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = { 'x-columns': JSON.stringify(columns) };
        return this.consumerPortalApi
            .get(`customer/${customerId}/tracking`, { headers: headers })
            .pipe(
                tap({
                    next: (contracts: TrackingResult[]) => {
                        if (contracts?.length > 0) {
                            const trackingDetails: TrackingResponse[] = [];
                            contracts?.map((contract: TrackingResult) => {
                                let address = '';
                                if (contract?.Addresses?.line1 && contract?.Addresses?.line1 !== '') {
                                    address += `${contract?.Addresses?.line1} ,`;
                                }
                                if (contract?.Addresses?.line2 && contract?.Addresses?.line2 !== '') {
                                    address += `${contract?.Addresses?.line2} ,`;
                                }
                                if (contract?.Addresses?.line3 && contract?.Addresses?.line3 !== '') {
                                    address += `${contract?.Addresses?.line3} ,`;
                                }
                                if (contract?.Addresses?.city && contract?.Addresses?.city !== '') {
                                    address += `${contract?.Addresses?.city} ,`;
                                }
                                if (contract?.Addresses?.Country && contract?.Addresses?.Country !== '') {
                                    address += `${contract?.Addresses?.Country} `;
                                }
                                if (contract?.Addresses?.postalCode && contract?.Addresses?.postalCode !== '') {
                                    address += `${contract?.Addresses?.postalCode} `;
                                }

                                // will pushpin on the map if lat long exists
                                trackingDetails.push({
                                    contractId: contract?.contractId,
                                    itemName: contract?.name,
                                    address,
                                    lat: contract?.Addresses?.Latitude,
                                    long: contract?.Addresses?.Longitude,
                                    found: contract?.Addresses?.Latitude && contract?.Addresses?.Longitude ? true : false,
                                    latLongResult: !(contract?.Addresses?.Latitude && contract?.Addresses?.Longitude)
                                        ? this.bingMapApi.getLatLong(
                                              contract?.Addresses?.city ?? '',
                                              contract?.Addresses?.line1 ?? '',
                                              bingMapApiUrl, // will come as part of configuration for Consumer Portal
                                              bingMapApiKey
                                          )
                                        : null
                                });
                            });
                            this.trackingDetails.next(trackingDetails);
                        } else {
                            this.trackingDetails.next([]);
                        }
                        this.loaded$.next(true);
                    },
                    error: (err: HttpErrorResponse) => {
                        this.logger.logError(err?.error?.message);
                        this.logger.alertDevError(err);
                        if (err?.error.message) {
                            alert(err?.error.message);
                        }
                        this.appFacadeService.setLoading(false);
                    }
                })
            )
            .subscribe();
    }

    handleDuplicates(arr: TrackingResponse[]): TrackingResponse[] {
        if (arr === undefined || !arr || arr.length === 0) {
            return [];
        }
        const obj: { [key: string]: TrackingResponse } = {};
        const filteredArray: TrackingResponse[] = [];

        for (let i = 0; i < arr.length; i++) {
            const key = Math.abs(Number(arr[i]?.lat)) + Math.abs(Number(arr[i]?.long));
            if (obj[key] !== undefined) {
                arr[i].lat = (Number(arr[i]?.lat) + 0.00001 * i).toString();
                arr[i].long = (Number(arr[i]?.long) + 0.00001 * i).toString();
                filteredArray.push(arr[i]);
            } else {
                obj[key] = arr[i];
                filteredArray.push(arr[i]);
            }
        }

        return filteredArray;
    }
}
