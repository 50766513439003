<div class="list-wrapper">
    <p>{{'instructionToOpenSingle' | translate}}</p>
    <div class="list-table-wrapper">
        <table>
            <thead>
                <tr>
                    <td>{{'companyName' | translate}}</td>
                    <td>{{'city' | translate}}</td>
                    <td>{{'accountNumber'| translate}}</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let consumer of (consumers)">
                    <td><a (click)="generateQuickLink(consumer.Id)" href="javascript:void(0)">{{consumer.CompanyName}}</a></td>
                    <td>{{consumer.Addresses?.[0]?.City}}</td>
                    <td>{{consumer.Id}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <por-base-button *ngIf="!noMoreRecord" (onclick)="loadMore.emit()" class="theme-button apply-btn" [featureName]="'admin-save-button-data'">
        {{ 'Load More' | translate }}
    </por-base-button>
</div>
