import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig } from '../models/consumer-login-config';
import { User } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@por/shared/core';
import { encrypt } from '@por/consumer-login/shared-lib';
import { AppFormValidators } from '../validators/app.validators';

@Component({
    selector: 'por-signup',
    templateUrl: './signup-component.component.html',
    styleUrls: ['./signup-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponentComponent implements OnInit {
    @Output()
    readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    readonly signupData: EventEmitter<User> = new EventEmitter<User>();

    @Output()
    readonly loading$: EventEmitter<boolean> = new EventEmitter<boolean>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;

    @Input() isDisableButton = false;

    signupForm = this.fb.group({
        username: ['', [Validators.required, AppFormValidators.emailValidator, Validators.maxLength(128)]],
        name: ['', [Validators.required, Validators.maxLength(128)]],
        companyName: ['', [Validators.required, Validators.maxLength(128)]],
        phoneNumber: ['', [Validators.required, AppFormValidators.phoneValidator, Validators.maxLength(15)]],
        password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]]
    });

    constructor(private readonly fb: FormBuilder, private readonly translateService: TranslateService) {}

    async ngOnInit(): Promise<void> {
        this.translateService.use(Language.EN_GB);
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
        }

        this.signupForm.setValue({
            username: '',
            name: '',
            companyName: '',
            phoneNumber: '',
            password: ''
        });
    }

    get username(): FormControl {
        return this.signupForm.get('username') as FormControl;
    }

    get name(): FormControl {
        return this.signupForm.get('name') as FormControl;
    }

    get companyName(): FormControl {
        return this.signupForm.get('companyName') as FormControl;
    }

    get phoneNumber(): FormControl {
        return this.signupForm.get('phoneNumber') as FormControl;
    }

    get password(): FormControl {
        return this.signupForm.get('password') as FormControl;
    }

    signup(): boolean {
        const email: string = this.signupForm.value.username as string;
        const password: string = this.signupForm.value.password as string;

        if (!this.signupForm.valid) {
            return false;
        }
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        this.signupData.emit({
            Email: email,
            Password: encrypt(password),
            Name: this.signupForm.value.name as string,
            CompanyName: this.signupForm.value.companyName as string,
            PhoneNumber: this.signupForm.value.phoneNumber as string,
            StoreName: this.inputs?.storeName ?? ''
        } as User);
        this.loading$.emit(true);
        return true;
    }

    setTemplate(templateName: string): void {
        this.selectedTemplate.emit(templateName);
    }
}
