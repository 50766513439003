import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, take, tap } from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DEMO_ENV_CONFIG } from 'libs/consumer-portal-demo/src/app-config';
import { DemoView } from '../../models/demo-view';
import { OrganizationType } from '../../models/org-type';
import { ComponentType } from '../../models/component-type';
import { AuthService } from '../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccessRequestResponse, AuthRecord } from '../../models/access-response';
import { LoaderService } from '../../services/loader.service';
import { QuickLinkService } from '../../services/quick-link.service';
import { isEmpty } from 'lodash';
import { QuickLinkResponse } from '../../models/quick-link-response';
import { ConsumerPortalConfig } from '../../models/consumer-portal-config';
import { ConsumerPortalEnviromentConfig } from '../../models/env-model';
import { LoggerService } from '../../services/logger.service';

@Component({
    selector: 'por-consumer-portal-demo',
    templateUrl: './consumer-portal-demo.component.html',
    styleUrls: ['./consumer-portal-demo.component.scss']
})
export class ConsumerPortalDemoComponent implements OnInit, OnDestroy {
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(DEMO_ENV_CONFIG) private readonly appConfig: ConsumerPortalEnviromentConfig,
        private readonly renderer2: Renderer2,
        public readonly loader: LoaderService,
        private readonly auth: AuthService,
        private readonly quickLinkService: QuickLinkService,
        private readonly logger: LoggerService
    ) {}

    subscriptions: Subscription[] = [];
    style!: HTMLLinkElement;
    config = '';
    private readonly quickLinkUrl = new BehaviorSubject<string>('');
    public quickLinkUrl$ = this.quickLinkUrl.asObservable();

    inputs: ConsumerPortalConfig | unknown = {
        organizationId: '',
        organizationType: '',
        bingMapApiUrl: '',
        bingMapApiKey: '',
        bingMapMapsLibUrl: '',
        googleMapsApiKey: ''
    };

    public demoView = DemoView;
    public componenType = ComponentType;
    public componentTypeOptions = [ComponentType.ADMIN, ComponentType.QUICK_LINK];
    view$ = new BehaviorSubject(DemoView.Button);
    organizations = OrganizationType;
    cpJsUrl = this.appConfig.uiUrl + '/main.js';
    adminJsUrl = this.appConfig.adminJSUrl + '/main.js';
    appOpened$ = new BehaviorSubject<boolean>(false);
    @Output() readonly appOpenedBefore = new EventEmitter();

    stepOneForm = new FormGroup({
        organizationId: new FormControl('', { validators: Validators.required }),
        organizationType: new FormControl(this.organizations.SYRINX, {}),
        email: new FormControl('', { validators: [Validators.required, Validators.email] }),
        component: new FormControl(this.componenType.ADMIN, {}),

        contractId: new FormControl(''),
        xApiKey: new FormControl('', { validators: [Validators.required] })
    });
    demoAppData = new BehaviorSubject<any>({});
    tab: BehaviorSubject<number> = new BehaviorSubject(0);

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    ngOnInit(): void {
        const demoAppData = localStorage.getItem('demoAppData') || '{}';
        if (!isEmpty(JSON.parse(demoAppData))) {
            this.demoAppData.next(JSON.parse(demoAppData));
            this.stepOneForm.controls.xApiKey.setValue(this.demoAppData.value?.xApiKey as any);
            this.stepOneForm.patchValue({
                xApiKey: this.demoAppData.value?.xApiKey as string,
                component: this.demoAppData.value?.component as ComponentType,
                contractId: this.demoAppData.value?.contractId as string,
                email: this.demoAppData.value?.email as string,
                organizationId: this.demoAppData.value?.organizationId as string,
                organizationType: this.demoAppData.value?.organizationType as OrganizationType
            });
            this.appOpenedBefore.emit(demoAppData);
            this.submitConfigurations();
        }

        this.style = this.renderer2.createElement('link') as HTMLLinkElement;
        // Add the style to the head section
        this.renderer2.appendChild(this.document.head, this.style);
        // Set type of the link item and path to the css file
        this.renderer2.setProperty(this.style, 'rel', 'stylesheet');
        this.renderer2.setProperty(this.style, 'href', this.appConfig.uiUrl + '/styles.css');

        this.changeView(this.demoView.StepOne);
        this.appOpened$.next(true);

        const params = new URL(this.document.location as any).searchParams;
        const token = params.get('token');
        if (!isEmpty(token)) {
            this.view$.next(DemoView.ConsumerPortal);
            this.appOpened$.next(false);
            return;
        }

        // TODO Push in subscription
        this.subscriptions.push(
            this.stepOneForm.get('component')?.valueChanges.subscribe(component => {
                if (component === this.componenType.QUICK_LINK) {
                    this.stepOneForm.get('contractId')?.setValidators([Validators.required]);
                } else {
                    this.stepOneForm.get('contractId')?.setValidators(null);
                }
                this.stepOneForm.get('contractId')?.updateValueAndValidity();
            }) as Subscription
        );

        this.subscriptions.push(
            this.auth.auth$.subscribe(authdata => {
                const data = authdata as AuthRecord;
                if (!data) return;
                const { accessToken } = data;

                if (!isEmpty(accessToken)) {
                    // if quick link then do the quick link work
                    if (this.stepOneForm.value.component === this.componenType.QUICK_LINK) {
                        // Call the quick link api and return here
                        this.subscriptions.push(
                            this.quickLinkService
                                .getQuickLinkUrl({
                                    contractId: this.stepOneForm.value?.contractId as string,
                                    apiKey: this.stepOneForm.value?.xApiKey as string
                                })
                                .pipe(
                                    tap({
                                        next: res => {
                                            const responseData = res as QuickLinkResponse;
                                            this.quickLinkUrl.next(responseData.Records.url);
                                            window.location.replace(responseData.Records.url);
                                        },
                                        error: (err: HttpErrorResponse) => {
                                            this.logger.logWarning({
                                                err
                                            });
                                            alert(err.error?.message);
                                            this.view$.next(DemoView.StepOne);
                                            this.appOpened$.next(true);
                                        }
                                    })
                                )
                                .subscribe()
                        );
                        return;
                    }
                }
            })
        );
    }

    changeView(view: DemoView) {
        this.view$.next(view);
    }

    onLogoutToDemoApp() {
        localStorage.clear();
        this.view$.next(this.demoView.StepOne);
        this.appOpened$.next(true);
    }

    refresh(): void {
        this.submitConfigurations();
    }

    tab1() {
        this.submitConfigurations();
        this.tab.next(1);
    }

    submitConfigurations() {
        if (!this.stepOneForm.valid) return;

        this.loader.showloader();
        if (isEmpty(this.demoAppData.value)) {
            localStorage.setItem('demoAppData', JSON.stringify(this.stepOneForm.value));
        }
        const { component, email, organizationId, organizationType, xApiKey } = this.stepOneForm.value;
        this.auth
            .login({
                orgId: organizationId as string,
                email: email as string,
                orgType: organizationType as string,
                apiKey: xApiKey as string
            })
            .pipe(
                take(1),
                tap({
                    next: res => {
                        const response = res as unknown as AccessRequestResponse;
                        this.inputs = {
                            organizationId: organizationId,
                            organizationType: organizationType,
                            /* eslint-disable @typescript-eslint/naming-convention  */
                            /**
                             * Note camelCase : DB Model
                             */
                            Auth: {
                                AccessToken: response.Records.accessToken,
                                RefreshToken: response.Records.RefreshToken,
                                Email: email
                            },
                            enabledFeatures: {
                                essentials: {
                                    invoices: false,
                                    logOff: false,
                                    consumerLogin: false,
                                    multipleCustomerSelection: true,
                                    payFullAmount: true
                                },
                                syrinx: {
                                    invoices: true,
                                    logOff: false,
                                    consumerLogin: false,
                                    multipleCustomerSelection: true,
                                    payFullAmount: false
                                },
                                expert: {
                                    invoices: true,
                                    logOff: false,
                                    consumerLogin: false,
                                    multipleCustomerSelection: true,
                                    payFullAmount: false,
                                    pdfDownloadFeature: false,
                                    textSearchMultipleFilters: true
                                }
                            }
                        } as ConsumerPortalConfig;

                        this.config = JSON.stringify(this.inputs);
                        //this will be based on the value selected which thing to load up
                        this.view$.next(component as unknown as DemoView);
                        this.appOpened$.next(false);
                        this.loader.hideloader();
                    },
                    error: (e: HttpErrorResponse) => {
                        this.logger.logError(e);
                        this.loader.hideloader();
                        alert(e?.error?.token);
                    }
                })
            )
            .subscribe();
    }
}
