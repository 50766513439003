import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppFacadeService } from '../../services/app-facade.service';
import { ContractCP } from '../../models/contract-model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'por-multi-payment-contract-panel',
    templateUrl: './multi-payment-contract-panel.component.html',
    styleUrls: ['./multi-payment-contract-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiPaymentContractPanelComponent implements OnInit, OnDestroy {
    @Input() isOpen = false; // Overlay open/close state
    @Input() trigger!: CdkOverlayOrigin; // Overlay trigger passed from parent
    @Output() readonly closeOverlay = new EventEmitter<void>(); // Emit event to parent when closing

    private readonly subscriptions: Subscription[] = [];
    total = 0;

    constructor(readonly appFacadeService: AppFacadeService) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.appFacadeService.getContractsTotal().subscribe(total => {
                this.total = total;
            })
        );
    }

    closeOverlayClick(): void {
        this.closeOverlay.emit();
    }

    removeContracts(contract: ContractCP | ContractCP[]) {
        const contractIds: string[] = Array.isArray(contract)
            ? contract.map(c => c.contractId) // If multiple contracts, get their IDs
            : [contract.contractId]; // If single contract, create an array with its ID

        this.appFacadeService.unsetSelectedContracts(contractIds);
    }

    onClickContinue(): void {
        if (this.total >= 1) {
            this.closeOverlay.emit();
            this.appFacadeService.multiPaymentContinue();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
