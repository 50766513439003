import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ContractDetail } from '../../../models/contract-model';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { DateUtility } from '../../../date.utility';
import { Consumed } from '../../../enums/consumed-payment-verify';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { FeatureToggleService } from '../../../services/feature-toggle.service';

@Component({
    selector: 'por-contract-payment-history-modal',
    templateUrl: './contract-payment-history-modal.component.html',
    styleUrls: ['./contract-payment-history-modal.component.scss']
})
export class ContractPaymentHistoryModalComponent {
    @Input() contractDetail!: ContractDetail[] | null;

    dateFormat: string;
    consumed = Consumed;

    constructor(@Inject(LOCALE_ID) locale: string, readonly appMediatorService: AppMediatorService, readonly featureToggleService: FeatureToggleService) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    isPaymentMethodAvailable() {
        return this.contractDetail?.some(x => x.Payment?.some(y => y.PaymentMethod));
    }
}
