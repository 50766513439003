import { Injectable } from '@angular/core';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(private readonly cpApi: ConsumerPortalApiService) {}

    uploadFile(file: File): Observable<{url : string | undefined}> {
        const form = new FormData();
        form.append('image', file);
        return this.cpApi.post({
            controller: 'upload/file',
            body: form,
            headers: {
                mimeType: 'multipart/form-data'  
            },
            method: 'POST'
        });
    }
}
