import { createReducer, on } from '@ngrx/store';
import { EventLogsResponse } from '../../../models/event-logs.interface';
import { saveEventLogsData, saveEventLogsSuccess } from './event-logs.actions';

export interface DataState {
    data: EventLogsResponse | null;
}

export const initialState: DataState = {
    data: null
};

export const eventLogsReducer = createReducer(
    initialState,
    on(saveEventLogsData, state => ({ ...state })),
    on(saveEventLogsSuccess, (state, { response }) => ({ ...state, data: response }))
);
