import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { Invoice } from '../../models/invoice-model';
import { RowLabelValue } from '../../models/row-label-value-model';
import { FormatCurrencyPipe } from '../../pipes/format-currency.pipe';

@Component({
    selector: 'por-invoices-full-width-row',
    templateUrl: './invoices-full-width-row.component.html',
    styleUrls: ['./invoices-full-width-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesFullWidthRowComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    constructor(@Inject(LOCALE_ID) locale: string, private readonly currencyPipe: FormatCurrencyPipe, private readonly date: DatePipe) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    invoice!: Invoice;
    invoiceKV: RowLabelValue[] = [];
    dateFormat = '';
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.invoice = { ...params.data };
        this.invoiceKV = [
            {
                label: 'invoiceNumber',
                value: this.invoice?.InvoiceNumber
            },
            {
                label: 'invoiceDate',
                value: this.date.transform(this.invoice?.InvoiceDate, this.dateFormat)
            },
            {
                label: 'invoiceTotal',
                value: this.currencyPipe.transform(this.invoice?.GrandTotal)
            }
        ];
    }

    refresh() {
        return true;
    }

    loadContractDetail() {
        this.params.context?.componentParent.loadContractDetailModal(this.invoice?.Identifiers.ContractId);
    }

    loadInvoiceDetail() {
        this.params.context?.componentParent.loadInvoiceDetailModal(this.params.data);
    }
}
