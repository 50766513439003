import { createAction, props } from '@ngrx/store';
import { ConsumerPortalState } from '../../../types/state.type';
import { Redirection } from '../../../types/redirection.type';
import { ComponentsToLoad, SubComponentsToLoad } from '../../../enums/components-to-load.enum';

enum AppActionTypes {
    SetDeviceType = '[App] Set Device type',
    SetLoading = '[App] Set Loading',
    SetConsumerPortalViewState = '[App] Set View State',
    SetAuthenticated = '[App] Set IsAuthenticated',
    SetRedirected = '[App] Set IsRedirected',
    SetAdminLoading = '[App] Set Admin Loading',
    LoadVersionRedirection = '[App] Load Version Redirection',
    SetVersionRedirection = '[App] Set Version Redirection',
    LoadVersionRedirectionFailure = 'App] Load Version Redirection Failure',
    SetActiveTab = '[App] Set Active Tab',
    SetSubActiveTab = '[App] Set Sub Active Tab',
    SetAdmin = '[App] Set Admin'
}

export const setDeviceType = createAction(AppActionTypes.SetDeviceType, props<{ isMobile: boolean }>());

export const setLoading = createAction(AppActionTypes.SetLoading, (isLoading: boolean) => ({ isLoading }));

export const setConsumerPortalState = createAction(AppActionTypes.SetConsumerPortalViewState, props<{ consumerPortalState: ConsumerPortalState }>());

export const setAuthenticated = createAction(AppActionTypes.SetAuthenticated, (isAuthenticated: boolean) => ({ isAuthenticated }));

export const setRedirected = createAction(AppActionTypes.SetRedirected, (isRedirected: boolean) => ({ isRedirected }));

export const setAdminLoading = createAction(AppActionTypes.SetAdminLoading, (isAdminLoaded: boolean) => ({ isAdminLoaded }));

export const loadVersionRedirection = createAction(AppActionTypes.LoadVersionRedirection, props<{ orgId: string }>());

export const setVersionRedirection = createAction(AppActionTypes.SetVersionRedirection, props<{ redirectionVersion: Redirection }>());

export const loadVersionRedirectionFailure = createAction(AppActionTypes.LoadVersionRedirectionFailure, props<{ error: string }>());

export const setActiveTab = createAction(AppActionTypes.SetActiveTab, props<{ activeTab: ComponentsToLoad }>());

export const setSubActiveTab = createAction(AppActionTypes.SetSubActiveTab, props<{ subActiveTab: SubComponentsToLoad }>());

export const setIsAdmin = createAction(AppActionTypes.SetAdmin, (isAdmin: boolean) => ({ isAdmin }));
