import { Injectable } from '@angular/core';
import { ConsumerPortalConfig } from '../models/consumer-portal-config';

@Injectable()
export class MapActionsService {
    public config?: ConsumerPortalConfig;
    private readonly googleMapsBaseUrl = 'https://maps.googleapis.com/maps/api/js';

    getScript() {
        return `${this.googleMapsBaseUrl}?key=${this.config?.googleMapsApiKey}&v=weekly&callback=Function.prototype`;
    }
}
