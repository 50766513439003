import { createReducer, on } from '@ngrx/store';
import { ItemOutCP } from '../../../models/item-out-model';
import { clearItemsOut, loadItemOuts, noRecordFoundItemsOut, updateRow } from '../items-out-actions/items-out-action.actions';

export const itemsOutFeatureKey = 'itemsOut';

export interface ItemsOutState {
    pageNumber: number;
    items: ItemOutCP[];
    noMoreRecord: boolean;
}

export const initialState: ItemsOutState = {
    pageNumber: 1,
    items: [],
    noMoreRecord: false
};

export const itemsOutReducer = createReducer(
    initialState,
    on(loadItemOuts, (state: ItemsOutState, { items }: { items: ItemOutCP[] }) => {
        return {
            ...state,
            items: [...state.items, ...items],
            pageNumber: state.pageNumber + 1
        };
    }),
    on(noRecordFoundItemsOut, (state: ItemsOutState, { loading }: { loading: boolean }) => {
        return {
            ...state,
            noMoreRecord: loading
        };
    }),
    on(clearItemsOut, (state: ItemsOutState) => {
        return {
            ...state,
            items: [],
            pageNumber: 1
        };
    }),
    on(updateRow, (state: ItemsOutState, { nodeId, colId, value }) => {
        const getDraftRowData = function (stateParam: ItemsOutState) {
            return stateParam.items.map((item: ItemOutCP) => {
                if (item.id === nodeId) {
                    return {
                        ...item,
                        [colId]: Array.isArray(value)
                            ? [...((item[colId as keyof ItemOutCP] as number[]) || []), ...value] // Append if value is an array
                            : value // Replace if value is not an array
                    };
                }
                return item;
            });
        };
        const draftRowData = getDraftRowData(state);
        return {
            ...state,
            items: draftRowData,
            pageNumber: state.pageNumber
        };
    })
);
