import { LoginSession } from '../../models/login-session.model';
import { Redirection } from '../../types/redirection.type';

export interface RedirectionState {
    redirection: Redirection | null;
    session: LoginSession | null;
    error: string | null;
    isConfigSet: boolean;
}

export const redirectionInitialState: RedirectionState = {
    redirection: null,
    session: null,
    error: null,
    isConfigSet: false
};
