<div class="toggle-wrapper">
    <mat-button-toggle-group (change)="toggleHandler($event)">
        <mat-button-toggle
            [class]="class || ''"
            class="theme-button-toggle"
            [ngClass]="{
                active: currentValue === option?.value ? true : !currentValue && option?.text === 'Show All'
            }"
            *ngFor="let option of values"
            [value]="option?.value || ''"
            porAddFeature
            [featureName]="featureName">
            <span>{{ option?.text }}</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>
