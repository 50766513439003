import { Injectable } from '@angular/core';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { RentalMailRequest } from '../models/rental-mail-request';

@Injectable({
    providedIn: 'root'
})
export class RedirectionService {
    constructor(private readonly consumerPortalApiService: ConsumerPortalApiService) {}

    sendEmailRMBNotFound(body: RentalMailRequest = {}) {
        return this.consumerPortalApiService.post({
            controller: `customer/notify/rental-company`,
            method: 'POST',
            body: body
        });
    }

    sendPorSupportEmail(errorMessage: string) {
        return this.consumerPortalApiService.post({
            controller: `customer/notify/por-support`,
            method: 'POST',
            body: {
                errorMessage: errorMessage
            }
        });
    }
}
