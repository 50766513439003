import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';

export const APP_CONFIG = new InjectionToken('Application config');

@Injectable({
    providedIn: 'root'
})
export class BingMapApiService {
    constructor(protected http: HttpClient, private readonly notification: NotificationService, private readonly consumerPortalApi: ConsumerPortalApiService) {}
    getLatLong(
        locality: string,
        addressLine: string,
        bingMapApiUrl: string | undefined, // will come as part of configuration for Consumer Portal
        bingMapApiKey: string | undefined
    ) {
        if (bingMapApiUrl && bingMapApiUrl.length > 5) {
            return this.http.get(`${bingMapApiUrl}/v1/Locations?&locality=${locality}&addressLine=${addressLine}&key=${bingMapApiKey}`).pipe(map(res => res));
        } else {
            this.notification.error('invalid Bing Map API URL on BING MAP SERVICE');
            return false;
        }
    }
}
