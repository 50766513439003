<div class="selected-item-actions">
    <div class="header-section">
        <div class="header">
            <h1 class="theme-heading">{{ 'SelectedItem' | translate }}</h1>
            <por-base-button [featureName]="'selected-item-actions-close'" (click)="onCloseButtonClick()" icon="close"></por-base-button>
        </div>
    </div>
    <div class="content">
        <div class="line-items-table">
            <table class="table">
                <thead>
                    <th>{{ 'contract' | translate }}</th>
                    <th>{{ 'Item' | translate }}#</th>
                    <th>{{ 'itemName' | translate }}</th>
                    <th>{{ 'HireDate' | translate }}</th>
                    <th>{{ 'Quantity' | translate }}</th>
                    <th>{{ 'status' | translate }}</th>
                    <th>{{ 'Request' | translate }}</th>
                </thead>
                <tbody>
                    <td>{{ contractDetail?.Name }}</td>
                    <td>{{ itemOutService.getLineItemKeyName(lineItem) }}</td>
                    <td [innerHTML]="lineItem.Name"></td>
                    <td>{{ lineItem.StartDateTime | formatDate : appFacadeService.featureToggleService.isSyrinx() }}</td>
                    <td>{{ lineItem.Quantity }}</td>
                    <td>{{ lineItem.Status }}</td>
                    <td></td>
                </tbody>
            </table>
        </div>
        <div class="row mt-3">
            <div class="col">
                <h1>{{ 'actions' | translate }}</h1>
                <hr />
                <div class="mt-3">
                    <por-request-actions-buttons
                        [uiUrl]="uiUrl ?? ''"
                        [sendDocumentButtonVisible]="isRental(lineItem.Classification)"
                        [requestServiceButtonVisible]="true"
                        (requestReturnClick)="renderRequestReturn()"
                        (sendDocumentClick)="renderSendDocumentLink()"
                        [sendDocumentButtonVisible]="(appFacadeService.checkIfDocumentsExist() | async) === true"
                        (requestServiceClick)="renderRequestService()"></por-request-actions-buttons>
                </div>
                <div class="mt-3">
                    <h1>{{ 'Documents' | translate }}</h1>
                    <hr />
                    <div class="mt-3">
                        <por-document-lists [uiUrl]="uiUrl ?? ''" [documentParams]="documentParams"></por-document-lists>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<por-base-modal [id]="'rental-request-page'" [modalWidth]="'modal-lg'">
    <ng-container [ngSwitch]="enabledTab$ | async">
        <ng-container *ngSwitchCase="'RequestReturnView'">
            <por-close-item-out
                [customerId]="customerId"
                [items]="itemOutCP"
                [uiUrl]="uiUrl"
                [contractDetail]="contractDetail"
                (isFormReturn)="handleRentalFormReturn()"
                (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-close-item-out>
        </ng-container>
        <ng-container *ngSwitchCase="'RequestServiceView'">
            <por-service-request
                [customerId]="customerId"
                [items]="itemOutCP"
                [uiUrl]="uiUrl"
                [contractDetail]="contractDetail"
                (isFormReturn)="handleRentalFormReturn()"
                (callOffRequestInfo)="handleRentalFormSubmission($event)"></por-service-request>
        </ng-container>
        <ng-container *ngSwitchCase="'SendDocumentsView'">
            <por-send-document-link
                [sendLinkRequestData]="sendLinkRequestData"
                (isFormReturn)="handleRentalFormReturn()"
                (sendLinkRequestInfo)="handleSendDocumentFormSubmission($event)"></por-send-document-link>
        </ng-container>
    </ng-container>
</por-base-modal>
