/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, forwardRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'por-base-textarea',
    templateUrl: './base-textarea.component.html',
    styleUrls: ['./base-textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseTextareaComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseTextareaComponent implements ControlValueAccessor, OnChanges {
    @Input() model!: string;
    @Input() label!: string;
    @Input() placeholder!: string;
    @Input() editButton: boolean = true;
    @Input() required: boolean = false;
    @Input() parentForm!: FormGroup;
    @Input() public formControlName!: string;
    @Output() readonly modelChange = new EventEmitter<string>();
    @Input() featureName = '';

    public value: string = '';
    public isDisabled!: boolean;
    public changed!: (value: string) => void;
    public touched!: () => void;

    writeValue(value: string): void {
        if (this.model !== '') {
            this.value = this.model;
            return;
        }
        this.value = value;
    }

    registerOnChange(fn: () => void): void {
        this.changed = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.['model']?.isFirstChange()) {
            this.value = this.model;
        }

        this.value = this.model;
    }
    onChange(event: Event): void {
        const value: string = (<HTMLInputElement>event.target).value;
        this.changed(value);
    }

    get formField(): FormControl {
        return this.parentForm?.get(this.formControlName) as FormControl;
    }

    updateNewValue(newValue?: Event) {
        if (newValue) {
            this.onChange(newValue);
        }
        this.modelChange.emit(this.model);
        this.value = this.model;
    }

    resetField() {
        this.model = this.value;
    }
}
