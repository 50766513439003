import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppMediatorService } from '../../services/app-mediator.service';
import { loadPaymentProcessorAvailability, loadPaymentProcessorAvailabilitySuccess, loadPaymentProcessorAvailabilityFailure } from '../actions/processors.actions';
import { Processors } from '../../payment/models/processors.interface';

@Injectable()
export class ProcessorsEffects {
    constructor(private readonly actions$: Actions, private readonly appMediatorService: AppMediatorService) {}

    loadPaymentProcessorAvailability$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPaymentProcessorAvailability),
            switchMap(({ customerId }) =>
                this.appMediatorService.porPayService.isPaymentProcessorAvailable(customerId).pipe(
                    map((processors: boolean | Processors[]) => loadPaymentProcessorAvailabilitySuccess({ processors })),
                    catchError(() => of(loadPaymentProcessorAvailabilityFailure()))
                )
            )
        )
    );
}
