import { createAction, props } from '@ngrx/store';
import { PaymentMinMaxBoundary } from '../../payment/models/payment-data.model';
import { ConsumerPortalConfig } from '../../models';
import { PaymentAppPayOutput, PayOutputEvent } from '../../payment/models/paymentOutput';

export enum PaymentActionTypes {
    SetMultiPaymentBoundaries = '[Payment] Set Multi Payment Boundaries',
    MultiPaymentBoundariesFail = '[Payment] Failed to load Payment Boundaries',
    SetConfigData = '[Payment] Set Config Data',
    SetTransactionLoaded = '[Payment] Set Transaction Loaded',
    SetPaymentAppInput = '[Payment] Set Payment App Input',
    CreateTransaction = '[Payment] Create Transaction',
    SuccessCreateTransaction = '[Payment] Create Transaction Success',
    FailedCreateTransaction = '[Payment] Create Transaction Failed',
    PaymentFinished = '[Payment] Payment Finished',
    FailVerifyPayment = '[Payment] Payment Finished Failed',
    SuccessVerifyPayment = '[Payment] Payment Finished Success',
    SetDialogEnabled = '[Payment] Set Dialog Enabled'
}

export const setMultiPaymentBoundaries = createAction(PaymentActionTypes.SetMultiPaymentBoundaries, props<{ paymentBoundaries: PaymentMinMaxBoundary[] | null }>());

export const setConfigData = createAction(PaymentActionTypes.SetConfigData, props<{ config: ConsumerPortalConfig | null }>());

export const setTransactionLoaded = createAction(PaymentActionTypes.SetTransactionLoaded, props<{ transactionLoaded: boolean }>());

export const setDialogEnabled = createAction(PaymentActionTypes.SetDialogEnabled, props<{ dialogEnabled: boolean }>());

export const setPaymentAppInput = createAction(PaymentActionTypes.SetPaymentAppInput, props<{ paymentAppInput: string }>());

export const failLoadPaymentBoundaries = createAction(PaymentActionTypes.MultiPaymentBoundariesFail, props<{ error: string }>());

export const createTransaction = createAction(PaymentActionTypes.CreateTransaction);

export const successCreateTransaction = createAction(PaymentActionTypes.SuccessCreateTransaction, props<{ res: unknown }>());

export const failCreateTransaction = createAction(PaymentActionTypes.FailedCreateTransaction, props<{ error: string }>());

export const onPaymentFinished = createAction(PaymentActionTypes.PaymentFinished, props<{ paymentAppOutput: PayOutputEvent }>());

export const failVerifyPayment = createAction(PaymentActionTypes.FailVerifyPayment, props<{ error: string }>());

export const successVerifyPayment = createAction(PaymentActionTypes.SuccessVerifyPayment, props<{ lastPaymentDetail: PaymentAppPayOutput }>());
