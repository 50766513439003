import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { CustomerSummaryActionTypes } from '../store/actions/customer-summary.actions';
import { LoggerService } from '../services/logger.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { Customer, CustomerSummary } from '../models/consumer';

@Injectable({
    providedIn: 'root'
})
export class AccountSummaryService {
    constructor(private readonly store: Store, private readonly logger: LoggerService, private readonly consumerPortalApiService: ConsumerPortalApiService) {}

    getCustomer(customerId: string | number) {
        return this.consumerPortalApiService.get<Customer>(`customer/${customerId}`).pipe(
            tap({
                next: (data: Customer[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    this.logger.logInfo('Something Went Wrong With summary api');
                    this.logger.logError(error?.error?.message);
                    this.logger.alertDevError(error);
                    error?.error?.message ? alert(error?.error?.message) : null;
                }
            })
        );
    }

    getCustomerSummary(customerId: string | number) {
        this.store.dispatch({ type: CustomerSummaryActionTypes.LoadCustomerSummary });
        return this.consumerPortalApiService.get<CustomerSummary>(`customer/${customerId}/summary`).pipe(
            tap({
                next: (data: CustomerSummary[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    this.logger.logInfo('Something Went Wrong With summary api');
                    this.logger.logError(error?.error?.message);
                    this.logger.alertDevError(error);
                    error?.error?.message ? alert(error?.error?.message) : null;
                }
            })
        );
    }
}
