import { ConsumerLoginConfig } from './model/consumer-login-config';

export const loginDefaults: ConsumerLoginConfig = {
    organizationId: '',
    styling: {
        themeColors: {
            primaryColor: '#000000',
            secondaryColor: '#83BE42'
        },
        busyIndicator: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        changePassword: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        forgotPassword: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        signin: {
            paragraph: { fontSize: '50px' },
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        signup: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        verify: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        }
    }
};
