import { Injectable } from '@angular/core';
import { PorPayService } from '../payment/services/por-pay.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
/**
 * The AppMediatorService class provides access to various services used by the consumer portal UI.
 */
export class AppMediatorService {
    constructor(private readonly porPaymentService: PorPayService, private readonly localStorage: LocalStorageService) {}

    /**
     * Gets the POR payment service.
     */
    get porPayService(): PorPayService {
        return this.porPaymentService;
    }

    /**
     * Gets the local storage service.
     */
    get localStorageService(): LocalStorageService {
        return this.localStorage;
    }
}
