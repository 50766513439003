import { Injectable } from '@angular/core';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { Observable, map } from 'rxjs';
import { Consumer } from '../models/consumer-model';
import { AnalyticsFilterType } from '../../types/analytics.type';
import { AnalyticsDataResponse } from '../interfaces/analytics-data-response.interface';

@Injectable({
    providedIn: 'root'
})
export class ConsumerService {
    constructor(private readonly consumerPortalApiService: ConsumerPortalApiService) {}

    getAllConsumers(pagenumber: number, search?: string): Observable<Consumer[]> {
        return this.consumerPortalApiService.get(this.prepareUrl(pagenumber, search)).pipe(
            map(res => {
                return res;
            })
        ) as unknown as Observable<Consumer[]>;
    }

    prepareUrl(pagenumber: number, search?: string) {
        if (search) {
            return `consumers/get-all?page=${pagenumber}&search=${search}`;
        }
        return `consumers/get-all?page=${pagenumber}`;
    }

    generateQuickLink(consumerId: string) {
        return this.consumerPortalApiService.post({
            controller: 'tokens/quicklink/generate',
            method: 'POST',
            body: {
                // Note camelCase: DB Model
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Id: consumerId
            }
        });
    }

    /**
     * Get Analytics data from api
     * @param filter
     * @returns Observable<AnalyticsDataResponse[]>
     */
    getAnalyticsData(filter?: AnalyticsFilterType): Observable<AnalyticsDataResponse[]> {
        const params = new URLSearchParams({
            requestType: filter ? filter.type.toString() : '',
            requestYear: filter ? filter.year.toString() : ''
        }).toString();
        return this.consumerPortalApiService.get(`consumers/analytics?${params}`).pipe(
            map(res => {
                return res;
            })
        ) as unknown as Observable<AnalyticsDataResponse[]>;
    }
}
