<div class="dialog-backdrop">
    <div class="dialog-box">
        <div class="dialog-content">
            <p class="error-message">{{ 'errorMessage' | translate }}</p>
            <button (click)="onRetryClick()">{{ 'Retry' | translate }}</button>

            <div class="error-details">
                <p>
                    <strong>{{ 'ErrorDetails' | translate }}:</strong>
                </p>
                <p>
                    <strong>{{ 'ErrorStatusCode' | translate }}:</strong>
                    {{ data.errorStatus }}
                </p>
                <p *ngIf="data.orgId">
                    <strong>{{ 'organizationId' | translate }}:</strong>
                    {{ data.orgId }}
                </p>
                <p *ngIf="data.contractId">
                    <strong>{{ 'contractId' | translate }}:</strong>
                    {{ data.contractId }}
                </p>
                <p *ngIf="data.customerId">
                    <strong>{{ 'customerId' | translate }}:</strong>
                    {{ data.customerId }}
                </p>
                <p>
                    <strong>{{ 'QuickLink' | translate }}:</strong>
                    {{ data.quickLink }}
                </p>
                <p>
                    <strong>{{ 'Date' | translate }}:</strong>
                    {{ data.date }}
                </p>
            </div>
        </div>
    </div>
</div>
