import { ConsumerPortalConfig } from '../lib/models/consumer-portal-config';

export const consumerPortalDefaults: ConsumerPortalConfig = {
    organizationId: '',
    organizationType: 'essentials',
    showLoader: true,
    styling: {
        themeColors: {
            primaryColor: '#000000',
            secondaryColor: '#83BE42'
        }
    },
    bingMapApiUrl: 'https://www.bing.com/api/maps/mapcontrol',
    bingMapMapsLibUrl: 'https://www.bing.com/api/maps/mapcontrol?callback=__onBingLoaded&branch=experimental&key=',
    bingMapApiKey: 'AkF-c5G2Nzmu4r9XXanocXsxs7lZRp2Fc4y6pxV5D_4S8CnbTQzy1fWu4gIUJDyY',
    googleMapsApiKey: 'AIzaSyDKjtTNX1ij-Mj1cHfgRePYht43Ye5zdqE',
    header: {
        logoUrl: ''
    },
    apiUrl: 'https://dev-consumer-portal-api.rentamation.com/v1',
    uiUrl: 'https://dev-consumer-portal-ui.rentamation.com',
    enabledFeatures: {
        essentials: {
            invoices: false,
            logOff: true,
            consumerLogin: false,
            multipleCustomerSelection: false,
            payFullAmount: true,
            textSearchMultipleFilters: true,
            pdfDownloadFeature: false,
            contractGridJobColumnAvailable: true,
            checkPaymentLicense: false,
            capturePaymentFeature: false, // affects the feature
            contractCancel: true, // affects the visibility of cancel button on contract details page
            contractEdit: true, // affects the visibility of edit button on contract details page
            canCapturePayment: ['Quote', 'Reservation', 'Open', 'Returned', 'Closed'], // affects the visibility of payment button on contract details page
            canContractCancel: ['Reservation'], // affects the visibility of cancel button on contract details page
            canContractEdit: [], // affects the visibility of edit button on contract details page
            showHeaderSections: false, // affects the visibility of logo in header and account drop down
            canSeeBalance: true, // affects visibity of balance  on contract-details page
            showDetailDocuments: false, // affects visibity of document section  on contract-details page table
            canHaveSelectedItemsActions: false, // show Item actions page on click of item name from contract detail
            productDetailFeature: true, // show or hide a link to product detail page on contract-details page table
            canHaveProductsKeyDocumentsView: false, // show key documents like Service Certificate, Inspection Certificate etc. view on product detail
            canHaveQuickLinkLoginButton: true, // Affects the visiblity of Login for more info button on quicklink,
            secureUrlLoginRequired: false, // Consumer has to be logged in to view PDF and Esign
            amountFieldVisible: true, // show or hide amount field on contract-details page table
            amoutDueFieldVisible: true // show or hide amount due field on contract-details page table
        },
        syrinx: {
            invoices: true,
            logOff: true,
            consumerLogin: true,
            multipleCustomerSelection: true,
            payFullAmount: false,
            textSearchMultipleFilters: false, // This Property enables search on the contract page if we enable it will search in other filters else not.
            pdfDownloadFeature: true,
            contractGridJobColumnAvailable: false,
            checkPaymentLicense: true,
            capturePaymentFeature: true,
            contractCancel: false,
            contractEdit: false,
            canCapturePayment: ['Quote', 'Reservation', 'Open', 'Returned', 'Closed'],
            canContractCancel: [],
            canContractEdit: [],
            showHeaderSections: true,
            canSeeBalance: false,
            showDetailDocuments: true,
            canHaveSelectedItemsActions: true,
            productDetailFeature: false,
            canHaveProductsKeyDocumentsView: true,
            canHaveQuickLinkLoginButton: false,
            displayInspectionDocument: true,
            displayServiceCertificate: true,
            secureUrlLoginRequired: false,
            amountFieldVisible: false,
            amoutDueFieldVisible: false
        },
        expert: {
            invoices: false,
            logOff: true,
            consumerLogin: true,
            multipleCustomerSelection: true,
            payFullAmount: true,
            textSearchMultipleFilters: true,
            pdfDownloadFeature: true,
            contractGridJobColumnAvailable: true,
            checkPaymentLicense: true,
            capturePaymentFeature: true,
            contractCancel: false,
            contractEdit: false,
            canCapturePayment: ['Quote', 'Reservation', 'Open', 'Returned', 'Closed'],
            canContractCancel: [],
            canContractEdit: [],
            showHeaderSections: true,
            canSeeBalance: true,
            showDetailDocuments: false,
            canHaveSelectedItemsActions: false,
            productDetailFeature: true,
            canHaveProductsKeyDocumentsView: false,
            canHaveQuickLinkLoginButton: true,
            secureUrlLoginRequired: false,
            amountFieldVisible: true,
            amoutDueFieldVisible: true
        }
    }
};
