/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: Required by API
export class ApiRequest {
    OrganizationId!: string;
    Email?: string;
    StoreName?: string;
    Password?: string;
    VerificationCode?: string;
    Name?: string;
    CompanyName?: string;
    PhoneNumber?: string;
}
