import { InjectionToken } from '@angular/core';
import { ContractStatus } from '../types/contract.type';
import { OrganizationVersionEnum } from '../enums/organization-version.enum';

/* eslint-disable @typescript-eslint/naming-convention */
export interface ConsumerPortalConfig {
    customerId?: string;
    organizationId: string;
    organizationType: string;
    sourceApp?: string;
    organizationName?: string;
    apiUrl?: string;
    uiUrl?: string;
    loginApiUrl?: string;
    bingMapApiUrl: string;
    bingMapApiKey: string;
    bingMapMapsLibUrl: string;
    googleMapsApiKey: string;
    contractId?: string;
    defaultSubDomain?: string; // Required for dev and local environments
    showLoader?: boolean;
    menu?: Partial<Menu>;
    header?: Partial<Header>;
    footer?: Partial<Footer>;
    consumerPortal?: {
        height: string;
        width: string;
    };
    styling?: {
        themeColors?: {
            primaryColor?: string;
            secondaryColor?: string;
        };
        accountSummary?: Styling;
        contracts?: Styling;
        itemsOut?: Styling;
        tracking?: Styling;
        invoices?: Styling;
        login?: Styling;
        menu?: Styling;
        header?: Styling;
        footer?: Styling;
    };
    Auth?: {
        accessToken?: string;
        AccessToken?: string; // Backward compatibility
        RefreshToken?: string;
        Email?: string;
        OrganizationType?: string;
    };
    enabledFeatures?: EnabledFeatures;
    initialInputConfigAccessToken?: string;
    routeTo?: {
        route: string; // 'Contracts' / 'Invoices'/ 'ItemsOut' / 'Tracking' / 'AccountSummary'
        routeParams?: string | routeParams; // if string then it would be 'ContractId' / 'InvoiceId'
    };
    AdImageURL?: {
        Banner1?: string;
        Banner1ExternalUrl?: string;
        Banner2?: string;
        Banner2ExternalUrl?: string;
        Banner3?: string;
        Banner3ExternalUrl?: string;
        Banner4?: string;
        Banner4ExternalUrl?: string;
    };
    licVersion?: OrganizationVersionEnum;
    storeName?: string;
    payMinimumAmountDueText?: string;
}

export interface routeParams {
    contractId?: string;
    contractCustomerId?: string;
    amount?: number;
    currencyCode?: string; // The 3-letter ISO 4217 uppercase currency code
}

export interface EnabledFeatures {
    essentials?: Features;
    syrinx?: Features;
    expert?: Features;
}

export interface Features {
    invoices?: boolean;
    logOff?: boolean;
    consumerLogin?: boolean;
    multipleCustomerSelection?: boolean;
    payFullAmount?: boolean;
    textSearchMultipleFilters?: boolean; // This Property enables search on the contract page if we enable it will search in other filters else not.
    pdfDownloadFeature?: boolean;
    contractGridJobColumnAvailable?: boolean;
    canSeeBalance?: boolean;
    checkPaymentLicense?: boolean;
    showDetailDocuments?: boolean;
    contractCancel?: boolean;
    contractEdit?: boolean;
    capturePaymentFeature?: boolean;
    showHeaderSections?: boolean;
    canCapturePayment?: ContractStatus[];
    canContractCancel?: ContractStatus[];
    canContractEdit?: ContractStatus[];
    canHaveSelectedItemsActions?: boolean;
    productDetailFeature?: boolean;
    canHaveProductsKeyDocumentsView?: boolean;
    canHaveQuickLinkLoginButton?: boolean;
    displayInspectionDocument?: boolean;
    displayServiceCertificate?: boolean;
    secureUrlLoginRequired?: boolean;
    callOffAbility?: boolean;
    trackingTab?: boolean;
    RequestServiceAbility?: boolean;
    MultiplePaymentAbility?: boolean;
    QuotesPaymentAbility?: boolean;
    amountFieldVisible?: boolean;
    amoutDueFieldVisible?: boolean;
    canSeeContractDetailSiteField?: boolean;
    canSeeContractDetailTotalPaid?: boolean;
    canSendFailedPaymentLogs?: boolean;
    isInvoicePdfDisplayed?: boolean;
}
export interface Styling {
    paragraph?: [];
    heading?: object[][];
    button?: object[][];
    buttonToggle?: object[][];
    table?: object[][];
    anchor?: object[];
    primaryColor?: [];
    secondaryColor?: [];
    listItem?: [];
}

export interface Menu {
    accountSummary?: boolean;
    contracts?: boolean;
    itemsOut?: boolean;
    tracking?: boolean;
    invoices?: boolean;
}

export interface Header {
    headerTitle: string;
    headerTitlePosition: string;
    logoUrl: string;
    logoPosition: string;
    logoWidth: string;
    faviconUrl: string;
    title: string;
    depotName?: string;
    exterConsumerPortalUrl?: string;
}

export interface Footer {
    footerText: string;
    position: string;
}

export const CONSUMER_PORTAL_APP_CONFIG = new InjectionToken<ConsumerPortalConfig>('Application config');
export const CPEnvironment = new InjectionToken('Consumer Portal Environment config');
