import { consumerPortalDefaults } from '../../input-config/consumer-portal-config';
import { ConsumerPortalAdminConfig } from '../models/consumer-portal-admin-config';
import { ConsumerPortalConfig, Styling } from '../models/consumer-portal-config';
import { Injectable } from '@angular/core';

type PorColors = Partial<{ [Key: string]: string }>;

@Injectable({
    providedIn: 'root'
})
export class SiteSettingService {
    setStyling(styling?: Styling, id?: string) {
        const paragraph = styling?.paragraph;
        const heading = styling?.heading;
        const button = styling?.button;
        const table = styling?.table;
        const anchor = styling?.anchor;
        const buttonToggle = styling?.buttonToggle;
        const listitem = styling?.listItem;

        // TODO: Replace use if document directly to Angular Renderer2
        const style = document.createElement('style');
        style.id = id ? id : 'por-id';

        if (listitem) {
            let listHtml = `.${id} .theme-list-item a, .${id} .theme-list-item span, .${id} .theme-list-item p{ `;
            for (const key in listitem) {
                if (Object.prototype.hasOwnProperty.call(listitem, key) && typeof listitem[key] === 'string') {
                    listHtml += `${key}: ${listitem[key]};`;
                }
            }
            listHtml += '}';
            style.innerHTML += listHtml;
        }

        if (buttonToggle) {
            /* Paragraph Style */
            const insideArray = [];
            let buttonToggleHtml = `.${id} .theme-button-toggle{ `;
            for (const key in buttonToggle) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(buttonToggle, key)) {
                    if (typeof buttonToggle[key] === 'string') {
                        buttonToggleHtml += `${key}: ${buttonToggle[key]};`;
                    }
                    if (typeof buttonToggle[key] === 'object' && key === 'inside') {
                        let insidehtml = `.${id} .theme-button-toggle span, .${id} .theme-button-toggle p{`;
                        for (const buttonKey in buttonToggle[key]) {
                            if (Object.prototype.hasOwnProperty.call(buttonToggle[key], buttonKey)) {
                                insidehtml += `${buttonKey}: ${buttonToggle[key][buttonKey]};`;
                            }
                        }
                        insidehtml += '}';
                        insideArray.push(insidehtml);
                    }
                }
            }
            buttonToggleHtml += '}';
            style.innerHTML += buttonToggleHtml + insideArray.concat(' ');
        }
        if (paragraph) {
            /* Paragraph Style */
            let paraHtml = `.${id} .theme-paragraph{ `;
            for (const key in paragraph) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(paragraph, key)) {
                    paraHtml += `${key}: ${paragraph[key]};`;
                }
            }
            paraHtml += '}';
            style.innerHTML += paraHtml;
        }
        if (heading) {
            /* Heading style */
            let headingHtml = `.${id} .theme-heading{ `;
            for (const key in heading) {
                if (Object.prototype.hasOwnProperty.call(heading, key)) {
                    headingHtml += `${key}: ${heading[key]};`;
                }
            }
            headingHtml += '}';
            style.innerHTML += headingHtml;
        }
        if (button) {
            /* Button style */
            const insideArray = [];
            let buttonHtml = `.${id} .theme-button{ `;
            for (const key in button) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(button, key)) {
                    if (typeof button[key] === 'string') {
                        buttonHtml += `${key}: ${button[key]};`;
                    }
                    if (typeof button[key] === 'object' && key === 'inside') {
                        let insidehtml = '.theme-button span, .theme-button p{';
                        for (const buttonKey in button[key]) {
                            if (Object.prototype.hasOwnProperty.call(button[key], buttonKey)) {
                                insidehtml += `${buttonKey}: ${button[key][buttonKey]};`;
                            }
                        }
                        insidehtml += '}';
                        insideArray.push(insidehtml);
                    }
                }
            }
            buttonHtml += '}';
            style.innerHTML += buttonHtml + insideArray.concat(' ');
        }

        if (anchor) {
            /* Button style */
            let anchorHtml = `.${id} .theme-anchor{ `;
            for (const key in anchor) {
                if (Object.prototype.hasOwnProperty.call(anchor, key)) {
                    anchorHtml += `${key}: ${anchor[key]};`;
                }
            }
            anchorHtml += '}';
            style.innerHTML += anchorHtml;
        }

        if (table) {
            let tableHtml = `.${id} .theme-table-row{ `;
            let tableHeaderhtml = `.${id} .theme-table-header{`;
            for (const key in table) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(table, key)) {
                    if (typeof table[key] === 'string') {
                        tableHtml += `${key}: ${table[key]};`;
                    }
                    if (key === 'header') {
                        for (const headerKey in table[key]) {
                            if (Object.prototype.hasOwnProperty.call(table[key], headerKey)) {
                                tableHeaderhtml += `${headerKey}: ${table[key][headerKey]};`;
                            }
                        }
                    }
                }
            }
            tableHtml += '}';
            tableHeaderhtml += '}';
            style.innerHTML += tableHtml + tableHeaderhtml;
        }
        /* Append in style tag */
        [style].map(el => {
            document.getElementsByTagName('head')[0].appendChild(el);
        });
    }

    setColors(colors: PorColors) {
        if (colors) {
            for (const key in colors) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(colors, key)) {
                    const value = colors[key];
                    document.documentElement.style.setProperty(`${key}`, `${value}`);
                }
            }
        }
    }

    setMenuStyling(styling?: Styling) {
        type Keys = keyof typeof styling;
        const style = document.createElement('style');
        style.id = 'por-menu-id';
        if (document.getElementById('por-menu-id')) {
            return;
        }

        if (styling) {
            let menuHtml = `.por-menu {`;
            for (const key in styling) {
                if (typeof styling[key as Keys] === 'string') {
                    menuHtml += `${key}: ${styling[key as Keys]};`;
                }
            }
            menuHtml += '}';
            style.innerHTML += menuHtml;
            document.getElementsByTagName('head')[0].appendChild(style);
        }
    }

    setHeaderFooterStyling(styling?: Styling, id?: string) {
        type Keys = keyof typeof styling;
        const style = document.createElement('style');
        style.id = id ? id : 'por-header-footer-id';
        if (document.getElementById(style.id)) {
            return;
        }

        if (styling) {
            let html = `.${id} {`;
            for (const key in styling) {
                if (typeof styling[key as Keys] === 'string') {
                    html += `${key}: ${styling[key as Keys]};`;
                }
            }
            html += '}';
            style.innerHTML += html;
            document.getElementsByTagName('head')[0].appendChild(style);
        }
    }

    setStylingForSite(config: ConsumerPortalConfig) {
        this.setStyling(config.styling?.accountSummary, 'por-ac-summary');
        this.setStyling(config.styling?.contracts, 'por-ac-contracts');
        this.setStyling(config.styling?.itemsOut, 'por-ac-itemsout');
        this.setStyling(config.styling?.tracking, 'por-ac-tracking');
        this.setStyling(config.styling?.invoices, 'por-ac-invoices');
        this.setHeaderFooterStyling(config.styling?.header, 'por-ac-header');
        this.setHeaderFooterStyling(config.styling?.footer, 'por-ac-footer');
        this.setMenuStyling(config.styling?.menu);
        let themeColors: PorColors = {
            /* eslint-disable @typescript-eslint/naming-convention */
            /**
             * Directly use in scss property
             */
            '--por-cp-primary': config.styling?.themeColors?.primaryColor || consumerPortalDefaults.styling?.themeColors?.primaryColor,
            '--por-cp-secondary': config.styling?.themeColors?.secondaryColor || consumerPortalDefaults.styling?.themeColors?.secondaryColor,
            '--por-color-primary': config.styling?.themeColors?.primaryColor || consumerPortalDefaults.styling?.themeColors?.primaryColor,
            '--por-color-secondary': config.styling?.themeColors?.secondaryColor || consumerPortalDefaults.styling?.themeColors?.secondaryColor
        };
        themeColors = {
            ...themeColors,
            '--por-cp-primary-text-color': this.invertColor(themeColors['--por-cp-primary']),
            '--por-cp-secondary-text-color': this.invertColor(themeColors['--por-cp-secondary'])
        };
        this.setColors(themeColors);
    }

    setStylingForAdminSite(config: ConsumerPortalAdminConfig) {
        let themeColors: PorColors = {
            '--por-cp-primary': config.styling?.themeColors?.primaryColor || consumerPortalDefaults.styling?.themeColors?.primaryColor,
            '--por-cp-secondary': config.styling?.themeColors?.secondaryColor || consumerPortalDefaults.styling?.themeColors?.secondaryColor,
            '--por-color-primary': config.styling?.themeColors?.primaryColor || consumerPortalDefaults.styling?.themeColors?.primaryColor,
            '--por-color-secondary': config.styling?.themeColors?.secondaryColor || consumerPortalDefaults.styling?.themeColors?.secondaryColor
        };
        themeColors = {
            ...themeColors,
            '--por-cp-primary-text-color': this.invertColor(themeColors['--por-cp-primary']),
            '--por-cp-secondary-text-color': this.invertColor(themeColors['--por-cp-secondary'])
        };
        this.setColors(themeColors);
    }

    /**
     * Ref: https://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
     * @param color
     * @returns string
     */
    invertColor(color: string | undefined): string {
        const primaryBlack = 'var(--por-cp-primary-black)';
        const white = 'var(--por-cp-white)';
        let textColor = white;
        if (color) {
            const brightness = this.calculateBrightness(color);
            const luminosity = this.calculateLuminosity(color);
            if (luminosity != null && brightness != null) {
                if (Math.abs(luminosity) - 120 < 75) {
                    if (brightness >= 130) {
                        textColor = primaryBlack;
                    }
                } else if (luminosity > 120) {
                    textColor = primaryBlack;
                }
            }
        }

        return textColor;
    }

    private calculateBrightness(color: string) {
        // Calculate brightness using the formula: 0.299*R + 0.587*G + 0.114*B
        const rgb = this.hexToRgb(color);
        return rgb != null ? 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2] : null;
    }

    private hexToRgb(hex: string): number[] | null {
        const match = hex.match(/\w\w/g);
        // Use optional chaining to safely access the result of match
        return match?.map(component => parseInt(component, 16)) ?? null;
    }

    private calculateLuminosity(color: string) {
        const rgb = this.hexToRgb(color);
        // L= 0.2126⋅R+0.7152⋅G+0.0722⋅B
        return rgb != null ? 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2] : null;
    }
}
