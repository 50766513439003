<ng-template porFeatureAvailability [featureToken]="featureToken">
    <div *ngIf="visible" class="text-align-center">
        <button
            porAddFeature
            class="btn-link button-loading-outer"
            [ngClass]="class"
            [disabled]="loading"
            [style.cursor]="loading ? 'default' : 'pointer'"
            [featureName]="featureName"
            (click)="buttonClick($event)">
            <img class="actions-image" src="{{ uiUrl }}/assets/images/{{ imgUrl }}" porAddFeature featureName="featureName" />
            <label porAddFeature featureName="featureName">{{ label }}</label>
            <div class="button-loading" *ngIf="loading">
                <img src="./assets/images/output-onlinegiftools.gif" alt="" />
            </div>
        </button>
        <div class="button-loading-text" *ngIf="loading">
            <span>{{ 'TryingToLoad' | translate }}</span>
        </div>
    </div>
</ng-template>
