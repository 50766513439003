import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, Inject, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerLoginConfig, CONSUMER_LOGIN_APP_CONFIG } from '../models/consumer-login-config';
import { SiteSettingService } from '../services/site-setting.service';
import { ConsumerLoginApiService } from '../services/consumer-login-api.service';
import { inputDefaults } from '../../input-config/input.defaults';
import { User, Verify, Auth, ApiRequest, UserLogin } from '../models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Messages } from '../messages/strings';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@por/shared/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { OrganizationVersionEnum } from '../enums/organization-version.enum';

@Component({
    selector: 'por-consumer-login',
    templateUrl: './consumer-login.component.html',
    styleUrls: ['./consumer-login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumerLoginComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Always include the non-null assertion operator for the input variable(s).
     * We assume they are always populated, tsc isn't smart enough to know that.
     */

    @Output()
    readonly loginSuccess: EventEmitter<Auth> = new EventEmitter<Auth>();

    inputs: ConsumerLoginConfig | undefined;
    @Input() config: string | undefined;

    verifyTargetTemplate = 'signin';
    apiUrl!: string;
    orgId!: string;

    tokenData!: object;
    selectedTemplate = 'signin';
    isDisableButton = false;
    showAlert = false;
    showLoader = false;
    @Input() alertMessage = '';
    @Input() quickLinkUrl = '';
    userEmail!: string;

    authData: BehaviorSubject<Auth> = new BehaviorSubject<Auth>({});
    private readonly demoBtnVisibilityCount = new BehaviorSubject<number>(0);
    demoBtnVisibilityCount$ = this.demoBtnVisibilityCount.asObservable();
    subscriptions: Subscription[] = [];

    constructor(
        private readonly siteSetting: SiteSettingService,
        private readonly apiService: ConsumerLoginApiService,
        @Inject(CONSUMER_LOGIN_APP_CONFIG) private readonly appConfig: ConsumerLoginConfig,
        private readonly translateService: TranslateService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.translateService.use(Language.EN_GB);
        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;
            this.inputs = {
                ...this.inputs,
                logoUrl: this.inputs.logoUrl,
                faviconUrl: this.inputs.faviconUrl || inputDefaults.faviconUrl
            };
        }

        if (this.inputs) this.siteSetting.setStylingForSite(this.inputs);
        await this.setData();

        if (this.alertMessage) {
            this.loadAlert(true, this.alertMessage);
        }
        const demoAppData = JSON.parse(localStorage.getItem('demoAppData') || '{}');
        if (!isEmpty(demoAppData)) {
            this.demoBtnVisibilityCount.next(5);
        }

        this.activeTemplate('signin');
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
        this.loadAlert(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.entries(changes).forEach(([key, value]) => {
            if (key === 'config' && value !== undefined) {
                /**
                 * Set input config which have been provided through when embeded index.html
                 */
                if (this.config && !isEmpty(this.config)) {
                    this.inputs = JSON.parse(this.config) as ConsumerLoginConfig;

                    // Set default values if not provided
                    this.inputs = {
                        ...this.inputs,
                        logoUrl: this.inputs.logoUrl || inputDefaults.logoUrl,
                        faviconUrl: this.inputs.faviconUrl || inputDefaults.faviconUrl
                    };
                    this.setData();
                    this.siteSetting.setStylingForSite(this.inputs);
                }
                return;
            }
        });
    }

    // Set default values if not provided
    async setData() {
        this.orgId = !isEmpty(this.inputs) && !isEmpty(this.inputs.organizationId) ? this.inputs.organizationId : inputDefaults.organizationId;
        this.apiUrl = (!isEmpty(this.inputs) && !isEmpty(this.inputs.loginApiUrl) ? this.inputs.loginApiUrl : inputDefaults.apiUrl) || '';
        this.apiService.setApiRoute(this.apiUrl);
    }

    activeTemplate(templateName: string) {
        this.selectedTemplate = templateName;
        this.loadLoader(false);

        this.loadAlert(false);
    }

    handleLoginData(loginData: User) {
        this.loadLoader(true);
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        const body: ApiRequest = {
            OrganizationId: this.orgId,
            Email: loginData.Email,
            Password: loginData.Password
        };
        this.subscriptions.push(
            this.apiService.getByPost<UserLogin>('auth', 'login', body).subscribe({
                next: data => {
                    if (!isEmpty(data) && !Array.isArray(data)) {
                        const response: UserLogin = data;
                        const authDataObj = {
                            OrganizationId: this.orgId,
                            Email: loginData.Email,
                            accessToken: response.accessToken,
                            RefreshToken: response.RefreshToken,
                            OrganizationType: response.OrganizationType
                        };
                        this.authData.next(authDataObj);
                        this.loginSuccess.emit(authDataObj);
                    }
                },
                error: e => {
                    /**
                     * If no status have been returned from api consider it has network error
                     */
                    if (e?.status === 0) {
                        this.loadAlert(true, this.translateService.instant('NetworkError'));
                    } else {
                        this.loadAlert(true, e.error?.message);
                    }
                    this.loadLoader(false);
                }
            })
        );
    }

    handleSignupData(signupData: User) {
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        this.loadLoader(true);
        this.verifyTargetTemplate = 'signin';
        const body: ApiRequest = {
            OrganizationId: this.orgId,
            Email: signupData.Email,
            Password: signupData.Password,
            StoreName: signupData.StoreName,
            Name: signupData.Name,
            CompanyName: signupData.CompanyName,
            PhoneNumber: signupData.PhoneNumber
        };
        this.subscriptions.push(
            this.apiService.add('auth', 'signup', body).subscribe({
                next: () => {
                    this.userEmail = body.Email as string;
                    this.activeTemplate('verify');

                    this.loadAlert(true, Messages.accountCreated);
                },
                error: e => {
                    this.loadAlert(true, e.error?.message);

                    this.loadLoader(false);
                }
            })
        );
    }

    handleVerificationData(verificationData: Verify) {
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        this.loadLoader(true);
        const body: ApiRequest = {
            OrganizationId: this.orgId,
            Email: verificationData.email,
            VerificationCode: verificationData.code
        };
        this.subscriptions.push(
            this.apiService.getByPost('auth', 'emails/verify', body).subscribe({
                next: () => {
                    this.userEmail = '';
                    this.activeTemplate(this.verifyTargetTemplate);

                    this.loadAlert(true, Messages.verificationCodeSuccess);
                },
                error: e => {
                    this.loadAlert(true, e.error?.message);

                    this.loadLoader(false);
                }
            })
        );
    }

    handleForgotPasswordData(forgotPasswordData: User) {
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        this.loadLoader(true);
        const body: ApiRequest = {
            OrganizationId: this.orgId,
            Email: forgotPasswordData.Email,
            StoreName: forgotPasswordData.StoreName
        };

        this.subscriptions.push(
            this.apiService.getByPost('auth', 'passwords/forget', body).subscribe({
                next: () => {
                    this.userEmail = body.Email as string;
                    this.loadAlert(true, Messages.passwordResetCode);
                    this.activeTemplate('change-password');
                },
                error: () => {
                    this.activeTemplate('change-password');
                }
            })
        );
    }

    handleChangePasswordData(changePasswordData: User) {
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        if (changePasswordData?.Email) {
            this.loadLoader(true);
            const body: ApiRequest = {
                OrganizationId: this.orgId,
                Email: changePasswordData.Email,
                VerificationCode: changePasswordData.code,
                Password: changePasswordData.Password
            };
            this.subscriptions.push(
                this.apiService.getByPost('auth', 'passwords/reset', body).subscribe({
                    next: () => {
                        this.userEmail = '';
                        this.activeTemplate('signin');

                        this.loadAlert(true, Messages.passwordChanged);
                    },
                    error: e => {
                        this.loadAlert(true, e.error?.message);

                        this.loadLoader(false);
                    }
                })
            );
        }
    }

    loadLoader(isShow: boolean): void {
        this.showLoader = isShow;
        this.isDisableButton = isShow;
        this.cdr.detectChanges();
    }

    loadAlert(isShow: boolean, message: string = ''): void {
        this.showAlert = isShow;
        this.alertMessage = message;
        this.cdr.detectChanges();
    }

    showDemoApp(): void {
        if (this.appConfig.production === true) {
            return;
        }
        let value: number = this.demoBtnVisibilityCount.value;
        this.demoBtnVisibilityCount.next(++value);
    }

    showAdvanceNotation(): boolean {
        return this.inputs?.license === OrganizationVersionEnum.Advanced;
    }
}
