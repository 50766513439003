import { createReducer, on } from '@ngrx/store';
import { initialState } from './app.state';
import {
    loadVersionRedirectionFailure,
    setActiveTab,
    setAdminLoading,
    setAuthenticated,
    setConsumerPortalState,
    setDeviceType,
    setIsAdmin,
    setLoading,
    setRedirected,
    setSubActiveTab,
    setVersionRedirection
} from './app.actions';
import { SubComponentsToLoad } from '../../../enums/components-to-load.enum';

export const appReducer = createReducer(
    initialState,
    on(setDeviceType, (state, { isMobile }) => ({ ...state, isMobile })),
    on(setLoading, (state, { isLoading }) => ({ ...state, isLoading })),
    on(setConsumerPortalState, (state, { consumerPortalState }) => ({ ...state, consumerPortalState })),
    on(setAuthenticated, (state, { isAuthenticated }) => ({ ...state, isAuthenticated })),
    on(setRedirected, (state, { isRedirected }) => ({ ...state, isRedirected })),
    on(setAdminLoading, (state, { isAdminLoaded }) => ({ ...state, isAdminLoading: isAdminLoaded })),
    on(setVersionRedirection, (state, { redirectionVersion }) => ({ ...state, versionRedirection: redirectionVersion })),
    on(loadVersionRedirectionFailure, (state, { error }) => ({ ...state, errorMessage: error })),
    on(setActiveTab, (state, { activeTab }) => ({
        ...state,
        activeTab: {
            parentTab: activeTab,
            childTab: SubComponentsToLoad.Default
        }
    })),
    on(setSubActiveTab, (state, { subActiveTab }) => ({
        ...state,
        activeTab: {
            parentTab: state.activeTab.parentTab,
            childTab: subActiveTab
        }
    })),
    on(setIsAdmin, (state, { isAdmin }) => ({ ...state, isAdmin }))
);
