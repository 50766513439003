import { Injectable } from '@angular/core';
import { CrossAppEventService, CrossAppEventDetail, CrossAppEventData, OriginAppName } from '@por/shared/ui/cross-app';
import { IDictionary } from '../shared/models/dictionary.interface';

@Injectable({
	providedIn: 'root',
})
export class AppEventService extends CrossAppEventService {
	appName = 'Consumer-Portal';

	// There could be multiple apps that dispatch events, so we can extend this to listen to multiple apps
	originAppName = OriginAppName[OriginAppName.Essentials]; 
	private actions: IDictionary<(x: CrossAppEventData | undefined) => void> = {};

	ingestEvent(event: CustomEvent): void
  	{
    	const detail: CrossAppEventDetail = event.detail;
		if (detail && detail.originAppName === this.originAppName && this.actions[detail.actionName])
		{
			this.actions[detail.actionName](detail.data);
		}
  	}

	override initialize() {
		super.initialize(this.ingestEvent.bind(this) as EventListener);
	}

	override dispatch<CrossAppEventDetail>(detail: CrossAppEventDetail)
	{
		const message = {
			...detail,
			originAppName: this.appName,
		}
		super.dispatch(message);
	}

	registerActions(actions: IDictionary<(x: CrossAppEventData | undefined) => void>) {
		this.actions = {
			...this.actions,
			...actions,
		};
  	}
}