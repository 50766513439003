import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DEMO_ENV_CONFIG } from '../../app-config';
import { isEmpty } from 'lodash';
import { QuickLinkResponse } from '../models/quick-link-response';
import { ConsumerPortalEnviromentConfig } from '../models/env-model';

@Injectable({
    providedIn: 'root'
})
export class QuickLinkService {
    constructor(private readonly http: HttpClient, @Inject(DEMO_ENV_CONFIG) private readonly appConfig: ConsumerPortalEnviromentConfig) {}

    private readonly baseUrl = this.appConfig.apiUrl;

    getQuickLinkUrl({ contractId, apiKey }: { contractId: string; apiKey: string }): Observable<QuickLinkResponse | unknown> {
        return this.http.post(
            this.generateApiUrl('/apikey/quicklink'),
            {
                /* eslint-disable @typescript-eslint/naming-convention  */
                /**
                 * Note camelCase : DB Model
                 */
                ContractId: contractId
            },
            {
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    generateApiUrl(url: string) {
        if (isEmpty(url)) {
            return url;
        }

        return this.baseUrl + url;
    }
}
